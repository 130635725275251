﻿import React, { CSSProperties } from 'react';
import { TtdField } from 'components/inputs/TtdInputField';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import withReadOnly from 'components/hocs/withReadOnly';
import TtdInputFieldArray from 'components/inputs/TtdInputFieldArray';
import getInputId from 'util/getInputId';
import './RowDeleteButtonInput.scss';

export enum DeleteMode {
    all = 'all',
    individual = 'individual',
    new = 'new',
}

type RowDeleteButtonInputFieldsProps = Omit<
    RowDeleteButtonInputProps,
    'style'
> & {
    fields: any;
    meta: any;
};

class RowDeleteButtonInputFields extends React.Component<
    RowDeleteButtonInputFieldsProps
> {
    static displayName = 'RowDeleteButtonInputFieldArray';

    remove = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Prevent the "row click" event from getting fired.  This should prevent the "selection" action for row for the EnhancedDataTable.
        event.stopPropagation();
        event.preventDefault();

        const {
            deleteMode,
            fields,
            meta,
            postRemoveHandler,
            preRemoveHandler,
            removeNewElements,
            overrideIndex,
            index,
        } = this.props;

        const indexToUse = overrideIndex
            ? overrideIndex(meta.form, fields.name, index)
            : index;
        if (preRemoveHandler) {
            preRemoveHandler(meta.form, fields.name, indexToUse);
        }
        if (deleteMode === DeleteMode.all) {
            fields.removeAll();
        } else if (deleteMode === DeleteMode.new) {
            removeNewElements(meta.form, fields.name);
        } else {
            if (indexToUse >= 0) {
                fields.remove(indexToUse);
            }
        }
        if (postRemoveHandler) {
            postRemoveHandler(meta.form, fields.name, indexToUse);
        }
    };

    render() {
        const { isReadOnly, className, id } = this.props;

        return (
            <Glyph
                id={getInputId(RowDeleteButtonInput, {
                    id,
                })}
                name='delete-small'
                className={`row-delete-button ${
                    isReadOnly ? 'row-delete-button--readonly' : ''
                } ${className}`}
                onClick={isReadOnly ? undefined : this.remove}
            />
        );
    }
}

export interface RowDeleteButtonInputProps {
    id: string;
    field: TtdField;
    style?: CSSProperties;
    className?: string;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly: boolean;
    // Remove mode, e.g. remove all items, new items or a single item
    deleteMode: DeleteMode;
    // index corresponding to the item that you want to remove
    index?: number;
    // optional post remove processor
    postRemoveHandler?(form: string, fieldName: string, index: number): void;
    // optional pre remove processor
    preRemoveHandler?(form: string, fieldName: string, index: number): void;
    // Function that removes all new elements from the field array
    removeNewElements(form: string, fieldName: string): void;
    // Optional to override the index being removed
    overrideIndex?(formName: string, field: string, index: number): number;
}

class RowDeleteButtonInput extends React.Component<
    RowDeleteButtonInputProps,
    {}
> {
    static displayName = 'RowDeleteButtonInput';

    render() {
        const {
            className,
            style,
            id,
            field,
            isReadOnly,
            deleteMode,
            postRemoveHandler,
            preRemoveHandler,
            removeNewElements,
            overrideIndex,
            index,
        } = this.props;

        return (
            <div style={style}>
                <TtdInputFieldArray
                    id={id}
                    className={className}
                    isReadOnly={isReadOnly}
                    postRemoveHandler={postRemoveHandler}
                    preRemoveHandler={preRemoveHandler}
                    field={field}
                    deleteMode={deleteMode}
                    index={index}
                    removeNewElements={removeNewElements}
                    overrideIndex={overrideIndex}
                    component={RowDeleteButtonInputFields}
                />
            </div>
        );
    }
}

export default withReadOnly(RowDeleteButtonInput);
