import * as React from 'react';
import SelectListControl from '../SelectListControl';
import { ITime, formatDateTime } from 'util/DateTimeUtils';
import withReadOnly, {
    ReadOnlyComponentProps,
} from 'components/hocs/withReadOnly';
import keycode from 'keycode';
import SwitchControl from '../SwitchControl';
import './TimeControl.scss';

import moment from 'moment';
export interface ITimeControlProps extends ReadOnlyComponentProps {
    id: string;
    value: ITime;
    onChange(newTime: ITime): void;
    className?: string;
    style?: React.CSSProperties;
    isEnabled?: boolean;
    is24HourTime?: boolean;
}

interface ITimeControlState {
    hourStream: number[];
    minuteStream: number[];
    is24HourTime: boolean;
}

class TimeControl extends React.Component<
    ITimeControlProps,
    ITimeControlState
> {
    private minuteLabel: HTMLAnchorElement;
    private hourLabel: HTMLAnchorElement;
    private amPmSwitch: React.RefObject<any> = React.createRef();

    constructor(props: ITimeControlProps) {
        super(props);
        this.state = {
            hourStream: [],
            minuteStream: [],
            is24HourTime: props.is24HourTime || false,
        };
    }
    static upperAmPm = [
        { id: 'AM', name: 'AM' },
        { id: 'PM', name: 'PM' },
    ];
    static lowerAmPm = [
        { id: 'am', name: 'am' },
        { id: 'pm', name: 'pm' },
    ];
    static emptyAmPm = [{ id: 'pm', name: '--' }];
    static available12Hours = [...Array(12).keys()].map((k) => k + 1);
    static available24Hours = [...Array(24).keys()].map((k) => k + 1);
    static availableMinutes = [...Array(60).keys()];
    static defaultProps: Partial<ITimeControlProps> = {
        isEnabled: true,
        className: '',
    };

    static getAvailableHours(is24HourTimeState: boolean) {
        return is24HourTimeState
            ? TimeControl.available24Hours
            : TimeControl.available12Hours;
    }
    /**
     * Determines whether we are using 24 hour time.
     *
     * If passed in prop has a value it will be used instead.
     */
    static is24HourTime(is24HourTimeProp) {
        if (is24HourTimeProp !== undefined) {
            return is24HourTimeProp;
        }

        // The current local uses 24 hour time if the long date format
        // doesn't have an 'a' or 'A'
        const longDateFormat = moment().localeData().longDateFormat('LT');
        return (
            longDateFormat.indexOf('a') === -1 &&
            longDateFormat.indexOf('A') === -1
        );
    }

    static getDerivedStateFromProps(
        props: ITimeControlProps,
        prevState: ITimeControlState
    ) {
        const is24HourTime = TimeControl.is24HourTime(props.is24HourTime);
        if (prevState.is24HourTime !== is24HourTime) {
            return {
                is24HourTime,
            } as Partial<ITimeControlState>;
        }
        return null;
    }

    static getAmPm = (props: ITimeControlProps) => {
        if (!props.value || props.value.hour === undefined) {
            return TimeControl.emptyAmPm;
        }
        if (moment().localeData().longDateFormat('LT').indexOf('A') === -1) {
            return TimeControl.lowerAmPm;
        } else {
            return TimeControl.upperAmPm;
        }
    };

    onAmPmKeyPress = (event: KeyboardEvent) => {
        const key = keycode(event);
        switch (key) {
            case 'left':
                this.minuteLabel.focus();
                event.preventDefault();
                event.stopImmediatePropagation();
                break;
            case 'p':
                this.onAmPmChange('pm');
                event.preventDefault();
                event.stopImmediatePropagation();
                break;
            case 'a':
                this.onAmPmChange('am');
                event.preventDefault();
                event.stopImmediatePropagation();
                break;
        }
    };

    onHourChange = (stringHour: string) => {
        const hour = parseInt(stringHour, 10);
        const { value, onChange } = this.props;
        const { is24HourTime } = this.state;
        let newHour = is24HourTime || value.hour < 12 ? hour : hour + 12;
        if (newHour === 12 && value.hour < 12 /* dealing with AM */) {
            newHour = 0;
        }
        onChange({ ...value, minute: value.minute || 0, hour: newHour });
    };

    onMinuteChange = (stringMinute: string) => {
        const minute = parseInt(stringMinute, 10);
        const { value, onChange } = this.props;
        onChange({ ...value, minute, hour: value.hour || 0 });
    };

    componentDidMount() {
        document.addEventListener('keydown', this.keypress);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keypress);
    }

    keypress = (event: KeyboardEvent) => {
        if (
            this.amPmSwitch.current &&
            event.target === this.amPmSwitch.current.wrapper
        ) {
            this.onAmPmKeyPress(event);
        }
    };

    bindDropDowns = (node: HTMLDivElement) => {
        if (node) {
            const anchors = node.querySelectorAll('a');
            this.hourLabel = anchors[0];
            this.minuteLabel = anchors[1];
        } else {
            this.hourLabel = undefined;
            this.minuteLabel = undefined;
        }
    };

    onHourKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.ctrlKey) {
            return;
        }
        const hourStream = [...this.state.hourStream];
        const { value = {} as ITime, onChange } = this.props;
        const { is24HourTime } = this.state;
        const key = keycode(event as any);
        const parsedKey = parseInt(key, 10);
        // typing into the label
        if (!isNaN(parsedKey)) {
            //let's try and make a selection
            const lastKey = hourStream[hourStream.length - 1];

            //combo scenario (lastKey was 0, 1( or 2 for 24HourTimeFormat))
            if (lastKey !== undefined) {
                this.setState({
                    // if the last key was zero so we'll blindly move to minutes,
                    // or the last key needs to be captured
                    hourStream: lastKey === 0 ? [] : [lastKey, parsedKey],
                    minuteStream: [],
                });
                if (lastKey === 0) {
                    // user entered zero last and is now trying to set a single digit value for hour
                    onChange({
                        ...value,
                        hour:
                            is24HourTime || value.hour < 12
                                ? parsedKey
                                : parsedKey + 12,
                    });
                    this.goToMinutes();
                    return;
                } else if (
                    (lastKey === 1 && (parsedKey < 3 || is24HourTime)) ||
                    (is24HourTime && lastKey === 2 && parsedKey < 4)
                ) {
                    // double digit 10,11,12
                    // 24 hour format 10-23
                    onChange({
                        ...value,
                        hour: 10 * lastKey + parsedKey,
                        minute: value.minute || 0,
                    });
                    this.goToMinutes();
                    return;
                }

                // any other combo would result in a blur and move to the minute field and redirect the input
                this.goToMinutes(parsedKey);
            } else {
                onChange({
                    ...value,
                    hour:
                        !is24HourTime && parsedKey === 0
                            ? value.hour > 11
                                ? 13
                                : 1
                            : !is24HourTime && value.hour > 11
                            ? parsedKey + 12
                            : parsedKey,
                    minute: value.minute,
                });
                if (
                    (!is24HourTime && parsedKey > 1) ||
                    (is24HourTime && parsedKey > 2)
                ) {
                    this.goToMinutes();
                    return;
                } else {
                    this.setState({
                        hourStream: [parsedKey],
                        minuteStream: [],
                    });
                    return;
                }
            }
        }

        switch (key) {
            case 'tab':
                this.goToMinutes();
                return;
            case 'backspace':
                this.clearStreams();
                onChange({
                    ...value,
                    hour: 0,
                });
                return;
            case 'down': {
                let downValue: number;
                if (value.hour === undefined || value.hour === null) {
                    downValue = 0;
                } else if (value.hour - 1 < 0) {
                    downValue = 11;
                } else if (value.hour === 12) {
                    downValue = 23;
                } else {
                    downValue = value.hour - 1;
                }

                onChange({
                    ...value,
                    minute: value.minute || 0,
                    hour: downValue,
                });
                this.setState({
                    minuteStream: [],
                    hourStream: [],
                });
                break;
            }
            case 'up': {
                let upValue: number;
                if (value.hour === 23) {
                    upValue = 12;
                } else if (value.hour === 11) {
                    upValue = 0;
                } else {
                    upValue = (value.hour || 0) + 1;
                }
                onChange({
                    ...value,
                    hour: upValue,
                });
                this.clearStreams();
                break;
            }
            case 'right':
                this.goToMinutes();
                break;
            case 'f12': // dev tools :)
                break;
            default:
                if (isNaN(parsedKey)) {
                    event.preventDefault();
                }
                break;
        }
    };

    clearStreams() {
        this.setState({
            minuteStream: [],
            hourStream: [],
        });
    }

    goToAmPm() {
        if (this.amPmSwitch.current) {
            this.clearStreams();
            this.amPmSwitch.current.focus();
        }
    }

    goToHours() {
        this.clearStreams();
        this.hourLabel.focus();
    }

    goToMinutes(minute?: number) {
        if (minute !== undefined) {
            const { value, onChange } = this.props;
            this.setState({
                minuteStream: [minute],
            });
            onChange({
                ...value,
                minute,
            });
        } else {
            this.clearStreams();
        }
        this.minuteLabel.focus();
    }

    onMinuteKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { onChange, value = {} as ITime } = this.props;
        const key = keycode(event as any);
        const parsedKey = parseInt(key, 10);
        const lastKey = this.state.minuteStream[
            this.state.minuteStream.length - 1
        ];
        switch (key) {
            case 'esc':
                event.preventDefault();
                return;
            case 'left':
                this.goToHours();
                return;
            case 'right':
                this.goToAmPm();
                return;
            case 'backspace':
                onChange({ ...value, minute: 0 });
                this.goToHours();
                break;
            case 'up':
                onChange({
                    ...value,
                    minute: value.minute === 59 ? 0 : value.minute + 1 || 0,
                    hour: value.hour !== undefined ? value.hour : 0,
                });
                break;
            case 'down':
                onChange({
                    ...value,
                    minute: value.minute === 0 ? 59 : value.minute - 1 || 0,
                    hour: value.hour !== undefined ? value.hour : 0,
                });
                break;
            default:
                if (!isNaN(parsedKey)) {
                    if (this.state.is24HourTime) {
                        this.setState({
                            minuteStream: [parsedKey],
                        });
                    }
                    if (lastKey < 6) {
                        //we'll append
                        onChange({
                            ...value,
                            minute: lastKey * 10 + parsedKey,
                            hour: value.hour !== undefined ? value.hour : 0,
                        });
                        this.goToAmPm();
                    } else {
                        // s
                        onChange({
                            ...value,
                            minute: parsedKey,
                            hour: value.hour !== undefined ? value.hour : 0,
                        });
                        if (parsedKey > 5) {
                            this.goToAmPm();
                        } else {
                            // this is the only condition where we would want to keep track of last key stroke
                            this.setState({
                                minuteStream: [parsedKey],
                            });
                        }
                    }
                    break;
                }
        }
    };

    onAmPmChange = (amPm: 'am' | 'pm' | 'AM' | 'PM') => {
        const { onChange, value } = this.props;
        if (!value) {
            return;
        }
        let newHour = value.hour;
        if (amPm.toLowerCase() === 'am' && value.hour > 11) {
            newHour = value.hour - 12;
        } else if (amPm.toLowerCase() === 'pm' && value.hour < 12) {
            newHour = value.hour + 12;
        }

        onChange({ ...value, minute: value.minute || 0, hour: newHour });
    };

    mapNumber(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    render() {
        const {
            id,
            value = {} as ITime,
            className,
            style,
            isEnabled,
            isReadOnly,
        } = this.props;
        const { is24HourTime } = this.state;
        if (isReadOnly) {
            return (
                <span className='time-control time-control--readonly'>
                    {formatDateTime(value, 'hh:mm A')}
                </span>
            );
        }
        const { hour, minute } = value;
        const amPm = TimeControl.getAmPm(this.props);
        return (
            <div
                id={id}
                className={`time-control ${className} ${
                    isEnabled ? '' : 'time-control--disabled'
                }`}
                style={style}
            >
                {/* typescript doesn't like the `disabled` attribute here but we need it for input styling
                ¯\_(ツ)_/¯ */}
                <div
                    className='time-control__input-wrapper'
                    ref={this.bindDropDowns}
                    // @ts-ignore
                    disabled={!isEnabled}
                >
                    <SelectListControl
                        isEnabled={isEnabled}
                        className={`${
                            hour === undefined
                                ? 'time-control__drop-down--empty'
                                : ''
                        } time-control__drop-down time-control__hour`}
                        id={`${id}-hour`}
                        availableItems={TimeControl.getAvailableHours(
                            is24HourTime
                        )}
                        onChange={this.onHourChange}
                        value={
                            hour !== undefined && hour !== null
                                ? this.mapNumber(
                                      !is24HourTime && hour === 0
                                          ? 12
                                          : is24HourTime
                                          ? hour
                                          : hour % 12 ||
                                            (hour !== undefined
                                                ? 12
                                                : undefined)
                                  )
                                : undefined
                        }
                        customItemMapper={this.mapNumber}
                        allowEmptySelection={false}
                        isInline
                        suppressGlyph
                        captureAllKeyPress
                        emptyText='--'
                        onKeyDown={this.onHourKeyDown}
                        theme='time-control'
                    />
                    <div className='time-control__colon'>:</div>
                    <SelectListControl
                        isEnabled={isEnabled}
                        className={`${
                            minute === undefined
                                ? 'time-control__drop-down--empty'
                                : ''
                        } time-control__drop-down time-control__minute`}
                        id={`${id}-minute`}
                        availableItems={TimeControl.availableMinutes}
                        onChange={this.onMinuteChange}
                        value={this.mapNumber(minute)}
                        customItemMapper={this.mapNumber}
                        suppressGlyph
                        isSearchEnabled
                        allowEmptySelection={false}
                        emptyText='--'
                        isInline
                        captureAllKeyPress
                        onKeyDown={this.onMinuteKeyDown}
                        theme='time-control'
                    />
                </div>
                {!is24HourTime && (
                    <SwitchControl
                        isEnabled={isEnabled}
                        ref={this.amPmSwitch}
                        availableItems={amPm}
                        value={hour > 11 ? amPm[1].id : amPm[0].id}
                        onChange={this.onAmPmChange}
                    />
                )}
            </div>
        );
    }
}

export default withReadOnly(TimeControl);
