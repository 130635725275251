import React from 'react';
import BasicDialog, { DIALOG_SIZE } from '../BasicDialog';
import DialogHeader from '../DialogHeader';
import DialogActionBar, { GLYPH_POSITIONS } from '../DialogActionBar';
import DialogStatusPage from '../DialogStatusPage';
import StepStatus from './StepStatus';
import ttdSteppedTaskDialogPage from './ttdSteppedTaskDialogPage';
import {
    DIALOG_STATUS,
    IDialogStatusDetails,
    DialogConfig,
    SuccessData,
    ErrorData,
} from '../DialogActions';
import { BUTTON_TYPE } from '../../Button';
import Strings from './SteppedTaskDialog.strings.json';
import { Localization } from '../../../../util/LocalizationService';

import './SteppedTaskDialog.scss';
import { ConnectedComponent } from 'react-redux';
import withRouter, { RouteComponentProps } from 'components/hocs/withRouter';
import { Prompt } from 'react-router-dom';

export interface SteppedTaskDialogStep {
    // The name of the step, used in a tooltip when hovering over the step
    // in the step status component.
    name: string;

    // The component that implements the particular step in the dialog.  It is
    // a React component (a class that extends React.Component) whose render()
    // method renders some UI, usually containing various input controls.  It
    // should not contain a form element, as the SteppedTaskDialog will wrap the
    // pageComponent with the appropriate form element and handle its submission.
    pageComponent: React.ComponentClass | ConnectedComponent<any, any>;

    stepAction?: {
        // The function that is called when this particular step of the dialog is completed
        // (whether it's the last step or somewhere in the middle).
        // This function usually calls an action creator that saves data to the server.
        // receives 2 parameters:
        // 1. values from redux-form.
        // 2. dialogConfig which contains properties used to manage the dialog's state, including success and error callbacks.
        //      See getDialogConfig() for what is included in the the config object
        onSubmit: (values: any, dialogConfig: DialogConfig) => void;

        // The name of the action, used as the label of the action button on the dialog.
        // If this is not specified, the default action name will be used.
        name?: string;

        // A label for the action, used as the label of the action button on the dialog
        // if name is not specified. If this and name are not specified, the default action
        // name will be used.
        label?: JSX.Element;

        // An optional custom message that is shown under the success icon and "Success!"
        // title when the action was successful.  This is often useful in conjunction with
        // the successAction to tell the user they can either close the dialog or
        // do the success action. This is only displayed when the dialog status is set to SUCCESS
        // (usually at the very end of the dialog when all steps have been completed)
        successMessage?: string | JSX.Element;

        // An optional custom message that is shown under the loading indicator while submitting.
        // This is only displayed when the dialog status is SUBMITTING, no matter what step we're on.
        // `Message` is displayed as the dialog's header. `Subtext` is smaller text beneath it.
        submittingSubtext?: string;
        submittingMessage?: string;

        // We can optionally display a count-up timer during long submissions (see MEGA-2650
        // for background and screenshots). This appears beneath the `submitting` message+subtext.
        showTimerWhileSubmitting?: boolean;
    };
}

// IMPORTANT: If you add a new prop to SteppedTaskDialog, you may need to add it to the list defined below in the render function.
export interface SteppedTaskDialogProps {
    // All dialogs require a globally unique ID.  It is the parameter to pass to
    // dialog actions (showDialog, hideDialog, etc.).
    dialogId: string;

    // The title of the dialog shown in the header bar.
    title: string;

    // The optional subtitle of the dialog shown in the header bar.
    subtitle?: string;

    // The size of the dialog.  We only allow medium or large stepped dialogs.
    size: DIALOG_SIZE.MEDIUM | DIALOG_SIZE.LARGE | DIALOG_SIZE.EXTRA_LARGE;

    // Optional property for dialogs of size SMALL or MEDIUM to define the maximum expected height of the dialog.
    // This is used to determine the vertical positioning of dialogs that don't expand to the full window height,
    // and is useful in situations where we don't know the exact height of the dialog content when it loads, likely
    // because the dialog content is loading asynchronously
    maxDialogHeight?: number;

    // The height of the dialog in pixels.
    height?: number;

    // An optional className to add to the root element in this component.
    className?: string;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'first-run-wizard' is a theme.
    theme?: string;

    // The configuration of the steps.  There should be more than one step
    // in this array -- if you find yourself adding only a single step here,
    // use a TaskDialog instead.
    steps: SteppedTaskDialogStep[];

    // The UiModel that is the focus of this dialog.  It's the model that is
    // validated when submitting each page and performing the final action.
    uiModel: any;

    // The optional initial model data to use when initializing this dialog.  You
    // must either provide this model (the synchronous method) OR dispatch an action
    // on an AspNetController using the onOpen prop.
    // Your action creator must take in the dialogConfig passed back from this dialog in the onOpen function
    // and pass it into the constructor of the AspNetController. If you do this, then standard dialog state changes
    // and errors will be handled automatically and the initial model fetched from the server will also be passed
    // back to the dialog automatically.
    // The synchronous method (providing the initialModel) might be used when
    // implementing a dialog that uses an empty model or a simple model (like a
    // creation dialog), while the asynchronous method might be used when implementing a
    // details dialog where the values for the model need to be fetched from the server.
    initialModel?: any;

    // Optional functions that implement custom validation and warnings for the
    // uiModel.  If provided, they are called whenever a step of the dialog is
    // attempted to be submitted.  The functions are called with the validation
    // service and the model as parameters.
    validate?: any;
    warn?: any;

    // An optional function, called when the actions for a particular step have been
    // created.  This allows the consumer to customize the actions for a
    // a particular step (e.g., adding another button).
    //
    // The function is called with these parameters:
    // 1. actions (the actions calculated by default for the step)
    // 2. currentStepIndex (the index of the current step)
    // 3. tryCancel (function used as an onCLick for cancel-type actions)
    // 4. submitForm (function used as an onClick for form-submission actions)
    //
    // The return value should be an array of DialogActionBar actions, see
    // DialogActionBar.propTypes: {
    //      label,
    //      onClick,
    //      type,
    //      isSubmission,
    //      isEnabled,
    //      isFlat,
    //      glyph,
    //      glyphPosition
    // }
    getStepActions?: () => any;

    // An optional object that describes an additional action to display on the
    // "success" page of the dialog (e.g., "Go to Ad Group" on the success page of the
    // create ad group wizard).
    successAction?: {
        label: JSX.Element;
        onClick: (dialogId: string, action: any) => void;
    };

    // An optional function, called when the user cancels the dialog (either via
    // the 'cancel' button or closing the dialog via the X or hitting the escape key).
    // If this function is provided, the dialog consumer is in charge of actually closing the dialog
    // if it's appropriate, confirming the dirty nature of the dialog, etc.
    // If this function is not provided, the dialog simply closes when the user cancels the dialog.
    onCancel?: (dialogId: string) => void;

    // An optional function, called when the dialog opens.  If initializing the dialog
    // with the asynchronous method, use this function to dispatch an action on an
    // AspNetController (see initalModel for more information)
    onOpen?: (config: any) => void;

    // An optional function, called when the dialog closes.
    onClose?: (
        dialogId: string,
        { currentStepIndex: number, completedSteps: any }
    ) => void;

    /** A callback function that will be called when the form is initialized */
    onFormInitialized?: (initialMode: any) => void;

    // show confirmation when user cancel's task dialog (default true)
    // this prop is ignored if a user passes in an {onCancel} prop function
    // if set true true and this form truly is "dirty" an additional confirmation dialog will render prior to closing this instance of dialog.
    showConfirmOnDirtyCancel?: boolean;

    // If the final page of the wizard has a "readonly" step at the end
    //(i.e., you're reviewing changes), this allows for that and a simple close button
    finalStepIsReview?: boolean;

    // Prevents the dialog from closing automatically after the success message, even if a successAction is not defined.
    // If a successAction is not defined and this prop is not true, the DialogActionBar component will close the dialog automatically
    // when the status is SUBMIT_SUCCESS
    disableAutoCloseOnSuccess?: boolean;

    // Optional function that is passed to the Wizard Page that allows the page to dynamically change the steps of the wizard
    // This function is passed to each page component and allows the consuming component to pass in new steps to SteppedTaskDialog
    // Implementation of how to do this is left up to consumer. A typical example is for the consuming component to store the steps in state (and state.steps is passed as the steps prop above),
    //  and then use modifySteps to change the steps in state.
    modifySteps?: (newSteps: any) => void;

    // Indicate whether to hide the number bar at the bottom when there is only 1 step (default false - the number bar always show)
    hideStepStatusBarWhenThereIsOnlyOneStep?: boolean;

    // Boolean flag to indicate whether to warn users on route change if isDirty == true
    shouldWarnUserOnRouteChange?: boolean;

    // From connected container
    dialogStatus: DIALOG_STATUS;
    initialValues?: any;
    currentStepIndex: number;
    completedSteps?: any;
    setDialogStatusDetails: (details: any) => void;
    hideDialog: (dialogId: string) => void;
    completeStep: (dialogId: string, index: number) => void;
    setCurrentStep: (dialogId: string, index: number) => void;
    submitForm: (formId: string) => void;
    destroyForm: (formId: string) => void;
    initializeDialogConfirmation: (formId: string, dialogId: string) => void;
    currentConfirmationFormName?: string;
    isDirty: boolean;
    formId: string;
    isConfirmationPending?: boolean;
}

interface SteppedTaskDialogState {
    isConfirmationPending: boolean;
    initialSteps: any;
}

type SteppedTaskDialogComponentProps = SteppedTaskDialogProps &
    RouteComponentProps<any>;

// A SteppedTaskDialog is a multi-stepped task dialog (a wizard), meant
// to enable the user to perform a task with a clean end step (usually
// saving some data).
// See TaskDialog for the single-stepped equivalent.
class SteppedTaskDialogComponent extends React.Component<
    SteppedTaskDialogComponentProps,
    SteppedTaskDialogState
> {
    static defaultProps = {
        size: DIALOG_SIZE.MEDIUM as const,
        className: '',
        theme: '',
        showConfirmOnDirtyCancel: true,
        finalStepIsReview: false,
        shouldWarnUserOnRouteChange: false,
    };

    currentPage: any;
    contentElem: any;
    disposeRouteLeaveHook: () => any;

    constructor(props) {
        super(props);

        this.state = {
            isConfirmationPending: false,
            // This component exposes a way for children to dynamically modify the steps, so it must track the initial and current step configuration
            initialSteps: this.props.steps,
        };

        this.wrappedOnCancel = this.wrappedOnCancel.bind(this);
        this.wrappedOnSubmit = this.wrappedOnSubmit.bind(this);
        this.wrappedOnOpen = this.wrappedOnOpen.bind(this);
        this.wrappedOnClose = this.wrappedOnClose.bind(this);

        this.submitForm = this.submitForm.bind(this);

        this.successCallback = this.successCallback.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.dialogProgressCallback = this.dialogProgressCallback.bind(this);

        this.buildDialogPage(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const currentPendingState = this.state.isConfirmationPending;

        this.setState((prevState, nextProps) => ({
            isConfirmationPending: nextProps.isConfirmationPending,
        }));

        if (
            currentPendingState &&
            !nextProps.isConfirmationPending &&
            nextProps.currentConfirmationFormName
        ) {
            this.props.hideDialog(this.props.dialogId);
        }

        if (
            this.props.steps !== nextProps.steps ||
            this.props.currentStepIndex !== nextProps.currentStepIndex ||
            this.props.uiModel !== nextProps.uiModel ||
            this.props.validate !== nextProps.validate ||
            this.props.warn !== nextProps.warn
        ) {
            this.buildDialogPage(nextProps);
        }

        // Ensure that when switching steps (forward or backward), we scroll the new page to the
        // top (since it's weird and annoying to have scrolled down to the bottom of step 1,
        // click next, and not be at the top of page 2).
        if (
            this.props.currentStepIndex !== nextProps.currentStepIndex &&
            this.contentElem
        ) {
            this.contentElem.scrollTop = 0;
        }
    }

    // Dialog Configs
    get currentStep() {
        return this.props.steps[this.props.currentStepIndex];
    }

    get successMessage() {
        return (
            this.currentStep.stepAction &&
            this.currentStep.stepAction.successMessage
        );
    }

    get successConfig(): IDialogStatusDetails {
        return {
            dialogStatus: DIALOG_STATUS.SUBMIT_SUCCESS,
            dialogId: this.props.dialogId,
            messageDetail:
                typeof this.successMessage === 'string'
                    ? this.successMessage
                    : undefined,
            statusText: Localization.getString(Strings.successText),
        };
    }

    getMessageDetail = (dialogStatus: DIALOG_STATUS) => {
        return dialogStatus === DIALOG_STATUS.SUBMIT_SUCCESS
            ? this.successMessage
            : undefined;
    };

    errorConfig(
        dialogErrorStatus,
        statusCode,
        statusText,
        messageGeneral,
        messageDetails
    ) {
        return {
            dialogId: this.props.dialogId,
            dialogStatus: dialogErrorStatus || DIALOG_STATUS.SUBMIT_FAILURE,
            statusCode: statusCode || 400,
            statusText:
                statusText ||
                Localization.getString(Strings.defaultApplicationError),
            messageGeneral: messageGeneral,
            messageDetails: messageDetails,
        };
    }

    initialConfig(initialData) {
        return {
            dialogStatus: DIALOG_STATUS.UNSUBMITTED,
            initialValues: initialData,
            onFormInitialized: this.props.onFormInitialized,
            dialogId: this.props.dialogId,
        };
    }

    // Dialog Utilities
    buildDialogPage(props) {
        this.currentPage = ttdSteppedTaskDialogPage(
            props.steps[props.currentStepIndex].pageComponent,
            props.formId,
            props.uiModel,
            props.validate,
            props.warn,
            props.currentStepIndex
        );
    }

    incrementCurrentStep() {
        this.props.setCurrentStep(
            this.props.dialogId,
            this.props.currentStepIndex + 1
        );
    }

    // Utility that generates the actions for the action bar based on the current
    // state of the dialog.
    getActions(
        dialogId,
        formId,
        steps,
        currentStepIndex,
        onCancel,
        getStepActions
    ) {
        const step = steps[currentStepIndex];

        const cancelAction = {
            label: Localization.getString(Strings.cancelButton),
            onClick: onCancel,
            isFlat: true,
        };

        const previousAction = {
            label: Localization.getString(Strings.backButton),
            glyph: 'left-arrow',
            glyphPosition: GLYPH_POSITIONS.BEFORE,
            onClick: () => {
                this.props.setCurrentStep(dialogId, currentStepIndex - 1);
            },
        };

        // Even if we have a stepAction, only show the green action button if we're on
        //  the last step
        const actionType =
            step.stepAction && currentStepIndex === steps.length - 1
                ? BUTTON_TYPE.ACTION
                : BUTTON_TYPE.PRIMARY;

        const defaultActionName =
            actionType === BUTTON_TYPE.ACTION
                ? Localization.getString(Strings.saveButton)
                : Localization.getString(Strings.nextButton);

        const mainAction = {
            label:
                (step.stepAction &&
                    (step.stepAction.name || step.stepAction.label)) ||
                defaultActionName,
            type: actionType,
            glyph:
                actionType !== BUTTON_TYPE.ACTION ? 'right-arrow' : undefined,
            glyphPosition: GLYPH_POSITIONS.AFTER,
            onClick: this.submitForm,
            isSubmission: true,
        };

        let actions = [];

        if (currentStepIndex === 0) {
            actions = [cancelAction, mainAction];
        } else {
            actions = [cancelAction, previousAction, mainAction];
        }

        if (getStepActions) {
            // The consumer has indicated that they want to possibly modify the
            // actions produced, so call them and let them do it.
            actions = getStepActions(
                actions,
                currentStepIndex,
                onCancel,
                this.submitForm
            );
        }

        return actions;
    }

    // Utility that merges the dialog client's step configuration and
    // redux-acquired step completion information into a form usable by
    // the step status control.
    getStepStatuses(steps, completedSteps) {
        return steps.map((step, index) => {
            return {
                name: step.name,
                isComplete: !!(completedSteps && completedSteps[index]),
            };
        });
    }

    getDialogConfig(nextStep, nextStatus, currentStatus): DialogConfig {
        return {
            dialogId: this.props.dialogId,
            successCallback: this.successCallback,
            errorCallback: this.errorCallback,
            progressCallback: this.dialogProgressCallback,
            successParams: {
                nextStatus,
                nextStep,
                currentStatus,
            },
            errorParams: {},
        };
    }

    // Wrapped Dialog Actions
    wrappedOnCancel() {
        if (this.props.onCancel) {
            this.props.onCancel(this.props.dialogId);
        } else {
            // By default, we'll hide the dialog when the cancel action happens (the close 'X' or hitting the escape key).
            // If the consumer passes an onCancel, they are in charge of hiding the dialog when they see fit (e.g., after
            // confirming the intention of the user).
            if (
                this.props.isDirty &&
                this.props.showConfirmOnDirtyCancel &&
                this.props.dialogStatus !== DIALOG_STATUS.SUBMIT_SUCCESS
            ) {
                this.props.initializeDialogConfirmation(
                    this.props.formId,
                    this.props.dialogId
                );
                return;
            }
            this.props.hideDialog(this.props.dialogId);
        }
    }

    wrappedOnSubmit(values) {
        // Mark the current step as complete.
        this.props.completeStep(
            this.props.dialogId,
            this.props.currentStepIndex
        );

        const step = this.currentStep;

        if (step.stepAction && step.stepAction.onSubmit) {
            // This step has a submit action
            this.props.setDialogStatusDetails({
                dialogStatus: DIALOG_STATUS.SUBMITTING,
                dialogId: this.props.dialogId,
                messageDetail: step.stepAction.submittingSubtext || '',
                showTimerWhileSubmitting:
                    step.stepAction.showTimerWhileSubmitting,
                statusText: step.stepAction.submittingMessage || '',
            });

            // Determine the default dialog config
            let nextStatus = DIALOG_STATUS.UNSUBMITTED;
            if (
                this.props.finalStepIsReview &&
                this.props.currentStepIndex + 1 === this.props.steps.length - 1
            ) {
                nextStatus = DIALOG_STATUS.REVIEW;
            } else if (
                this.props.currentStepIndex ===
                this.props.steps.length - 1
            ) {
                nextStatus = DIALOG_STATUS.SUBMIT_SUCCESS;
            }

            const nextStep =
                this.props.currentStepIndex === this.props.steps.length - 1
                    ? this.props.currentStepIndex
                    : this.props.currentStepIndex + 1;

            const config = this.getDialogConfig(
                nextStep,
                nextStatus,
                this.props.dialogStatus
            );

            step.stepAction.onSubmit(values, config);
        } else if (this.hasNextStep) {
            this.incrementCurrentStep();
        }
    }

    wrappedOnOpen() {
        if (this.props.initialModel) {
            this.props.setDialogStatusDetails({
                dialogStatus: DIALOG_STATUS.UNSUBMITTED,
                initialValues: this.props.initialModel,
                onFormInitialized: this.props.onFormInitialized,
                dialogId: this.props.dialogId,
            });
        } else {
            this.props.setDialogStatusDetails({
                dialogId: this.props.dialogId,
                dialogStatus: DIALOG_STATUS.INITIALIZING,
            });
        }

        if (this.props.onOpen) {
            // Don't increment the step after an onOpen (should stay on step 0)
            const config = this.getDialogConfig(
                this.props.currentStepIndex,
                DIALOG_STATUS.UNSUBMITTED,
                DIALOG_STATUS.INITIALIZING
            );

            this.props.onOpen(config);
        }
    }

    wrappedOnClose() {
        // We told redux form to not destroy the form on unmount, so we're in charge of its
        // destruction.  Now is the time to do so, since the dialog is closing.
        this.props.destroyForm(this.props.formId);

        // If we have setRouteLeaveHook then it's on us to dispose of the listener on dialog close.
        if (this.disposeRouteLeaveHook) {
            this.disposeRouteLeaveHook();
        }

        if (this.props.onClose) {
            this.props.onClose(this.props.dialogId, {
                currentStepIndex: this.props.currentStepIndex,
                completedSteps: this.props.completedSteps,
            });
        }
    }

    submitForm() {
        this.props.submitForm(this.props.formId);
    }

    // Callbacks
    successCallback = (successData?: SuccessData) => {
        if (!successData) return;

        switch (successData.nextStatus) {
            case DIALOG_STATUS.UNSUBMITTED:
                this.props.setDialogStatusDetails(
                    successData.currentStatus === DIALOG_STATUS.INITIALIZING
                        ? this.initialConfig(successData.successResponse)
                        : {
                              dialogId: this.props.dialogId,
                              dialogStatus: DIALOG_STATUS.UNSUBMITTED,
                          }
                );
                break;
            case DIALOG_STATUS.REVIEW:
                this.props.setDialogStatusDetails({
                    dialogId: this.props.dialogId,
                    dialogStatus: DIALOG_STATUS.REVIEW,
                });
                break;
            case DIALOG_STATUS.SUBMIT_SUCCESS:
                this.props.setDialogStatusDetails(this.successConfig);
                break;
            case DIALOG_STATUS.SUBMITTING:
                // Get our step's submitting message
                this.props.setDialogStatusDetails({
                    dialogId: this.props.dialogId,
                    dialogStatus: successData.nextStatus,
                    statusText:
                        this.currentStep.stepAction.submittingMessage || '',
                });
                break;
            default:
                this.props.setDialogStatusDetails({
                    dialogId: this.props.dialogId,
                    dialogStatus: successData.nextStatus,
                });
                break;
        }

        if (
            successData.nextStep &&
            successData.nextStep !== this.props.currentStepIndex
        ) {
            this.props.setCurrentStep(
                this.props.dialogId,
                successData.nextStep
            );
        }
    };

    errorCallback = (errorData: ErrorData) => {
        this.props.setDialogStatusDetails({
            dialogId: this.props.dialogId, // don't expect dialogId to be passed with the errorData
            dialogStatus: DIALOG_STATUS.ERROR,
            statusCode: errorData.statusCode || 400,
            statusText:
                errorData.type ||
                Localization.getString(Strings.defaultApplicationError),
            messageGeneral: errorData.message,
            messageDetail: errorData.messageDetail || '',
        });
    };

    // Progress rate is 0-1. You must pass a separate flag indicating completeness -- this is to try and avoid potential rounding issues with the progressRate.
    dialogProgressCallback = (progressRate, isComplete) => {
        if (!isComplete) {
            // Still submitting
            this.props.setDialogStatusDetails({
                dialogId: this.props.dialogId,
                dialogStatus:
                    progressRate === 1
                        ? DIALOG_STATUS.UNSUBMITTED
                        : DIALOG_STATUS.SUBMITTING,
                pollingProgress: progressRate || 0,
                statusText: this.currentStep.stepAction.submittingMessage || '',
            });

            if (this.hasNextStep && progressRate >= 1) {
                this.incrementCurrentStep();
            }
        } else {
            // Done
            this.props.setDialogStatusDetails(this.successConfig);
        }
    };

    get hasNextStep() {
        return this.props.currentStepIndex !== this.props.steps.length - 1;
    }

    render() {
        // Every single prop defined in the SteppedTaskDialogProps interface is defined here.
        // This is because we want to pull out any *additional* props (...rest) and pass them through to the wizard page.
        // IMPORTANT: If you add a new prop to SteppedTaskDialog, you may need to add it to the list below.
        const {
            dialogId,
            title,
            subtitle,
            size,
            maxDialogHeight,
            height,
            className,
            theme,
            steps,
            uiModel,
            initialModel,
            validate,
            warn,
            getStepActions,
            successAction,
            onCancel,
            onOpen,
            onClose,
            showConfirmOnDirtyCancel,
            disableAutoCloseOnSuccess,
            finalStepIsReview,
            dialogStatus,
            initialValues,
            currentStepIndex,
            completedSteps,
            setDialogStatusDetails,
            hideDialog,
            completeStep,
            setCurrentStep,
            submitForm,
            destroyForm,
            initializeDialogConfirmation,
            currentConfirmationFormName,
            isDirty,
            formId,
            isConfirmationPending,
            modifySteps,
            hideStepStatusBarWhenThereIsOnlyOneStep,
            shouldWarnUserOnRouteChange,
            ...rest
        } = this.props;

        const CurrentPage = this.currentPage;

        const actions = this.getActions(
            dialogId,
            formId,
            steps,
            currentStepIndex,
            this.wrappedOnCancel,
            getStepActions
        );
        const stepStatuses = this.getStepStatuses(steps, completedSteps);

        return (
            <BasicDialog
                dialogId={dialogId}
                size={size}
                maxDialogHeight={maxDialogHeight}
                height={height}
                escapeToCancel
                className={`stepped-task-dialog ${className}`}
                theme={theme}
                onCancel={this.wrappedOnCancel}
                onOpen={this.wrappedOnOpen}
                onClose={this.wrappedOnClose}
            >
                <Prompt
                    message={Localization.getString(
                        Strings.confirmNavigationMessage
                    )}
                    when={isDirty && shouldWarnUserOnRouteChange}
                />
                <DialogHeader
                    dialogId={dialogId}
                    theme={theme}
                    title={title}
                    subtitle={subtitle}
                    onCancel={this.wrappedOnCancel}
                />
                <DialogStatusPage
                    dialogId={dialogId}
                    className='stepped-task-dialog__content'
                    getMessageDetail={this.getMessageDetail}
                />
                <div
                    ref={(elem) => {
                        this.contentElem = elem;
                    }}
                    className='stepped-task-dialog__content basic-dialog__content'
                    style={{
                        display:
                            dialogStatus === DIALOG_STATUS.UNSUBMITTED ||
                            dialogStatus === DIALOG_STATUS.REVIEW
                                ? 'block'
                                : 'none',
                    }}
                >
                    <CurrentPage
                        onSubmit={this.wrappedOnSubmit}
                        submitForm={this.submitForm}
                        onCancel={this.wrappedOnCancel}
                        modifySteps={modifySteps}
                        steps={this.state.initialSteps}
                        {...rest}
                    />
                </div>
                <div className='stepped-task-dialog__footer'>
                    {dialogStatus !== DIALOG_STATUS.INITIALIZING &&
                        dialogStatus !== DIALOG_STATUS.SUBMITTING && (
                            <DialogActionBar
                                dialogId={dialogId}
                                actions={actions}
                                onClose={this.wrappedOnClose}
                                onCancel={this.wrappedOnCancel}
                                successAction={successAction}
                                retryAction={this.submitForm}
                                className='stepped-task-dialog__actions'
                                theme={theme}
                                disableAutoCloseOnSuccess={
                                    this.props.disableAutoCloseOnSuccess
                                }
                            >
                                {(stepStatuses.length > 1 ||
                                    !hideStepStatusBarWhenThereIsOnlyOneStep) &&
                                    (dialogStatus ===
                                        DIALOG_STATUS.UNSUBMITTED ||
                                        dialogStatus ===
                                            DIALOG_STATUS.REVIEW) && (
                                        <StepStatus
                                            dialogId={dialogId}
                                            steps={stepStatuses}
                                            currentStepIndex={currentStepIndex}
                                            className={`stepped-task-dialog__steps stepped-task-dialog__steps--${size} stepped-task-dialog__steps-completed--${
                                                stepStatuses.filter(
                                                    (s) => s.isComplete
                                                ).length
                                            }`}
                                            onStepClick={setCurrentStep}
                                        />
                                    )}
                            </DialogActionBar>
                        )}
                </div>
            </BasicDialog>
        );
    }
}

export default withRouter<
    typeof SteppedTaskDialogComponent,
    SteppedTaskDialogComponentProps
>(SteppedTaskDialogComponent);
