import React, { Component, ReactNode } from 'react';
import BasicDialog, { DIALOG_SIZE } from '../BasicDialog';
import { DIALOG_STATUS } from '../DialogActions';
import DialogStatusPage from '../DialogStatusPage';
import DialogActionBar, { SuccessAction } from '../DialogActionBar';
import DialogHeader from '../DialogHeader';
import { Localization } from '../../../../util/LocalizationService';
import Strings from './ImmediateActionDialog.strings.json';
import './ImmediateActionDialog.scss';

export interface ImmediateActionDialogProps {
    /**
     * The ID of the dialog
     */
    dialogId: string;
    /**
     * The size of the dialog. Defaults to SMALL
     */
    size?: DIALOG_SIZE.SMALL | DIALOG_SIZE.MEDIUM;
    /** An optional custom message or component that is shown under the success icon and "Success!"
     * title when the action was successful.  This is often useful in conjunction with
     * the successAction to tell the user they can either close the dialog or
     * do the success action.
     */
    message?: ReactNode;
    /**  An optional custom message or component that is shown under the success icon and "Success!"
     * title when the action was successful.  This is often useful in conjunction with
     * the successAction to tell the user they can either close the dialog or
     * do the success action.
     */
    successMessage?: ReactNode;
    /**
     * Action to display on success. This will prevent the dialog from auto-closing.
     */
    successAction?: SuccessAction;
    /**
     * Optional field to indicate that the close button should not render on success
     */

    suppressCloseButtonOnSuccess?: boolean;
    /**
     * Optional callback to be executed when the user cancels
     */
    onCancel?: () => void;

    /**
     * Optional callback to be executed when the dialog closes
     */
    onClose?: () => void;

    /**
     * Function to execute the long operation, we expect this to return a promise
     */
    submitAsync: (config: { errorCallback: ErrorCallback }) => Promise<any>;
}

type ErrorCallback = (errorData: {
    statusCode?: number;
    statusMessage?: string;
}) => void;

export interface ImmediateActionDialogStateProps {
    dialogStatus: DIALOG_STATUS;
}

export interface ImmediateActionDialogDispatchProps {
    /**
     * Function to set the current status of the dialog, see DialogStatusPage component
     */
    setDialogStatusDetails: (status: any) => void;
    /**
     * Function to hide a particular dialog
     */
    hideDialog: (dialog: string) => void;
}

/**
 * A Dialog that presents a loading indicator while an XHR is immediately executed and doesn't allow cancellation.
 * On error the user can retry the same operation or dismiss the dialog by clicking cancel or the close button.
 */
export default class ImmediateActionDialogComponent extends Component<
    ImmediateActionDialogProps &
        ImmediateActionDialogStateProps &
        ImmediateActionDialogDispatchProps
> {
    static defaultProps = {
        size: DIALOG_SIZE.SMALL,
    };

    successConfig = () => {
        return {
            dialogId: this.props.dialogId,
            dialogStatus: DIALOG_STATUS.SUBMIT_SUCCESS,
            messageDetail: this.props.successMessage,
            statusText: this.props.successMessage
                ? null
                : Localization.getString(Strings.success),
        };
    };

    errorConfig = () => {
        return {
            dialogId: this.props.dialogId,
            statusText: Localization.getString(Strings.error),
        };
    };

    errorCallback: ErrorCallback = (errorData) => {
        const { statusText, ...rest } = this.errorConfig();
        this.props.setDialogStatusDetails({
            dialogStatus: DIALOG_STATUS.ERROR,
            statusCode: errorData.statusCode || 400,
            statusText: errorData.statusMessage || statusText,
            ...rest,
        });
    };

    getMessageDetail = (dialogStatus: DIALOG_STATUS) => {
        return dialogStatus === DIALOG_STATUS.SUBMIT_SUCCESS
            ? this.props.successMessage
            : undefined;
    };

    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        this.props.hideDialog(this.props.dialogId);
    };

    handleSubmit = (dialogId) => {
        const {
            setDialogStatusDetails,
            submitAsync,
            hideDialog,
            message,
        } = this.props;

        // Loader dialog immediately advances to the submitting state
        setDialogStatusDetails({
            dialogStatus: DIALOG_STATUS.SUBMITTING,
            dialogId,
            statusText: message,
        });

        submitAsync({ errorCallback: this.errorCallback }).then(() => {
            const {
                setDialogStatusDetails,
                dialogId,
                successAction,
            } = this.props;
            setDialogStatusDetails(this.successConfig());

            if (!successAction) {
                // auto-close the dialog but gives some time for the success animation to be displayed
                setTimeout(() => hideDialog(dialogId), 3000);
            }
        });
    };

    render() {
        const {
            dialogId,
            size,
            dialogStatus,
            successAction,
            suppressCloseButtonOnSuccess,
            onClose,
        } = this.props;
        return (
            <BasicDialog
                dialogId={dialogId}
                size={size}
                className='immediate-action-dialog'
                onOpen={this.handleSubmit}
                onClose={onClose}
            >
                {(dialogStatus === DIALOG_STATUS.ERROR ||
                    (dialogStatus === DIALOG_STATUS.SUBMIT_SUCCESS &&
                        suppressCloseButtonOnSuccess)) && (
                    <DialogHeader
                        dialogId={dialogId}
                        onCancel={this.handleCancel}
                        theme='transparent-header'
                    />
                )}

                <DialogStatusPage
                    dialogId={dialogId}
                    className='immediate-action-dialog__content'
                    getMessageDetail={this.getMessageDetail}
                    theme='immediate-action'
                />

                {dialogStatus === DIALOG_STATUS.SUBMIT_SUCCESS &&
                    successAction && (
                        <div className='immediate-action-dialog__footer'>
                            <DialogActionBar
                                dialogId={dialogId}
                                successAction={successAction}
                                suppressCloseButtonOnSuccess={
                                    suppressCloseButtonOnSuccess
                                }
                            />
                        </div>
                    )}
                {dialogStatus === DIALOG_STATUS.ERROR && (
                    <div className='immediate-action-dialog__footer'>
                        <DialogActionBar
                            dialogId={dialogId}
                            actions={[]}
                            onCancel={this.handleCancel}
                            retryAction={this.handleSubmit}
                            suppressBackButtonOnError={true}
                        />
                    </div>
                )}
            </BasicDialog>
        );
    }
}
