import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from 'util/PermissionsService';

/**
 * Create a context to store the ReadOnly state.
 */
const ReadOnlyContext = React.createContext(false);

interface ReadOnlyComponentProps {
    value: boolean;
    requiredPermissions?: string;
    isOverride?: boolean;
}

/**
 * The ReadOnly component creates a ReadOnlyContext based on the
 * passed props that all controls/inputs supporting isReadOnly (via
 * the withReadOnly HOC) will understand and respond to.
 */
class ReadOnlyComponent extends Component<ReadOnlyComponentProps> {
    static displayName = 'ReadOnly';

    static Consumer = ReadOnlyContext.Consumer;

    render() {
        // We don't explicitly reference "requiredPermission" here because that is folded
        // into "value" by the connected component we expose to the rest of the world.
        const { value, isOverride } = this.props;

        return (
            <ReadOnlyContext.Consumer>
                {(isReadOnlyFromParentContext) => (
                    <ReadOnlyContext.Provider
                        value={
                            isOverride
                                ? value
                                : isReadOnlyFromParentContext || value
                        }
                    >
                        {this.props.children}
                    </ReadOnlyContext.Provider>
                )}
            </ReadOnlyContext.Consumer>
        );
    }
}

class AlwaysAllowInteractionComponent extends Component {
    render() {
        return (
            <ReadOnlyComponent value={false} isOverride>
                {this.props.children}
            </ReadOnlyComponent>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const isReadOnlyByPermission =
        ownProps.requiredPermission &&
        !hasPermission(state, ownProps.requiredPermission);
    return {
        value: ownProps.value || !!isReadOnlyByPermission,
    };
};

const ConnectedReadOnlyComponent = connect(mapStateToProps)(ReadOnlyComponent);

export default {
    AlwaysAllowInteraction: AlwaysAllowInteractionComponent,
    Provider: ConnectedReadOnlyComponent,
    Consumer: ReadOnlyContext.Consumer,
};
