import React, { Component, ReactNode } from 'react';
import { TtdField, UiFieldModel } from 'components/inputs/TtdInputField';
import themeClassName from '../../../util/Themes';
import './LabelledInput.scss';

export enum InputSize {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XXL = 'xxl',
    AUTO = 'auto',
}

export type INPUT_SIZES = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'auto';

export interface LabelledInputProps {
    // The field associated with the label
    field?: TtdField;
    // Label text to show for inputs
    label?: string | ReactNode;
    // Size of the component
    size?: INPUT_SIZES;
    // Whether the input is a required field
    isRequired?: boolean;
    // Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    // Children to render within the labelled input
    children: ReactNode;
    // Optional class for the input
    className?: string;
    // align label to the left of the input.
    isInline?: boolean;
    // a component to annotate the label part of the component, such as a help button
    labelAnnotation?: ReactNode;
    // Optional theme
    theme?: string;
}

// Represents a value with a label rendered underneath.
class LabelledInput extends Component<LabelledInputProps> {
    static defaultProps = {
        size: InputSize.SM,
        isRequired: false,
        isReadOnly: false,
        theme: '',
        className: '',
    };

    render() {
        const {
            field,
            label,
            size,
            isRequired,
            isReadOnly,
            children,
            className,
            theme,
            isInline,
            labelAnnotation,
        } = this.props;

        const classes = [className, `labelled-input labelled-input--${size}`];
        if (isReadOnly) {
            classes.push('labelled-input--readonly');
        }
        if (isInline) {
            classes.push('labelled-input--inline');
        }
        if (theme) {
            classes.push(themeClassName(theme));
        }

        return (
            <div className={classes.join(' ')}>
                {label && (
                    <div className='labelled-input__label'>
                        <label>
                            {label}
                            <span className='labelled-input__label__is-required'>
                                {((field &&
                                    (field as UiFieldModel).isRequired) ||
                                    isRequired) &&
                                    '*'}
                            </span>
                            {labelAnnotation}
                        </label>
                    </div>
                )}
                <div className='labelled-input__input'>{children}</div>
            </div>
        );
    }
}

export default LabelledInput;
