﻿import NumberFormattingService from './NumberFormattingService';

export class Reflection {
    static isEmptyValue(val) {
        return val === null || val === undefined || val.length === 0;
    }

    /**
     * Converts a value from storage (e.g., stored in redux-form state) into
     * an actual JavaScript number.
     *
     * Why all of the hand-wringing about how we store numbers? Because we
     * want to preserve what the user really entered. If they entered "3."
     * we need to be able to populate the textbox with "3.", so we need to
     * store the string.
     *
     * But we also want to avoid parsing the string repeatedly while we
     * inspect the model (e.g., for validation). To that end, we store
     * an object that contains both the textual and numeric representation
     * of the user's input.
     *
     * We don't have to parse more than once and we don't lose the value
     * the user actually entered into the text box.
     */
    static toNumberFromStorage(value) {
        if (Reflection.isEmptyValue(value)) {
            return null;
        }

        const type = typeof value;

        if (type === 'string') {
            return NumberFormattingService.parseNumber(value);
        } else if (type === 'number') {
            return value;
        } else if (type === 'object') {
            return value.value;
        } else {
            return NaN;
        }
    }

    /**
     * A very straightforward composition of other functions.
     */
    static toNumberFromInput(value) {
        return Reflection.toStorageFromInput(
            value,
            Reflection.toNumberFromStorage
        );
    }

    /**
     * Converts a value from storage (e.g., stored in redux-form state) into
     * a value that can be placed into an input (e.g., a textbox).
     */
    static toInputFromStorage(value) {
        if (Reflection.isEmptyValue(value)) {
            return '';
        }

        if (typeof value === 'object') {
            return value.text;
        } else if (typeof value === 'number') {
            return NumberFormattingService.formatNumber(
                value,
                NumberFormattingService.getDecimalPlacesInNumber(value)
            );
        } else {
            return value;
        }
    }

    /**
     * Converts a value from an input (e.g., a textbox) into an object
     * that can be stored in redux-form state.
     */
    static toStorageFromInput(value, valueTransformFn?) {
        if (value === '') {
            return value;
        }

        if (typeof value === 'string') {
            try {
                const number = NumberFormattingService.parseNumberExact(value);
                const decimalPlaces = NumberFormattingService.getDecimalPlacesInString(
                    value
                );

                const parsedValue = NumberFormattingService.formatNumber(
                    number,
                    decimalPlaces
                );

                const parsedExact = NumberFormattingService.parseNumberExact(
                    value
                );
                const parsedDecimalPlaces = NumberFormattingService.getDecimalPlacesInString(
                    parsedExact.toString()
                );

                // If we can round-trip to the final string perfectly, then we can keep the
                // value as a number.
                if (
                    parsedValue === value &&
                    decimalPlaces === parsedDecimalPlaces
                ) {
                    return valueTransformFn ? valueTransformFn(number) : number;
                } else {
                    return {
                        text: value,
                        value: valueTransformFn
                            ? valueTransformFn(number)
                            : number,
                    };
                }
            } catch (e) {
                // Just let it fall through if there is a problem.
            }
        }

        if (Reflection.isEmptyValue(value)) {
            return null;
        }

        if (typeof value === 'number') {
            return valueTransformFn ? valueTransformFn(value) : value;
        }

        try {
            const numberValue = NumberFormattingService.parseNumberExact(value);
            return {
                text: value,
                value: valueTransformFn
                    ? valueTransformFn(numberValue)
                    : numberValue,
            };
        } catch (e) {
            return {
                text: value,
                value: NaN,
            };
        }
    }
}
