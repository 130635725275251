import { getFormInitialValues, change } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import RowDeleteButtonInput from './RowDeleteButtonInput';

const removeNewElements = (targetForm, targetField) => {
    return (dispatch, getState) => {
        const formInitialValues = getFormInitialValues(targetForm)(getState());
        const initialValues = get(formInitialValues, targetField, []);
        dispatch(change(targetForm, targetField, initialValues));
    };
};

const actionCreators = {
    removeNewElements,
};

export default connect(null, actionCreators)(RowDeleteButtonInput);
