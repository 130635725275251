import React, { Component } from 'react';
import { TtdInputFieldComponentProps } from 'components/inputs/TtdInputField';
import FixedContentInput from 'components/inputs/FixedContentInput';
import DecimalInput from 'components/inputs/DecimalInput';
import CountInput from 'components/inputs/CountInput';
import { Reflection } from 'util/Reflection';
import { ReadOnlyComponentProps } from 'components/hocs/withReadOnly';
import { Parser, Normalizer, Formatter } from 'redux-form';

/**
 * @summary  This component is really just some syntactic sugar and styling (adds a percentage sign at the end of the rendered input) around a Decimal or Count input.
 * Please see those inputs for complete documentation.
 */
export interface PercentageInputProps
    extends ReadOnlyComponentProps,
        TtdInputFieldComponentProps<{
            placeholder?: string;
            onChange?(
                event: React.ChangeEvent<HTMLInputElement>,
                newValue: number,
                oldValue: number
            );
            isEnabled?: boolean;
            isInline?: boolean;
            maxLength?: number;
            decimalLimit?: number;
            min?: number;
            max?: number;
            isValidationInline?: boolean;
            allowNegative?: boolean;
            onBeforeChange?(newValue: number, oldValue: number);
        }> {
    format: never;
    parse: never;
    normalize: never;
}

/**
 * @summary PercentageInput formats a number as a percentage.
 * @caution This can be a bit confusing since the component will shift decimal spaces by a magnitude of 100
 * so what the user sees as 32% will actually be .32 as a numeric value when saved.
 */
export default class PercentageInput extends Component<PercentageInputProps> {
    static defaultProps: Partial<PercentageInputProps> = {
        decimalLimit: 0,
        isEnabled: true,
    };

    format: Formatter = (value) => {
        // The underlying value of a percentage is consumed as a ratio (90% === 0.9)
        // So we display the value formatted as a percentage and not as a ratio.
        if (typeof value === 'number') {
            // Adding the + makes Javascript treat the toFixed() result as a number, which removes
            // the insignificant zeros.  Found this trick in:
            // https://stackoverflow.com/questions/3612744/remove-insignificant-trailing-zeros-from-a-number/3613112
            return +(value * 100).toFixed(this.props.decimalLimit);
        }
        return Reflection.toInputFromStorage(value);
    };

    parse: Parser = (value) => {
        // Store the Ratio value of the user input in redux-form.
        return Reflection.toStorageFromInput(value, (n) => n / 100);
    };

    normalize: Normalizer = (value, previousValue) => {
        if (
            this.props.onBeforeChange &&
            this.props.onBeforeChange(value, previousValue) === false
        ) {
            return previousValue;
        } else {
            return value;
        }
    };

    render() {
        const { decimalLimit, ...rest } = this.props;

        const component = decimalLimit > 0 ? DecimalInput : CountInput;
        return (
            <FixedContentInput
                {...(rest as any)}
                component={component}
                isContentAfterInput
                content='%'
                decimalLimit={decimalLimit}
                format={this.format}
                parse={this.parse}
                normalize={this.normalize}
            />
        );
    }
}
