import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import NumberFormattingService from 'util/NumberFormattingService';
import themeClassName from 'util/Themes';
import withReadOnly from 'components/hocs/withReadOnly';
import 'components/controls/TextControl/TextControl.scss';
import './CountControl.scss';

export interface CountControlProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
    value?: number;
    isReadOnly?: boolean;
    isEnabled?: boolean;
    isInline?: boolean;
    theme?: string;
}

class CountControl extends Component<CountControlProps> {
    private mask: any;

    constructor(props: CountControlProps) {
        super(props);

        this.mask = createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: NumberFormattingService.getThousandsSeparator(),
            allowDecimal: false,
            allowNegative: false,
        });
    }

    static defaultProps = {
        isEnabled: true,
    };

    render() {
        const {
            isReadOnly,
            isInline,
            className,
            value,
            isEnabled,
            theme,
            ...rest
        } = this.props;

        const classes = ['count-control', 'text-control'];

        if (isInline) {
            classes.push('input--inline');
            classes.push('input--right-align');
        }

        if (className) {
            classes.push(className);
        }

        if (theme) {
            classes.push(themeClassName(theme));
        }

        return (
            <div className={classes.join(' ')}>
                {this.props.isReadOnly ? (
                    <div className='input--readonly'>
                        {NumberFormattingService.formatInteger(value)}
                    </div>
                ) : (
                    <MaskedInput
                        mask={this.mask}
                        guide={true}
                        placeholderChar={'\u2000'}
                        autoComplete='off'
                        value={value}
                        disabled={!isEnabled}
                        {...rest}
                    />
                )}
            </div>
        );
    }
}

export default withReadOnly(CountControl);
