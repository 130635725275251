import React, { CSSProperties } from 'react';
import TimeControl from 'components/controls/TimeControl';
import { Observable } from 'rxjs/Rx';
import { DayPickerRangeController, CalendarDay } from 'react-dates';
import { clickSource, focusSource } from 'util/EventSubscriptions';
import LabelledInput from 'components/display/LabelledInput';
import getInputId from 'util/getInputId';
import { Localization } from 'util/LocalizationService';
import {
    moment,
    fromDateTimeUtcToMomentUtc,
    fromDateTimeAndTimeZoneToMomentInTimezone,
    fromMomentUtcToDateTimeUtc,
    getMomentStartOfDay,
    getTodayMomentUtc,
    getDateTimeStartOfDay,
    IDateTime,
    ITimeZone,
} from 'util/DateTimeUtils';
import Strings from './DatePickerInput.strings.json';
import CheckboxInput from 'components/inputs/CheckboxInput';
import DateTimeTextInput from 'components/inputs/DateTimeTextInput';
import ValidationOnlyInput from 'components/inputs/ValidationOnlyInput';
import { touch, Fields, FormSection, WrappedFieldProps } from 'redux-form';
import TtdInputField, {
    TtdField,
    UiFieldModel,
} from 'components/inputs/TtdInputField';
import ValidationError from 'components/display/ValidationError';
import themeClassName from 'util/Themes';
import './DateRangePickerInput.scss';
import './DatePickerCalendarOverrides.scss';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const HAS_NO_END_DATE = 'hasNoEndDate';

type StartOrEndDate = 'startDate' | 'endDate';

/**
 * Checks if day should be blocked in the calendar based on a start and end date range.
 *
 * @note MM: I think that we should be passing in moments, not UiDateTime, because this comparison
 *          is not very good. How do we know that the moment passed in is in the same timezone as
 *          the dateTimes?
 *
 * @param {IDateTime} minStartDate
 * @param {IDateTime} maxEndDate
 * @returns {function(*=): (boolean | boolean)}
 */
export const getIsDayBlocked = (
    minStartDate?: IDateTime,
    maxEndDate?: IDateTime
) => {
    return (day) => {
        if (!day) {
            return true;
        }

        // If passed in object is not a moment, there is nothing we can do.
        if (!moment.isMoment(day) || !day.isValid()) {
            return true;
        }

        let dayToCompare = day;

        // If the day did not have timeZone passed in, we need to do a little
        // manipulation to give it one. For simplicity, just use UTC.
        if (!dayToCompare.tz()) {
            dayToCompare = fromDateTimeUtcToMomentUtc(
                fromMomentUtcToDateTimeUtc(dayToCompare)
            );
        }

        // Need to use the same timeZone as the moment that was passed in, otherwise
        // the comparison could be messed up.
        const timeZone = {
            name: dayToCompare.tz(),
        };

        // If passed in day is before the minimum start date, then the day should be clocked.
        if (minStartDate) {
            const startMoment = fromDateTimeAndTimeZoneToMomentInTimezone(
                minStartDate,
                timeZone,
                timeZone
            ).startOf('day');

            if (dayToCompare.isBefore(startMoment)) {
                return true;
            }
        }

        // If passed in day is after the maximum end date, then the day should be clocked.
        if (maxEndDate) {
            const endMoment = fromDateTimeAndTimeZoneToMomentInTimezone(
                maxEndDate,
                timeZone,
                timeZone
            ).endOf('day');

            if (dayToCompare.isAfter(endMoment)) {
                return true;
            }
        }

        // Day has passed the min start and max end checks so it should not be blocked.
        return false;
    };
};

interface DateRangePickerFieldState {
    minStartDate: IDateTime;
    maxEndDate: IDateTime;
    isDayBlocked(day): boolean;
    isRecomputingHighlightState?: boolean;
}

interface DateRangePickerFieldProps extends DateRangePickerInputProps {
    startDate?: WrappedFieldProps;
    endDate?: WrappedFieldProps;
    hasNoEndDate?: WrappedFieldProps;
    onFocusChange?(field: StartOrEndDate): void;
    isOpen?: boolean;
    focusedInput?: StartOrEndDate;
}

export class DateRangePickerField extends React.Component<
    DateRangePickerFieldProps,
    DateRangePickerFieldState
> {
    private onStartTimeChange: any;
    private onEndTimeChange: any;

    constructor(props) {
        super(props);
        this.state = {
            minStartDate: props.minStartDate,
            maxEndDate: props.maxEndDate,
            isDayBlocked: getIsDayBlocked(props.minStartDate, props.maxEndDate),
        };

        this.onStartTimeChange = this.onTimeChange.bind(this, START_DATE);
        this.onEndTimeChange = this.onTimeChange.bind(this, END_DATE);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { minStartDate, maxEndDate } = nextProps;

        const newState: Partial<DateRangePickerFieldState> = {};

        if (
            maxEndDate !== prevState.maxEndDate ||
            minStartDate !== prevState.minStartDate
        ) {
            newState.isDayBlocked = getIsDayBlocked(minStartDate, maxEndDate);
            newState.maxEndDate = maxEndDate;
            newState.minStartDate = minStartDate;
        }

        if (newState.isDayBlocked !== prevState.isDayBlocked) {
            return newState;
        }
        return null;
    }

    onTimeChange = (field, time) => {
        if (time) {
            this.props[field].input.onChange(time);
        }
        this.toggleInput();
    };

    // force react-dates to recompute highlighted state.
    toggleInput = () => {
        if (!this.props.endDateIsOptional) {
            return;
        }
        this.setState({ isRecomputingHighlightState: true });
        setTimeout(() => this.setState({ isRecomputingHighlightState: false }));
    };

    onDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
        const {
            isStartDateReadOnly,
            isEndDateReadOnly,
            startDate: oldStartDate,
            endDate: oldEndDate,
            endDateIsOptional,
            hasNoEndDate,
            includesTime,
            predefineEndTimeToEndOfDay,
        } = this.props;

        if (!isStartDateReadOnly && newStartDate) {
            const { hour = 0, minute = 0 } = oldStartDate.input.value;

            let onChangeStartDate = fromMomentUtcToDateTimeUtc(
                newStartDate.hour(hour).minute(minute)
            );

            if (!includesTime) {
                onChangeStartDate = getDateTimeStartOfDay(onChangeStartDate);
            }

            oldStartDate.input.onChange(onChangeStartDate);
        }

        if (!isEndDateReadOnly && newEndDate) {
            let endDateToSave = null;
            if (
                (!endDateIsOptional || !hasNoEndDate.input.value) &&
                newEndDate
            ) {
                const {
                    hour = predefineEndTimeToEndOfDay ? 23 : 0,
                    minute = predefineEndTimeToEndOfDay ? 59 : 0,
                } = oldEndDate.input.value;

                endDateToSave = fromMomentUtcToDateTimeUtc(
                    newEndDate.hour(hour).minute(minute)
                );

                if (!includesTime) {
                    endDateToSave = getDateTimeStartOfDay(endDateToSave);
                }
            }
            oldEndDate.input.onChange(endDateToSave);
        }

        // ensure both inputs are touched when the date changes for validation purposes, so treat this as a single input.
        const { meta } = oldEndDate;
        meta.dispatch(
            touch(meta.form, oldEndDate.input.name, oldStartDate.input.name)
        );
        this.toggleInput();
    };

    onHasNoEndDateChange = ({ target }) => {
        if (target.checked) {
            this.props[END_DATE].input.onChange(null);
            this.props.onFocusChange(START_DATE);
        }
        this.toggleInput();
    };

    isDayHighlighted = (date) => {
        if (
            !this.props.hasNoEndDate.input.value ||
            !this.props[START_DATE].input.value
        ) {
            return false;
        }

        return (
            date.diff(
                fromDateTimeUtcToMomentUtc(this.props[START_DATE].input.value)
            ) > 0
        );
    };

    renderAdditionalControls = () => {
        const {
            includesTime,
            timeZoneLabel,
            endDateIsOptional,
            hasNoEndDate,
            endDate,
            startDate,
        } = this.props;

        // We only need to render this section if we're going to show the
        // time controls or the "end date optional" checkbox.
        if (!includesTime && !endDateIsOptional) {
            return;
        }

        const timeLabel = timeZoneLabel
            ? Localization.getString(Strings.timeLabelWithTz, {
                  tz: timeZoneLabel.shortName,
              })
            : Localization.getString(Strings.timeLabel);

        return (
            <div className='date-range-picker__additional-things'>
                <div className='date-range-picker__additional-control'>
                    {includesTime && (
                        <LabelledInput label={timeLabel}>
                            <TimeControl
                                isEnabled={!!startDate.input.value}
                                id={`${getInputId(TimeControl, startDate)}`}
                                value={
                                    this.props[START_DATE].input.value ||
                                    undefined
                                }
                                onChange={this.onStartTimeChange}
                            />
                        </LabelledInput>
                    )}
                </div>
                <div className='date-range-picker__additional-control'>
                    {includesTime && (
                        <LabelledInput label={timeLabel}>
                            <TimeControl
                                isEnabled={
                                    !!endDate.input.value &&
                                    !hasNoEndDate.input.value
                                }
                                id={`${getInputId(TimeControl, endDate)}`}
                                value={
                                    this.props[END_DATE].input.value ||
                                    undefined
                                }
                                onChange={this.onEndTimeChange}
                            />
                        </LabelledInput>
                    )}
                    {endDateIsOptional && (
                        <CheckboxInput
                            className='date-range-picker__end-date-optional'
                            field={HAS_NO_END_DATE}
                            onChange={this.onHasNoEndDateChange}
                            label={Strings.hasNoEndDate}
                            isValidationInline={true}
                        />
                    )}
                </div>
            </div>
        );
    };

    render() {
        const {
            startDate: startDateRaw,
            endDate: endDateRaw,
            isOpen,
            focusedInput,
            onFocusChange,
            includesTime,
        } = this.props;

        let startDate = getMomentStartOfDay(
            fromDateTimeUtcToMomentUtc(startDateRaw.input.value)
        );
        startDate = startDate && startDate.isValid() ? startDate : null;

        let endDate = getMomentStartOfDay(
            fromDateTimeUtcToMomentUtc(endDateRaw.input.value)
        );
        endDate = endDate && endDate.isValid() ? endDate : null;

        // Assemble classes for root element.
        const classes = ['date-range-picker__picker-wrapper'];

        if (includesTime) {
            classes.push('date-range-picker__picker-wrapper--with-time');
        }

        const pickerClass = `date-range-picker__picker ${
            isOpen
                ? 'date-range-picker__picker--open'
                : 'date-range-picker__picker--closed'
        }`;

        return (
            <div className={classes.join(' ')}>
                <div className={pickerClass}>
                    {isOpen && (
                        <DayPickerRangeController
                            isDayHighlighted={this.isDayHighlighted}
                            numberOfMonths={2}
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={this.onDatesChange}
                            focusedInput={
                                this.state.isRecomputingHighlightState
                                    ? null
                                    : focusedInput || START_DATE
                            }
                            // this is to allow opening up the Calendar on the date you clicked into
                            initialVisibleMonth={() =>
                                focusedInput === END_DATE
                                    ? endDate ||
                                      startDate ||
                                      getTodayMomentUtc()
                                    : startDate ||
                                      endDate ||
                                      getTodayMomentUtc()
                            }
                            onFocusChange={onFocusChange}
                            renderCalendarInfo={this.renderAdditionalControls}
                            hideKeyboardShortcutsPanel
                            isDayBlocked={this.state.isDayBlocked}
                            renderCalendarDay={({ modifiers, ...props }) => {
                                return (
                                    <CalendarDay
                                        modifiers={
                                            modifiers instanceof Set
                                                ? modifiers
                                                : new Set()
                                        }
                                        {...props}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

interface DateRangePickerInputFieldsState {
    isOpen: boolean;
    focusedInput?: StartOrEndDate;
}

/** The component used for actually rendering the input field. */
class DateRangePickerInputFields extends React.Component<
    {
        fieldProps: DateRangePickerInputProps;
    } & WrappedFieldProps,
    DateRangePickerInputFieldsState
> {
    constructor(props) {
        super(props);

        this.onEndDateFocus = this.onTextFocus.bind(this, END_DATE);
        this.onStartDateFocus = this.onTextFocus.bind(this, START_DATE);
        this.state = {
            isOpen: false, // is the picker open?
        };
    }

    private onEndDateFocus: any;
    private onStartDateFocus: any;
    private rootNode: HTMLElement;
    componentWillUnmount() {
        this.clickAwaySubscription.unsubscribe();
    }

    componentDidUpdate(_, prevState) {
        if (!this.state.isOpen && prevState.isOpen) {
            this.props.fieldProps.onBlur();
        }
    }

    /** Callback when the text field is focused. */
    onTextFocus(focusedInput) {
        this.setState({ isOpen: true, focusedInput });
    }

    /** Close the popup when clicking away. */
    clickAwaySubscription = (Observable as any)
        .merge(
            clickSource.filter(() => {
                return this.state.isOpen;
            }, this),
            focusSource
        )
        .subscribe((e) => {
            const isDescendantOfRoot =
                this.rootNode && this.rootNode.contains(e.target as Node);
            if (!isDescendantOfRoot) {
                this.setState({ isOpen: false });
            }
        });

    onFocusChange = (focusedInput) => {
        this.setState({ focusedInput });
    };

    render() {
        const {
            includesTime,
            timeZoneLabel,
            className,
            theme,
            style,
            isReadOnly,
            isStartDateReadOnly,
            isEndDateReadOnly,
            maxEndDate,
            minStartDate,
            isEnabled,
            field,
            endDateIsOptional,
            isInline,
            isValidationInline,
            predefineEndTimeToEndOfDay,
        } = this.props.fieldProps;
        const { meta, input } = this.props;

        const startDateReadOnly = isReadOnly || isStartDateReadOnly;
        const endDateReadOnly = isReadOnly || isEndDateReadOnly;
        const hasNoEndDate = input.value.hasNoEndDate;

        // Assemble root classes.
        const rootClasses = [
            'date-range-picker',
            'date-picker-calendar-overrides',
            themeClassName(theme),
            className,
        ];
        if (isInline) {
            rootClasses.push('date-range-picker--inline');
        }
        if (isReadOnly) {
            rootClasses.push('date-range-picker--readonly');
        }
        if (isValidationInline) {
            rootClasses.push('date-range-picker--inline-validation');
        }

        // Picker base classes.
        const pickerBaseClasses = ['date-range-picker__text-input'];
        if (isEnabled && !isReadOnly) {
            pickerBaseClasses.push('date-range-picker__text-input--active');
        }
        if (!isEnabled) {
            pickerBaseClasses.push('date-range-picker__text-input--disabled');
        }

        // Assemble date start picker classes.
        const startPickerClasses = [...pickerBaseClasses];
        if (startDateReadOnly) {
            startPickerClasses.push('date-range-picker__text-input--readonly');
        }
        if (this.state.isOpen && this.state.focusedInput !== END_DATE) {
            startPickerClasses.push('date-range-picker__text-input--focused');
        }

        // Assemble date end picker classes.
        const endPickerClasses = [...pickerBaseClasses];
        if (endDateReadOnly) {
            endPickerClasses.push('date-range-picker__text-input--readonly');
        }
        if (this.state.isOpen && this.state.focusedInput === END_DATE) {
            endPickerClasses.push('date-range-picker__text-input--focused');
        }

        // Assemble the wrapper classes.
        const wrapperClasses = ['date-range-picker__input-wrapper'];
        if (isEnabled && !isReadOnly) {
            wrapperClasses.push('date-range-picker__input-wrapper--active');
        }
        if (!isEnabled) {
            wrapperClasses.push('date-range-picker__input-wrapper--disabled');
        }
        if (isInline) {
            wrapperClasses.push('date-range-picker__input-wrapper--inline');
        } else {
            wrapperClasses.push('date-range-picker__input-wrapper--standard');
        }

        const divProps = {
            className: wrapperClasses.join(' '),
            disabled:
                // We want to manually add the [disabled] attribute to this element
                // so it automatically inherits the disabled CSS styling.
                isEnabled ? undefined : true,
        };

        return (
            <FormSection
                name={(field as UiFieldModel).name || (field as string)}
            >
                {/* This Fragment prevents `FormSection` from injecting an empty `div`. */}
                <>
                    <div
                        className={rootClasses.join(' ')}
                        ref={(node) => {
                            this.rootNode = node;
                        }}
                        id={getInputId(DateRangePickerField, this.props)}
                        style={style}
                    >
                        <div {...divProps}>
                            <DateTimeTextInput
                                isReadOnly={startDateReadOnly}
                                isEnabled={isEnabled}
                                isInline={isInline}
                                field={START_DATE}
                                className={startPickerClasses.join(' ')}
                                onFocus={this.onStartDateFocus}
                                includesTime={includesTime}
                                theme='date-range-picker'
                            />
                            <span
                                className={`date-range-picker__divider ${
                                    isInline
                                        ? 'date-range-picker__divider--inline'
                                        : ''
                                }`}
                            >
                                -
                            </span>
                            <DateTimeTextInput
                                field={END_DATE}
                                isEnabled={isEnabled}
                                isInline={isInline}
                                placeholder={
                                    hasNoEndDate ? Strings.noEndDate : ''
                                }
                                isReadOnly={endDateReadOnly}
                                className={endPickerClasses.join(' ')}
                                onFocus={this.onEndDateFocus}
                                includesTime={includesTime}
                                theme='date-range-picker'
                            />
                        </div>
                        {((!isReadOnly && // not readonly (this implies the whole thing is readonly)
                            (!startDateReadOnly || !endDateReadOnly)) || // either start or end-date is not readonly
                            hasNoEndDate) && (
                            <Fields
                                maxEndDate={maxEndDate}
                                minStartDate={minStartDate}
                                component={DateRangePickerField}
                                names={[START_DATE, END_DATE, HAS_NO_END_DATE]}
                                isOpen={this.state.isOpen}
                                includesTime={includesTime}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={this.onFocusChange}
                                isStartDateReadOnly={startDateReadOnly}
                                isEndDateReadOnly={endDateReadOnly}
                                endDateIsOptional={endDateIsOptional}
                                timeZoneLabel={timeZoneLabel}
                                predefineEndTimeToEndOfDay={
                                    predefineEndTimeToEndOfDay
                                }
                            />
                        )}

                        <div
                            className={`date-range-picker__validation ${
                                isValidationInline
                                    ? 'date-range-picker__validation--inline'
                                    : ''
                            }`}
                        >
                            {!startDateReadOnly && (
                                <ValidationOnlyInput
                                    field='startDate'
                                    className='date-range-picker__validator'
                                    isValidationInline={isValidationInline}
                                />
                            )}
                            {!endDateReadOnly && (
                                <ValidationOnlyInput
                                    field='endDate'
                                    className='date-range-picker__validator'
                                    isValidationInline={isValidationInline}
                                />
                            )}
                            <ValidationError
                                meta={meta}
                                isInline={isValidationInline}
                            />
                        </div>
                    </div>
                </>
            </FormSection>
        );
    }
}

type DateRangePickerInputTheme = 'campaign-flights' | 'date-range-filter';

export interface DateRangePickerInputProps {
    field: TtdField;
    // do the date inputs also contain time of day?
    includesTime?: boolean;
    // ui time zone used for labeling the time input when includesTime is set to true.
    timeZoneLabel: ITimeZone;
    // Whether to display validation for this component inline
    isValidationInline?: boolean;
    // Whether to display this component in the inline style
    isInline?: boolean;
    // show a checkbox allowing for an open end date.
    endDateIsOptional?: boolean;
    // css class to apply to the wrapper
    className?: string;
    // unique ID to assign to the component
    id?: string;
    // component theme
    theme?: DateRangePickerInputTheme;
    // style to apply to the wrapper
    style?: CSSProperties;
    // is this readonly?
    isReadOnly?: boolean;
    // Start date is fixed, cannot change
    isStartDateReadOnly?: boolean;
    // End date is fixed, cannot change
    isEndDateReadOnly?: boolean;
    // is this enabled, defaults to true?
    isEnabled?: boolean;
    // Should end time be pre-defined to the end of day instead of beginning?
    predefineEndTimeToEndOfDay?: boolean;
    // optional date to set as the maximum end date selectable.
    maxEndDate?: IDateTime;
    // optional date to set as the earliest start date selectable
    minStartDate?: IDateTime;
    // optional onBlur callback
    onBlur?(e?: React.FormEvent): void;
}

export class DateRangePickerInput extends React.Component<
    DateRangePickerInputProps
> {
    static displayName = 'DateRangePickerInput';

    static defaultProps: Partial<DateRangePickerInputProps> = {
        includesTime: false,
        isEnabled: true,
        isInline: false,
        className: '',
        onBlur: () => null,
    };

    render() {
        return (
            <TtdInputField
                component={DateRangePickerInputFields}
                field={this.props.field}
                fieldProps={this.props}
            />
        );
    }
}
