import ReactDOM from 'react-dom';

/**
 * Uses a best-effort algorithm to determine the DOM element to set focus.
 *
 * If the given object is a react component instance with a focus() method, it calls focus()
 * Otherwise it finds the first focusable element under the DOM element decorated with the 'autoFocus' class name.
 *
 * @param {Element|Object} componentOrElement - React component instance or DOM element to set focus to
 */
/**
 * @param {Boolean} selectText - if set to true, the text of the input will be selected after focus.
 */
export function autoFocus(
    componentOrElement,
    selectText?,
    isFocusableFunc = isFocusable
) {
    const isElement = isDOMElement(componentOrElement);
    if (
        ((isElement && isFocusableFunc(componentOrElement)) || !isElement) &&
        componentOrElement.focus
    ) {
        componentOrElement.focus();
        if (selectText) {
            componentOrElement.select();
        }
        return componentOrElement;
    } else {
        const element = isElement
            ? componentOrElement
            : ReactDOM.findDOMNode(componentOrElement);
        if (element) {
            const autoFocusElements =
                element.classList && element.classList.contains('autoFocus')
                    ? [element]
                    : element.getElementsByClassName('autoFocus');
            if (autoFocusElements && autoFocusElements.length > 0) {
                const autoFocusElement = autoFocusElements[0];
                if (isFocusableFunc(autoFocusElement)) {
                    autoFocusElement.focus();
                    if (selectText) {
                        autoFocusElement.select();
                    }
                    return autoFocusElement;
                } else {
                    const focusableCandidates = autoFocusElement.querySelectorAll(
                        getFocusableSelector()
                    );
                    for (let i = 0; i < focusableCandidates.length; i++) {
                        if (isFocusableFunc(focusableCandidates[i])) {
                            focusableCandidates[i].focus();
                            if (selectText) {
                                focusableCandidates[i].select();
                            }
                            return focusableCandidates[i];
                        }
                    }
                }
            }
        }
    }
    return null;
}

export function isFocusable(element, isVisible = isElementVisible) {
    if (!element || !isVisible(element)) {
        return false;
    }

    const tagName = element.tagName.toLowerCase();
    if (
        tagName === 'input' ||
        tagName === 'select' ||
        tagName === 'button' ||
        tagName === 'textarea'
    ) {
        return true;
    }

    if (tagName === 'a' && element.hasAttribute('href')) {
        return true;
    }

    if (element.hasAttribute('contenteditable')) {
        return true;
    }

    if (hasValidTabIndex(element)) {
        return true;
    }

    return false;
}

function isDOMElement(element) {
    return !!element && element instanceof Element;
}

function getFocusableSelector() {
    const selector =
        '' +
        'a,' +
        'a[href],' +
        'area[href],' +
        'input, select, textarea, button,' +
        '[tabindex],' +
        '[contenteditable]';

    return selector;
}

function hasValidTabIndex(element) {
    const hasTabIndex = element.hasAttribute('tabindex');
    if (!hasTabIndex) {
        return false;
    }

    const tabIndex = element.getAttribute('tabindex');
    return tabIndex && !isNaN(tabIndex);
}

function isElementVisible(element) {
    // http://blog.jquery.com/2009/02/20/jquery-1-3-2-released/#:visible.2F:hidden_Overhauled
    return element.offsetWidth > 0 && element.offsetHeight > 0;
}
