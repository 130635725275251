// Can be used for cases where a component requires a function (e.g. onChange in CheckboxControl), but the
// component is enclosed in a wrapper component that does the handling (e.g. "select all" checkbox
// in a MultiSelectListControl); avoids the constant re-rendering that a () => {} can cause
export function emptyFunction() {}

// Regex refer to https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid, \d matches the version number.
export const isUUID = (id: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-\d[0-9a-f]{3}-[89AB0][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        id
    );
};
