import React, { ReactNode, isValidElement, Fragment } from 'react';

import {
    DataFilter,
    DataFilterProps,
} from 'components/display/DataFilters/SingleDataFilter';
import DataFilters, {
    DataFiltersProps,
    CLEAR_FILTER_BUTTON_POSITION,
} from 'components/display/DataFilters';
import Filters, { FiltersProps } from 'components/display/Filters';
import FilterCoordinator from 'util/FilterCoordinator';
import {
    FilterProps,
    FilterProp,
} from '../DataFilters/SingleDataFilter/FilterProps';

export interface BaseCoordinatedDataFiltersProps<TFilter>
    extends Omit<
            DataFiltersProps,
            'clearFields' | 'stateKey' | 'leftFilters' | 'rightFilters'
        >,
        Pick<FiltersProps, 'embedFiltersInPortal' | 'maxFilterElements'> {
    onFilterChange(filter: TFilter): void;

    handleFilterRequest?(requestParameters: any): void;

    handleFilterResponse?(response: any): any;

    handleFilterError?(error: any): any;

    children?: ReactNode;
}

export enum CoordinatedDataFilterMode {
    AllVisible = 'AllVisible',
    HideOverflow = 'HideOverflow',
}

export type CoordinatedDataFiltersFiltersProps =
    | {
          filterMode?: CoordinatedDataFilterMode.AllVisible;
          leftFilters?: Array<ReactNode | FilterProp>;
          rightFilters?: Array<ReactNode | FilterProp>;
          filters?: never;
      }
    | {
          filterMode: CoordinatedDataFilterMode.HideOverflow;
          leftFilters?: never;
          rightFilters?: never;
          filters: FilterProps;
      };

// Explicitly not exporting this else typings don't work correctly
type CoordinatedDataFiltersProps<TFilter> = BaseCoordinatedDataFiltersProps<
    TFilter
> &
    CoordinatedDataFiltersFiltersProps;

class CoordinatedDataFiltersComponent<TFilter> extends React.Component<
    CoordinatedDataFiltersProps<TFilter>
> {
    filterCoordinator: FilterCoordinator;

    static defaultProps = {
        initialValues: {},
        leftFilters: [],
        rightFilters: [],
        clearableFilters: [],
        clearFilterButtonPosition: CLEAR_FILTER_BUTTON_POSITION.CENTER,
        filterMode: CoordinatedDataFilterMode.AllVisible,
    };

    constructor(props: CoordinatedDataFiltersProps<TFilter>) {
        super(props);
        this.filterCoordinator = this.getFilterCoordinator();
    }

    getFilterProps = () => {
        // Not destructuring to help types propagate through correctly
        if (this.props.filterMode === CoordinatedDataFilterMode.HideOverflow) {
            const {
                filters,
                embedFiltersInPortal,
                maxFilterElements,
            } = this.props;
            return {
                filters: filters.filter((f) => !!f),
                embedFiltersInPortal,
                maxFilterElements,
            };
        } else {
            const { leftFilters, rightFilters } = this.props;
            const getFilterComponent = (
                filter: FiltersProps['filters'][0],
                index: number
            ) => {
                const Component = isValidElement(filter)
                    ? Fragment
                    : DataFilter;
                const componentProps = isValidElement(filter)
                    ? ({} as never)
                    : ({
                          filter,
                          className: 'enhanced-datatable-filters__filter',
                      } as DataFilterProps);
                return (
                    <Component key={index} {...componentProps}>
                        {filter}
                    </Component>
                );
            };

            const left = (leftFilters || [])
                .filter((f) => !!f)
                .map(getFilterComponent);
            const right = (rightFilters || [])
                .filter((f) => !!f)
                .map(getFilterComponent);

            return {
                leftFilters: left,
                rightFilters: right,
            };
        }
    };

    getFilterCoordinator = () => {
        return new FilterCoordinator<TFilter>({
            getRequest: (filter) => {
                return this.props.onFilterChange(filter);
            },
            handleResponse: (response) => {
                return this.props.handleFilterResponse
                    ? this.props.handleFilterResponse(response)
                    : Promise.resolve();
            },
            handleError: (error) => {
                return this.props.handleFilterError
                    ? this.props.handleFilterError(error)
                    : Promise.resolve();
            },
        });
    };

    onFilterChanged = (filter: TFilter) => {
        this.filterCoordinator && this.filterCoordinator.push(filter);
    };

    render() {
        const {
            className,
            children,
            initialValues,
            clearableFilters,
            formName,
            filterMode,
            onClearFilters,
        } = this.props;

        const FiltersComponent =
            filterMode === CoordinatedDataFilterMode.HideOverflow
                ? Filters
                : DataFilters;
        return (
            <>
                <FiltersComponent
                    className={className}
                    onChange={this.onFilterChanged}
                    initialValues={initialValues}
                    clearableFilters={clearableFilters}
                    onClearFilters={onClearFilters}
                    formName={formName}
                    {...this.getFilterProps()}
                />
                {children}
            </>
        );
    }
}

export default CoordinatedDataFiltersComponent;
