﻿import React, { SyntheticEvent } from 'react';
import withReadOnly, {
    ReadOnlyComponentProps,
} from 'components/hocs/withReadOnly';
import themeClassName from 'util/Themes';
import './ToggleControl.scss';

export interface ToggleControlProps extends ReadOnlyComponentProps {
    id: string;
    name?: string;
    onBeforeChange?(nextValue: boolean, previousValue: boolean): boolean;
    onChange(value: boolean): void;
    onBlur?(event: SyntheticEvent): void;
    onFocus?(event: SyntheticEvent): void;
    isEnabled?: boolean;
    // Most instances of ToggleControl don't have a "loading" state -
    // they change immediately from On to Off upon clicking. Some,
    // however, such as the AdGroup Status toggle, do something non-trivial
    // before switching (such as making an API call, etc). For these
    // controls, we want to give the user some visual feedback
    // immediately upon click (isLoading => true) so they know the
    // system is "working" behind the scenes. (And so they don't try
    // re-clicking the toggle because they thought nothing happened.)
    isLoading?: boolean;
    className?: string;
    style?: React.CSSProperties;
    theme?: 'stoplight' | '';
    value: boolean;
}

class ToggleControl extends React.Component<ToggleControlProps> {
    static defaultProps: Partial<ToggleControlProps> = {
        isEnabled: true,
        theme: '',
    };

    onChange = (event: SyntheticEvent) => {
        const newValue = !this.props.value;

        if (
            this.props.onBeforeChange &&
            this.props.onBeforeChange(newValue, this.props.value) === false
        ) {
            return;
        }

        if (this.props.onChange) {
            this.props.onChange(newValue);
        }

        // Force a focus/blur event even though the component can't take focus.
        // This lets redux-form know that the component was touched.
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    render() {
        // We don't want use name or onChange directly, but we do want them to be excluded from '...rest'
        const {
            id,
            className,
            style,
            theme,
            onChange,
            onBeforeChange,
            isReadOnly,
            isEnabled,
            isLoading,
            value,
            ...rest
        } = this.props;

        const isActive = !isReadOnly && isEnabled;

        const rootClasses = ['toggle-control', className || ''];
        if (theme) {
            rootClasses.push(themeClassName(theme));
        }

        const toggleClasses = ['toggle-control__toggle'];
        if (value) {
            toggleClasses.push('toggle-control__toggle--checked');
        }
        if (!isActive) {
            toggleClasses.push('toggle-control__toggle--disabled');
        }
        if (isLoading) {
            toggleClasses.push('toggle-control__toggle--loading');
        }

        return (
            <div className={rootClasses.join(' ')} style={style}>
                <input
                    type='checkbox'
                    id={id}
                    className='toggle-control__input'
                    disabled={!isActive}
                    checked={!!value}
                    readOnly={isReadOnly}
                    // Disable onChange while loading to ensure someone can't repeatedly press the
                    // spacebar to rapidly toggle the control.
                    onChange={
                        isActive && !isLoading ? this.onChange : undefined
                    }
                    {...rest}
                />
                <label
                    className={toggleClasses.join(' ')}
                    htmlFor={id}
                    data-clickable='true'
                />
            </div>
        );
    }
}

export default withReadOnly(ToggleControl);
