import React, { Component } from 'react';
import TtdInputField, {
    TtdInputFieldComponentProps,
} from 'components/inputs/TtdInputField';
import ValidationError from 'components/display/ValidationError';
import getInputId from 'util/getInputId';
import EnumSelectListControl, {
    EnumSelectListControlProps,
} from 'components/controls/EnumSelectListControl/EnumSelectListControl';

import { WrappedFieldProps } from 'redux-form';
import { IUiEnum } from 'util/UiEnumUtils';

export interface EnumSelectListInputProps<T extends IUiEnum>
    extends Omit<
        TtdInputFieldComponentProps<
            Omit<EnumSelectListControlProps<T>, 'onChange' | 'value' | 'id'>
        >,
        'onChange'
    > {
    style?: React.CSSProperties;
    className?: string;
    onChange?(val: T[keyof T]): void;
    initialSelectedValue?: any;
    isInline?: boolean;
    showValidationError?: boolean;
    id?: string;
}

interface EnumSelectListControlFieldProps<T extends IUiEnum>
    extends Omit<EnumSelectListInputProps<T>, 'onChange'> {
    onFieldValueChange?(val: any): void;
}

class EnumSelectListInputField<T extends IUiEnum> extends Component<
    EnumSelectListControlFieldProps<T> & WrappedFieldProps
> {
    onChange = (val) => {
        this.props.input.onChange(val);
        this.props.onFieldValueChange && this.props.onFieldValueChange(val);
    };

    render() {
        const {
            input,
            meta,
            initialSelectedValue,
            showValidationError,
            ...rest
        } = this.props;

        return (
            <span>
                <EnumSelectListControl<T>
                    id={getInputId(EnumSelectListInputComponent, this.props)}
                    onChange={this.onChange}
                    value={input.value || initialSelectedValue}
                    {...rest}
                />
                {showValidationError && (
                    <ValidationError
                        isInline={this.props.isInline}
                        meta={meta}
                    />
                )}
            </span>
        );
    }
}

export default class EnumSelectListInputComponent<
    T extends IUiEnum
> extends Component<EnumSelectListInputProps<T>> {
    static defaultProps = {
        isEnabled: true,
        showValidationError: true,
    };

    render() {
        const { className, onChange, style, field, ...rest } = this.props;
        return (
            <div className={className} style={style}>
                <TtdInputField
                    {...rest}
                    onFieldValueChange={onChange}
                    field={field}
                    component={EnumSelectListInputField}
                />
            </div>
        );
    }
}
