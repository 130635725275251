import React from 'react';
import { Prompt } from 'react-router-dom';
import BasicDialog, { DIALOG_SIZE } from '../BasicDialog';
import DialogHeader from '../DialogHeader';
import DialogActionBar from '../DialogActionBar';
import DialogStatusPage from '../DialogStatusPage';
import { FormChangeFunctionType } from '../../../hocs/ttdForm/ttdForm';
import ttdTaskDialogPage from './ttdTaskDialogPage';
import {
    DIALOG_STATUS,
    IDialogStatusDetails,
    DialogConfig,
    SuccessData,
    ErrorData,
} from '../DialogActions';
import { BUTTON_TYPE } from '../../Button';
import Strings from './TaskDialogComponent.strings.json';
import { Localization } from 'util/LocalizationService';
import withRouter, { RouteComponentProps } from 'components/hocs/withRouter';
import './TaskDialog.scss';

export interface TaskDialogProps extends React.Props<TaskDialogProps> {
    // All dialogs require a globally unique ID.  It is the parameter to pass to
    // dialog actions (showDialog, hideDialog, etc.).
    dialogId: string;

    // The title of the dialog shown in the header bar.
    title: string;

    // The optional subtitle of the dialog shown in the header bar.
    subtitle?: string;

    // The size of the dialog.
    size: DIALOG_SIZE;

    // Optional property for dialogs of size SMALL or MEDIUM to define the maximum expected height of the dialog.
    // This is used to determine the vertical positioning of dialogs that don't expand to the full window height.
    maxDialogHeight?: number;

    // The height of the dialog in pixels.
    height?: number;

    // An optional className to add to the root element in this component.
    className?: string;

    // An optional theme for this component.
    theme?: string;

    // An optional theme for the dialog header
    headerTheme?: string;

    // The UiModel that is the focus of this dialog.  It's the model that is
    // validated when submitting the page and performing the final action.
    uiModel?: any;

    // The optional initial model data to use when initializing this dialog.  You
    // must either provide this model (the synchronous method) OR dispatch an action
    // on an AspNetController using the onOpen prop.
    // Your action creator must take in the dialogConfig passed back from this dialog in the onOpen function
    // and pass it into the constructor of the AspNetController. If you do this, then standard dialog state changes
    // and errors will be handled automatically and the initial model fetched from the server will also be passed
    // back to the dialog automatically.
    // The synchronous method (providing the initialModel) might be used when
    // implementing a dialog that uses an empty model or a simple model (like a
    // creation dialog), while the asynchronous method might be used when implementing a
    // details dialog where the values for the model need to be fetched from the server.
    initialModel?: any;

    // Optional functions that implement custom validation and warnings for the
    // uiModel.  If provided, they are called whenever a step of the dialog is
    // attempted to be submitted.  The functions are called with the validation
    // service and the model as parameters.
    validate?: any;
    warn?: any;

    // The configuration of the final action of this dialog.
    action?: {
        // The function that is called when the final action of the dialog is to be
        // executed (after all steps have been completed and the model validated).
        // This function usually calls an action creator that saves data to the server.
        // receives 2 parameters:
        // 1. values from redux-form.
        // 2. dialogConfig which contains properties used to manage the dialog's state, including success and error callbacks.
        //      See getDialogConfig() for what is included in the the config object
        onSubmit: (values: any, dialogConfig: DialogConfig) => void;

        // The name of the action, used as the label of the action button on the dialog.
        // If this is not specified, the default action name will be used.
        name?: string;

        // The type of the action.
        type: BUTTON_TYPE;

        // An optional custom title that is shown under the success icon
        // instead of the traditional "Success!" title
        successTitle?: string;

        // An optional custom message or component that is shown under the success icon and "Success!"
        // title when the action was successful.  This is often useful in conjunction with
        // the successAction to tell the user they can either close the dialog or
        // do the success action.
        successMessage?: string | JSX.Element;

        // An optional custom message that is shown under the loading indicator while submitting.
        // `Message` is displayed as the dialog's header. `Subtext` is smaller text beneath it.
        submittingSubtext?: string;
        submittingMessage?: string;

        // We can optionally display a count-up timer during long submissions (see MEGA-2650
        // for background and screenshots). This appears beneath the `submitting` message+subtext.
        showTimerWhileSubmitting?: boolean;

        // Is the action button enabled? Must be set to actual false for the button to be disabled.
        // Note: true by default.
        isEnabled?: boolean;
    };

    getStatusMessageTitle?(DIALOG_STATUS): JSX.Element;

    // An optional function, called when the actions for the dialog are created.  This
    // allows the consumer to override the buttons for a task dialog entirely (e.g.,
    // having multiple actions).  If provided, the function should return the list of actions (in
    // the shape of actions from the DialogActionBarComponent).
    //
    // If not provided (the default), the consumer should provide the action property (see above) to
    // get the standard "cancel" and action buttons.
    //
    // The function is called with two parameters:
    // 1. tryCancel (function used as an onClick for cancel-type actions)
    // 2. submitForm (function used as an onClick for form-submission actions)
    getTaskActions?: any;

    // Boolean indicating whether hitting the enter key when the dialog is open should trigger a submit action
    enterToSubmit?: boolean;

    // An optional object that describes an additional action to display on the
    // "success" page of the dialog (e.g., "Go to Ad Group" on the success page of the
    // create ad group wizard).
    successAction?: {
        label: JSX.Element;
        onClick: (dialogId: string, action: any) => void;
    };

    // An optional function, called when the user cancels the dialog (either via
    // the 'cancel' button or closing the dialog via the X or hitting the escape key).
    // If this function is provided, the dialog consumer is in charge of actually closing the dialog
    // if it's appropriate, confirming the dirty nature of the dialog, etc.
    // If this function is not provided, the dialog simply closes when the user cancels the dialog.
    onCancel?: (dialogId: string) => void;

    // An optional function, called when the dialog opens.  If initializing the dialog
    // with the asynchronous method, use this function to dispatch an action on an
    // AspNetController (see initialModel for more information).
    onOpen?: (config: any) => void;

    // An optional function, called when the dialog closes.
    onClose?: (dialogId: string) => void;

    // An optional override to specify the text to include in the final "close" dialog.
    closeActionLabel?: string;

    //content to show in the left section of the action bar component.
    actionBarContent?: JSX.Element;

    // show confirmation when user cancel's task dialog (default true)
    // this prop is ignored if a user passes in an {onCancel} prop function
    // if set true true and this form truly is "dirty" an additional confirmation dialog will render prior to closing this instance of dialog.
    showConfirmOnDirtyCancel?: boolean;

    // Prevents the dialog from closing automatically after the success message, even if a successAction is not defined.
    // If a successAction is not defined and this prop is not true, the DialogActionBar component will close the dialog automatically
    // when the status is SUBMIT_SUCCESS
    disableAutoCloseOnSuccess?: boolean;

    // Prevents back button from rendering on error
    suppressBackButtonOnError?: boolean;
    // Prevents retry button from rendering on error
    suppressRetryButtonOnError?: boolean;
    // Prevents the cancel button from rendering on the success page
    suppressCloseButtonOnSuccess?: boolean;

    // From connected container
    dialogStatus: DIALOG_STATUS;
    //initialValues: PropTypes.object,
    setDialogStatusDetails: (details: IDialogStatusDetails) => void;
    hideDialog: (dialogId: string) => void;
    submitForm: (formId: string) => void;
    destroyForm: (formId: string) => void;
    initializeDialogConfirmation: (formId: string, dialogId: string) => void;
    //currentConfirmationFormName: string,
    isDirty: boolean;
    formId: string;
    isConfirmationPending: boolean;

    // A callback function that will be called with all the form values any time any of the form values change.
    onChange?: FormChangeFunctionType;

    // A callback function that will be called when the form is initialized
    onFormInitialized?(initialMode: any): void;

    // Boolean flag to indicate whether to warn users on route change if isDirty == true
    shouldWarnUserOnRouteChange?: boolean;
}

interface TaskDialogState {
    isConfirmationPending: boolean;
}

export const closeDialogAction = (tryCancel, submitForm) => [
    {
        label: Localization.getString(Strings.close),
        type: BUTTON_TYPE.PRIMARY,
        isFlat: true,
        onClick: tryCancel,
    },
];

type TaskDialogComponentProps = TaskDialogProps & RouteComponentProps<any>;

// A TaskDialog is a single-stepped task dialog, meant to enable the user
// to perform a task with a clear end step (usually saving some data).
// See SteppedTaskDialog for the multi-stepped equivalent (wizards).
class TaskDialogComponent extends React.Component<
    TaskDialogComponentProps,
    TaskDialogState
> {
    static propTypes = {};

    static defaultProps = {
        size: DIALOG_SIZE.MEDIUM,
        className: '',
        showConfirmOnDirtyCancel: true,
        enterToSubmit: false,
        // TODO: Fixed when updting withRouter, please make default props match props definition of action
        action: {} as TaskDialogProps['action'],
        shouldWarnUserOnRouteChange: false,
    };

    dialogPage: any;
    currentStep: any;
    taskDialogFooter: HTMLDivElement;
    disposeRouteLeaveHook: () => any;

    constructor(props) {
        super(props);

        this.state = {
            isConfirmationPending: false,
        };

        this.wrappedOnCancel = this.wrappedOnCancel.bind(this);
        this.wrappedOnSubmit = this.wrappedOnSubmit.bind(this);
        this.wrappedOnOpen = this.wrappedOnOpen.bind(this);
        this.wrappedOnClose = this.wrappedOnClose.bind(this);
        this.retryAction = this.retryAction.bind(this);

        this.successCallback = this.successCallback.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.dialogProgressCallback = this.dialogProgressCallback.bind(this);

        this.dialogPage = ttdTaskDialogPage(
            this.props.formId,
            this.props.uiModel,
            this.props.validate,
            this.props.warn,
            this.props.onChange
        );
    }

    componentWillReceiveProps(nextProps) {
        const currentPendingState = this.state.isConfirmationPending;

        this.setState((prevState, nextProps) => ({
            isConfirmationPending: nextProps.isConfirmationPending,
        }));

        if (
            currentPendingState &&
            !nextProps.isConfirmationPending &&
            nextProps.currentConfirmationFormName
        ) {
            this.props.hideDialog(this.props.dialogId);
        }

        if (
            this.props.uiModel !== nextProps.uiModel ||
            this.props.validate !== nextProps.validate ||
            this.props.warn !== nextProps.warn
        ) {
            this.dialogPage = ttdTaskDialogPage(
                this.props.formId,
                nextProps.uiModel,
                nextProps.validate,
                nextProps.warn,
                this.props.onChange
            );
        }
    }

    componentDidUpdate() {
        if (this.taskDialogFooter) {
            const hiddenClassName = 'task-dialog__footer--hidden';
            if (!this.taskDialogFooter.hasChildNodes()) {
                !this.taskDialogFooter.classList.contains(hiddenClassName) &&
                    this.taskDialogFooter.classList.add(hiddenClassName);
            } else {
                this.taskDialogFooter.classList.remove(hiddenClassName);
            }
        }
    }

    // Dialog Configs
    get successConfig(): IDialogStatusDetails {
        return {
            dialogStatus: DIALOG_STATUS.SUBMIT_SUCCESS,
            dialogId: this.props.dialogId,
            messageDetail:
                typeof this.props.action.successMessage === 'string'
                    ? this.props.action.successMessage
                    : undefined,
            statusText:
                this.props.action.successTitle ||
                Localization.getString(Strings.success),
        };
    }

    getMessageDetail = (dialogStatus: DIALOG_STATUS) => {
        return dialogStatus === DIALOG_STATUS.SUBMIT_SUCCESS
            ? this.props.action.successMessage
            : undefined;
    };

    get errorConfig() {
        return {
            dialogStatus: DIALOG_STATUS.SUBMIT_FAILURE,
            dialogId: this.props.dialogId,
        };
    }

    initialConfig(initialData) {
        return {
            dialogStatus: DIALOG_STATUS.UNSUBMITTED,
            initialValues: initialData,
            onFormInitialized: this.props.onFormInitialized,
            dialogId: this.props.dialogId,
        };
    }

    getSubmitFormFn(formId) {
        return () => {
            if (this.props.dialogStatus === DIALOG_STATUS.UNSUBMITTED) {
                this.props.submitForm(formId);
            }
        };
    }

    // Utility that generates the actions for the action bar.
    getActions(dialogId, submitFormFn, action, onCancel, getTaskActions) {
        if (getTaskActions) {
            // The consumer has indicated that they want to control the actions,
            // so call them and let them do it.
            return getTaskActions(onCancel, submitFormFn);
        }

        return [
            {
                label: Localization.getString(Strings.cancel),
                onClick: onCancel,
                isFlat: true,
            },
            {
                label: action.name || Localization.getString(Strings.save),
                type: action.type || BUTTON_TYPE.ACTION,
                onClick: submitFormFn,
                isSubmission: true,
                isEnabled: !(action.isEnabled === false),
            },
        ];
    }

    getDialogConfig(nextStatus): DialogConfig {
        return {
            dialogId: this.props.dialogId,
            successCallback: this.successCallback,
            errorCallback: this.errorCallback,
            progressCallback: this.dialogProgressCallback,
            successParams: {
                nextStatus: nextStatus,
            },
            errorParams: {},
        };
    }

    // Wrapped Dialog Actions
    wrappedOnCancel() {
        if (this.props.onCancel) {
            this.props.onCancel(this.props.dialogId);
        } else {
            // By default, we'll hide the dialog when the cancel action happens (the close 'X' or hitting the escape key).
            // If the consumer passes an onCancel, they are in charge of hiding the dialog when they see fit (e.g., after
            // confirming the intention of the user).
            if (
                this.props.isDirty &&
                this.props.showConfirmOnDirtyCancel &&
                this.props.dialogStatus !== DIALOG_STATUS.SUBMIT_SUCCESS
            ) {
                this.props.initializeDialogConfirmation(
                    this.props.formId,
                    this.props.dialogId
                );
                return;
            }
            this.props.hideDialog(this.props.dialogId);
        }
    }

    wrappedOnSubmit(values) {
        this.props.setDialogStatusDetails({
            dialogStatus: DIALOG_STATUS.SUBMITTING,
            dialogId: this.props.dialogId,
            messageDetail: this.props.action.submittingSubtext || '',
            showTimerWhileSubmitting: this.props.action
                .showTimerWhileSubmitting,
            statusText: this.props.action.submittingMessage || '',
        });

        // Determine the default dialog config
        const config = this.getDialogConfig(DIALOG_STATUS.SUBMIT_SUCCESS);

        this.props.action.onSubmit(values, config);
    }

    wrappedOnOpen() {
        if (this.props.initialModel) {
            this.props.setDialogStatusDetails({
                dialogStatus: DIALOG_STATUS.UNSUBMITTED,
                initialValues: this.props.initialModel,
                onFormInitialized: this.props.onFormInitialized,
                dialogId: this.props.dialogId,
            });
        } else {
            this.props.setDialogStatusDetails({
                dialogStatus: DIALOG_STATUS.INITIALIZING,
                dialogId: this.props.dialogId,
            });
        }

        if (this.props.onOpen) {
            const config = this.getDialogConfig(DIALOG_STATUS.UNSUBMITTED);
            this.props.onOpen(config);
        }
    }

    wrappedOnClose() {
        // We told redux form to not destroy the form on unmount, so we're in charge of its
        // destruction.  Now is the time to do so, since the dialog is closing.
        this.props.destroyForm(this.props.formId);

        if (this.disposeRouteLeaveHook) {
            this.disposeRouteLeaveHook();
        }

        if (this.props.onClose) {
            this.props.onClose(this.props.dialogId);
        }
    }

    retryAction() {
        return this.props.submitForm(this.props.formId);
    }

    // Callbacks
    successCallback = (successData: SuccessData) => {
        if (!successData) return;

        switch (successData.nextStatus) {
            case DIALOG_STATUS.UNSUBMITTED:
                this.props.setDialogStatusDetails(
                    this.initialConfig(successData.successResponse)
                );
                break;
            case DIALOG_STATUS.SUBMIT_SUCCESS:
                this.props.setDialogStatusDetails(this.successConfig);
                break;
            case DIALOG_STATUS.SUBMITTING:
                // Get our step's submitting message
                this.props.setDialogStatusDetails({
                    dialogId: this.props.dialogId,
                    dialogStatus: successData.nextStatus,
                    statusText:
                        (this.currentStep &&
                            this.currentStep.stepAction &&
                            this.currentStep.stepAction.submittingMessage) ||
                        '',
                });
                break;
            default:
                this.props.setDialogStatusDetails({
                    dialogId: this.props.dialogId,
                    dialogStatus: successData.nextStatus,
                });
                break;
        }
    };

    static statusCodesNotBackable = [422];
    static statusCodesNotRetryable = [422];

    errorCallback = (errorData: ErrorData) => {
        this.props.setDialogStatusDetails({
            dialogId: this.props.dialogId, // don't expect dialogId to be passed with the errorData
            dialogStatus: DIALOG_STATUS.ERROR,
            statusCode: errorData.statusCode || 400,
            statusText: errorData.type || 'Client-side application error.',
            suppressBackButtonOnError: TaskDialogComponent.statusCodesNotBackable.includes(
                errorData.statusCode
            ),
            suppressRetryButtonOnError: TaskDialogComponent.statusCodesNotRetryable.includes(
                errorData.statusCode
            ),
            messageGeneral: errorData.message,
            messageDetail: errorData.messageDetail || '',
        });
    };

    // Progress rate is 0-1. You must pass a separate flag indicating completeness -- this is to try and avoid potential rounding issues with the progressRate.
    dialogProgressCallback = (progressRate, isComplete) => {
        if (!isComplete) {
            // Still submitting
            this.props.setDialogStatusDetails({
                dialogId: this.props.dialogId,
                dialogStatus: DIALOG_STATUS.SUBMITTING,
                pollingProgress: progressRate || 0,
                statusText:
                    (this.currentStep &&
                        this.currentStep.stepAction &&
                        this.currentStep.stepAction.submittingMessage) ||
                    '',
            });
        } else {
            // Done
            this.props.setDialogStatusDetails(this.successConfig);
        }
    };

    render() {
        const {
            dialogId,
            title,
            subtitle,
            size,
            maxDialogHeight,
            className,
            theme,
            headerTheme,
            height,
            action,
            getTaskActions,
            successAction,
            actionBarContent,
            closeActionLabel,
            dialogStatus,
            enterToSubmit,
            shouldWarnUserOnRouteChange,
            isDirty,
        } = this.props;

        const submitFormFn = this.getSubmitFormFn(this.props.formId);
        const actions = this.getActions(
            dialogId,
            submitFormFn,
            action,
            this.wrappedOnCancel,
            getTaskActions
        );
        const DialogPage = this.dialogPage;

        return (
            <>
                <Prompt
                    message={Localization.getString(
                        Strings.confirmNavigationMessage
                    )}
                    when={shouldWarnUserOnRouteChange && isDirty}
                />

                <BasicDialog
                    dialogId={dialogId}
                    escapeToCancel
                    size={size}
                    maxDialogHeight={maxDialogHeight}
                    className={`task-dialog ${className}`}
                    height={height}
                    theme={theme}
                    onCancel={this.wrappedOnCancel}
                    onOpen={this.wrappedOnOpen}
                    onClose={this.wrappedOnClose}
                    onEnterKeyHit={enterToSubmit ? submitFormFn : undefined}
                >
                    <DialogHeader
                        dialogId={dialogId}
                        title={title}
                        subtitle={subtitle}
                        onCancel={this.wrappedOnCancel}
                        theme={headerTheme}
                    />
                    <DialogStatusPage
                        dialogId={dialogId}
                        className='task-dialog__content'
                        getMessageDetail={this.getMessageDetail}
                        getMessageTitle={this.props.getStatusMessageTitle}
                    />
                    <div
                        className='task-dialog__content basic-dialog__content'
                        style={{
                            display:
                                dialogStatus === DIALOG_STATUS.UNSUBMITTED
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        <DialogPage onSubmit={this.wrappedOnSubmit}>
                            {this.props.children}
                        </DialogPage>
                    </div>
                    <div
                        className='task-dialog__footer'
                        ref={(node) => {
                            this.taskDialogFooter = node;
                        }}
                    >
                        {dialogStatus !== DIALOG_STATUS.INITIALIZING &&
                            dialogStatus !== DIALOG_STATUS.SUBMITTING && (
                                <DialogActionBar
                                    dialogId={dialogId}
                                    actions={actions}
                                    onClose={this.wrappedOnClose}
                                    onCancel={this.wrappedOnCancel}
                                    successAction={successAction}
                                    retryAction={this.retryAction}
                                    closeActionLabel={closeActionLabel}
                                    className='task-dialog__actions'
                                    disableAutoCloseOnSuccess={
                                        this.props.disableAutoCloseOnSuccess
                                    }
                                    suppressBackButtonOnError={
                                        this.props.suppressBackButtonOnError
                                    }
                                    suppressRetryButtonOnError={
                                        this.props.suppressRetryButtonOnError
                                    }
                                    suppressCloseButtonOnSuccess={
                                        this.props.suppressCloseButtonOnSuccess
                                    }
                                    theme={theme}
                                >
                                    {actionBarContent}
                                </DialogActionBar>
                            )}
                    </div>
                </BasicDialog>
            </>
        );
    }
}

export default withRouter<typeof TaskDialogComponent, TaskDialogComponentProps>(
    TaskDialogComponent
);
