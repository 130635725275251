import React, { PureComponent } from 'react';

export default class DotsLoader extends PureComponent<{
    color: string;
    className?: string;
}> {
    render() {
        const { color, className } = this.props;
        return (
            <svg
                width='200px'
                height='200px'
                className={className}
                viewBox='0 0 100 100'
                preserveAspectRatio='xMidYMid'
            >
                <circle cx='84' cy='50' r='0' fill={color}>
                    <animate
                        attributeName='r'
                        values='10;0;0;0;0'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                    <animate
                        attributeName='cx'
                        values='84;84;84;84;84'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                </circle>
                <circle cx='16' cy='50' r='7.74187' fill={color}>
                    <animate
                        attributeName='r'
                        values='0;10;10;10;0'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='-1.25s'
                    />
                    <animate
                        attributeName='cx'
                        values='16;16;50;84;84'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='-1.25s'
                    />
                </circle>
                <circle cx='84' cy='50' r='2.25813' fill={color}>
                    <animate
                        attributeName='r'
                        values='0;10;10;10;0'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='-0.625s'
                    />
                    <animate
                        attributeName='cx'
                        values='16;16;50;84;84'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='-0.625s'
                    />
                </circle>
                <circle cx='76.3224' cy='50' r='10' fill={color}>
                    <animate
                        attributeName='r'
                        values='0;10;10;10;0'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                    <animate
                        attributeName='cx'
                        values='16;16;50;84;84'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                </circle>
                <circle cx='42.3224' cy='50' r='10' fill={color}>
                    <animate
                        attributeName='r'
                        values='0;0;10;10;10'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                    <animate
                        attributeName='cx'
                        values='16;16;16;50;84'
                        keyTimes='0;0.25;0.5;0.75;1'
                        keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
                        calcMode='spline'
                        dur='2.5s'
                        repeatCount='indefinite'
                        begin='0s'
                    />
                </circle>
            </svg>
        );
    }
}
