import React, { CSSProperties } from 'react';
import ValidationError from 'components/display/ValidationError';
import TtdInputField, { TtdField } from 'components/inputs/TtdInputField';
import { WrappedFieldProps } from 'redux-form';

export interface ValidationOnlyInputProps {
    /**
     * Either a string or InputPropTypes field object (that contains name and isRequired)
     */
    field: TtdField;

    /**
     * Additional CSS class to apply to the component
     */

    className?: string;

    /**
     * Any additional style rules to apply to the component
     */

    style?: CSSProperties;

    /**
     * An optional boolean that indicates whether the validation errors for this input should be rendered
     * inline or not (see ValidationErrorComponent for more information).
     */

    isValidationInline?: boolean;

    hideIfNoErrors?: boolean;
}

/**
 * @summary A ValidationOnlyInput is a specialized input that displays any validation
 * errors on a field, but has no control to edit the field.
 */
class ValidationOnlyInput extends React.Component<ValidationOnlyInputProps> {
    static defaultProps: Partial<ValidationOnlyInputProps> = {
        className: '',
    };

    static displayName = 'ValidationOnlyInput';

    renderInput = ({
        meta,
        hideIfNoErrors,
        isValidationInline,
    }: WrappedFieldProps & ValidationOnlyInputProps) => {
        return (
            <ValidationError
                meta={meta}
                hideIfNoErrors={hideIfNoErrors}
                isInline={isValidationInline}
            />
        );
    };

    render() {
        const { className, style, ...rest } = this.props;
        return (
            <div className={className} style={style}>
                <TtdInputField {...rest} component={this.renderInput} />
            </div>
        );
    }
}

export default ValidationOnlyInput;
