﻿class Environment {
    constructor() {
        // The purpose of this code is to find the width of the scrollbar.
        // Because this can vary with browser & operating system, we have
        // to look at the actual runtime environment to do the calculation.

        // The approach is taken from here:
        // https://github.com/ojame/react-scrollbars/issues/13

        const scrollbarElement = document.createElement('div');
        scrollbarElement.style.width = '100px';
        scrollbarElement.style.height = '100px';
        scrollbarElement.style.overflow = 'scroll';
        scrollbarElement.style.position = 'absolute';
        scrollbarElement.style.top = '-100%';
        scrollbarElement.style.left = '-100%';
        document.body.appendChild(scrollbarElement);
        Object.defineProperty(this, 'scrollBarSize', {
            writable: false,
            value: scrollbarElement.offsetWidth - scrollbarElement.clientWidth,
        });
    }

    readonly scrollBarSize: number;

    get screenHeightPixels() {
        return window.screen.availHeight;
    }

    get isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }

    get isTest() {
        return process.env.NODE_ENV === 'test';
    }

    get isProduction() {
        return process.env.NODE_ENV === 'production';
    }

    get isWindows() {
        return navigator.platform.startsWith('Win');
    }
}

const environment = new Environment();
export default environment;
