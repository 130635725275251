import React from 'react';
import ReactDOM from 'react-dom';
import HorizontalList from 'components/display/HorizontalList';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import RadioControl from './RadioControl';
import withReadOnly from 'components/hocs/withReadOnly';
import themeClassName from 'util/Themes';
import './RadioGroupControl.scss';

export enum LAYOUT {
    horizontal = 'horizontal',
    block = 'block',
    // eslint-disable-next-line camelcase
    block_reverse = 'block-reverse',
    vertical = 'vertical',
    // eslint-disable-next-line camelcase
    vertical_reverse = 'vertical-reverse',
}

export interface RadioGroupControlProps<T> {
    className?: string;
    // style rules to apply to the control
    style?: React.CSSProperties;
    // styling specific for the radio options
    radioControlClass?: string;
    // unique ID to assign to the component
    id: string;
    // display name property of the values
    displayNameKey?: string;
    // primary key of the objects backing the radio controls
    primaryKey?: string;
    // set to false to disable the radio group
    isEnabled?: boolean;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    // required function which handles the change event of selecting a radio item.
    onChange?(value: any): void;
    // vertical, horizontal or block
    layout?: LAYOUT;
    // To disable an individual item set a property $isEnabled on the individual item equal to false
    availableValues: T[];
    // The currently selected radio option.
    value?: T;
    // Required name of this radio group
    name?: string;
    // Calculate the width and set automatically
    autoCalculateWidth?: boolean;
    // Optional theme
    theme?: string;
    // Optional label renderer
    renderLabel?: (label: string, item: T) => string;
}

class RadioGroupControl<T = {}> extends React.Component<
    RadioGroupControlProps<T>
> {
    static LAYOUT = LAYOUT;

    static defaultProps = {
        layout: LAYOUT.vertical,
        isEnabled: true,
        className: '',
        radioControlClass: '',
        autoCalculateWidth: true,
    };

    componentDidMount() {
        if (
            this.props.layout === RadioGroupControl.LAYOUT.block &&
            this.props.autoCalculateWidth
        ) {
            this.calculateBlockRadioWidths();
        }
    }

    /**
     * Automatically calculates the widths of the radio buttons and sets the widths of all of them
     * to the widest button (this makes the radio group look consistent, so there aren't radio buttons
     * of different widths).
     */
    calculateBlockRadioWidths = () => {
        // There are times when we need to calculate the radio button widths when the radio group is
        // not visible in the DOM (e.g., when they are in a dialog and the dialog is transitioning
        // open).  In that case, it's hard to calculate the widths (because the radio buttons generally
        // have computed widths of 0). Thus, our strategy is as follows: clone the entire radio group
        // DOM and attach it temporarily to the root node of the application, measure the widths of those
        // cloned nodes, then remove and destroy the cloned DOM.  NOTE: This is NOT a pattern to emulate
        // (here it's unfortunately necessary, but we don't want to make a habit out of this).

        const element = ReactDOM.findDOMNode(this);

        // Clone the radio group DOM and attach it temporarily to the root of the application.
        const clonedElement = element.cloneNode(true) as HTMLElement;
        const root = document.getElementById('root');
        root.appendChild(clonedElement);

        // Measure the widths of the cloned (and visible) radio buttons, noting the max width found.
        const clonedItems = clonedElement.querySelectorAll(
            '.horizontal-list__item'
        );
        const maxWidth = Math.max(
            ...[...clonedItems].map((e: HTMLElement) => e.offsetWidth)
        );

        // Set the widths of the real radio buttons to the max width found.
        const items = (element as HTMLElement).querySelectorAll(
            '.horizontal-list__item'
        );
        items.forEach((item: HTMLElement) => {
            item.style.width = maxWidth + 'px';
        });

        // Pull out the cloned element from the DOM to clean up after ourselves.
        root.removeChild(clonedElement);
    };

    get hasAtLeastOneGlyph() {
        return !!(this.props.availableValues || []).find((item) => {
            // Please fix if you edit this file.  Added when adding linting to maui
            // eslint-disable-next-line dot-notation
            return !!item['glyph'];
        });
    }

    render() {
        const {
            layout,
            isReadOnly,
            isEnabled,
            primaryKey,
            className,
            style,
            radioControlClass,
            displayNameKey,
            value,
            availableValues,
            name,
            theme,
            id,
            renderLabel,
        } = this.props;

        const selectedValue =
            value !== undefined && value[primaryKey] !== undefined
                ? value[primaryKey]
                : value;
        if (
            typeof availableValues[0] === 'object' &&
            (!primaryKey || !displayNameKey)
        ) {
            const error = new Error(
                'If radio values are objects, you must specify both [primaryKey] and [displayNameKey] props.'
            );
            console.error(error);
        }

        const radios = availableValues.map((item: any, index: number) => {
            const itemIdValue =
                item !== undefined && item[primaryKey] !== undefined
                    ? item[primaryKey]
                    : item;
            const isChecked = itemIdValue === selectedValue;

            // Per style guidelines, only the selected option from a readonly radio group is shown.
            // https://atlassian.thetradedesk.com/confluence/display/URTU/Style+Guides?preview=/7307280/19596162/RadioGroup_StyleGuide_Megagon.pdf
            if (isReadOnly && !isChecked) {
                return;
            }

            let label = displayNameKey ? item[displayNameKey] : item;
            if (item.glyph) {
                label = (
                    <div className='radio-group__glyph-label-wrapper'>
                        <Glyph
                            name={item.glyph}
                            className={`radio-group__glyph radio-group__glyph--${
                                isChecked ? 'selected' : 'not-selected'
                            }`}
                            title={item.glyphTitle || ''}
                        />
                        <div className='radio-group__glyph-label'>{label}</div>
                    </div>
                );
            }
            if (item.icon) {
                label = (
                    <div className='radio-group__glyph-label-wrapper'>
                        <div className='radio-group__icon'>{item.icon}</div>
                        <div className='radio-group__glyph-label'>{label}</div>
                    </div>
                );
            }

            return (
                <RadioControl
                    className={`radio-input--${index} ${radioControlClass}`}
                    value={item}
                    name={name}
                    key={index}
                    isChecked={isChecked}
                    isReadOnly={isReadOnly}
                    label={renderLabel ? renderLabel(label, item) : label}
                    isEnabled={isEnabled && item.isEnabled !== false}
                    id={`${id}-${index}`}
                    onChange={this.props.onChange}
                />
            );
        });

        const classes = ['radio-group', className];
        if (theme) {
            classes.push(themeClassName(theme));
        }

        // Readonly radio groups are rendered in a peculiar way. Only apply the layout
        // class if this isn't a readonly group.
        if (isReadOnly) {
            classes.push('radio-group--readonly');
        } else {
            classes.push(`radio-group--${layout}`);
        }

        if (this.hasAtLeastOneGlyph) {
            classes.push('radio-group--with-glyph');
        }

        return (
            <div id={id} className={classes.join(' ')} style={style}>
                {layout !== RadioGroupControl.LAYOUT.vertical &&
                layout !== RadioGroupControl.LAYOUT.vertical_reverse &&
                !isReadOnly ? (
                    <HorizontalList children={radios} />
                ) : (
                    radios
                )}
            </div>
        );
    }
}

export default withReadOnly(RadioGroupControl);
