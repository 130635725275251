﻿import React, {
    Component,
    MouseEvent,
    ReactNode,
    ButtonHTMLAttributes,
} from 'react';
import MultilineText from '../MultilineText';
// '<Glyph>' is deprecated in favor of direct SVG imports
import Glyph from '../Glyph';
import themeClassName from '../../../util/Themes';

import './Card.scss';

export interface ActionItem {
    glyphName?: string;
    onClick?: (args: any) => void;
    className?: string;
}

export interface CardProps {
    // An optional className to add to the root element in this component.
    className?: string;

    // An optional style object to apply to the component.
    style?: React.CSSProperties;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'add-targeting-panel' is a theme.
    theme?: string;

    // An optional title for the card.
    title?: ReactNode;

    // An optional array of actions, displayed on hover.
    actionItems?: ActionItem[];

    /**
     * Optionally indicates if the card is disabled and cannot be clicked on.
     * Used when `onCardClick` is specified.
     */
    isDisabled?: boolean;

    // An optional function to call when the card is clicked anywhere.
    onCardClick?: (event?: MouseEvent<HTMLButtonElement>) => void;

    // An optional ref so the parent can access the underlying DOM element for measurement
    forwardRef?: any;

    children?: ReactNode;

    id?: string;
}

// A Card is a component that renders a rectangular area on the screen that contains
// information (rendered in the children).  A card also has the following features:
//    1. May be set to "raise up" on hover (simulates lifting up from the page)
//    2. May be passed a click handler
//    3. May have a "upper bar" or "lower bar" (usually a stripe of color to indicate something)
//    4. May be passed a set of "actions" that appear on hover
export default class Card extends Component<CardProps, {}> {
    static defaultProps = {
        className: '',
        theme: '',
        actionItems: [],
    };

    render() {
        const {
            className,
            style,
            theme,
            title,
            actionItems,
            onCardClick,
            children,
            forwardRef,
            id,
            isDisabled,
        } = this.props;

        let RootElement: any = 'div';
        const classes = ['card', className];
        const additionalProps = {} as ButtonHTMLAttributes<HTMLButtonElement>;
        if (onCardClick) {
            classes.push('card--clickable', 'reset-styles');
            // Clickable cards are actual <button> elements (so that they support tab/keyboard navigation).
            // We're not using our own <Button> component here because it adds too many additional layers
            // to the DOM, and cards are a core styling element where we can't tolerate those extra elements.
            RootElement = 'button';

            if (isDisabled) {
                additionalProps.disabled = true;
                classes.push(themeClassName('flat-card'));
            }
        }
        if (theme) {
            classes.push(themeClassName(theme));
        }

        return (
            <RootElement
                className={classes.join(' ')}
                style={style}
                onClick={onCardClick}
                type={onCardClick ? 'button' : null}
                {...additionalProps}
                ref={forwardRef}
            >
                <div id={id} className='card__inner'>
                    {title && (
                        <div className='card__title'>
                            {typeof title === 'string' ? (
                                <MultilineText text={title} maxLines={2} />
                            ) : (
                                title
                            )}
                        </div>
                    )}
                    {children}
                </div>
                {actionItems != null && actionItems.length > 0 && (
                    <div className='card__actions'>
                        <div className='card__action-list'>
                            {actionItems.map((action, index) => {
                                return (
                                    <Glyph
                                        key={index}
                                        name={action.glyphName}
                                        onClick={() => action.onClick(this)}
                                        className={`card__action ${
                                            action.className || ''
                                        }`}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </RootElement>
        );
    }
}
