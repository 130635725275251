import React, { Component } from 'react';
import TtdInputField from '../TtdInputField';
import { CheckboxInputField } from './CheckboxInputField';
import { CheckboxInputProps } from './CheckboxInputProps';

export class CheckboxInput extends Component<CheckboxInputProps> {
    static defaultProps = {
        isValidationInline: false,
        singleInput: true,
    };

    static displayName = 'CheckboxInput';

    render() {
        const { className, style, ...rest } = this.props;
        return (
            <div className={className || ''} style={style}>
                <TtdInputField
                    {...rest}
                    // There's a bug in redux-form (6.7.0) that makes unchecked checkboxes have a value of
                    // empty string instead of false -- this works around it (by ensuring it's either true or false).
                    parse={(value) => !!value}
                    component={CheckboxInputField}
                />
            </div>
        );
    }
}

export default CheckboxInput;
