import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { reduxForm, ConfigProps, InjectedFormProps, Fields } from 'redux-form';
import TtdInputField, { getFieldName } from 'components/inputs/TtdInputField';
import { Filter } from './Filter';
import { OverflowFilters } from './OverflowFilters';
import {
    FilterProps,
    FilterProp,
} from '../DataFilters/SingleDataFilter/FilterProps';
import { DataFilter } from '../DataFilters/SingleDataFilter';
import './Filters.scss';

export interface FiltersProps {
    className?: string;
    style?: CSSProperties;
    filters: FilterProps;
    initialValues?: Object;
    onChange: ConfigProps['onChange'];
    form?: string;
    maxFilterElements?: number;
    embedFiltersInPortal?: boolean;
}

function FiltersForm({ initialValues, onChange, form, ...rest }: FiltersProps) {
    const Form = useRef(null);
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (!initialized) {
            Form.current = reduxForm({
                destroyOnUnmount: true,
                initialValues,
                onChange,
                form: form || v4(),
            })(Filters);
            setInitialized(true);
        }
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
    // eslint-disable-next-line react/jsx-pascal-case
    return initialized && <Form.current {...rest} />;
}

function Filters({
    className = '',
    style,
    filters,
    maxFilterElements = 5,
    embedFiltersInPortal,
}: FiltersProps & InjectedFormProps) {
    // If the max we want to show is 5, and we have 5, we should show them all.
    // If we have 6, we should only show 4 to make room for the overflow.
    const visibleFilterCount =
        filters.length > maxFilterElements
            ? maxFilterElements - 1
            : filters.length;

    const [visibleFilters, overflowFilters] = [
        filters.slice(0, visibleFilterCount),
        filters.slice(visibleFilterCount),
    ];
    const overflowFields = overflowFilters.map((f) => getFieldName(f.field));

    return (
        <div style={style} className={`ac-filters ${className}`}>
            {visibleFilters.map((filter, index) => {
                return (
                    <TtdInputField
                        field={filter.field}
                        component={Filter}
                        label={filter.label}
                        inputNode={
                            <DataFilter
                                filter={
                                    {
                                        ...filter,
                                        embedInPortal: embedFiltersInPortal,
                                    } as FilterProp
                                }
                                className='ac-filters__input'
                                labelTheme='filter'
                            />
                        }
                        key={index}
                        embedFilterInPortal={embedFiltersInPortal}
                        description={filter.description}
                        displayNameKey={filter.displayNameKey}
                    />
                );
            })}
            {overflowFilters.length > 0 && (
                <Fields
                    names={overflowFields}
                    filters={overflowFilters}
                    component={OverflowFilters}
                />
            )}
        </div>
    );
}

export default FiltersForm;
