﻿import { connect } from 'react-redux';
import ValidationErrorComponent, {
    ValidationErrorActionProps,
    ValidationErrorProps,
} from './ValidationErrorComponent';
import { setFieldErrorCount } from '../../../reducers/navigationcontrol';

const mapActionCreators = {
    setFieldErrorCount,
};

export default connect<{}, ValidationErrorActionProps, ValidationErrorProps>(
    null,
    mapActionCreators
)(ValidationErrorComponent);
