import React, { ChangeEvent } from 'react';
import { EventWithDataHandler, WrappedFieldProps } from 'redux-form';
import TtdInputField, { TtdField } from 'components/inputs/TtdInputField';
import ToggleControl, {
    ToggleControlProps,
} from 'components/controls/ToggleControl';
import ValidationError from 'components/display/ValidationError';
import ValidationService from 'util/ValidationService';
import getInputId from 'util/getInputId';
import './ToggleInput.scss';

export interface ToggleInputProps
    extends Omit<
        ToggleControlProps,
        'onChange' | 'value' | 'onBlur' | 'onFocus' | 'id'
    > {
    field: TtdField;
    onBeforeChange?(nextValue: boolean, previousValue: boolean): boolean;
    onChange?: EventWithDataHandler<ChangeEvent<boolean>>;
    isValidationInline?: boolean;
    hideValidationIfNoErrors?: boolean;
    id?: string;
}

class ToggleInput extends React.Component<ToggleInputProps> {
    static displayName = 'ToggleInput';

    static defaultProps = {
        hideValidationIfNoErrors: false,
    };

    normalize = (value, previousValue) => {
        const { onBeforeChange } = this.props;

        if (onBeforeChange && onBeforeChange(value, previousValue) === false) {
            return previousValue;
        } else {
            return value;
        }
    };

    getClassName = (meta) => {
        const errors = ValidationService.getVisibleErrors(meta);

        const errorClass =
            errors.length > 0
                ? 'toggle-input--error'
                : 'toggle-input--no-error';

        const inlineClass = this.props.isValidationInline
            ? 'toggle-input--inline'
            : '';
        return `toggle-input ${errorClass} ${inlineClass}`;
    };

    renderInput = (fieldProps: ToggleInputProps & WrappedFieldProps) => {
        const {
            input,
            meta,
            isValidationInline,
            theme,
            isEnabled,
            isReadOnly,
            isLoading,
            hideValidationIfNoErrors,
            id,
        } = fieldProps;

        return (
            <div className={this.getClassName(meta)}>
                {
                    // Apply all "input" props to the input. In more complex components
                    // we might want to break these out and apply them piecemeal. For
                    // documentation on what props are available in the input, see:
                    // http://redux-form.com/6.5.0/docs/api/Field.md/
                }
                <ToggleControl
                    id={id || getInputId(ToggleControl, fieldProps)}
                    isEnabled={isEnabled}
                    isReadOnly={isReadOnly}
                    isLoading={isLoading}
                    theme={theme}
                    onChange={input.onChange}
                    {...input}
                />
                {
                    // We might not be able to always render the error so easily - e.g.,
                    // complex components might display "inline errors" in different ways
                    // or just not support them.
                }
                <ValidationError
                    meta={meta}
                    isInline={isValidationInline}
                    hideIfNoErrors={hideValidationIfNoErrors}
                />
            </div>
        );
    };

    render() {
        const {
            field,
            isValidationInline,
            theme,
            className,
            style,
            hideValidationIfNoErrors,
            ...rest
        } = this.props;

        return (
            <div className={className} style={style}>
                <TtdInputField
                    field={field}
                    isValidationInline={isValidationInline}
                    hideValidationIfNoErrors={hideValidationIfNoErrors}
                    component={this.renderInput}
                    theme={theme}
                    normalize={this.normalize}
                    {...rest}
                />
            </div>
        );
    }
}

export default ToggleInput;
