import React, { Component } from 'react';
import { InjectedFormProps } from 'redux-form';
import Button, { BUTTON_TYPE } from 'components/display/Button';
import themeClassName from 'util/Themes';
import { Localization } from 'util/LocalizationService';
import Strings from './DataFilters.strings.json';
import './DataFilters.scss';

export enum CLEAR_FILTER_BUTTON_POSITION {
    CENTER = 'CENTER',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export interface DataFiltersProps {
    className?: string;
    style?: React.CSSProperties;

    /**
     * Optional form name for the data filters form
     */
    formName?: string;

    // Provided to redux form; initial form values if any
    initialValues: any;
    // Provided to redux form; reinitialize initialValues if they change after the form is already
    // initialized
    enableReinitialize?: boolean;
    // Filter components to be anchored to the left
    leftFilters?: React.ReactNode;
    // Filter components to be anchored to the right (after the Clear Filters button)
    rightFilters?: React.ReactNode;
    // Filter fields that can be cleared  via the Clear Filters button
    clearableFilters?: string[];

    // Location to position the Clear Filters button, from the CLEAR_FILTER_BUTTON_POSITION constant
    clearFilterButtonPosition: CLEAR_FILTER_BUTTON_POSITION;

    /**
     * Optional function to call instead of redux-form's clearFields when clearing filters
     *
     * @param {string} formName The form name for the DataFilters. Defaults to the component's `stateKey` if not passed in.
     * @param {string[]} clearableFilters The list of filters which can be cleared.
     * @memberof DataFiltersProps
     */
    onClearFilters?(formName: string, clearableFilters: string[]): void;

    // From container - name of filters that are dirty
    hasClearableFieldValues?: boolean;

    stateKey: string;

    // Whether to destroy the form when the component unmounts (defaults to true)
    destroyOnUnmount: boolean;

    // Optionally hide the clear filters button (defaults to true)
    showClearFiltersButton?: boolean;

    // Optional theme to add to the component styling
    theme?: 'full-width-search' | 'no-left-filters';
}

export default class DataFiltersComponent extends Component<
    DataFiltersProps &
        InjectedFormProps & {
            // Types haven't been updated
            // https://redux-form.com/8.3.0/docs/api/props.md/#-code-clearfields-keeptouched-boolean-persistentsubmiterrors-boolean-fields-string-code-
            clearFields: (
                keepTouched: boolean,
                persistentSubmitErrors: boolean,
                ...fields: string[]
            ) => void;
        },
    {}
> {
    static defaultProps = {
        initialValues: {},
        theme: '',
        leftFilters: [],
        rightFilters: [],
        clearableFilters: [],
        clearFilterButtonPosition: CLEAR_FILTER_BUTTON_POSITION.CENTER,
        destroyOnUnmount: true,
        showClearFiltersButton: true,
    };

    onClearFilterClick = () => {
        const {
            formName,
            stateKey,
            clearableFilters,
            onClearFilters,
            clearFields,
        } = this.props;
        if (onClearFilters) {
            // Use the formName if provided, else fallback to the stateKey which was used as the formName
            onClearFilters(formName || stateKey, clearableFilters);
        } else {
            clearFields(false, false, ...clearableFilters);
        }
    };

    render() {
        const {
            className,
            theme,
            style,
            leftFilters,
            rightFilters,
            hasClearableFieldValues,
            clearFilterButtonPosition,
            showClearFiltersButton,
        } = this.props;

        const clearFiltersButton = (
            <Button
                isFlat
                type={BUTTON_TYPE.PRIMARY}
                id='data-filters-container__clear-filters'
                className={`data-filters-container__clear-filters ${
                    hasClearableFieldValues
                        ? 'data-filters-container__clear-filters--visible'
                        : ''
                }${
                    clearFilterButtonPosition ===
                    CLEAR_FILTER_BUTTON_POSITION.CENTER
                        ? ' data-filters-container__clear-filters--center'
                        : ''
                }`}
                onClick={this.onClearFilterClick}
            >
                {Localization.getString(Strings.clearFilters)}
            </Button>
        );

        const classes = ['data-filters-container', className];
        if (theme) {
            classes.push(themeClassName(theme));
        }

        return (
            <div className={classes.join(' ')} style={style}>
                <div className='data-filters-container__left'>
                    {leftFilters}
                </div>

                {showClearFiltersButton &&
                    clearFilterButtonPosition ===
                        CLEAR_FILTER_BUTTON_POSITION.CENTER &&
                    clearFiltersButton}

                <div className='data-filters-container__right'>
                    <div className='data-filters-container__right-filters'>
                        {rightFilters}
                    </div>

                    {showClearFiltersButton &&
                        clearFilterButtonPosition ===
                            CLEAR_FILTER_BUTTON_POSITION.BOTTOM_RIGHT &&
                        clearFiltersButton}
                </div>
            </div>
        );
    }
}
