import React, { CSSProperties } from 'react';
import memoize from 'lodash/memoize';
/*import DataFilters, { DataFiltersProps } from 'components/display/DataFilters';
import DataTable, {
    DataTableProps,
    SORT_DIRECTION
} from 'components/display/DataTable';*/
import LabelledInput, {
    LabelledInputProps,
    InputSize,
} from 'components/display/LabelledInput';
import EnumMultiSelectListInput, {
    EnumMultiSelectListInputProps,
} from 'components/inputs/EnumMultiSelectListInput';
import EnumSelectListInput, {
    EnumSelectListInputProps,
} from 'components/inputs/EnumSelectListInput';
import SelectListInput, {
    SelectListInputProps,
} from 'components/inputs/SelectListInput';
import MultiSelectListInput, {
    MultiSelectListInputProps,
} from 'components/inputs/MultiSelectListInput';
import TextSearchInput, {
    TextSearchInputProps,
} from 'components/inputs/TextSearchInput';
import { TtdField } from 'components/inputs/TtdInputField';
import { Localization } from 'util/LocalizationService';
import { IUiEnum } from 'util/UiEnumUtils';
import Strings from './SingleDataFilter.strings.json';
import CheckboxInput, {
    CheckboxInputProps,
} from 'components/inputs/CheckboxInput';
import {
    DatePickerInput,
    DateRangePickerInput,
} from 'components/inputs/DatePickerInput';
import FixedContentInput from 'components/inputs/FixedContentInput';
import DecimalInput from 'components/inputs/DecimalInput';
import CountInput from 'components/inputs/CountInput';
import PercentageInput from 'components/inputs/PercentageInput';
import ToggleInput from 'components/inputs/ToggleInput';
import CurrencyInput from 'components/inputs/CurrencyInput';
import CheckboxGroup, {
    CheckboxGroupLayout,
} from 'components/inputs/CheckboxGroup';
import RadioGroupInput, { LAYOUT } from 'components/inputs/RadioGroupInput';
import { FilterProp } from './FilterProps';

export enum DATA_FILTER_FIELD_TYPES {
    TEXT_SEARCH = 'TEXT_SEARCH',
    ENUM_SELECT = 'ENUM_SELECT',
    ENUM_MULTI_SELECT = 'ENUM_MULTI_SELECT',
    SELECT = 'SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    CHECKBOX = 'CHECKBOX',
    DATETIME = 'DATETIME',
    DATE_RANGE = 'DATE_RANGE',
    FIXED_CONTENT = 'FIXED_CONTENT',
    DECIMAL = 'DECIMAL',
    PERCENTAGE = 'PERCENTAGE',
    COUNT = 'COUNT',
    TOGGLE = 'TOGGLE',
    CURRENCY = 'CURRENCY',
    CHECKBOX_GROUP = 'CHECKBOX_GROUP',
    RADIO_GROUP = 'RADIO_GROUP',
}

const DEFAULT_DATA_FILTER_CONFIGS = {
    [DATA_FILTER_FIELD_TYPES.CHECKBOX_GROUP]: {
        FilterComponent: CheckboxGroup,
        defaultFieldConfig: {
            layout: CheckboxGroupLayout.vertical,
            theme: 'filter',
            renderLabel: (label, item) =>
                `${label}${'count' in item ? ` (${item.count})` : ''}`,
        },
    },
    [DATA_FILTER_FIELD_TYPES.RADIO_GROUP]: {
        FilterComponent: RadioGroupInput,
        defaultFieldConfig: {
            layout: LAYOUT.vertical,
            theme: 'filter',
            renderLabel: (label, item) =>
                `${label}${'count' in item ? ` (${item.count})` : ''}`,
        },
    },
    [DATA_FILTER_FIELD_TYPES.TEXT_SEARCH]: {
        FilterComponent: TextSearchInput,
        defaultFieldConfig: {
            debounceTimeInMilliseconds: 400,
            placeholder: Localization.getString(Strings.searchPlaceholder),
        },
    },
    [DATA_FILTER_FIELD_TYPES.ENUM_SELECT]: {
        FilterComponent: EnumSelectListInput,
        defaultFieldConfig: {
            emptyText: Localization.getString(Strings.selectPlaceholder),
            itemName: '',
        },
    },
    [DATA_FILTER_FIELD_TYPES.ENUM_MULTI_SELECT]: {
        FilterComponent: EnumMultiSelectListInput,
        defaultFieldConfig: {
            emptyText: Localization.getString(Strings.selectPlaceholder),
            itemName: '',
        },
    },
    [DATA_FILTER_FIELD_TYPES.SELECT]: {
        FilterComponent: SelectListInput,
        defaultFieldConfig: {
            emptyText: Localization.getString(Strings.selectPlaceholder),
            itemName: '',
        },
    },
    [DATA_FILTER_FIELD_TYPES.MULTI_SELECT]: {
        FilterComponent: MultiSelectListInput,
        defaultFieldConfig: {
            emptyText: Localization.getString(Strings.selectPlaceholder),
            itemName: '',
        },
    },
    [DATA_FILTER_FIELD_TYPES.CHECKBOX]: {
        FilterComponent: CheckboxInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.DATETIME]: {
        FilterComponent: DatePickerInput,
        defaultFieldConfig: {
            theme: 'date-picker-filter',
        },
    },
    [DATA_FILTER_FIELD_TYPES.DATE_RANGE]: {
        FilterComponent: DateRangePickerInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.FIXED_CONTENT]: {
        FilterComponent: FixedContentInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.DECIMAL]: {
        FilterComponent: DecimalInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.COUNT]: {
        FilterComponent: CountInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.CURRENCY]: {
        FilterComponent: CurrencyInput,
        defaultFieldConfig: {
            currency: {},
        },
    },
    [DATA_FILTER_FIELD_TYPES.PERCENTAGE]: {
        FilterComponent: PercentageInput,
        defaultFieldConfig: {},
    },
    [DATA_FILTER_FIELD_TYPES.TOGGLE]: {
        FilterComponent: ToggleInput,
        defaultFieldConfig: {
            theme: 'stoplight',
        },
    },
};

export interface LabelledFilterProps extends LabelledInputProps {}

export interface TextSearchDataFilterProps
    extends Omit<
        TextSearchInputProps,
        | 'id'
        | 'field'
        | 'isReadOnly'
        | 'isEnabled'
        | 'isInline'
        | 'isReadOnly'
        | 'placeholder'
    > {}

export interface EnumSelectDataFilterProps<TEnum extends IUiEnum>
    extends Omit<
        EnumSelectListInputProps<TEnum>,
        | 'id'
        | 'field'
        | 'isReadOnly'
        | 'isEnabled'
        | 'isInline'
        | 'isReadOnly'
        | 'emptyText'
    > {}

export interface EnumMultiSelectDataFilterProps<TEnum extends IUiEnum>
    extends Omit<
        EnumMultiSelectListInputProps<TEnum>,
        | 'id'
        | 'field'
        | 'isReadOnly'
        | 'isEnabled'
        | 'isInline'
        | 'isReadOnly'
        | 'emptyText'
    > {}

export interface SelectListDataFilterProps<TSelect>
    extends Omit<
        SelectListInputProps<TSelect>,
        | 'id'
        | 'field'
        | 'isReadOnly'
        | 'isEnabled'
        | 'isInline'
        | 'isReadOnly'
        | 'emptyText'
    > {}

export interface MultiSelectListDataFilterProps<TSelect>
    extends Omit<
        MultiSelectListInputProps<TSelect>,
        | 'id'
        | 'field'
        | 'isReadOnly'
        | 'isEnabled'
        | 'isInline'
        | 'isReadOnly'
        | 'emptyText'
    > {}

export interface CheckboxInputFilterProps
    extends Omit<CheckboxInputProps, 'isValidationInline' | 'initialValue'> {}

export interface SingleDataFilterInputProps<TEnum extends IUiEnum, TSelect> {
    dataFilterType: DATA_FILTER_FIELD_TYPES;

    fieldConfig: Partial<FilterProp>;

    field: TtdField;

    className?: string;

    style?: CSSProperties;

    placeholder?: string;

    isEnabled?: boolean;

    isInline?: boolean;

    isReadOnly?: boolean;

    labelConfig?: LabelledInputProps;
}

class SingleDataFilterInput<
    TEnum extends IUiEnum,
    TSelect
> extends React.Component<SingleDataFilterInputProps<TEnum, TSelect>> {
    static defaultProps: Partial<SingleDataFilterInputProps<any, any>> = {
        isEnabled: true,
        isInline: false,
        isReadOnly: false,
    };

    getDataFilterComponent = memoize((props) => {
        const {
            dataFilterType,
            field,
            fieldConfig,
            placeholder,
            ...rest
        } = props;

        const {
            FilterComponent,
            defaultFieldConfig,
        } = DEFAULT_DATA_FILTER_CONFIGS[dataFilterType];
        return (
            FilterComponent && (
                <FilterComponent
                    isValidationInline
                    {...(defaultFieldConfig || {})}
                    {...rest}
                    {...(fieldConfig || {})}
                    field={field}
                />
            )
        );
    });

    render() {
        const { className = '', style, labelConfig, ...rest } = this.props;
        const component = this.getDataFilterComponent(rest);
        return (
            <div className={`data-filter-container ${className}`} style={style}>
                {component &&
                    (labelConfig ? (
                        <LabelledInput
                            isInline
                            size={InputSize.AUTO}
                            {...labelConfig}
                        >
                            {component}
                        </LabelledInput>
                    ) : (
                        component
                    ))}
            </div>
        );
    }
}

export interface DataFilterProps {
    filter: FilterProp;
    className?: string;
    labelTheme?: string;
    isInline?: boolean;
}

export function DataFilter({
    filter,
    className = '',
    labelTheme = '',
    isInline = true,
}: DataFilterProps) {
    const { field, label, ...rest } = filter;

    return (
        <SingleDataFilterInput
            className={className}
            dataFilterType={filter.filterType}
            field={field}
            fieldConfig={rest}
            isInline={isInline}
            labelConfig={
                label
                    ? {
                          children: null,
                          label,
                          theme: labelTheme,
                          isInline,
                      }
                    : undefined
            }
        />
    );
}

export default SingleDataFilterInput;
