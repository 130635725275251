// eslint-disable-next-line import/no-webpack-loader-syntax
import zIndexVariables from '!!sass-variable-loader!styles/variables/z-index.scss';

// Reads in z-index values from our SASS variables file to expose to the
// rest of the application.

const ZIndexKeys = [
    'Default',
    'Promoted',
    'Active',
    'ActionBar',
    'PageHeader',
    'Dropdown',
    'Dialog',
    'Toastie',
    'Help',
    'Tooltip',
    'Callout',
] as const;

type ZIndexValues = { [k in typeof ZIndexKeys[number]]: number };
const zIndexValues: ZIndexValues = ZIndexKeys.reduce(
    (valuesMap, key) => ({
        ...valuesMap,
        [key]: Number(zIndexVariables[`zindex${key}`]),
    }),
    {} as ZIndexValues
);

export default zIndexValues;
