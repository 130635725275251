import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DropDownMenu from 'components/display/DropDownMenu';
import DropDownMenuItem, {
    DropDownMenuItemProps,
} from 'components/display/DropDownMenuItem';
import CheckboxControl from 'components/controls/CheckboxControl';
import ToolTip, { DisplayMode } from 'components/display/ToolTip';
import { clickSource } from 'util/EventSubscriptions';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import selectList, {
    SelectListProps,
    isHeader,
    HeaderItem,
} from 'components/hocs/selectList/selectList';
import withReadOnly from 'components/hocs/withReadOnly';
import { PrimaryColorOptions } from 'util/Colors';
import { emptyFunction } from 'util/HelperUtils';
import { Localization } from 'util/LocalizationService';
import Strings from './MultiSelectListControl.strings.json';
import './MultiSelectListControl.scss';

export type MultiSelectListControlProps<T> = SelectListProps<T> & {
    /**
     * unique id assigned to the component
     */
    id: string;

    /**
     * optional number indicating a colored bar.  can be used in conjunction with PillList component for displaying selected items.
     */
    color?: PrimaryColorOptions;

    /**
     * optional function to handle clicks on the individual menu items
     */
    onItemClick?(item: T, event: React.MouseEvent, index: number): void;

    /**
     * optional function to handle when an item's state (selected or deselected) is changed
     */
    onItemChange?(item: T, event?: React.ChangeEvent<HTMLInputElement>): void;
    /**
     * optional function for additional actions when removing focus from the search field
     */
    onSearchBlur?(event: React.FocusEvent): void;

    /**
     * optional function to handle a change to the select all checkbox
     */
    onToggleSelectAll?(event, newFields): void;

    /**
     * array of the selected items in the select list
     */
    selectedItems: T[];

    /**
     * Indicates whether the select all checkbox should be available in the multi select list
     */
    canSelectAll?: boolean;

    /**
     * optional function that modify the select-list items prior to rendering
     */
    customItemMapper?(item: T, index): any;

    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/

    isReadOnly?: boolean;

    /**
     * optional plural display name for multiple non-"s" plurality.  ie countries
     */
    pluralDisplayName?: string;

    /**
     * if true, shows the number of selections in closed select field
     */
    showSummary?: boolean;

    /* whether to display the summary with the skittle count instead of normal label
     */
    displayLabelSkittle?: boolean;

    /**
     * optional text to display in the field when searching is enabled (e.g. "Enter at least 2 characters to search")
     */
    searchPlaceholder?: string;

    /**
     * if true, shows the drop-down menu with "no selections available" as the only item
     */

    showNoSelectionsAvailable?: boolean;

    /**
     * optional number to display for amount of items currently selected.
     */
    selectedCount?: number;

    /*
     * Indicate whether the drop-down is to open align left, right, or default
     */
    alignment?: 'left' | 'right' | 'none';

    // Additional prop types are defined by the selectInput HOC, see that component for details,
    showSearchWhenClosed?: boolean;

    /**
     * function for defining how to display the control as readonly when isReadOnly is true
     */
    renderReadOnly?(selectedItems, displayNameKey, itemType): JSX.Element;

    /**
     * Toggle the list open on focus even if we show search when closed
     */
    toggleWhenClosed?: boolean;

    // Optionally pass a theme to each dropdown item
    dropdownMenuItemTheme?: DropDownMenuItemProps<never>['theme'];

    // Optionally pass a theme to the dropdown menu
    dropdownMenuTheme?: 'multi-select-filters';

    //Optional element to display if no results found
    noElementsAvailable?: JSX.Element;

    // If true, use allSelectedItemsMessage when all items selected. Else, use selectedItemsMessage.
    showAllSelectedItemsMessage?: boolean;
};

interface MultiSelectListControlState {
    isOpen?: boolean;
}

class MultiSelectListControl<T> extends Component<
    MultiSelectListControlProps<T>,
    MultiSelectListControlState
> {
    static defaultProps = {
        selectedItems: [],
        showSummary: true,
        searchPlaceholder: Localization.getString(Strings.searchPlaceholder),
        showNoSelectionsAvailable: true,
        onItemChange: () => undefined,
        onItemClick: () => undefined,
        showAllSelectedItemsMessage: true,
    };

    focusTimeout: number;

    constructor(props) {
        super(props);
        this.onSearchFocus = this.onSearchFocus.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onSearchBlur = this.onSearchBlur.bind(this);
        this.customItemMapper = this.customItemMapper.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);

        this.state = {
            isOpen: !this.props.showSearchWhenClosed
                ? undefined
                : this.props.showSearchWhenClosed &&
                  !this.props.toggleWhenClosed,
        };
    }

    customItemMapper(item, index) {
        return (
            (this.props.customItemMapper &&
                this.props.customItemMapper(item, index)) ||
            item
        );
    }

    onSearchFocus(e) {
        if (this.props.showSearchWhenClosed && !this.state.isOpen) {
            this.props.onToggle(true);
            this.setState({ isOpen: true });
        }
        if (this.props.onSearchFocus) {
            this.props.onSearchFocus(e);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.focusTimeout);
        this.clickAwaySubscription.unsubscribe();
    }

    onSearchBlur(e) {
        if (this.props.onSearchBlur) {
            this.props.onSearchBlur(e);
        }
    }

    clickAwaySubscription = clickSource
        .filter(() => {
            return this.state.isOpen;
        }, this)
        .delay(0) // give child components the chance to prevent closing of the menu.
        .subscribe((e: any) => {
            if (this.props.showSearchWhenClosed) {
                this.focusTimeout = window.setTimeout(() => {
                    const isLocalFocus = ReactDOM.findDOMNode(this).contains(
                        document.activeElement
                    );
                    if (!isLocalFocus && !e.defaultPrevented) {
                        this.setState({ isOpen: false });
                    }
                }, 250);
            }
        });

    onSearchClick(e) {
        if (!this.props.showSearchWhenClosed) {
            e.preventDefault();
        }
    }

    onChange(item, event: Event, callback) {
        if (item.isDisabled || isHeader(item)) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        callback(item, event);
        event.stopPropagation();
    }

    getSummaryDisplay(selectItems, itemName) {
        return (
            (selectItems && selectItems.length > 0 && (
                <div>
                    <div className='header'>{itemName}</div>
                    <div
                        className={
                            'skittle count' +
                            (((selectItems.length - 1) % 7) + 1)
                        }
                    >
                        {selectItems.length}
                    </div>
                </div>
            )) ||
            itemName
        );
    }

    toggleSelectAll(event, areAllSelected, itemType, fieldMap, primaryKey) {
        event.preventDefault();
        const newFields = areAllSelected
            ? (this.props.selectedItems as any).filter((item) =>
                  // deselecting any currently visible items, so filter them out from overall `selectedItems`
                  this.props.availableItems.every(
                      (availableItem) =>
                          this.getItemIdValue(
                              availableItem,
                              itemType,
                              primaryKey
                          ) !== this.getItemIdValue(item, itemType, primaryKey)
                  )
              )
            : [
                  // selecting any currently visible items, so concatenate them with overall `selectedItems` to create new selected list
                  ...(this.props.availableItems as any).filter(
                      (item) =>
                          !item.isDisabled &&
                          !isHeader(item) &&
                          this.props.selectedItems.every(
                              (selectedItem) =>
                                  this.getItemIdValue(
                                      selectedItem,
                                      itemType,
                                      primaryKey
                                  ) !==
                                  this.getItemIdValue(
                                      item,
                                      itemType,
                                      primaryKey
                                  )
                          )
                  ),
                  ...this.props.selectedItems,
              ];
        this.props.onToggleSelectAll(event, newFields);
    }

    loadAdditionalItems = (event) => {
        event.preventDefault();
        this.props.onReadyForMoreData(event);
    };

    getItemIdValue(item, itemType, primaryKey) {
        const value = item[primaryKey || (itemType && itemType.$primaryKey)];
        return value !== undefined ? value : item;
    }

    isItemSelected(item, itemIdValue, fieldMap) {
        return (
            (!item.isDisabled &&
                !isHeader(itemIdValue) &&
                fieldMap[itemIdValue] !== undefined) ||
            (!!item.isSelectableWhenDisabled &&
                !isHeader(itemIdValue) &&
                fieldMap[itemIdValue] !== undefined)
        );
    }

    onItemChange = (item, event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onItemChange(item, event);
        event.stopPropagation();
    };

    render() {
        const {
            id,
            selectedItems,
            onItemClick,
            isReadOnly,
            pluralDisplayName,
            showSummary,
            displayLabelSkittle,
            searchPlaceholder,
            showNoSelectionsAvailable,
            noElementsAvailable,
            alignment,
            canSelectAll,
            dropdownMenuItemTheme,
            dropdownMenuTheme,

            // these should be passed in by the selectInput HOC (components/hocs/selectList), see that component for details
            showSearch,
            onSearchInputKeyDown,
            clearSearch,
            searchText,
            onLabelMouseEnter,
            onLabelMouseLeave,
            onLabelClick,
            onToggle,
            getItemDisplay,
            getItemTooltip,
            tooltipDisplayMode,
            renderReadOnly,

            // the following props are defined within the selectInput HOC
            style,
            className,
            itemName,
            itemType,
            isEnabled,
            isRetrievingData,
            availableItems,
            primaryKey,
            displayNameKey,
            tooltipNameKey,
            onNeedData,
            emptyText,
            showSearchWhenClosed,
            embedInPortal,
            selectedCount,
            staticLastMenuItems,
            showAllSelectedItemsMessage,
        } = this.props;

        if (isReadOnly) {
            return renderReadOnly(selectedItems, displayNameKey, itemType);
        }
        let controlDisplayValue;
        if (showSearch) {
            controlDisplayValue = (
                <span className='select-list__search'>
                    <input
                        value={searchText}
                        type='text'
                        onBlur={this.onSearchBlur}
                        onFocus={this.onSearchFocus}
                        placeholder={searchPlaceholder}
                        onChange={onNeedData}
                        onClick={this.onSearchClick}
                        onKeyDown={onSearchInputKeyDown}
                    />
                    <Glyph name='close' onClick={clearSearch} />
                </span>
            );
        } else {
            const length =
                selectedCount !== undefined
                    ? selectedCount
                    : selectedItems.length || 0;
            const pluralDisplay = pluralDisplayName || itemName + 's';
            if (length && showSummary) {
                if (displayLabelSkittle) {
                    controlDisplayValue = this.getSummaryDisplay(
                        selectedItems,
                        itemName
                    );
                } else {
                    const isMoreDataAvailable =
                        staticLastMenuItems && staticLastMenuItems.length;
                    controlDisplayValue = Localization.getString(
                        showAllSelectedItemsMessage &&
                            (availableItems as any).filter(
                                (item) => !isHeader(item)
                            ).length === length &&
                            !isMoreDataAvailable &&
                            length > 1 // Checks for the edge case where there's only one item in the list
                            ? Strings.allSelectedItemsMessage
                            : Strings.selectedItemsMessage,
                        {
                            numItems: length,
                            item: length > 1 ? pluralDisplay : itemName,
                        }
                    );
                }
            } else {
                controlDisplayValue =
                    emptyText ||
                    Localization.getString(Strings.defaultEmptyText, {
                        pluralDisplayName: pluralDisplay,
                    });
            }
            controlDisplayValue = (
                <ToolTip
                    content={controlDisplayValue}
                    displayMode={tooltipDisplayMode || DisplayMode.overflow}
                >
                    <div
                        className='select-list__tooltip-content'
                        onMouseEnter={onLabelMouseEnter}
                        onMouseLeave={onLabelMouseLeave}
                    >
                        {controlDisplayValue}
                    </div>
                </ToolTip>
            );
        }

        const fieldMap = (selectedItems || ([] as any)).reduce(
            (map, item: any, index: number) => {
                let key: string | number;
                if (typeof item === 'object' && primaryKey) {
                    key = item[primaryKey];
                } else {
                    key = item;
                }
                map[key] = index;
                return map;
            },
            {}
        );

        // count of all items that are currently selectable (i.e. currently visible in the list), which may differ from the
        // total number of items possible for this list, depending on whether a search query string is applied
        const numSelectableItems = (availableItems as any).filter(
            (item) => !item.isDisabled && !isHeader(item)
        ).length;
        // count of the selected items in `availableItems`, which may differ from the total number of `selectedItems`,
        // depending on whether a search query string is applied
        const numSelectedAvailableItems = (availableItems as any).filter(
            (item) => {
                const itemIdValue = this.getItemIdValue(
                    item,
                    itemType,
                    primaryKey
                );
                return this.isItemSelected(item, itemIdValue, fieldMap);
            }
        ).length;
        // true if all active items in the `availableItems` list are selected
        const allSelected =
            numSelectedAvailableItems > 0 &&
            numSelectableItems === numSelectedAvailableItems;

        let menuItems = (availableItems as any)
            .map(this.customItemMapper)
            .map((item, index) => {
                const itemIdValue = this.getItemIdValue(
                    item,
                    itemType,
                    primaryKey
                );
                const selected = this.isItemSelected(
                    item,
                    itemIdValue,
                    fieldMap
                );
                const isHeaderItem = isHeader(item);
                const label = isHeaderItem ? (
                    <HeaderItem item={item} />
                ) : (
                    getItemDisplay(item, displayNameKey, itemType, selected)
                );
                const tooltip = getItemTooltip(item, tooltipNameKey, itemType);
                return (
                    <DropDownMenuItem
                        onClick={(e) =>
                            this.onChange(item, e as any, (innerItem, event) =>
                                onItemClick(
                                    innerItem,
                                    event,
                                    fieldMap[itemIdValue]
                                )
                            )
                        }
                        id={`${id}__select-list-multiple-${itemIdValue}`}
                        tooltip={tooltip || label}
                        tooltipDisplayMode={
                            tooltipDisplayMode ||
                            (tooltip
                                ? DisplayMode.default
                                : DisplayMode.overflow)
                        }
                        isEnabled={isEnabled && !item.isDisabled}
                        theme={
                            isHeaderItem
                                ? 'header'
                                : dropdownMenuItemTheme || undefined
                        }
                        disableFocus={isHeaderItem}
                        key={index}
                        className={`select-list-item select-list-item--multiple${
                            selected
                                ? ' select-list-item--selected'
                                : item.isDisabled
                                ? ' select-list-item--disabled'
                                : ''
                        } ${item.menuItemClassName} ${
                            item.isHidden ? 'select-list-item--hidden' : ''
                        }`}
                    >
                        {isHeaderItem ? (
                            label
                        ) : (
                            <CheckboxControl
                                id={`${id}-item-${itemIdValue}`}
                                value={selected}
                                tabIndex={-1}
                                label={label}
                                isEnabled={isEnabled && !item.isDisabled}
                                name={itemIdValue.toString()}
                                onChange={(e) => this.onItemChange(item, e)}
                            />
                        )}
                    </DropDownMenuItem>
                );
            });

        if (canSelectAll) {
            menuItems = [
                <DropDownMenuItem
                    id={`${id}_select-list-multiple-select-all`}
                    onClick={(e) =>
                        this.toggleSelectAll(
                            e,
                            allSelected,
                            itemType,
                            fieldMap,
                            primaryKey
                        )
                    }
                    isEnabled={isEnabled}
                    key={-1}
                    className={`select-list-item select-list-item--multiple${
                        allSelected ? ' select-list-item--selected' : ''
                    }`}
                >
                    <CheckboxControl
                        id={`${id}-item-selectAll`}
                        value={allSelected}
                        tabIndex={-1}
                        isIndeterminate={
                            numSelectedAvailableItems > 0 &&
                            numSelectableItems !== numSelectedAvailableItems
                        }
                        label={`${
                            allSelected
                                ? Localization.getString(Strings.unselectAll)
                                : Localization.getString(Strings.selectAll)
                        }
                            ${Localization.getString(Strings.numResults, {
                                numResults: numSelectableItems,
                            })}`}
                        isEnabled={isEnabled}
                        onChange={emptyFunction}
                    />
                </DropDownMenuItem>,
            ].concat(menuItems);
        }

        if (
            staticLastMenuItems &&
            staticLastMenuItems.length > 0 &&
            menuItems.length > 0
        ) {
            menuItems = menuItems.concat(staticLastMenuItems);
        }

        return (
            <DropDownMenu
                id={id}
                hasTabIndex={!showSearchWhenClosed}
                alignment={alignment || 'none'}
                style={style}
                className={`select-list--multiple ${className || ''}`}
                label={controlDisplayValue}
                isOpen={this.state.isOpen}
                onClick={onLabelClick}
                onToggle={onToggle}
                embedInPortal={embedInPortal}
                offsetTop={8}
                theme={dropdownMenuTheme}
                children={
                    menuItems.length
                        ? menuItems
                        : isRetrievingData
                        ? [
                              <DropDownMenuItem
                                  key='initializing-data'
                                  id={`${id}__no-selections-initializing-data`}
                                  className='select-list-item--initializing'
                              >
                                  {Localization.getString(
                                      Strings.initializingData,
                                      {
                                          pluralDisplayName:
                                              pluralDisplayName ||
                                              Strings.defaultPluralItemName,
                                      }
                                  )}
                              </DropDownMenuItem>,
                          ]
                        : showNoSelectionsAvailable
                        ? [
                              noElementsAvailable || (
                                  <DropDownMenuItem
                                      key='no-selections'
                                      id={`${id}__no-selections-available`}
                                  >
                                      {Localization.getString(
                                          Strings.noSelectionsAvailable
                                      )}
                                  </DropDownMenuItem>
                              ),
                          ]
                        : undefined
                }
            />
        );
    }
}

export default withReadOnly<MultiSelectListControlProps<any>>(
    selectList(MultiSelectListControl)
);
