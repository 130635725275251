import React, { Component } from 'react';
import { Reflection } from 'util/Reflection';
import themeClassName from 'util/Themes';
import 'rxjs/add/operator/debounceTime';
import { Subject } from 'rxjs/Subject';
import withReadOnly from 'components/hocs/withReadOnly';
import { Observable } from 'rxjs/Observable';
import './TextControl.scss';

export interface TextControlProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    value?: string;
    // set to true in order to render a textarea
    isMultiline?: boolean;
    // is the component enabled?
    isEnabled?: boolean;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    // display the component without lines
    isInline?: boolean;
    // name to give the input/textarea
    name?: string;
    // time to wait before emitting the onChange event for the text input
    debounceTimeInMilliseconds?: number;
    // should textarea auto grow as user types?
    autoResize?: boolean;
    // should text be hidden
    isPasswordFormat?: boolean;
    className?: string;
    // optional class name for the input element
    inputClassName?: string;
    style?: React.CSSProperties;
    theme?: string;
}

class TextControl extends Component<TextControlProps> {
    static defaultProps = {
        isEnabled: true,
        debounceTimeInMilliseconds: 0,
        autoResize: true,
    };

    private subject: Observable<any> | Subject<any> = undefined;
    private subscription;
    private input: HTMLInputElement | HTMLTextAreaElement;
    private pipeChange: (value?: any) => void;
    constructor(props: TextControlProps) {
        super(props);
        this.onTextAreaChange = this.onTextAreaChange.bind(this);
        this.resizeTextArea = this.resizeTextArea.bind(this);
        this.onChange = this.onChange.bind(this);
        const subject = new Subject();

        // TODO: There is a bug here - if the debounceTimeInMilliseconds changes after construction, the behavior of the component will not change.
        // https://atlassian.thetradedesk.com/jira/browse/MEGA-1103
        this.subject =
            this.props.debounceTimeInMilliseconds > 0
                ? subject.debounceTime(this.props.debounceTimeInMilliseconds)
                : subject;
        this.pipeChange = (this.subject as Subject<any>).next.bind(
            this.subject
        );

        if (this.props.onChange) {
            this.subscription = this.subject.subscribe((e) => {
                this.props.onChange(e);
            });
        }
    }

    componentDidMount() {
        if (
            this.input &&
            this.props.value !== undefined &&
            this.props.value !== null
        ) {
            this.input.value = this.props.value;

            // If we have a text-area and we supply its value, we need to resize the TextArea
            // on first load (instead just rely on text-area changed)
            if (this.input.tagName === 'TEXTAREA') {
                this.resizeTextArea(this.input as HTMLTextAreaElement);
            }
        }
    }

    componentWillUnmount() {
        this.subscription && this.subscription.unsubscribe();
    }

    onTextAreaChange(e) {
        if (this.props.autoResize) {
            this.resizeTextArea(e.target);
        }
        this.onChange(e);
    }

    onChange(e) {
        e.persist();
        this.pipeChange(e);
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.input &&
            nextProps.value !== undefined &&
            this.props.value !== null
        ) {
            this.input.value = nextProps.value;
        }
    }

    resizeTextArea(element: HTMLTextAreaElement) {
        if (!element || !this.props.autoResize) {
            return;
        }

        element.style.height =
            (this.props.style && (this.props.style.minHeight as string)) ||
            '64px';
        if (element.offsetHeight < element.scrollHeight) {
            element.style.height = element.scrollHeight + 'px';
        }
    }

    render() {
        const {
            isMultiline,
            debounceTimeInMilliseconds,
            autoResize,
            isReadOnly,
            isInline,
            isEnabled,
            className,
            inputClassName,
            style,
            onChange,
            value,
            isPasswordFormat,
            theme,
            ...rest
        } = this.props;
        const wrapperClass = `text-control ${isInline ? 'input--inline' : ''} ${
            className || ''
        } ${themeClassName(theme)}`;

        return (
            <div className={wrapperClass} style={style}>
                {isReadOnly ? (
                    <span className='text-control--readonly'>
                        {Reflection.isEmptyValue(this.props.value)
                            ? 'None'
                            : this.props.value}
                    </span>
                ) : isMultiline ? (
                    <textarea
                        className={`text-control__textarea ${
                            autoResize
                                ? 'text-control__textarea--auto-resize'
                                : ''
                        }`}
                        ref={(node) => {
                            this.input = node;
                        }}
                        disabled={!isEnabled}
                        {...(rest as React.DetailedHTMLProps<
                            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
                            HTMLTextAreaElement
                        >)}
                        onChange={this.onTextAreaChange}
                    />
                ) : (
                    <input
                        className={inputClassName || ''}
                        disabled={!isEnabled}
                        type={this.props.isPasswordFormat ? 'password' : 'text'}
                        ref={(node) => {
                            this.input = node;
                        }}
                        autoComplete='off'
                        {...rest}
                        onChange={this.onChange}
                    />
                )}
            </div>
        );
    }
}

export default withReadOnly<TextControlProps>(TextControl);
