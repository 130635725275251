import React, { CSSProperties } from 'react';
import { Observable } from 'rxjs/Rx';
import TtdInputField, { TtdField } from 'components/inputs/TtdInputField';
import ValidationError from 'components/display/ValidationError';
import TimeControl from 'components/controls/TimeControl';
import { DayPickerSingleDateController, CalendarDay } from 'react-dates';
import { clickSource, focusSource } from 'util/EventSubscriptions';
import LabelledInput from 'components/display/LabelledInput';
import getInputId from 'util/getInputId';
import { Localization } from 'util/LocalizationService';
import Strings from './DatePickerInput.strings.json';
import DateTimeTextInput from 'components/inputs/DateTimeTextInput';
import {
    moment,
    fromDateTimeUtcToMomentUtc,
    getMomentStartOfDay,
    fromMomentUtcToDateTimeUtc,
    getDateTimeStartOfDay,
    ITimeZone,
    IDateTime,
} from 'util/DateTimeUtils';
import classNames from 'util/classNames';
import themeClassName from 'util/Themes';
import './DatePickerInput.scss';
import './DatePickerCalendarOverrides.scss';
import { PartialObject } from 'lodash';
import { WrappedFieldProps } from 'redux-form';

type DatePickerInputTheme = 'date-picker-filter';

export interface DatePickerInputProps {
    // css class to apply to the control
    className?: string;

    // style rules to apply to the control
    style?: CSSProperties;

    // unique ID to assign to the component
    id?: string;

    // what field the form should bind to in the model
    field: TtdField;

    // whether or not to include time
    includesTime?: boolean;

    // Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)
    isReadOnly?: boolean;

    isEnabled?: boolean;

    // Whether to display this component in the inline style
    isInline?: boolean;

    // optional time zone used as a label only
    timeZoneLabel?: ITimeZone;

    // is picker open?  set for manual control.
    isOpen?: boolean;

    // Optional theme
    theme?: DatePickerInputTheme;
}

/**
 * An input control for picking dates and optionally times, binding to a UiDateTime model.
 */
export class DatePickerInput extends React.Component<DatePickerInputProps> {
    static defaultProps: Partial<DatePickerInputProps> = {
        className: '',
        isInline: false,
        includesTime: false,
    };

    static displayName = 'DatePickerInput';
    private rootNode: HTMLElement;
    state = {
        isOpen: false,
    };

    componentWillUnmount() {
        this.clickAwaySubscription.unsubscribe();
    }

    // Close the popup when clicking away.
    clickAwaySubscription = Observable.merge(
        clickSource.filter(() => {
            return this.state.isOpen;
        }, this),
        focusSource
    ).subscribe((e?: PartialObject<MouseEvent | FocusEvent>) => {
        const isDescendantOfRoot =
            this.rootNode && this.rootNode.contains(e.target as Node);
        if (!isDescendantOfRoot) {
            this.setState({ isOpen: false });
        }
    });

    open = () => {
        this.setState({ isOpen: true });
    };

    parse = (date) => {
        if (!moment.isMoment(date)) {
            return date;
        }

        let dateTime = fromMomentUtcToDateTimeUtc(date);

        if (!this.props.includesTime) {
            dateTime = getDateTimeStartOfDay(dateTime);
        }

        return dateTime;
    };

    render() {
        const {
            field,
            className,
            includesTime,
            isEnabled,
            isInline,
            isReadOnly,
            theme,
        } = this.props;

        const rootClasses = classNames(
            'date-picker date-picker-calendar-overrides',
            className,
            themeClassName(theme)
        );

        return (
            <div
                className={rootClasses}
                ref={(node) => {
                    this.rootNode = node;
                }}
            >
                <DateTimeTextInput
                    field={field}
                    onFocus={this.open}
                    includesTime={includesTime}
                    isEnabled={isEnabled}
                    isReadOnly={isReadOnly}
                    isInline={isInline}
                />
                <TtdInputField
                    field={field}
                    parse={this.parse}
                    component={DatePickerInputField}
                    isOpen={this.state.isOpen}
                    {...this.props}
                />
            </div>
        );
    }
}

export default DatePickerInput;

/** The component used for actually rendering the input field. */
export class DatePickerInputField extends React.Component<
    DatePickerInputProps & WrappedFieldProps
> {
    static displayName = 'DatePickerInputField';

    /** Callback when a date is chosen in the date picker. */
    onDateChange = (date) => {
        // If we had a previous date, we need to preserve the time.
        const { hour, minute } = this.props.input.value || ({} as IDateTime);
        if (minute !== undefined || hour !== undefined) {
            date.hour(hour || 12).minute(minute || 0);
        } else {
            date.hour(12).minute(0);
        }

        this.props.input.onChange(date);
    };

    // for some reason the compiler doesn't see the use of this in our subscription...
    // @ts-ignore
    private rootNode: HTMLDivElement;

    renderTimeInfo = () => {
        const { timeZoneLabel, input } = this.props;
        const label = timeZoneLabel
            ? Localization.getString(Strings.timeLabelWithTz, {
                  tz: timeZoneLabel.shortName,
              })
            : Localization.getString(Strings.timeLabel);

        return (
            <LabelledInput
                label={label}
                className='date-picker__time'
                size='auto'
            >
                <TimeControl
                    id={`tc-${getInputId(TimeControl, this.props)}`}
                    value={input.value || undefined}
                    onChange={this.props.input.onChange}
                    isEnabled={!!input.value}
                />
            </LabelledInput>
        );
    };

    render() {
        const {
            includesTime,
            input,
            meta,
            className,
            style,
            isReadOnly,
            isOpen,
        } = this.props;

        const pickerIsOpen = !isReadOnly && isOpen;

        const rootClasses = `date-picker__picker-wrapper ${className}`;
        const pickerClasses = `date-picker__picker ${
            pickerIsOpen
                ? 'date-picker__picker--open'
                : 'date-picker__picker--closed'
        }`;

        let pickerDateInput = fromDateTimeUtcToMomentUtc(input.value);

        if (!includesTime) {
            pickerDateInput = getMomentStartOfDay(pickerDateInput);
        }

        return (
            <div
                className={rootClasses}
                style={style}
                ref={(node) => {
                    this.rootNode = node;
                }}
            >
                <div className={pickerClasses}>
                    {pickerIsOpen && (
                        <DayPickerSingleDateController
                            hideKeyboardShortcutsPanel
                            date={pickerDateInput}
                            onDateChange={this.onDateChange}
                            renderCalendarInfo={
                                includesTime ? this.renderTimeInfo : undefined
                            }
                            renderCalendarDay={({ modifiers, ...props }) => {
                                return (
                                    <CalendarDay
                                        modifiers={
                                            modifiers instanceof Set
                                                ? modifiers
                                                : new Set()
                                        }
                                        {...props}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
                <ValidationError meta={meta} />
            </div>
        );
    }
}
