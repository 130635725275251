import React from 'react';
import { BaseFieldProps } from 'redux-form';
import TtdInputField, {
    TtdInputFieldExtensionProps,
} from 'components/inputs/TtdInputField';
import RadioGroupControl, {
    RadioGroupControlProps,
    LAYOUT,
} from 'components/controls/RadioGroupControl/RadioGroupControl';
import ValidationService from 'util/ValidationService';
import ValidationError, {
    ValidationErrorProps,
} from 'components/display/ValidationError';
import getInputId from 'util/getInputId';

export interface RadioGroupInputProps
    extends Omit<RadioGroupControlProps<{}>, 'onChange' | 'id'>,
        Pick<ValidationErrorProps, 'hideIfNoErrors'>, // <-- picking this prop from the Validation component's props
        TtdInputFieldExtensionProps {
    isValidationInline?: boolean;
    itemType?: any;
    onChange?(value: any): void;
    showValidation?: boolean;
    renderLabel?: (label: string, item: any) => string;
}

export { LAYOUT };

class RadioGroupInput extends React.Component<
    RadioGroupInputProps & BaseFieldProps
> {
    static LAYOUT = LAYOUT;

    static defaultProps = {
        isValidationInline: false,
        showValidation: true,
        ...RadioGroupControl.defaultProps,
    };

    constructor(props) {
        super(props);
        this.renderInput = this.renderInput.bind(this);
    }

    onChange(val, callback) {
        this.props.onChange && this.props.onChange(val);
        callback(val);
    }

    renderInput(refs) {
        const {
            meta,
            primaryKey,
            availableValues,
            displayNameKey,
            input,
            theme,
            layout,
            isValidationInline,
            isEnabled,
            isReadOnly,
            autoCalculateWidth,
            radioControlClass,
            showValidation,
            hideIfNoErrors,
            renderLabel,
        } = refs;
        const errors = ValidationService.getVisibleErrors(meta);
        const errorClass = (errors.length && 'radio-input--error') || '';

        return (
            <div className={`radio-group radio-group--${layout} ${errorClass}`}>
                <RadioGroupControl
                    id={getInputId(RadioGroupInput, refs)}
                    value={input.value}
                    layout={layout}
                    theme={theme}
                    name={input.name}
                    availableValues={availableValues}
                    primaryKey={primaryKey}
                    displayNameKey={displayNameKey}
                    isEnabled={isEnabled}
                    isReadOnly={isReadOnly}
                    autoCalculateWidth={autoCalculateWidth}
                    radioControlClass={radioControlClass}
                    onChange={(val) => this.onChange(val, input.onChange)}
                    renderLabel={renderLabel}
                />
                {showValidation && (
                    <ValidationError
                        isInline={isValidationInline}
                        meta={meta}
                        hideIfNoErrors={hideIfNoErrors}
                    />
                )}
            </div>
        );
    }

    render() {
        const {
            primaryKey,
            itemType,
            displayNameKey,
            theme,
            field,
            format,
            parse,
            layout,
            isValidationInline,
            isEnabled,
            isReadOnly,
            autoCalculateWidth,
            radioControlClass,
            renderLabel,
        } = this.props;

        const thisPrimaryKey = primaryKey || (itemType && itemType.$primaryKey);
        const thisDisplayNameKey =
            displayNameKey || (itemType && itemType.$displayNameKey);
        return (
            <div className={this.props.className} style={this.props.style}>
                <TtdInputField
                    availableValues={this.props.availableValues}
                    displayNameKey={thisDisplayNameKey}
                    primaryKey={thisPrimaryKey}
                    field={field}
                    format={format}
                    parse={parse}
                    component={this.renderInput}
                    theme={theme}
                    layout={layout}
                    isValidationInline={isValidationInline}
                    isEnabled={isEnabled}
                    isReadOnly={isReadOnly}
                    autoCalculateWidth={autoCalculateWidth}
                    radioControlClass={radioControlClass}
                    renderLabel={renderLabel}
                />
            </div>
        );
    }
}

export default RadioGroupInput;
