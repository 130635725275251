import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import SelectListControl, {
    SelectListControlProps,
} from 'components/controls/SelectListControl/SelectListControl';
import {
    getSortedAvailableValueModels,
    getAvailableValueModels,
    getValueModelById,
    getIdFromValueModel,
    IUiEnum,
    EnumValueModel,
    IEnumControlProps,
} from 'util/UiEnumUtils';

export interface EnumSelectListControlProps<T extends IUiEnum>
    extends IEnumControlProps<T>,
        Omit<
            SelectListControlProps<T>,
            | 'availableItems'
            | 'value'
            | 'primaryKey'
            | 'displayNameKey'
            | 'onChange'
            | 'onLabelMouseLeave'
            | 'onLabelMouseEnter'
            | 'onNeedData'
            | 'onReadyForMoreData'
            | 'onSearchFocus'
            | 'onSearchInputKeyDown'
            | 'onKeyDown'
            | 'onLabelClick'
            | 'onBlur'
            | 'clearSearch'
            | 'clearSearchTextOnEmptySelection'
            | 'customItemMapper'
            | 'isRetrievingData'
            | 'isMoreDataAvailable'
            | 'isSearchEnabled'
        > {
    /**
     * @summary should this be naturally sorted?
     */
    disableSort?: boolean;
    /**
     * @summary function called when the value of the list has changed.
     */
    onChange(value: T[keyof T]): void;

    customItemMapper?(item: EnumValueModel<T>, index: number): any;
    value?: T[keyof T];
}

// This implements a select list control that uses an enum (UiEnum) object's values as the available values.
export default class EnumSelectListControl<T extends IUiEnum> extends Component<
    EnumSelectListControlProps<T>
> {
    static defaultProps = {
        enumValuesToInclude: [],
        enumValuesToExclude: [],
    };

    constructor(props: EnumSelectListControlProps<T>) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.setAvailableItems(props);
        this.setValueModel(this.props.value);
    }

    availableItems: EnumValueModel<T>[];
    valueModel: EnumValueModel<T>;

    setAvailableItems(props: EnumSelectListControlProps<T>) {
        this.availableItems = props.disableSort
            ? getAvailableValueModels(
                  props.enumType,
                  props.enumValuesToInclude,
                  props.enumValuesToExclude
              )
            : getSortedAvailableValueModels(
                  props.enumType,
                  props.enumValuesToInclude,
                  props.enumValuesToExclude
              );
    }

    setValueModel(v) {
        this.valueModel = getValueModelById(v, this.availableItems);
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.enumType !== nextProps.enumType ||
            !isEqual(
                this.props.enumValuesToInclude,
                nextProps.enumValuesToInclude
            ) ||
            !isEqual(
                this.props.enumValuesToExclude,
                nextProps.enumValuesToExclude
            )
        ) {
            this.setAvailableItems(nextProps);
        }

        if (this.props.value !== nextProps.value) {
            this.setValueModel(nextProps.value);
        }
    }

    onChange(val) {
        const value = getIdFromValueModel<T>(val);
        this.props.onChange(value);
    }

    render() {
        const {
            enumType,
            enumValuesToInclude,
            enumValuesToExclude,
            onChange,
            value,
            embedInPortal,
            ...rest
        } = this.props;
        return (
            <SelectListControl
                {...rest}
                primaryKey='id'
                displayNameKey='name'
                tooltipNameKey='tooltip'
                availableItems={this.availableItems}
                onChange={this.onChange}
                value={this.valueModel}
                embedInPortal={embedInPortal}
            />
        );
    }
}
