import React from 'react';
import withReadOnly from 'components/hocs/withReadOnly';
import './RadioControl.scss';

export interface RadioControlProps {
    value: number | string | object;
    // Specify true if this item is selected
    isChecked?: boolean;
    // The label of this radio control
    label?: JSX.Element | string;
    // The function handling the select event of this radio control.
    onChange(value: any): void;
    // Set isEnabled to false in order to disable this radio option
    isEnabled?: boolean;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    // Required name of this radio, must be the same for all radios in a group.
    name: string;
    // The css class to apply to the radio
    className?: string;
    // The style rules to apply to the radio
    style?: React.CSSProperties;
    // Required id of the radio
    id: string;
}

class RadioControl extends React.Component<RadioControlProps> {
    static defaultProps = {
        isEnabled: true,
        className: '',
    };

    render() {
        const {
            value,
            label,
            name,
            onChange,
            isEnabled,
            isReadOnly,
            isChecked,
            className,
            style,
            id,
        } = this.props;
        const rootClasses = ['radio-control', className];

        // Per style guide, readonly radio control are rendered as a simple text label.
        // https://atlassian.thetradedesk.com/confluence/pages/viewpage.action?spaceKey=URTU&title=Style+Guides&preview=/7307280/18120826/RadioButton_StyleGuide_Megagon.pdf
        if (isReadOnly) {
            rootClasses.push('radio-control--readonly');
            return <div className={rootClasses.join(' ')}>{label}</div>;
        }

        // Otherwise, figure out what classes we're going to apply to each element in this control.
        const labelClasses = ['radio-control__label'];
        const inputClasses = ['radio-control__input'];

        const enabledClass = isEnabled ? 'enabled' : 'disabled';
        rootClasses.push(`radio-control--${enabledClass}`);
        labelClasses.push(`radio-control__label--${enabledClass}`);
        inputClasses.push(`radio-control__input--${enabledClass}`);

        const selectedClass = isChecked ? 'selected' : 'not-selected';
        rootClasses.push(`radio-control--${selectedClass}`);
        labelClasses.push(`radio-control__label--${selectedClass}`);
        inputClasses.push(`radio-control__input--${selectedClass}`);

        return (
            <div className={rootClasses.join(' ')} style={style}>
                <input
                    id={id}
                    className={inputClasses.join(' ')}
                    name={name}
                    type='radio'
                    checked={isChecked}
                    readOnly={isReadOnly}
                    disabled={!isEnabled || isReadOnly}
                    onChange={() => onChange(value)}
                />
                <label
                    className={labelClasses.join(' ')}
                    htmlFor={id}
                    data-clickable='true'
                >
                    {label}
                </label>
            </div>
        );
    }
}

export default withReadOnly(RadioControl);
