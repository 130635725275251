import React, { Component } from 'react';
import { getFieldName } from '../TtdInputField';
import ValidationError from '../../display/ValidationError';
import MultiSelectListControl from '../../controls/MultiSelectListControl/MultiSelectListControl';
import getInputId from '../../../util/getInputId';
import { MultiSelectListInputProps } from './MultiSelectListInputProps';
import { WrappedFieldArrayProps, change } from 'redux-form';
import { cloneDeep } from 'lodash';

export type MultiSelectListInputFieldProps<T> = WrappedFieldArrayProps<T> &
    Omit<MultiSelectListInputProps<T>, 'className' | 'style'> & {
        change: typeof change;
    };

export default class MultiSelectListInputField<T> extends Component<
    MultiSelectListInputFieldProps<T>
> {
    id: string;
    static displayName = 'MultiSelectListInputField';

    constructor(props: MultiSelectListInputFieldProps<T>) {
        super(props);
        const { meta, field } = props;
        this.id = getInputId(MultiSelectListInputField, {
            meta,
            input: { name: getFieldName(field) },
        });
    }

    onChange = (item, event, index) => {
        const { fields, onChange } = this.props;
        const wasPreviouslySelected = index !== undefined;
        const newValues = [...(fields.getAll() || [])];
        event.preventDefault();

        if (wasPreviouslySelected) {
            // remove them now...
            newValues.splice(index, 1);
        } else {
            // add them to the newValues
            newValues.push(item);
        }

        if (onChange) {
            item = onChange(
                event,
                item,
                !wasPreviouslySelected,
                newValues.map(cloneDeep)
            );
        }

        return wasPreviouslySelected ? fields.remove(index) : fields.push(item);
    };

    onCheckedChange = (item) => {
        const { fields, shouldShowLoading } = this.props;
        if (!shouldShowLoading) {
            fields.push(item);
        }
    };

    // this replaces the current fields array with `newFields`, likely after a select/unselect all
    replaceFields = (event, newFields) => {
        const { fields, change, meta, onChange } = this.props;
        change(meta.form, fields.name, newFields);
        if (onChange) {
            onChange(event, undefined, undefined, newFields);
        }
    };

    render() {
        const { fields, meta, showValidation, ...props } = this.props;
        const identifier =
            props.primaryKey || (props.itemType && props.itemType.$primaryKey);
        return (
            <span>
                <MultiSelectListControl
                    {...props}
                    id={this.id}
                    selectedItems={fields.getAll() || []}
                    primaryKey={identifier}
                    onItemChange={this.onCheckedChange}
                    onItemClick={this.onChange}
                    onToggleSelectAll={this.replaceFields}
                    onKeyDown={this.props.onSearchInputKeyDown}
                />
                {showValidation && (
                    <ValidationError isInline={props.isInline} meta={meta} />
                )}
            </span>
        );
    }
}
