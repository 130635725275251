import React, { Component, ComponentType, Ref, forwardRef } from 'react';
import IsExportModeContext from 'components/contexts/IsExportModeContext';
import { getComponentDisplayName } from 'util/getComponentDisplayName';
import hoistNonReactStatics from 'hoist-non-react-statics';

export interface InjectedWithIsExportMode {
    isExportMode?: boolean;
}

// A dead-simple HOC: Exposes the `isExportMode` context value to the wrapped
// component as a `isExportMode` prop.
export default function withIsExportMode<
    ComponentProps extends InjectedWithIsExportMode
>(WrappedComponent: ComponentType<ComponentProps>) {
    type withRef = { forwardedRef: Ref<unknown> };

    class WithIsExportMode extends Component<
        ComponentProps & withRef & InjectedWithIsExportMode
    > {
        // Given this HOC a human-readable name in the Component tree.
        static displayName = `WithIsExportMode[${getComponentDisplayName(
            WrappedComponent
        )}]`;

        render() {
            // If component already has `isExportMode` set as a prop, use
            // whatever value it has.
            const {
                forwardedRef,
                isExportMode: propIsExportMode,
                ...rest
            } = this.props;

            // Pull `isExportMode` from context and pass to wrapped component.
            return (
                <IsExportModeContext.Consumer>
                    {(isExportMode) => (
                        <WrappedComponent
                            ref={forwardedRef}
                            {...(rest as ComponentProps)}
                            isExportMode={
                                propIsExportMode !== undefined
                                    ? propIsExportMode
                                    : isExportMode
                            }
                        />
                    )}
                </IsExportModeContext.Consumer>
            );
        }
    }

    // Ensure static members remain on wrapped component.
    return hoistNonReactStatics(
        forwardRef((props: ComponentProps & InjectedWithIsExportMode, ref) => {
            return <WithIsExportMode {...props} forwardedRef={ref} />;
        }),
        WrappedComponent
    );
}
