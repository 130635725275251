import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { getDialogState } from '../Dialog';
import zIndexes from '../../../util/ZIndex';

import DropDownMenuComponent, {
    DropDownMenuProps,
} from './DropDownMenuComponent';

const mapStateToProps = (state) => {
    return {
        zIndex: getDialogState(state).currentMaxZIndex + zIndexes.Dropdown,
    };
};

export default connect<{ zIndex: number }, {}, DropDownMenuProps>(
    mapStateToProps
)(DropDownMenuComponent as ComponentType);
