﻿import React, { Component } from 'react';

import DataTable, {
    DataTableProps,
    SORT_DIRECTION,
} from 'components/display/DataTable';
import CheckboxControl from 'components/controls/CheckboxControl';

import { DataTableColumn } from '../DataTable';
import EnhancedDataView, {
    EnhancedDataViewProps,
    SelectAllState,
    InjectedDataViewProps,
} from '../EnhancedDataView';
import './EnhancedDataTable.scss';

export interface EnhancedDataTableProps<
    TRow,
    TTableData = {},
    TRowData = {},
    THeaderData = {},
    TFooterData = {},
    TFilter = {},
    TFilterResponse = {}
>
    extends Omit<
            DataTableProps<
                TRow,
                TTableData,
                TRowData,
                THeaderData,
                TFooterData
            >,
            'sorting'
        >,
        Pick<
            EnhancedDataViewProps<
                TRow,
                TTableData,
                TRowData,
                TFilter,
                TFilterResponse
            >,
            | 'formName'
            | 'fieldName'
            | 'filtering'
            | 'onSelectionChanged'
            | 'toolbarActions'
            | 'maxToolbarActionsDisplay'
        > {
    sorting?: {
        defaultDirection: SORT_DIRECTION;

        defaultColumn: string;

        // Optional boolean which indicates if all the rows (initial and newly added) should be sorted.
        // By default only the initial rows are sorted and not the newly added ones.
        sortAllRows?: boolean;

        rowsArePreSorted?: boolean;
    };

    /** True to enable selection via checkboxes */
    isRowCheckboxSelectionEnabled?: boolean;

    totalAvailableRows?: number;
}

interface EnhancedDataTableState<TRow> {
    columns: DataTableColumn<TRow>[];
    computedColumns: DataTableColumn<TRow>[];
    rows: TRow[];
    isRowCheckboxSelectionEnabled?: boolean;
    selectAllState: SelectAllState;
}

/**
 * A wrapper for DataTable to enable row selection.
 */
export class EnhancedDataTableComponent<
    TRow,
    TTableData,
    TRowData,
    THeaderData,
    TFooterData,
    TFilter,
    TFilterResponse
> extends Component<
    EnhancedDataTableProps<
        TRow,
        TTableData,
        TRowData,
        THeaderData,
        TFooterData,
        TFilter,
        TFilterResponse
    > &
        InjectedDataViewProps<TRow, TTableData>
> {
    state: EnhancedDataTableState<TRow> = {
        columns: [],
        computedColumns: [],
        rows: [],
        isRowCheckboxSelectionEnabled: undefined,
        selectAllState: undefined,
    };

    static getDerivedStateFromProps(
        nextProps: EnhancedDataTableProps<any> & InjectedDataViewProps<any>,
        prevState: EnhancedDataTableState<any>
    ) {
        if (
            nextProps.columns !== prevState.columns ||
            nextProps.isRowCheckboxSelectionEnabled !==
                prevState.isRowCheckboxSelectionEnabled ||
            nextProps.selectAllState !== prevState.selectAllState ||
            nextProps.rows !== prevState.rows
        ) {
            return {
                rows: nextProps.rows,
                computedColumns: nextProps.isRowCheckboxSelectionEnabled
                    ? [
                          {
                              id: 'enhanced-data-table-selection',
                              header: (
                                  <CheckboxControl
                                      id='enhanced-data-table-selection__select-all'
                                      name='select-all'
                                      className='enhanced-datatable__checkbox'
                                      onChange={nextProps.selectAllChange}
                                      label=''
                                      value={[
                                          SelectAllState.AllKnownItemsSelected,
                                          SelectAllState.AllVisibleSelected,
                                      ].includes(nextProps.selectAllState)}
                                  />
                              ),
                              width: {
                                  minWidthPixels: 50,
                                  flexGrow: 0,
                                  flexShrink: 0,
                              },
                              layout: {
                                  order: 0,
                              },
                              alignment: {
                                  vertical: DataTable.VERTICAL_ALIGNMENT.CENTER,
                                  horizontal:
                                      DataTable.HORIZONTAL_ALIGNMENT.CENTER,
                              },
                              renderContent: (
                                  row: any,
                                  index,
                                  rowData,
                                  tableData: {
                                      getPrimaryKey(row): string;
                                      selectionMap: any;
                                  }
                              ) => {
                                  return (
                                      <CheckboxControl
                                          value={
                                              !!tableData.selectionMap[
                                                  tableData.getPrimaryKey(row)
                                              ]
                                          }
                                          id={`enhanced-datatable-row-selection__${tableData.getPrimaryKey(
                                              row
                                          )}`}
                                          onChange={nextProps.getOnCheckedChange(
                                              row
                                          )}
                                          isEnabled={
                                              !nextProps.getIsRowDisabled ||
                                              !nextProps.getIsRowDisabled(
                                                  row,
                                                  rowData,
                                                  tableData
                                              )
                                          }
                                          className='enhanced-datatable__checkbox'
                                      />
                                  );
                              },
                          } as DataTableColumn<any>,
                          ...nextProps.columns,
                      ]
                    : nextProps.columns,
                columns: nextProps.columns,
                isRowCheckboxSelectionEnabled:
                    nextProps.isRowCheckboxSelectionEnabled,
                selectAllState: nextProps.selectAllState,
            } as EnhancedDataTableState<any>;
        }
        return null;
    }

    render() {
        const {
            className,
            isRowCheckboxSelectionEnabled,
            columns,
            selectAllState,
            selectAllChange,
            getOnCheckedChange,
            filtering,
            totalAvailableRows,
            ...rest
        } = this.props;

        return (
            <DataTable
                className={`enhanced-datatable ${className}`}
                {...rest}
                columns={this.state.computedColumns}
            />
        );
    }
}

/**
 * A convenience component which wraps the DataTable within the EnhancedDataView to add sorting, filtering, selections and bulk actions.
 */
class EnhancedDataTableComponentWrapper<
    TRow,
    TTableData,
    TRowData,
    THeaderData,
    TFooterData,
    TFilter,
    TFilterResponse
> extends Component<
    EnhancedDataTableProps<
        TRow,
        TTableData,
        TRowData,
        THeaderData,
        TFooterData,
        TFilter,
        TFilterResponse
    >
> {
    static getDeleteColumn = DataTable.getDeleteColumn;
    static getToggleColumn = DataTable.getToggleColumn;
    static THEME = DataTable.THEME;
    static ROW_HEIGHT = DataTable.ROW_HEIGHT;
    static SORT_DIRECTION = DataTable.SORT_DIRECTION;
    static VERTICAL_ALIGNMENT = DataTable.VERTICAL_ALIGNMENT;
    static HORIZONTAL_ALIGNMENT = DataTable.HORIZONTAL_ALIGNMENT;
    static COMPARATOR = DataTable.COMPARATOR;
    static ADDED_ITEMS_LOCATION = DataTable.ADDED_ITEMS_LOCATION;
    static FILTER_MODE = EnhancedDataView.FILTER_MODE;

    static defaultProps = {
        className: '',
    };

    render() {
        const {
            className,
            isRowCheckboxSelectionEnabled,
            toolbarActions = [],
            maxToolbarActionsDisplay,
            totalAvailableRows,
            sorting,
            tableData,
            columns,
            ...rest
        } = this.props;

        return (
            <EnhancedDataView
                className={className}
                items={rest.rows}
                viewData={tableData}
                sorting={
                    sorting
                        ? {
                              ...sorting,
                              defaultField: sorting.defaultColumn,
                              itemsArePreSorted: sorting.rowsArePreSorted,
                              sortAllItems: sorting.sortAllRows,
                          }
                        : undefined
                }
                totalAvailableItems={totalAvailableRows}
                enableItemSelection={isRowCheckboxSelectionEnabled}
                toolbarActions={toolbarActions}
                maxToolbarActionsDisplay={maxToolbarActionsDisplay}
                fields={columns}
                emptyItemsContent={rest.emptyRowsContent}
                getIsItemDisabled={rest.getIsRowDisabled}
                getIsItemVisible={rest.getIsRowVisible}
                getItemHeightPixels={rest.getRowHeightPixels}
                itemsData={rest.rowData}
                renderItems={rest.renderRows}
                emptyContent={rest.emptyRowsContent}
                onItemClick={rest.onRowClick as any}
                {...rest}
            >
                <EnhancedDataTableComponent
                    className={className}
                    isRowCheckboxSelectionEnabled={
                        isRowCheckboxSelectionEnabled
                    }
                    columns={columns}
                    {...(rest as any)} // any - to handle the props injected via EnhancedDataView
                />
            </EnhancedDataView>
        );
    }
}

export default EnhancedDataTableComponentWrapper;
