import React, { Component, ReactNode, CSSProperties } from 'react';
import TtdInputFieldArray, {
    TtdInputFieldArrayComponentProps,
} from 'components/inputs/TtdInputFieldArray';
import getInputId from 'util/getInputId';
import CheckboxControl from 'components/controls/CheckboxControl';
import ValidationError from 'components/display/ValidationError';
import HorizontalList from 'components/display/HorizontalList';
import './CheckboxGroup.scss';
import { IUiModel } from '../TtdInputField';
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form';

export enum CheckboxGroupLayout {
    horizontal = 'horizontal',
    vertical = 'vertical',
    grid = 'grid',
}

type CheckboxGroupTheme = 'filter' | 'wizard-koa-options' | 'with-checkmarks';

interface RenderCheckboxProps<T> {
    item: T;
    fields: FieldArrayFieldsProps<T>;
    index: number;
    isReadOnly: boolean;
    id: string;
    name: string;
    label: any;
    value: { index: number };
    onChange(
        item: T,
        fields: FieldArrayFieldsProps<T>,
        value?: { index: number }
    ): void;
    renderLabel?: (label: string, item: T) => string;
    theme?: CheckboxGroupTheme;
}

export interface CheckboxGroupProps<T>
    extends TtdInputFieldArrayComponentProps<{
        isReadOnly?: boolean;
        // All items to display as checkboxes.
        collection: T[];
        // name of the display name field/property of the items in the collection.
        displayNameKey?: keyof T;
        // name of the primary key field/property of the items in the collection.
        primaryKey?: keyof T;
        // UiModel with PrimaryKeyField and DisplayField Attributes.
        itemType?: IUiModel<T>;
        // the html id attribute
        id: string;
        // optional render prop to render a different checkbox
        renderCheckbox?(props: RenderCheckboxProps<T>): ReactNode;
        // vertical or horizontal layout. Defaults to vertical.
        layout?: CheckboxGroupLayout;
        // set spacing between individual checkboxes for lists
        gutterSize?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
        // set how many elements should be in the grid
        gridWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
        // The following props are passed down to horizontal list
        allowWrapping?: boolean;
        alignment?: 'left' | 'right' | 'center' | 'justify';
        theme?: CheckboxGroupTheme;
        className?: string;
        style?: CSSProperties;
        renderLabel?: (label: string, item: T) => string;
    }> {}

type CheckboxGroupFieldProps<T> = WrappedFieldArrayProps<T> &
    CheckboxGroupProps<T>;

class CheckboxGroupFields<T> extends Component<CheckboxGroupFieldProps<T>> {
    renderCheckbox = ({
        item,
        fields,
        index,
        id,
        name,
        label,
        value,
        isReadOnly,
        onChange,
        renderLabel,
        theme,
    }: RenderCheckboxProps<T>) => (
        <CheckboxControl
            key={index}
            id={id}
            name={name}
            label={renderLabel ? renderLabel(label, item) : label}
            value={value !== undefined}
            isReadOnly={isReadOnly}
            onChange={(e) => onChange(item, fields, value)}
            theme={theme}
        />
    );

    onChange = (
        item: T,
        fields: FieldArrayFieldsProps<T>,
        selectedValue: { index: number }
    ) => {
        if (selectedValue) {
            fields.remove(selectedValue.index);
        } else {
            fields.push(item);
        }
    };

    render() {
        const {
            meta,
            fields,
            itemType,
            primaryKey,
            displayNameKey,
            isReadOnly,
            theme,
        } = this.props;
        const id = getInputId(CheckboxGroup, {
            meta,
            input: { name: fields.name },
        });
        const $primaryKey = (itemType && itemType.$primaryKey) || primaryKey;
        const $displayNameKey =
            (itemType && itemType.$displayNameKey) || displayNameKey;
        const map = (fields.getAll() || []).reduce((map: any, item, index) => {
            const pkValue = item[$primaryKey];
            map[pkValue !== undefined ? pkValue : item] = {
                index,
            };
            return map;
        }, {} as {}) as any;
        const renderCheckbox = this.props.renderCheckbox || this.renderCheckbox;
        const checkBoxes = this.props.collection.map((item, index) => {
            // map of the selected ids for performant lookup of selected items for rendering.
            const idValue = (item[$primaryKey] !== undefined
                ? item[$primaryKey]
                : item) as T[keyof T];
            const selectedValue = map[idValue];
            return renderCheckbox({
                item,
                fields,
                index,
                isReadOnly,
                id: `${id}-${idValue}`,
                name: `${fields.name}-${index}`,
                label: item[$displayNameKey] || item,
                value: selectedValue,
                onChange: this.onChange,
                renderLabel: this.props.renderLabel,
                theme,
            });
        });

        const defaultClassName =
            this.props.layout === CheckboxGroup.LAYOUT.grid
                ? 'checkbox-group-grid'
                : 'checkbox-group';

        const classes = [defaultClassName];
        if (
            this.props.layout === CheckboxGroup.LAYOUT.grid &&
            this.props.gridWidth
        ) {
            classes.push(defaultClassName + `--size-${this.props.gridWidth}`);
        }

        return (
            <div className={classes.join(' ')} id={id}>
                {this.props.layout === CheckboxGroup.LAYOUT.horizontal ? (
                    <HorizontalList
                        children={checkBoxes}
                        gutterSize={this.props.gutterSize}
                        alignment={this.props.alignment}
                        allowWrapping={this.props.allowWrapping}
                        theme={this.props.theme}
                    />
                ) : (
                    checkBoxes
                )}
                <ValidationError meta={meta} />
            </div>
        );
    }
}

export default class CheckboxGroup<T> extends Component<CheckboxGroupProps<T>> {
    static LAYOUT = CheckboxGroupLayout;

    static defaultProps = {
        layout: CheckboxGroup.LAYOUT.vertical,
        className: '',
    };

    static displayName = 'CheckboxGroupInput';

    render() {
        const { className, style, id, ...rest } = this.props;
        return (
            <div
                className={this.props.className}
                style={this.props.style}
                id={this.props.id}
            >
                <TtdInputFieldArray {...rest} component={CheckboxGroupFields} />
            </div>
        );
    }
}
