import React, { Component } from 'react';
import withReadOnly from 'components/hocs/withReadOnly';
import themeClassName from 'util/Themes';
import './CheckboxControl.scss';

type CheckboxControlTheme = 'filter' | 'wizard-koa-options' | 'with-checkmarks';

export interface CheckboxControlProps {
    id: string;
    value?: boolean;
    label?: React.ReactNode;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
    /** True if checkbox is in an "indeterminate" state. This is most likely to be used if this is a Select All checkbox */
    isIndeterminate?: boolean;
    isEnabled?: boolean;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    tabIndex?: number;
    className?: string;
    style?: React.CSSProperties;
    name?: string;
    theme?: CheckboxControlTheme;
}

class CheckboxControl extends Component<CheckboxControlProps> {
    static displayName = 'Checkbox';

    static defaultProps: Partial<CheckboxControlProps> = {
        tabIndex: 0,
        className: '',
        isEnabled: true,
    };

    input: HTMLElement;

    render() {
        const {
            id,
            value,
            label,
            onChange,
            isIndeterminate,
            isEnabled,
            isReadOnly,
            tabIndex,
            className,
            style,
            name,
            theme,
        } = this.props;

        const rootClasses = [
            'checkbox-control',
            className,
            themeClassName(theme),
        ];
        const labelClasses = ['checkbox-control__label'];
        const checkmarkClasses = ['checkbox-control__checkmark'];

        if (!isEnabled || isReadOnly) {
            rootClasses.push('checkbox-control--disabled');
            labelClasses.push('checkbox-control__label--disabled');
            checkmarkClasses.push('checkbox-control__checkmark--disabled');
        } else {
            rootClasses.push('checkbox-control--enabled');
            labelClasses.push('checkbox-control__label--enabled');
        }
        if (value) {
            labelClasses.push('checkbox-control__label--checked');
            checkmarkClasses.push('checkbox-control__checkmark--checked');
        }
        if (isIndeterminate) {
            rootClasses.push('checkbox-control--indeterminate');
            labelClasses.push('checkbox-control__label--indeterminate');
        }

        return (
            <div style={style} className={rootClasses.join(' ')} key={id}>
                <div className='checkbox-control__wrapper'>
                    <input
                        id={id}
                        className='checkbox-control__input'
                        name={name}
                        type='checkbox'
                        tabIndex={tabIndex}
                        checked={value === undefined ? undefined : !!value}
                        readOnly={isReadOnly}
                        disabled={!isEnabled || isReadOnly}
                        onChange={onChange}
                        ref={(node) => {
                            this.input = node;
                        }}
                    />
                    <label
                        className={labelClasses.join(' ')}
                        htmlFor={!isReadOnly ? id : undefined}
                        data-clickable='true'
                    >
                        {label}
                    </label>
                </div>
            </div>
        );
    }
}

export default withReadOnly(CheckboxControl);
