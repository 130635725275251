﻿import React from 'react';
import themeClassName from 'util/Themes';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import ToolTip from 'components/display/ToolTip';
import './ValidationErrorInline.scss';

export interface ValidationErrorInlineProps {
    error: string;
    style?: React.CSSProperties;
    theme?: 'proposal-deal-details';
}

export default function ValidationErrorInline({
    error,
    style,
    theme,
}: ValidationErrorInlineProps) {
    return (
        <div className={`validation-error-inline ${themeClassName(theme)}`}>
            <ToolTip theme='error' content={error}>
                <Glyph
                    className='validation-error-inline__glyph'
                    name='exclamation'
                    style={style}
                />
            </ToolTip>
        </div>
    );
}
