﻿import React, { ReactNode } from 'react';
import Button, { BUTTON_TYPE } from '../../Button';
import ToolTip, { DisplayMode } from '../../ToolTip';
import { DIALOG_STATUS, IStatusDetails } from '../DialogActions';
import themeClassName from '../../../../util/Themes';

import { Localization } from '../../../../util/LocalizationService';
import Strings from './DialogActionBar.strings.json';

import './DialogActionBar.scss';

// Positions where the the glyph can appear on an action button in relation to the action's text
export enum GLYPH_POSITIONS {
    BEFORE = 'before',
    AFTER = 'after',
}

export interface Action {
    // The name of the action, used as a label on the button.
    label: ReactNode;

    // The function called when the action is clicked.  It takes the dialogId and
    // the action object as parameters.
    onClick: (dialogId: string, action: any) => void;

    // The type of the action -- if not provided, defaults to primary or secondary depending on the
    // number of buttons in the action bar.
    type?: BUTTON_TYPE;

    // Boolean indicating whether the action is a 'submission' action or not.
    isSubmission?: boolean;

    // Boolean indicating whether the action is enabled (clickable) or not.
    isEnabled?: boolean;

    // Boolean indicating whether the button should be rendered in a flat style or not (default is not).
    isFlat?: boolean;

    // Name of glyph where, if specified, it will appear next to the action's label on the button
    glyph?: string;

    // Specifies if the glyph should appear before or after the button's label (defaults to 'before')
    glyphPosition?: GLYPH_POSITIONS;
}

export interface SuccessAction {
    label: ReactNode;
    onClick: (dialogId: string, action: any) => void;
}

export interface DialogActionBarProps {
    // The ID of the containing dialog.
    dialogId: string;

    // An optional className to add to the root element in this component.
    className?: string;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'first-run-wizard' is a theme.
    theme?: string;

    // The actions to display when the dialog is in the UNSUBMITTED (normal) state.
    actions: Action[];

    // An optional object that describes an additional action to display on the
    // "success" page of a dialog (e.g., "Go to Ad Group" on the success page of the
    // create ad group wizard).  Used only in task dialogs (task or stepped task).
    successAction?: SuccessAction;

    // An optional override to specify the text to include in the final "close" dialog.
    closeActionLabel?: string;

    // The function to call when the dialog fails to submit and the user clicks "retry".
    // This prop is only required for action bars in task dialogs (task or stepped task).
    retryAction: (dialogId: string, action: any) => void;

    onCancel: (dialogId: string, action: any) => void;

    disableAutoCloseOnSuccess?: boolean;

    // From connected container
    setDialogStatusDetails: (
        dialogStatus: DIALOG_STATUS,
        dialogId: string
    ) => void;
    statusDetails: IStatusDetails;
    isFormValid?: boolean;
    // current count of validation errors on the form (if there's a form)
    fieldErrorCount: number;
    // indicates whether the current dialog has a corresponding form
    hasForm: boolean;
    currentStepIndex: number;

    // Optional field to indicate the back button should not render on error
    suppressBackButtonOnError?: boolean;
    // Optional field to indicate the retry button should not render on error
    suppressRetryButtonOnError?: boolean;
    // Optional field to indicate that the close button should not render on success
    suppressCloseButtonOnSuccess?: boolean;

    hideDialog: (dialogId: string) => void;
}

export default class DialogActionBarComponent extends React.Component<
    DialogActionBarProps,
    {}
> {
    static defaultProps = {
        className: '',
        theme: '',
        closeActionLabel: '',
        glyphPosition: GLYPH_POSITIONS.BEFORE,
    };

    autoHideTimeout: any;

    componentDidMount() {
        const curStatus =
            (this.props.statusDetails &&
                this.props.statusDetails.dialogStatus) ||
            DIALOG_STATUS.NONE;

        if (
            curStatus === DIALOG_STATUS.SUBMIT_SUCCESS &&
            !this.props.successAction &&
            !this.props.disableAutoCloseOnSuccess
        ) {
            // The dialog is in the SUBMIT_SUCCESS status, and there is no "successAction", which means
            // there's nothing to do but close the dialog.  As a convenience, we close the dialog for the
            // user after a small timeout.
            this.autoHideTimeout = setTimeout(() => {
                this.props.hideDialog(this.props.dialogId);
            }, 3000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.autoHideTimeout);
    }

    getButtons(
        dialogId,
        normalActions,
        successAction,
        retryAction,
        onCancel,
        hideDialog,
        statusDetails,
        setDialogStatusDetails,
        isFormValid,
        fieldErrorCount,
        hasForm
    ) {
        let actions = [];

        switch (statusDetails.dialogStatus) {
            case DIALOG_STATUS.INITIALIZING:
                break;
            case DIALOG_STATUS.SUBMIT_SUCCESS:
                // NOTE: If there is no successAction, the dialog will close automatically.
                if (successAction || this.props.disableAutoCloseOnSuccess) {
                    if (!this.props.suppressCloseButtonOnSuccess) {
                        actions.push({
                            label:
                                this.props.closeActionLabel ||
                                Localization.getString(Strings.closeButton),
                            onClick: (dialogId) => {
                                hideDialog(dialogId);
                            },
                        });
                    }

                    if (successAction) {
                        actions.push({
                            label: successAction.label,
                            type: BUTTON_TYPE.PRIMARY,
                            onClick: (dialogId) => {
                                hideDialog(dialogId, true /*syncClose*/);
                                successAction.onClick(dialogId, successAction);
                            },
                        });
                    }
                }
                break;
            case DIALOG_STATUS.SUBMITTING:
                break;
            case DIALOG_STATUS.UNSUBMITTED:
                actions = normalActions;
                break;
            case DIALOG_STATUS.REVIEW:
                actions.push({
                    label: Localization.getString(Strings.closeButton),
                    onClick: (dialogId) => {
                        hideDialog(dialogId);
                    },
                });
                break;
            case DIALOG_STATUS.ERROR:
                actions.push({
                    label: Localization.getString(Strings.cancelButton),
                    onClick: onCancel,
                });
                if (
                    !this.props.suppressBackButtonOnError &&
                    !statusDetails.suppressBackButtonOnError
                ) {
                    actions.push({
                        label: Localization.getString(Strings.backButton),
                        onClick: (dialogId) => {
                            setDialogStatusDetails({
                                dialogStatus: DIALOG_STATUS.UNSUBMITTED,
                                dialogId,
                            });
                        },
                    });
                }
                if (
                    !this.props.suppressRetryButtonOnError &&
                    !statusDetails.suppressRetryButtonOnError
                ) {
                    actions.push({
                        label: Localization.getString(Strings.retryButton),
                        onClick: retryAction,
                    });
                }
                break;
        }

        let toolTipMessage = '';
        if (fieldErrorCount > 0 && !isFormValid) {
            toolTipMessage =
                fieldErrorCount === 1
                    ? Localization.getString(Strings.errorMessageSingle)
                    : Localization.getString(Strings.errorMessagePlural, {
                          fieldErrorCount,
                      });
        }

        // wrapped indicates that it has a tooltip. need to do some slightly different styling for these.  the 'only-child' styling
        // will unintentionally fire for these buttons since they're the only child of their tooltip.
        const buttonClassName =
            actions.length > 1
                ? 'dialog-action-bar__wrapped__button'
                : 'dialog-action-bar__button';

        return actions.map((action, index) => {
            // Use the specified action type, but if it's not specified,
            // make it the primary if it's the only button;
            // otherwise, make any unspecified button a secondary button.
            const buttonType =
                action.type ||
                (actions.length === 1
                    ? BUTTON_TYPE.PRIMARY
                    : BUTTON_TYPE.SECONDARY);

            // follow the guidance from action.isEnabled if defined
            let enabled = true;
            if (action.isEnabled !== undefined) {
                enabled = action.isEnabled;
            }

            // follow the guidance from action.isFlat if defined
            let flat = false;
            if (action.isFlat !== undefined) {
                flat = action.isFlat;
            }

            // if the action belongs to a dialog with a form (Task or SteppedTask) and the action progresses the form
            // (submission or 'next'), it can be blocked by an invalid form.  We want to wrap these with an explanatory tooltip
            const isBlockingAction = action.isSubmission && hasForm;

            // if a glyph was specified, figure out if it should appear before or after
            const glyphProps = {};
            if (action.glyph !== undefined) {
                const glyphPosition =
                    action.glyphPosition === GLYPH_POSITIONS.BEFORE
                        ? 'glyphBefore'
                        : 'glyphAfter';
                glyphProps[glyphPosition] = action.glyph;
            }

            return isBlockingAction ? (
                <div className={buttonClassName} key={index}>
                    <ToolTip
                        content={toolTipMessage}
                        theme='error'
                        // Ideally, we'd prefer to use DisplayMode.force here to always show the tooltip but that causes the tooltip to
                        // be abandoned awkwardly when the dialog is dragged around the screen.
                        displayMode={DisplayMode.default}
                    >
                        <Button
                            id={`${dialogId}-ActionBar-${
                                action.label.replace
                                    ? action.label.replace(/ /g, '_')
                                    : action.id || index
                            }`}
                            onClick={() => {
                                action.onClick(dialogId, action);
                            }}
                            type={buttonType}
                            isEnabled={enabled}
                            isFlat={flat}
                            {...glyphProps}
                        >
                            {action.label}
                        </Button>
                    </ToolTip>
                </div>
            ) : (
                <Button
                    id={`${dialogId}-ActionBar-${action.label.replace(
                        / /g,
                        '_'
                    )}`}
                    key={index}
                    onClick={() => {
                        action.onClick(dialogId, action);
                    }}
                    type={buttonType}
                    isEnabled={enabled}
                    isFlat={flat}
                    className={buttonClassName}
                    {...glyphProps}
                >
                    {action.label}
                </Button>
            );
        });
    }

    render() {
        const {
            dialogId,
            actions,
            className,
            theme,
            successAction,
            retryAction,
            onCancel,
            hideDialog,
            setDialogStatusDetails,
            statusDetails,
            isFormValid,
            fieldErrorCount,
            hasForm,
            children,
        } = this.props;

        const buttons = this.getButtons(
            dialogId,
            actions,
            successAction,
            retryAction,
            onCancel,
            hideDialog,
            statusDetails,
            setDialogStatusDetails,
            isFormValid,
            fieldErrorCount,
            hasForm
        );

        return (
            buttons.length > 0 && (
                <div
                    className={`dialog-action-bar ${className} ${themeClassName(
                        theme
                    )}`}
                >
                    {children}
                    {buttons}
                </div>
            )
        );
    }
}
