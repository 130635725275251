import { connect } from 'react-redux';
import { setDialogStatusDetails, hideDialog } from '../DialogActions';
import { destroy, submit, isDirty } from 'redux-form';
import {
    initializeDialogConfirmation,
    getNavigationState,
} from '../../../../reducers/navigationcontrol';
import TaskDialogComponent from './TaskDialogComponent';
import { getDialogState } from '../DialogReducer';

const mapActionCreators = {
    setDialogStatusDetails,
    hideDialog,
    initializeDialogConfirmation,
    submitForm: submit,
    destroyForm: destroy,
};

const mapStateToProps = (state, ownProps) => {
    const dialogInstance = getDialogState(state).instances[ownProps.dialogId];
    const dialogStatus =
        dialogInstance && dialogInstance.statusDetails.dialogStatus;
    const navState = getNavigationState(state);
    const isConfirmationPending =
        navState.parentDialogId === ownProps.dialogId
            ? navState.isConfirmationPending
            : false;
    const formName = navState.formName;
    const formId = ownProps.formName || `${ownProps.dialogId}-form`;
    const dirty = isDirty(formId)(state);

    return {
        dialogStatus,
        isDirty: dirty,
        currentConfirmationFormName: formName,
        isConfirmationPending,
        formId,
    };
};

export default connect(mapStateToProps, mapActionCreators, null, {
    forwardRef: true,
})(TaskDialogComponent as any);
