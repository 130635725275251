import React from 'react';
import { Matching, GetProps } from 'react-redux';
import { getComponentDisplayName } from 'util/getComponentDisplayName';
import hoistNonReactStatics, { NonReactStatics } from 'hoist-non-react-statics';

export default function createPropProviderHOC<MappedProps, OwnProps>(
    name: string,
    mapProps: (ownProps: OwnProps) => MappedProps
) {
    return function <
        C extends React.ComponentType<Matching<MappedProps, ComponentProps>>,
        // GetProps does not work if there are optional props,
        // see: https://github.com/microsoft/TypeScript/issues/40552
        ComponentProps = GetProps<C>,
        RefType = unknown
    >(
        WrappedComponent: C
    ): /* expose statics other than defaultProps and propTypes */
    NonReactStatics<C> &
        /* our component forwards refs */
        React.ForwardRefExoticComponent<
            // Omit ref from props
            React.PropsWithoutRef<
                // merge defaultProps with Props so you don't need to provide them
                JSX.LibraryManagedAttributes<
                    C,
                    Pick<
                        ComponentProps,
                        Exclude<keyof ComponentProps, keyof MappedProps>
                    >
                > &
                    OwnProps // handle own props
            > &
                React.RefAttributes<RefType> // allow ref={ref}
        > {
        const Component = React.forwardRef<
            RefType,
            JSX.LibraryManagedAttributes<
                C,
                Omit<ComponentProps, keyof MappedProps>
            > &
                OwnProps
        >((props: any, ref: any) => {
            const newProps = mapProps(props);
            return <WrappedComponent {...props} {...newProps} ref={ref} />;
        });

        Component.displayName = `${name}(${getComponentDisplayName(
            WrappedComponent
        )})`;

        const ComponentWithStatics = hoistNonReactStatics(
            Component,
            WrappedComponent
        );

        return ComponentWithStatics;
    };
}
