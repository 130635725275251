import Promise from 'bluebird';

// Utility function which finds the slice of redux state at the given key.
// The key (which may be a simple string or a dot-delimited path string) is the
// same string used when injecting a reducer using injectReducer.  We use named
// constants in the modules files to allow these keys to be shared between these
// calls and the call to injectReducer.
//
// NOTE: This function isn't intended to find arbitary points in redux state, but
// rather only the "slices" (top-level state) that a reducer governs.  This is because
// this function will throw if it cannot find sub objects along the path.
// This beehavior is for development purposes only.  In production we will return an eempty object
// when nothing is found at the state path.
export const getStateByKey = (state, key) => {
    if (key === undefined) {
        throw new Error(
            'The value of "key" was undefined in getStateByKey - remember to pass in both state & key as parameters (you likely only passed in the key).'
        );
    }

    const keyParts = key.split('.');

    let currentNode = state;
    let lastCurrentNode;
    for (let i = 0; i < keyParts.length; i++) {
        lastCurrentNode = currentNode;
        currentNode = currentNode[keyParts[i]];

        if (currentNode === undefined) {
            // find item in array keyParts[i] something like "lists[12]"
            const indexMatch = /([a-zA-Z0-9$\-@_]+)\[(\d+)\]/.exec(keyParts[i]);
            if (indexMatch) {
                currentNode = lastCurrentNode[indexMatch[1]][indexMatch[2]];
            }
        }

        if (currentNode === undefined) {
            throw new StateNotFoundError(
                `Missing object in key ${key}: ${keyParts[i]}`
            );
        }
    }

    return currentNode;
};

const defaultTimeout =
    (1000 * 60 * 5) /* 5 minutes */ / 4; /* every 250 milliseconds*/

/**
 * Utility method used for retrieving an item we expect to be in the redux state.  The most typical reason we can't just "get" the
 * path property from state synchronously is that there is a chance the property is coming from the server asynchronously.  Thus this
 * method will "poll" the state tree until it finds what it looks for or the @see tries run out.
 *
 * The danger in this result timing out and being handled is that there could be a problem, and the user will never
 * get notified.  This case is usually handled by a network request either timing out or returning a 500 error in which a @see Toastie
 * notification will indicate to the user that there was a problem.
 *
 * @param {Function} getState method to get "fresh" state.  Typically coming from thunk async action creators
 * @param {String} path dot and bracket string path on the object from the result of calling getState
 * @param {Number} tries number of tries to attempt.
 * @throws {StateNotFoundError} after the number of attempts has been exhausted.
 * @return {Promise} Promise of a property at the given {path} from the result of {getState}
 */
export const waitForStateByPath = (getState, path, tries = defaultTimeout) => {
    return new Promise((resolve, reject) => {
        try {
            return resolve(getStateByKey(getState(), path));
        } catch (err) {
            if (tries <= 0) {
                return reject(err);
            }
            return Promise.delay(250)
                .then(() => waitForStateByPath(getState, path, tries - 1))
                .then(resolve)
                .catch(reject);
        }
    });
};

export class StateNotFoundError extends Error {
    constructor(...params) {
        super(...params);
        (Error as any).captureStackTrace(this, StateNotFoundError);
    }
}
