﻿import React, {
    createRef,
    Component,
    CSSProperties,
    MouseEvent,
    ReactNode,
    RefObject,
} from 'react';
import isEqual from 'lodash/isEqual';
import ReadOnlyContext from 'components/contexts/ReadOnlyContext';

// sort function to order columns in the row. This is also used in `DataTable` to sort the headers
export const sortColumns = (
    c1: DataColumn<any, any, any>,
    c2: DataColumn<any, any, any>
) => c1.layout.order - c2.layout.order || c1.id.localeCompare(c2.id);

export type DataColumnLayout = {
    // If true, this is a fixed column (meaning it will stay in place when the table is scrolled horizontally).
    isFixed?: boolean;
    // In what order is this column displayed?
    order: number;
};

export type DataColumn<TRow, TTableData, TRowData> = {
    // class name to apply to the column container
    columnContainerClassName?: string;

    // A unique ID for this column (within its table)
    id: string;

    layout?: DataColumnLayout;

    // Optional function to get a dynamic CSS class to apply to a particular column. Use columns.className if the class should be applied to all columns.
    // (row, rowIndex, rowData[id], tableData) => element
    getColumnClassName?(
        row: TRow,
        rowIndex?: number,
        rowData?: TRowData,
        tableData?: TTableData
    ): React.ReactNode;

    // string of class names to apply to the column
    columnClassName?: string;

    // an object containing style properties to apply when a horizontal scrollbar is not in use
    styleWithoutHorizontalScrollbar?: CSSProperties;

    // an object containing style properties to apply when a horizontal scrollbar is in use
    styleWithHorizontalScrollbar?: CSSProperties;

    // Render function for the content of this column.
    //
    // Should be a PURE function, where the output is determined
    // only by the inputs.
    //
    // (row, rowIndex, rowData[id], tableData) => element
    renderContent: (
        row: TRow,
        rowIndex?: number,
        rowData?: TRowData,
        tableData?: TTableData
    ) => ReactNode;

    // when horizontal alignment is defined, this is used to position the content
    marginStyle?: string;
};

export interface DataTableRowProps<TRow, TTableData, TRowData> {
    // Column information from the table. This has precomputed values
    // and the reference will only get updated when there is a change.
    columnInformation: { [id: string]: DataColumn<TRow, TTableData, TRowData> };

    // Arbitrary data from the table that is passed to the rendering functions for columns
    tableData?: TTableData;

    // The height of this row, in pixels.
    rowHeightPixels: number;

    // The height of the row's expanded content, in pixels.
    expandedRowHeightPixels?: number;

    // Render function for the expanded row content.
    // (obj, rowIndex) -> elements
    renderExpandedRowContent?(
        row: TRow,
        rowIndex: number,
        rowData?: TRowData
    ): ReactNode[];

    // Is there a horizontal scrollbar in effect?
    hasHorizontalScrollbar?: boolean;

    // Is the table scrolled?
    scrollLeftPixels?: number;

    // The index of this particular row.
    rowIndex: number;

    // Additional data to be passed to the row the rendering functions for columns
    // and expanded row content.
    rowData?: TRowData;

    // The value of the row.
    value?: TRow;

    // If the row is off-screen, we'll do a simple render.
    isOffScreen?: boolean;

    // Is the row selected?
    isSelected?: boolean;

    // Indicates that the row is disabled
    isDisabled?: boolean;

    // Apply CSS class to a row.
    className?: string;

    // Optional function to handle when the row is clicked
    onRowClick?(
        columnId: string,
        row: TRow,
        rowData?: TRowData,
        event?: MouseEvent<HTMLDivElement>
    ): void;
}

class DataTableRow<TRow, TTableData, TRowData> extends Component<
    DataTableRowProps<TRow, TTableData, TRowData>
> {
    columns: DataColumn<TRow, TTableData, TRowData>[];
    domRef: RefObject<HTMLDivElement>;

    constructor(props: DataTableRowProps<TRow, TTableData, TRowData>) {
        super(props);
        this.columns = this.getColumnInformation(
            this.props.columnInformation || {}
        );
        this.domRef = createRef();
    }

    shouldComponentUpdate(
        nextProps: DataTableRowProps<TRow, TTableData, TRowData>
    ) {
        // When the row was offscreen and it is still offscreen,
        // there is no reason to re-render unless the row height
        // has changed.
        if (this.props.isOffScreen && nextProps.isOffScreen) {
            return (
                this.props.rowHeightPixels !== nextProps.rowHeightPixels ||
                this.props.expandedRowHeightPixels !==
                    nextProps.expandedRowHeightPixels
            );
        }

        // If the row's offscreen state has changed, then we DO
        // have to re-render.
        if (this.props.isOffScreen !== nextProps.isOffScreen) {
            return true;
        }

        /*
         * Check the rest of the props for changes.
         */

        if (this.props.columnInformation !== nextProps.columnInformation) {
            return true;
        }

        if (this.props.tableData !== nextProps.tableData) {
            return true;
        }

        if (this.props.rowHeightPixels !== nextProps.rowHeightPixels) {
            return true;
        }

        if (
            this.props.expandedRowHeightPixels !==
            nextProps.expandedRowHeightPixels
        ) {
            return true;
        }

        if (
            this.props.renderExpandedRowContent !==
            nextProps.renderExpandedRowContent
        ) {
            return true;
        }

        if (
            this.props.hasHorizontalScrollbar !==
            nextProps.hasHorizontalScrollbar
        ) {
            return true;
        }

        if (this.props.scrollLeftPixels !== nextProps.scrollLeftPixels) {
            return true;
        }

        if (this.props.rowIndex !== nextProps.rowIndex) {
            return true;
        }

        if (this.props.isSelected !== nextProps.isSelected) {
            return true;
        }

        if (this.props.isDisabled !== nextProps.isDisabled) {
            return true;
        }

        // We need to do a deep-comparison of the "value" prop because we don't
        // know anything about what is stored inside of it (only our render
        // functions know how what changed might impact the render). We wait
        // until after all of the cheaper checks above are run though, since
        // a deep comparison *can* be expensive.
        if (!isEqual(this.props.value, nextProps.value)) {
            return true;
        }

        return false;
    }

    private handleRowClick = (
        columnId: string,
        row: TRow,
        rowData: TRowData,
        e: MouseEvent<HTMLDivElement>
    ) => {
        if (this.props.onRowClick) {
            this.props.onRowClick(columnId, row, rowData, e);
        }
    };

    componentWillReceiveProps(
        nextProps: DataTableRowProps<TRow, TTableData, TRowData>
    ) {
        if (this.props.columnInformation !== nextProps.columnInformation) {
            this.columns = this.getColumnInformation(
                nextProps.columnInformation
            );
        }
    }

    scrollIntoView = (tableBody: HTMLDivElement) => {
        const elem = this.domRef.current;
        const elemRect = elem.getBoundingClientRect();
        const tableRect = tableBody.getBoundingClientRect();
        if (
            elemRect.top < tableRect.top ||
            elemRect.bottom > tableRect.bottom
        ) {
            elem.scrollIntoView();
        }
    };

    getColumnInformation = (
        columnInformation: DataTableRowProps<
            TRow,
            TTableData,
            TRowData
        >['columnInformation']
    ) => {
        return Object.values(columnInformation).sort(sortColumns);
    };

    getRowStyle = (heightPixels?: number) => {
        if (!heightPixels) return undefined;

        return {
            height: heightPixels + 'px',
            maxHeight: heightPixels + 'px',
            minHeight: heightPixels + 'px',
        };
    };

    renderExpandedRowContent = () => {
        if (!this.props.expandedRowHeightPixels) {
            return null;
        }

        const expandedContentStyle = this.getRowStyle(
            this.props.expandedRowHeightPixels
        );

        return (
            <div className='datatable__row' style={expandedContentStyle}>
                {this.props.renderExpandedRowContent(
                    this.props.value,
                    this.props.rowIndex,
                    this.props.rowData
                )}
            </div>
        );
    };

    renderRow = () => {
        if (this.props.isOffScreen) {
            return (
                <div
                    ref={this.domRef}
                    className='datatable__row datatable__row--offscreen'
                    style={this.getRowStyle(
                        this.props.rowHeightPixels +
                            (this.props.expandedRowHeightPixels || 0)
                    )}
                >
                    <div className='datatable__row--offscreen__content' />
                </div>
            );
        } else {
            const style = this.getRowStyle(this.props.rowHeightPixels);
            return (
                <div ref={this.domRef}>
                    <div
                        className={`datatable__row ${
                            this.props.isSelected
                                ? 'datatable__row--selected'
                                : ''
                        } ${
                            this.props.isDisabled
                                ? 'datatable__row--disabled'
                                : ''
                        } ${this.props.className}`}
                        style={style}
                    >
                        {/* Render the columns in the row. */}
                        {this.columns.map((info) => {
                            const containerStyle = {};
                            if (info.marginStyle) {
                                containerStyle[info.marginStyle] = 'auto';
                            }

                            return (
                                <div
                                    key={info.id}
                                    className={`${info.columnClassName} ${
                                        info.getColumnClassName
                                            ? info.getColumnClassName(
                                                  this.props.value,
                                                  this.props.rowIndex,
                                                  this.props.rowData,
                                                  this.props.tableData
                                              )
                                            : ''
                                    }`}
                                    onClick={(e) =>
                                        this.handleRowClick(
                                            info.id,
                                            this.props.value,
                                            this.props.rowData,
                                            e
                                        )
                                    }
                                    style={{
                                        ...(this.props.hasHorizontalScrollbar
                                            ? info.styleWithHorizontalScrollbar
                                            : info.styleWithoutHorizontalScrollbar),
                                        left: info.layout.isFixed
                                            ? undefined
                                            : -this.props.scrollLeftPixels,
                                    }}
                                >
                                    <div
                                        className={
                                            info.columnContainerClassName
                                        }
                                        style={containerStyle}
                                    >
                                        {info.renderContent(
                                            this.props.value,
                                            this.props.rowIndex,
                                            this.props.rowData,
                                            this.props.tableData
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* Render expanded content if the row is expanded. */}
                    {this.renderExpandedRowContent()}
                </div>
            );
        }
    };

    render() {
        return this.props.isDisabled ? (
            <ReadOnlyContext.Provider value={true}>
                {this.renderRow()}
            </ReadOnlyContext.Provider>
        ) : (
            this.renderRow()
        );
    }
}

export default DataTableRow;
