﻿import { connect } from 'react-redux';
import { getFormInitialValues, change } from 'redux-form';
import get from 'lodash/get';

import {
    initializeDataViewConfig,
    updateDataViewConfigSortField,
    updateDataViewConfigSortDirection,
    selectAllVisible,
    itemSelect,
    clearAllSelections,
    selectAllKnownValues,
    getDataViewStateById,
} from './EnhancedDataViewReducer';
import EnhancedDataViewComponent, {
    EnhancedDataViewActionProps,
    EnhancedDataViewProps,
    EnhancedDataViewStateProps,
} from './EnhancedDataViewComponent';

const mapActionCreators = {
    initializeDataViewConfig,
    updateDataViewConfigSortField,
    updateDataViewConfigSortDirection,
    selectAllVisible,
    clearAllSelections,
    selectAllKnownValues,
    itemSelect,
    change,
};

const mapStateToProps = (state, ownProps: EnhancedDataViewProps<any>) => {
    const viewState = getDataViewStateById(state, ownProps.id);
    let initial;
    let map;

    if (ownProps.formName && ownProps.fieldName) {
        const initialFormValues = getFormInitialValues(ownProps.formName)(
            state
        );
        initial = get(initialFormValues, ownProps.fieldName, null);
    } else {
        initial = ownProps.items;
    }

    if (initial) {
        map = {};
        const items = initial;
        items.forEach((item) => {
            map[ownProps.getPrimaryKey(item)] = true;
        });
    }

    return {
        sortField: viewState.sortField,
        sortDirection: viewState.sortDirection,
        initialItems: map,
        selectAllState: viewState.selectAllState,
        selectionMap: viewState.selectionMap,
    };
};

export default connect<
    EnhancedDataViewStateProps,
    EnhancedDataViewActionProps<any>,
    Omit<
        EnhancedDataViewProps<{}>,
        'sortField' | 'sortDirection' | 'initialItems'
    >
>(mapStateToProps, mapActionCreators, null, {
    forwardRef: true,
})(EnhancedDataViewComponent);
