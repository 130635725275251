import React, { ReactNode } from 'react';
import './FilterLabel.scss';

interface FilterLabelProps {
    onClick(): void;
    hasValue: boolean;
    labelText: ReactNode;
    hasOpenDialog?: boolean;
}

function FilterLabel({
    hasValue,
    hasOpenDialog,
    onClick,
    labelText,
}: FilterLabelProps) {
    return (
        <button
            className={`ac-filter-label ${
                hasValue ? 'ac-filter-label--with-value' : ''
            } ${hasOpenDialog ? 'ac-filter-label--open' : ''}`}
            onClick={onClick}
        >
            {labelText}
        </button>
    );
}

export default FilterLabel;
