export default class PositionService {
    // `baseOffset` is the gutter for top/bottom positioning of the element.
    // If `useFullHeight` is true, the element will take up all the space of the parent, minus the gutter.
    // Otherwise, the allowed space for the element is `maxElementHeight` if it's set, or the actual height
    // of the element at the time of positioning.
    static centerElement(
        element,
        parent,
        baseOffset = 0,
        useFullHeight = false,
        maxElementHeight = undefined
    ) {
        const parentHeight = document.body.offsetHeight;
        const elementRect = element.getBoundingClientRect();
        const elementHeight = elementRect.height;
        let topOffset = useFullHeight
            ? baseOffset
            : (parentHeight -
                  (maxElementHeight !== undefined
                      ? maxElementHeight
                      : elementHeight)) /
              2;
        if (topOffset < baseOffset) {
            topOffset = baseOffset;
        }
        element.style.top = `${topOffset}px`;
        // center horizontally
        let leftOffset =
            elementRect.left + document.body.scrollLeft - element.offsetLeft;

        if (leftOffset > 0) {
            leftOffset = -leftOffset;
        }
        leftOffset =
            (document.body.offsetWidth - elementRect.width) / 2 + leftOffset;

        element.style.left = `${leftOffset}px`;
    }

    static positionElement(element, left, top) {
        if (top !== undefined) {
            element.style.top = `${top}px`;
        }

        if (left !== undefined) {
            element.style.left = `${left}px`;
        }
    }
}
