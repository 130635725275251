﻿import { connect } from 'react-redux';
import { isValid } from 'redux-form';
import { hideDialog, setDialogStatusDetails } from '../DialogActions';
import DialogActionBarComponent, {
    DialogActionBarProps,
} from './DialogActionBarComponent';
import { getDialogState } from '../../Dialog/DialogReducer';

const mapActionCreators = {
    hideDialog,
    setDialogStatusDetails,
};

const mapStateToProps = (state, ownProps: DialogActionBarProps) => {
    const fieldErrorCount = state.maui.navigation.fieldErrorCount;
    const dialogInstance = getDialogState(state).instances[ownProps.dialogId];
    const statusDetails = dialogInstance && dialogInstance.statusDetails;
    const hasForm = state.form[`${ownProps.dialogId}-form`];
    const valid = isValid(`${ownProps.dialogId}-form`)(state);
    const stepIndex = dialogInstance && dialogInstance.currentStepIndex;

    return {
        statusDetails: statusDetails,
        isFormValid: valid,
        fieldErrorCount: fieldErrorCount,
        hasForm: !!hasForm,
        currentStepIndex: stepIndex,
    };
};

export default connect(
    mapStateToProps,
    mapActionCreators
)(DialogActionBarComponent as any) as any;
