﻿import React from 'react';
import ValidationError from '../../../components/display/ValidationError';
import getInputId from '../../../util/getInputId';
import ValidationService from '../../../util/ValidationService';
import TtdInputField, { TtdInputFieldExtensionProps } from '../TtdInputField';
import TextControl, {
    TextControlProps,
} from '../../../components/controls/TextControl/TextControl';

export type TextInputProps = TtdInputFieldExtensionProps &
    Omit<TextControlProps, 'value'> & {
        isValidationInline?: boolean;
        showValidation?: boolean;
        hideIfNoErrors?: boolean;
        onBeforeChange?(value: string, previousValue: string): boolean;
        controlClassName?: string;
    };

class TextInput extends React.Component<TextInputProps> {
    static defaultProps = {
        showValidation: true,
    };

    static displayName = 'TextInput';

    normalize = (value, previousValue) => {
        if (
            this.props.onBeforeChange &&
            this.props.onBeforeChange(value, previousValue) === false
        ) {
            return previousValue;
        } else {
            return value;
        }
    };

    renderInput = (fieldProps) => {
        const {
            input,
            meta,
            isValidationInline,
            showValidation,
            hideIfNoErrors,
            onBeforeChange,
            field,
            controlClassName,
            ...rest
        } = fieldProps;
        const inputId = getInputId(TextInput, fieldProps);
        const errors = ValidationService.getVisibleErrors(meta);
        const errorClass = (errors.length && 'input--error') || '';
        const wrapperClass = ValidationService.getWrapperClass(
            'text-input',
            isValidationInline
        );

        return (
            <div className={wrapperClass}>
                <TextControl
                    {...input}
                    {...rest}
                    id={inputId}
                    className={`${errorClass} ${controlClassName}`}
                />
                {showValidation && (
                    <ValidationError
                        meta={meta}
                        isInline={fieldProps.isValidationInline}
                        hideIfNoErrors={hideIfNoErrors}
                        inlineTopOffsetPixels={3}
                        inlineLeftOffsetPixels={8}
                    />
                )}
            </div>
        );
    };

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <TtdInputField
                    {...this.props}
                    component={this.renderInput}
                    normalize={this.normalize}
                />
            </div>
        );
    }
}

export default TextInput;
