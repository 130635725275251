import React, { Component } from 'react';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import Indicator, { TYPES } from 'components/display/Indicator';
import keycode from 'keycode';
import withReadOnly from 'components/hocs/withReadOnly';
import './SwitchControl.scss';

export type AvailableSwitchControlItem = {
    id: string;
    name: string;
};

export interface SwitchControlProps {
    availableItems: AvailableSwitchControlItem[];

    // Indicates if the control can be interacted with
    isEnabled?: boolean;
    isReadOnly?: boolean;

    // Function called after selected value changed
    onChange(value: string): void;
    renderItem?(value: string): string | JSX.Element;

    // Represents the id of an item in the `availableItems` property
    value: string;
    className?: string;
    style?: React.CSSProperties;
    indicator?: TYPES;
    id?: string;
    ref?: React.RefObject<SwitchControl>;
}

class SwitchControl extends Component<SwitchControlProps> {
    static defaultProps = {
        isEnabled: true,
    };

    private nextItem: (
        event: React.MouseEvent<HTMLDivElement> | Event
    ) => void = undefined;
    private previousItem: (
        event: React.MouseEvent<HTMLDivElement> | Event
    ) => void = undefined;

    constructor(props: SwitchControlProps) {
        super(props);
        this.nextItem = this.navigate.bind(this, 1);
        this.previousItem = this.navigate.bind(this, -1);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    renderItem(item: AvailableSwitchControlItem) {
        return this.props.renderItem
            ? this.props.renderItem(item.id)
            : item.name;
    }

    navigate(amount: number, event: Event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();

        if (!this.props.isEnabled) {
            return;
        }

        const index = this.props.availableItems.findIndex(
            (v) => v.id === this.props.value
        );
        const nextIndex = index + amount;
        let value = this.props.availableItems[nextIndex];

        if (!value && amount < 0) {
            value = this.props.availableItems[
                this.props.availableItems.length - 1
            ];
        } else if (!value) {
            value = this.props.availableItems[0];
        }

        this.props.onChange(value.id);
    }

    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        const e = (event as unknown) as Event;
        switch (keycode(e)) {
            case 'up': {
                this.previousItem(e);
                break;
            }
            case 'space':
            case 'down': {
                this.nextItem(e);
                break;
            }
        }
    }

    private wrapper: HTMLDivElement;

    public focus = () => {
        this.wrapper.focus();
    };
    render() {
        const {
            availableItems,
            className,
            style,
            isEnabled,
            isReadOnly,
            indicator,
        } = this.props;

        const isComponentEnabled = isEnabled && !isReadOnly;

        return (
            <div
                className={`${className} switch-control ${
                    isComponentEnabled ? '' : 'switch-control--disabled'
                }`}
                style={style}
                tabIndex={isComponentEnabled ? 0 : -1}
                onKeyDown={this.onKeyDown}
                onClick={this.nextItem}
                ref={(node) => {
                    this.wrapper = node;
                }}
            >
                {indicator && <Indicator type={indicator} />}
                <ul className='switch-control__list'>
                    {availableItems.map((item) => (
                        <li
                            key={item.id}
                            className={`switch-control__list-item switch-control__list-item${
                                item.id === this.props.value
                                    ? '--selected'
                                    : '--not-selected'
                            }`}
                        >
                            {this.renderItem(item)}
                        </li>
                    ))}
                </ul>
                <div
                    className='switch-control__navigation'
                    data-clickable='true'
                >
                    <Glyph
                        name='up-arrow'
                        tabIndex={-1}
                        onClick={
                            isComponentEnabled ? this.previousItem : undefined
                        }
                    />
                    <Glyph
                        name='down-arrow'
                        tabIndex={-1}
                        onClick={isComponentEnabled ? this.nextItem : undefined}
                    />
                </div>
            </div>
        );
    }
}

export default withReadOnly(SwitchControl);
