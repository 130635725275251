import React, { Component } from 'react';
import Button, { BUTTON_TYPE } from 'components/display/Button';
import DropDownMenu from 'components/display/DropDownMenu';
import DropDownMenuItem from 'components/display/DropDownMenuItem';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import Environment from 'util/Environment';
import { Localization } from 'util/LocalizationService';

import { SelectAllState } from '../EnhancedDataViewReducer';
import Strings from './DataViewToolbar.strings.json';
import './DataViewToolbar.scss';

export interface SelectionMap {
    [id: string]: boolean;
}

export interface DataViewToolbarComponentProps<TItem> {
    selectAllState: SelectAllState;
    selectAllKnownItems(id: string, items: SelectionMap): void;
    totalAvailableItems: number;
    selectionMap: SelectionMap;
    toolbarActions: DataViewToolbarAction<TItem>[];
    maxToolbarActionsDisplay?: number;
    clearSelections(): void;
    isVisible: boolean;
}

export interface DataViewToolbarAction<TItem> {
    /**
     * @summary action which fires when user clicks the glyph or label
     * @param selectedItems which items are selected
     * @param allAvailableItemsSelected are all the items selected including ones not in the view currently?
     * @param clearSelections callback to fire once action is complete.
     */
    action(
        selectedItems: string[],
        allAvailableItemsSelected: boolean,
        clearSelections: () => void
    ): void;
    /**
     * @summary name of the action to display
     */
    label: string;
    /**
     * @summary is the button disabled?
     */
    isEnabled?: boolean;
    /**
     * @summary name of glyph to display
     */
    glyph?: string;
}

export default class DataViewToolbarComponent<TItem> extends Component<
    DataViewToolbarComponentProps<TItem>
> {
    static defaultProps = {
        maxToolbarActionsDisplay: 3,
    };

    onActionClick = (action: DataViewToolbarAction<TItem>) => {
        action.action(
            Object.keys(this.props.selectionMap),
            this.props.selectAllState === SelectAllState.AllKnownItemsSelected,
            this.props.clearSelections
        );
    };

    render() {
        const {
            selectAllState,
            totalAvailableItems,
            selectionMap,
            selectAllKnownItems,
            toolbarActions,
            maxToolbarActionsDisplay,
            isVisible,
        } = this.props;

        if (!isVisible) {
            return null;
        }

        const toolbarClass = Environment.isWindows
            ? ' enhanced-dataview-toolbar--windows'
            : '';
        const groupedActions = toolbarActions.slice(maxToolbarActionsDisplay);

        const singleActions = toolbarActions.slice(0, maxToolbarActionsDisplay);
        return (
            <div className={`enhanced-dataview-toolbar ${toolbarClass}`}>
                <div className='enhanced-dataview-toolbar__counter'>
                    <div className='enhanced-dataview-toolbar__counter-total'>
                        <div className='enhanced-dataview-toolbar__counter-total-count'>
                            {selectAllState ===
                            SelectAllState.AllKnownItemsSelected
                                ? totalAvailableItems
                                : Object.keys(selectionMap).length}
                        </div>
                        <span>{Localization.getString(Strings.selected)}</span>
                    </div>
                    <Button
                        isFlat
                        type={BUTTON_TYPE.SECONDARY}
                        onClick={selectAllKnownItems}
                        className='enhanced-dataview-toolbar__counter-select-all'
                        id='enhanced-dataview-toolbar__counter-select-all'
                    >
                        {Localization.getString(
                            selectAllState ===
                                SelectAllState.AllKnownItemsSelected
                                ? Strings.clearAll
                                : Strings.selectAll,
                            {
                                totalAvailableItems,
                            }
                        )}
                    </Button>
                </div>
                <div className='enhanced-dataview-toolbar__spacer' />
                <div className='enhanced-dataview-toolbar__action-wrapper'>
                    {singleActions.map((action) => {
                        const key = action.label.replace(/\s/g, '_');
                        return (
                            <Button
                                isFlat
                                isEnabled={action.isEnabled}
                                key={key}
                                theme='data-view-toolbar'
                                type={BUTTON_TYPE.SECONDARY}
                                className='enhanced-dataview-toolbar__item enhanced-dataview-toolbar__item-button'
                                onClick={() => this.onActionClick(action)}
                                glyphBefore={action.glyph}
                                id={`enhanced-dataview-toolbar__item__${key}`}
                            >
                                <div className='enhanced-dataview-toolbar__button-label'>
                                    {action.label}
                                </div>
                            </Button>
                        );
                    })}
                    {groupedActions.length > 0 && (
                        <DropDownMenu
                            alignment='left'
                            suppressGlyph
                            theme='data-view-toolbar'
                            tabIndex={-1}
                            label={
                                <Button
                                    glyphBefore='menu'
                                    id='enhanced-dataview-toolbar__item-menu'
                                    isFlat
                                />
                            }
                            className='enhanced-dataview-toolbar__item enhanced-dataview-toolbar__item-menu'
                            id='enhanced-dataview-toolbar__action-bar-menu__groupedActions'
                        >
                            {groupedActions.map((action) => {
                                const key = action.label.replace(/\s/g, '_');
                                return (
                                    <DropDownMenuItem
                                        key={key}
                                        isEnabled={action.isEnabled}
                                        onClick={() =>
                                            this.onActionClick(action)
                                        }
                                        id={`enhanced-dataview-toolbar__action-bar-menu-item__${key}`}
                                    >
                                        {action.glyph && (
                                            <Glyph name={action.glyph} />
                                        )}
                                        {action.label}
                                    </DropDownMenuItem>
                                );
                            })}
                        </DropDownMenu>
                    )}
                </div>
                <div className='enhanced-dataview-toolbar__spacer' />
                <Button
                    className='enhanced-dataview-toolbar__item enhanced-dataview-toolbar__close'
                    onClick={this.props.clearSelections}
                    isFlat
                    id='enhanced-dataview-toolbar__close'
                    glyphBefore='close'
                />
            </div>
        );
    }
}
