/**
 * This file contains utilities for managing and converting DateTime + TimeZone to and from Moment.
 *
 * There are not too many specific helper methods here, just the main conversion ones.
 * The reason for this is that we should always supply a DateTime and TimeZone and create a Moment
 * from that. Moment then contains a TON of features for comparisons, sorting, etc that have been
 * time tested. Therefore, it makes more sense to rely on that library instead of coming up with our own stuff.
 *
 * Additionally, the goal was to really start thinking about TimeZone with conjunction to DateTime.
 * DateTime by itself does not mean anything, but it is easy to be consumed by the UI. However,
 * for DateTime data to be correct, we need to always thinking about it together with TimeZone that
 * it belongs to.
 */

// region moment exports.

import moment from 'moment-timezone';

// Re-export moment-timezone so that others can use it as necessary. The idea here is that this would be
// the only source of moment to be used.
export { moment };

// endregion

// region Types.
export interface IDate {
    year: number;
    month: number;
    day: number;
}

export interface ITime {
    hour: number;
    minute: number;
    second?: number;
    millisecond?: number;
}

export interface ITimeZone {
    /**
     * Linux/Olson name.
     */
    name: string;
    shortName?: string;
}

export interface IDateTime extends IDate, ITime {}
// endregion

// region Constants.

export const DEFAULT_MOMENT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_MOMENT_DATE_PLUS_TIME_FORMAT = `${DEFAULT_MOMENT_DATE_FORMAT} hh:mm A`;

// endregion

// region Helpers.

const UtcTimezone = 'Etc/UTC';

/**
 * Should not be exported!
 *
 * Returns an object that has the preferred date format.
 */
function getUserPreferredDataFormatStrings() {
    return {
        DATE_ONLY: DEFAULT_MOMENT_DATE_FORMAT,
        DATE_AND_TIME: DEFAULT_MOMENT_DATE_PLUS_TIME_FORMAT,
    };
}

/**
 * Returns current moment time in Utc.
 */
export const getNowMomentUtc = (): moment.Moment => {
    return getNowMomentInTimeZone(getUtcTimeZone());
};

/**
 * returns current moment time in specified timeZone.
 */
export const getNowMomentInTimeZone = (timeZone: ITimeZone): moment.Moment => {
    return moment.tz(timeZone.name);
};

/**
 * Validates that the components of passed in DateTime object are correct.
 */
export const isValidDateTime = (dateTime: IDateTime): boolean => {
    if (!dateTime) {
        return false;
    }

    const isDefined = (value) => value !== undefined && value !== null;

    const isValidNumber = (value) => {
        // new Number(null) actually results in number 0, so doing this check separately.
        if (!isDefined(value)) {
            return false;
        }

        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line no-new-wrappers
        return !Number.isNaN(new Number(value).valueOf());
    };

    // Make sure that all of the main components are numbers.
    if (
        !['year', 'month', 'day', 'hour', 'minute'].every((key) =>
            isValidNumber(dateTime[key])
        )
    ) {
        return false;
    }

    // If seconds is not defined, but milliseconds is, that is bad dateTime.
    if (!isDefined(dateTime.second)) {
        if (isDefined(dateTime.millisecond)) {
            return false;
        }
    } else {
        // Second is defined, need to check if it is a good number.
        if (!isValidNumber(dateTime.second)) {
            return false;
        }

        // Millisecond could be either undefined or it could be a good number.
        if (
            isDefined(dateTime.millisecond) &&
            !isValidNumber(dateTime.millisecond)
        ) {
            return false;
        }
    }

    // Final check is to make sure that the date is actually valid.
    return fromDateTimeUtcToMomentUtc(dateTime).isValid();
};

// endregion

// region Start of day.

/**
 * Returns a moment object that is set to the beginning of the day of the passed in moment object.
 */
export const getMomentStartOfDay = (value: moment.Moment): moment.Moment => {
    if (!value) {
        return null;
    }

    return moment(value).startOf('day');
};

/**
 * Returns a DateTime object that is set to the beginning of the day of the passed in DateTime object.
 *
 * @note This is basically the same as doing a toDate conversion.
 */
export const getDateTimeStartOfDay = (value: IDateTime): IDateTime => {
    if (!value) {
        return null;
    }

    const output = { ...value };

    output.hour = 0;
    output.minute = 0;
    output.second = 0;
    output.millisecond = 0;

    return output;
};

/**
 * Returns a moment object representing the beginning of day for utc today.
 */
export const getTodayMomentUtc = (): moment.Moment => {
    return getMomentStartOfDay(getNowMomentUtc());
};

/**
 * Returns a DateTime object representing the beginning of day for utc today.
 */
export const getTodayDateTimeUtc = (): IDateTime => {
    return getDateTimeStartOfDay(fromMomentUtcToDateTimeUtc(getNowMomentUtc()));
};

// endregion

// region Timezone.

/**
 * Returns Utc ITimeZone object.
 */
export const getUtcTimeZone = (): ITimeZone => {
    return getTimeZoneFromName(UtcTimezone);
};

/**
 * Get the user's local time zone
 */
export const getUserTimeZone = (): ITimeZone => {
    const tz = moment.tz.guess();
    return {
        name: tz,
        shortName: null,
    };
};

/**
 * Creates ITimeZone object from a timeZone name.
 *
 * @warning This method does not check for correctness of the timeZone name.
 */
export const getTimeZoneFromName = (timeZoneName: string): ITimeZone => {
    return {
        name: timeZoneName,
    } as ITimeZone;
};

// endregion

// region Formatting.

/**
 * Formats the date & time based on the user's preferences.
 * Should be used, by default, when displaying data to the user.
 */
export const formatDateTimeForUser = (
    dateTime: IDateTime | ITime | IDate,
    fromTimeZone: ITimeZone,
    displayTimeZone: ITimeZone
): string => {
    const moment = fromDateTimeAndTimeZoneToMomentInTimezone(
        dateTime,
        fromTimeZone,
        displayTimeZone
    );
    return moment.format(getUserPreferredDataFormatStrings().DATE_AND_TIME);
};

/**
 * Formats datetime to the desired format.
 */
export const formatDateTime = (
    dateTime: IDateTime | ITime | IDate,
    format: string
): string => {
    // Convert to moment and then format it.
    const value = fromDateTimeUtcToMomentUtc(dateTime);

    if (!value) {
        return null;
    }

    if (!format) {
        throw new Error('Format string must be defined.');
    }

    return value.format(format);
};

/**
 * Formats a DateTime range according to the passed in moment format.
 *
 * @warning The format is required!
 * @note This is an equivalent of the old function in DateTimeService, just simplified with more restrictions.
 */
export const formatDateTimeRange = (
    range: { startDate?: IDateTime | IDate; endDate?: IDateTime | IDate },
    format: string
): string => {
    const noRange = '--';

    if (!range) {
        return noRange;
    }

    const { startDate, endDate } = range;

    if (!startDate || !endDate) {
        return noRange;
    }

    if (!format) {
        throw new Error('No date time format was provided.');
    }

    // Formats flight date display to be, for example, "01/01/2019 - 03/31/2019"
    return `${formatDateTime(range.startDate, format)} - ${formatDateTime(
        range.endDate,
        format
    )}`;
};

// endregion

// region from DateTime to Moment.

/**
 * Creates moment object in a specific timezone from DateTime object in a specific timezone.
 */
export const fromDateTimeAndTimeZoneToMomentInTimezone = (
    dateTime: IDateTime | ITime | IDate,
    fromTimeZone: ITimeZone,
    toTimeZone: ITimeZone
): moment.Moment => {
    if (!fromTimeZone) {
        throw new Error('fromTimeZone must be defined.');
    }

    if (!toTimeZone) {
        throw new Error('toTimeZone must be defined.');
    }

    if (!dateTime) {
        return null;
    }

    const tempMoment = getMomentDataObjectFromMoment(
        getNowMomentInTimeZone(fromTimeZone),
        true
    );

    // Create new object and add defaults for date.
    // We need to access properties on dateTime value because it can possibly be a model
    // in which case we don't get the properties automatically and Object.assign does not work.
    let {
        year = tempMoment.year,
        month = tempMoment.month,
        day = tempMoment.day,
        hour = 0,
        minute = 0,
        second = 0,
        millisecond = 0,
    } = dateTime as IDateTime;

    // Need to adjust month because moment uses 0-based month.
    month--;

    second = second || 0;
    millisecond = millisecond || 0;

    const momentSourceTimeZone = moment.tz(
        { year, month, day, hour, minute, second, millisecond },
        fromTimeZone.name
    );

    // Set the time properties on the created date moment object.
    return momentSourceTimeZone.tz(toTimeZone.name);
};

/**
 * Creates moment object from DateTime assuming Utc timezone for origin and target.
 */
export const fromDateTimeUtcToMomentUtc = (
    dateTime: IDateTime | ITime | IDate
): moment.Moment => {
    return fromDateTimeAndTimeZoneToMomentInTimezone(
        dateTime,
        getUtcTimeZone(),
        getUtcTimeZone()
    );
};

// endregion

// region from Moment to DateTime.

/**
 * Helper method for creating new moment objects from existing one based on its data.
 *
 * @warning Do not export this function! It is intended as a helper function in this file. If we export it,
 *          devs will most likely end up using it for moment-DateTime conversions, which is not desired.
 *          Instead, devs should always be using the timezone-aware version, `fromMomentToDateTimeInTimeZone` or
 *          if they are dealing with both times in UTC, `fromMomentUtcToDateTimeUtc` can be used.
 */
const getMomentDataObjectFromMoment = (
    momentObject: moment.Moment,
    shouldAdjustMonthForZeroIndex = false
): IDateTime => {
    return {
        year: momentObject.year(),
        month: momentObject.month() + (shouldAdjustMonthForZeroIndex ? 1 : 0),
        day: momentObject.date(),
        hour: momentObject.hour(),
        minute: momentObject.minute(),
        second: momentObject.second(),
        millisecond: momentObject.millisecond(),
    };
};

/**
 * Converts a moment object with timeZone in it to a DateTime object in the specified timezone.
 *
 * @note Use this method if you correctly created moment object with a timezone.
 */
export const fromMomentToDateTimeInTimeZone = (
    momentObject: moment.Moment,
    toTimeZone: ITimeZone
): IDateTime => {
    if (!toTimeZone) {
        throw new Error('toTimeZone must be defined.');
    }

    if (!momentObject) {
        return null;
    }

    if (!momentObject.tz()) {
        throw new Error('moment object must have a timezone.');
    }

    // Convert to the target timezone.
    // Note: Need to wrap the original moment in a new one so that it does not alter the
    // original object.
    const targetMoment = moment(momentObject).tz(toTimeZone.name);

    // Create DateTime from moment.
    return getMomentDataObjectFromMoment(targetMoment, true) as IDateTime;
};

/**
 * Converts a moment object and specified timeZone to a DateTime object in the specified timeZone.
 *
 * @note This method only works when a moment does not have a timezone attached to it.
 */
export const fromMomentAndTimeZoneToDateTimeInTimeZone = (
    momentObject: moment.Moment,
    fromTimeZone: ITimeZone,
    toTimeZone: ITimeZone
): IDateTime => {
    // Need to have from timeZone.
    if (!fromTimeZone) {
        throw new Error('fromTimeZone must be defined.');
    }

    if (!momentObject) {
        return null;
    }

    // Moment object cannot have timeZone defined, otherwise we should be using the
    // other method because this might return some strange results.
    if (momentObject.tz()) {
        throw new Error(
            "moment object cannot have a timezone when using this method. Please use 'fromMomentToDateTimeInTimeZone' instead."
        );
    }

    // Create a new moment object with the same data and from timeZone.
    const fromMoment = moment.tz(
        getMomentDataObjectFromMoment(momentObject),
        fromTimeZone.name
    );

    // Use the new moment object and run it through the method that accepts moment with timezone.
    return fromMomentToDateTimeInTimeZone(fromMoment, toTimeZone);
};

/**
 * Converts a moment object in utc timeZone to DateTime object in utc timeZone.
 *
 * @note Moment object must either have no timeZone, or Utc timeZone assigned to it.
 */
export const fromMomentUtcToDateTimeUtc = (
    momentObject: moment.Moment
): IDateTime => {
    if (!momentObject) {
        return null;
    }

    if (!['UTC', 'Etc/GMT', 'Etc/UTC', undefined].includes(momentObject.tz())) {
        throw new Error(
            'moment object must have either no timeZone or utc timeZone defined.'
        );
    }

    // Create new utc moment to handle all cases and use the method that expects moment with a timezone.
    return fromMomentToDateTimeInTimeZone(
        moment.tz(
            getMomentDataObjectFromMoment(momentObject),
            getUtcTimeZone().name
        ),
        getUtcTimeZone()
    );
};

// endregion

// region Conversions.

/**
 * Converts an object to ITime object.
 *
 * @note Ensures that hour and second are properly set.
 */
export const toTime = (value: object): ITime => {
    if (!value) {
        return null;
    }

    const { hour, minute, second, millisecond } = value as ITime;

    const isValid = (inputValue, upperBoundInclusive) => {
        return (
            Number.isInteger(inputValue) &&
            inputValue >= 0 &&
            inputValue <= upperBoundInclusive
        );
    };

    const time = {
        hour: isValid(hour, 23) ? hour : 0,
        minute: isValid(minute, 59) ? minute : 0,
        second: isValid(second, 59) ? second : undefined,
    } as ITime;

    // Only parse millisecond if we had seconds.
    if (time.second !== undefined) {
        time.millisecond = isValid(millisecond, 999) ? millisecond : undefined;
    }

    // Remove second and millisecond if they are not defined.
    if (time.second === undefined) {
        delete time.second;
    }

    if (time.millisecond === undefined) {
        delete time.millisecond;
    }

    return time;
};

/**
 * Converts an object to IDate object.
 *
 * @note If the date is invalid, it returns null.
 */
export const toDate = (value: object): IDate => {
    if (!value) {
        return null;
    }

    const { year, month, day } = value as IDate;

    if (!year || !month || !day) {
        return null;
    }

    const date = {
        year,
        month,
        day,
    } as IDate;

    // Create a moment object so that we can check to see if it is valid.
    const momentObject = fromDateTimeUtcToMomentUtc(date);

    if (!momentObject || !momentObject.isValid()) {
        return null;
    }

    return date;
};

// endregion

// region Comparisons.

export const isNewerDate = (
    currentDate: object,
    dateToCheck: object
): boolean => {
    if (!currentDate) {
        return false;
    }

    if (!dateToCheck) {
        return false;
    }

    const currentDateObj = moment(currentDate);
    const comparisonDateObj = moment(dateToCheck);

    if (!currentDateObj.isValid()) {
        return false;
    }

    if (!comparisonDateObj.isValid()) {
        return false;
    }

    return comparisonDateObj.isAfter(currentDateObj);
};

// endregion
