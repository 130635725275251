import { connect } from 'react-redux';
import { setDialogStatusDetails, hideDialog } from '../DialogActions';
import ImmediateActionDialogComponent, {
    ImmediateActionDialogProps,
    ImmediateActionDialogStateProps,
    ImmediateActionDialogDispatchProps,
} from './ImmediateActionDialogComponent';
import { getDialogState } from '../../Dialog/DialogReducer';

const mapActionCreators = {
    setDialogStatusDetails,
    hideDialog,
};

const mapStateToProps = (state, ownProps: ImmediateActionDialogProps) => {
    const dialogInstance = getDialogState(state).instances[ownProps.dialogId];
    const dialogStatus =
        dialogInstance && dialogInstance.statusDetails.dialogStatus;
    return {
        dialogStatus,
    };
};

export default connect<
    ImmediateActionDialogStateProps,
    typeof mapActionCreators,
    ImmediateActionDialogProps
>(
    mapStateToProps,
    mapActionCreators
)(
    ImmediateActionDialogComponent as React.ComponentClass<
        ImmediateActionDialogProps &
            ImmediateActionDialogStateProps &
            ImmediateActionDialogDispatchProps
    >
);
