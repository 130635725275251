import setInPath from 'lodash/set'; // set(object, path, value)
import getInPath from 'lodash/get'; // get(object, path, [defaultValue])
import unsetInPath from 'lodash/unset'; // unset(object, path)
import { Localization } from './LocalizationService';
import { WrappedFieldMetaProps, FieldArrayMetaProps } from 'redux-form';

const emptyArray: readonly any[] = Object.freeze([]);

export interface IValidationService {
    error(property: string, message: string): void;
}

const ValidationKeys = [
    '$immediate_errors',
    '$on_submit_errors',
    '$warnings',
] as const;

/** Possible keys for validation warnings and errors */
export type ValidationIssuesKey = typeof ValidationKeys[number];

function isValidationIssuesKey(str: string): str is ValidationIssuesKey {
    return ValidationKeys.includes(str as ValidationIssuesKey);
}

export type ValidationError = {
    [k in ValidationIssuesKey]?: string[] | ValidationError;
};

export type ComplexValidationProperty = {
    /** path/to/validation_error */
    name: string;

    validators?: Validator[];
};

export type ValidationProperty = ComplexValidationProperty | string | undefined;

export type ErrorMessage = string | undefined;

export type ValidatorOptions = { ErrorMessage: ErrorMessage };

export type Validator = {
    validator?: {
        validate: (
            service: IValidationService,
            model: ValidatableModel,
            property: ValidationProperty,
            options: ValidatorOptions
        ) => void;
    };
    options?: ValidatorOptions;
};

/**
 * Base type for a validatable UI model
 */
export type ValidatableModel = {
    constructor?: {
        validatableProperties?: ValidationProperty[];
        validatableChildren?: string[];
        validatableChildArrays?: string[];
    } & Function;
    validate?: (service: IValidationService) => void;
    warn?: (service: IValidationService) => void;
    [k: string]: any;
};

const validationPropertyName = (property: ValidationProperty): string => {
    return typeof property === 'string' ? property : property.name;
};

/**
 * ValidationService acts as a bridge between our UI models and the redux-forms validation
 * system. It is triggered by a call to ValidationService.validate and then calls through to
 * the validation functions on the UIModel. Those in turn, call back to report errors, warnings,
 * etc. getVisibleErrors is used to translate the errors into something displayable to the user.
 */
class ValidationService implements IValidationService {
    errors: Record<string, any>;
    errorCount: number;
    warningCount: number;
    _validationErrorPath: string;
    _validationModel?: ValidatableModel;

    constructor() {
        this.errors = {};
        this.errorCount = 0;
        this.warningCount = 0;
        this._validationErrorPath = '';
    }

    /**
     * When implementing input-level validation, you can use this method
     * to return an error object from a string. See FrequencyListInputComponent.js
     * for an example of use.
     *
     * @param template Key to localize in a .strings.json file (see `LocalizationService.getString`)
     * @param values Values to replace, if any (see `LocalizationService.getString`)
     */
    static createInputValidationError(
        template: string,
        values: Record<string, string | number>
    ): Pick<ValidationError, '$immediate_errors'> {
        return {
            // eslint-disable-next-line camelcase
            $immediate_errors: [Localization.getString(template, values)],
        };
    }

    /**
     * When implementing submission validation, you can use this method
     * to return an error object from a string. See
     * for an example of use.
     *
     * @param template Key to localize in a .strings.json file (see `LocalizationService.getString`)
     * @param values Values to replace, if any (see `LocalizationService.getString`)
     */
    static createSubmissionValidationError(
        template: string,
        values: Record<string, string | number>
    ): Pick<ValidationError, '$on_submit_errors'> {
        return {
            // eslint-disable-next-line camelcase
            $on_submit_errors: [Localization.getString(template, values)],
        };
    }

    /**
     * Report a validation error, which will be displayed immediately.
     * @param property The relative path to the property with the validation error,
     *                 if a subproperty.
     * @param message The error message.
     */
    error(property: ValidationProperty, message: ErrorMessage) {
        if (!message) {
            message = validationPropertyName(property);
            property = undefined;
        }

        this._logError('$immediate_errors', property, message);
    }

    /**
     * Report a validation error, which will be displayed on submit.
     * @param property The relative path to the property with the validation error,
     *                            if a subproperty.
     * @param message The error message.
     */
    errorOnSubmit(property: ValidationProperty, message: ErrorMessage) {
        if (!message) {
            message = validationPropertyName(property);
            property = undefined;
        }

        this._logError('$on_submit_errors', property, message);
    }

    /**
     * Report a validation warning, which will be displayed immediately.
     * @param property The relative path to the property with the validation error,
     *                            if a subproperty.
     * @param message The error message.
     */
    warning(property: ValidationProperty, message: ErrorMessage) {
        if (!message) {
            message = validationPropertyName(property);
            property = undefined;
        }

        this._logError('$warnings', property, message);
    }

    /**
     * Get immediate errors at the given path.
     */
    getImmediateErrorsFromPath(path: string): ValidationError[] {
        return (
            getInPath(this.errors, `${path}.${'$immediate_errors'}`) ||
            emptyArray
        );
    }

    /**
     * Get submit errors at the given path.
     */
    getSubmitErrorsFromPath(path: string): ValidationError[] {
        return (
            getInPath(this.errors, `${path}.${'$on_submit_errors'}`) ||
            emptyArray
        );
    }

    /**
     * Get all errors at the given path.
     */
    getErrorsFromPath(path: string): ValidationError[] {
        return this.getImmediateErrorsFromPath(path).concat(
            this.getSubmitErrorsFromPath(path)
        );
    }

    /**
     * Get warnings at the given path.
     */
    getWarningsFromPath(path: string): ValidationError[] {
        return getInPath(this.errors, `${path}.${'$warnings'}`) || emptyArray;
    }

    /**
     * The results of validation, in the form expected by redux-forms.
     */
    get results() {
        if (this.errorCount > 0) {
            return this.errors;
        } else {
            return undefined;
        }
    }

    /**
     * True if there were errors in validation.
     */
    get hasErrors() {
        return this.errorCount > 0;
    }

    /**
     * True if there were warnings in validation.
     */
    get hasWarnings() {
        return this.warningCount > 0;
    }

    /**
     * Clears an error (or set of errors) at the current point being validated
     * @param property If specified, it's a relative path to the property with the error.
     *                 If not specified, the error is at the current root being validated.
     */
    clearErrors(property: ValidationProperty) {
        let errorPath = this._validationErrorPath;
        let modelProperty = this._validationModel;
        if (property) {
            errorPath = ValidationService._appendToPath(errorPath, property);
            modelProperty = getInPath(
                modelProperty,
                validationPropertyName(property)
            );
        }

        let errorValue = getInPath(this.errors, errorPath);
        // Sometimes errors on arrays go under ._error (due to redux-forms constraints) but not always.
        // If we don't have an errorValue, and we have an array, try to get it with the _error suffix
        if (!errorValue && Array.isArray(modelProperty)) {
            errorValue = getInPath(this.errors, `${errorPath}._error`);
        }

        if (errorValue !== undefined) {
            let numErrors = 0;
            let numWarnings = 0;

            // Since we're removing all errors for a property, we also want to remove its child properties,
            // but since we need to update the counts as well, we need to recursively navigate down to get
            // the individual counts).
            const findCounts = (
                errorObj: ValidationError[] | ValidationError
            ) => {
                if (Array.isArray(errorObj)) {
                    errorObj.forEach((errorItem) => findCounts(errorItem));
                } else {
                    // Iterate through the properties of the errorObj and, if the property is one of the
                    // ValidationService error/warning keys, then we update the appropriate count with the
                    // length of that error/warning array.  If it doesn't contain one of the error/warning
                    // keys, then recurse down further.
                    Object.keys(errorObj).forEach((errorObjKey) => {
                        const errors = errorObj[errorObjKey];
                        if (
                            isValidationIssuesKey(errorObjKey) &&
                            Array.isArray(errors)
                        ) {
                            const numIssues = errors.length;
                            if (errorObjKey === '$warnings') {
                                numWarnings += numIssues;
                            } else {
                                numErrors += numIssues;
                            }
                        } else {
                            findCounts(errorObj[errorObjKey]);
                        }
                    });
                }
            };
            findCounts(errorValue);

            this.errorCount -=
                numErrors > this.errorCount ? this.errorCount : numErrors;
            this.warningCount -=
                numWarnings > this.warningCount
                    ? this.warningCount
                    : numWarnings;

            // Remove the path from the errors object
            unsetInPath(this.errors, errorPath);

            // By clearing out the existing errors for this path, it's possible the parent properties now just have
            // empty objects in this.errors.  This also unsets any empty parents from this path.
            this._clearEmptyParents(errorPath);
        }
    }

    static isWrappedFieldMeta(
        meta: WrappedFieldMetaProps | FieldArrayMetaProps
    ): meta is WrappedFieldMetaProps {
        return (meta as WrappedFieldMetaProps).touched !== undefined;
    }

    /**
     * Get the visible errors given the field meta data
     * @param meta The field metadata
     * @return {Array} Array of strings of errors to display
     */
    static getVisibleErrors(
        meta: Omit<WrappedFieldMetaProps | FieldArrayMetaProps, 'error'> & {
            error?: { [k in ValidationIssuesKey]?: string[] };
        }
    ): readonly string[] {
        const { error, submitFailed, submitting } = meta;
        let touched = false;

        if (!error) return emptyArray;

        if (this.isWrappedFieldMeta(meta)) {
            touched = meta.touched;
        }

        const submitted = submitFailed || submitting;

        if (!touched && !submitted) {
            return emptyArray;
        } else if (touched && !submitted) {
            return error.$immediate_errors || emptyArray;
        } else {
            const immediateErrors = error.$immediate_errors;
            const submitErrors = error.$on_submit_errors;

            if (immediateErrors && submitErrors) {
                return immediateErrors.concat(submitErrors);
            } else if (immediateErrors) {
                return immediateErrors;
            } else if (submitErrors) {
                return submitErrors;
            } else {
                return emptyArray;
            }
        }
    }

    /**
     * Validate a model
     * @param {Object} model The model to validate
     * @callback custom Callback for additional validation
     */
    static validate(
        model: ValidatableModel,
        custom?: (service: ValidationService, model: ValidatableModel) => void
    ): ValidationService {
        const service = new ValidationService();

        service._validationModel = model;
        service._validationErrorPath = '';
        service._validateHelper();

        if (custom) {
            service._validationModel = model;
            service._validationErrorPath = '';
            custom(service, model);
        }

        return service;
    }

    /**
     * Handle warning validation per redux-forms. This doesn't go through the
     * full recursive validation with UI models.
     * @param {Object} model The model to validate
     * @callback custom Callback for additional validation
     */
    static warn(
        model: ValidatableModel,
        custom?: (service: ValidationService, model: ValidatableModel) => void
    ): ValidationService {
        const service = new ValidationService();

        if (model === undefined || model === null) {
            return service;
        }

        service._validationModel = model;

        if (model.warn) {
            model.warn(service);
        }

        if (custom) {
            custom(service, model);
        }

        return service;
    }

    /**
     * Logs an error at the current point being validated
     * @param type The type of error
     * @param property If specified, it's a relative path to the property with the error.
     *                          If not specified, the error is at the current root being validated.
     * @param message The error message.
     */
    _logError(
        type: ValidationIssuesKey,
        property: ValidationProperty,
        message: ErrorMessage
    ) {
        // Track the total number of errors.
        if (type === '$warnings') {
            this.warningCount++;
        } else {
            this.errorCount++;
        }

        let errorPath = this._validationErrorPath;
        let modelProperty = this._validationModel;
        if (property) {
            errorPath = ValidationService._appendToPath(errorPath, property);
            modelProperty = getInPath(
                modelProperty,
                validationPropertyName(property)
            );
        }

        // redux-forms requires that errors on arrays go under ._error
        if (Array.isArray(modelProperty)) {
            errorPath += '._error';
        }

        let errorValue = getInPath(this.errors, errorPath);
        if (errorValue === undefined) {
            errorValue = {};
            setInPath(this.errors, errorPath, errorValue);
        }

        // Log the error.
        let errors = errorValue[type];
        if (!errors) {
            errors = [];
            errorValue[type] = errors;
        }
        errors.push(message);
    }

    /**
     * _validationHelper recursively validates the model. We keep track of where we are in the model and generated
     * errors with _validationModel and _validationErrorPath. This approach lets us be efficient about only generating
     * objects as needed.
     */
    _validateHelper() {
        const model = this._validationModel;
        const errorPath = this._validationErrorPath;
        if (model === undefined || model === null) {
            return;
        }

        // Validate the model
        model.constructor &&
            model.constructor.validatableProperties &&
            model.constructor.validatableProperties
                .filter(
                    (property): property is ComplexValidationProperty =>
                        property && typeof property !== 'string'
                )
                .forEach(({ validators, name }) => {
                    this.__validateProperty(validators, model, name);
                });

        // For each validatable property child property
        if (model.constructor.validatableChildren) {
            for (const child of model.constructor.validatableChildren) {
                this._validationErrorPath = ValidationService._appendToPath(
                    errorPath,
                    child
                );
                this._validationModel = model[child];
                this._validateHelper();
            }
        }

        // For each collection of objects w/ validatable properties
        if (model.constructor.validatableChildArrays) {
            for (const childArrayProperty of model.constructor
                .validatableChildArrays) {
                const childArrayLength = model[childArrayProperty].length;
                for (let index = 0; index < childArrayLength; ++index) {}
                let index = 0;
                for (const _ of model[childArrayProperty + 'Iterator']()) {
                    this._validationModel = model[childArrayProperty][index];
                    this._validationErrorPath = ValidationService._appendToPath(
                        errorPath,
                        `${childArrayProperty}[${index}]`
                    );
                    this._validateHelper();
                    index++;
                }
            }
        }
        // This is happening last because we're looking at the validity of the contents of a child to determine the validity of a parent property.
        if (model.validate) {
            // reset the error path so that we're validating the correct "scope".
            this._validationErrorPath = errorPath;
            this._validationModel = model;
            model.validate(this);
        }
    }

    __validateProperty(
        validators: Validator[],
        model: ValidatableModel,
        property: string
    ) {
        for (const key in validators) {
            const attribute = validators[key];
            attribute.validator &&
                attribute.validator.validate(
                    this,
                    model,
                    property,
                    attribute && attribute.options
                );
            if (this.errors[property]) {
                return;
            }
        }
    }

    /**
     * Removes any empty objects from the errors object from the path up to the top level parent
     * @param validationErrorPath If specified, it's the starting point to start traversing up the hierarchy
     */
    _clearEmptyParents(validationErrorPath: string) {
        let parentPath = validationErrorPath;
        const immediateParentIndex = (parentPath || '').lastIndexOf('.');
        if (immediateParentIndex > -1) {
            parentPath = parentPath.slice(0, immediateParentIndex);
            const parentErrors = getInPath(this.errors, parentPath);
            if (parentErrors && Object.keys(parentErrors).length === 0) {
                unsetInPath(this.errors, parentPath);
                this._clearEmptyParents(parentPath);
            }
        }
    }

    /**
     * Append to a path to a property, adding the '.' as needed.
     * @param  currentPath - The base path to append to
     * @param  childProperty - The path to be appended, it could be a string or a property object.
     * @return The appended paths.
     */
    static _appendToPath(
        currentPath: string,
        childProperty: ValidationProperty
    ): string {
        let result = currentPath;
        if (result.length > 0) {
            result += '.';
        }

        result += validationPropertyName(childProperty);

        return result;
    }

    /**
     * It is useful to target the wrapper class of an input when it should have
     * inline validation.
     * For example in tables where a glyph is rendered next to the input when
     * input errors are detected.
     * This helper function standardizes the class added for all inputs.
     * @param  baseClass          The basic wrapper class of the input.
     * @param  isValidationInline Whether the input has inline validation.
     * @return                    The appropriate wrapper class for the input.
     */
    static getWrapperClass(
        baseClass: string,
        isValidationInline: boolean
    ): string {
        let wrapperClass = baseClass;
        if (isValidationInline) {
            // Helper class used to position the inline validation glyph.
            wrapperClass += ' input-inline-validation';
        }
        return wrapperClass;
    }
}

export default ValidationService;
