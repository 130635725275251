import { createBrowserHistory, BrowserHistory } from 'history';
const browserHistory = createBrowserHistory({ window });

// This is an easy way to type browser history in your source file so you can access it without casting locally.
export function getBrowserHistory<
    T extends Record<string, string | string[]>
>() {
    return browserHistory as BrowserHistory<T>;
}

export { browserHistory };
