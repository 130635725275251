import React, { Component, CSSProperties, ChangeEvent } from 'react';
import TtdInputField, { TtdField } from 'components/inputs/TtdInputField';
import getInputId from 'util/getInputId';
import ValidationService from 'util/ValidationService';
import { Reflection } from 'util/Reflection';
import {
    moment,
    formatDateTime,
    DEFAULT_MOMENT_DATE_PLUS_TIME_FORMAT,
    DEFAULT_MOMENT_DATE_FORMAT,
    IDateTime,
} from 'util/DateTimeUtils';
import themeClassName from 'util/Themes';
import './DateTimeText.scss';
import { WrappedFieldProps } from 'redux-form';

interface DateTimeTextInputProps {
    style?: CSSProperties;
    className?: string;
    theme?: 'date-range-picker' | '';
    includesTime?: boolean;
    isReadOnly?: boolean;
    isEnabled?: boolean;
    isInline?: boolean;
    onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
    field?: TtdField;
    placeholder?: string;
    onChange?(value: ChangeEvent<IDateTime>): void;
}

class DateTimeTextInputField extends Component<
    DateTimeTextInputProps &
        WrappedFieldProps & {
            onInputFocus: DateTimeTextInputProps['onFocus'];
        }
> {
    render() {
        const {
            meta,
            input,
            includesTime,
            isReadOnly,
            onInputFocus,
            className,
            theme,
            style,
            placeholder,
            isInline,
            isEnabled,
        } = this.props;
        const errors = ValidationService.getVisibleErrors(meta);

        // Build classes to apply.
        const classes = [
            'date-time-text-input',
            className,
            themeClassName(theme),
        ];

        if (errors.length) {
            classes.push('date-time-text-input--error');
        }

        // Render plain, readonly version of value.
        if (isReadOnly) {
            classes.push('date-time-text-input--readonly');
            return (
                <div className={classes.join(' ')}>
                    <div className='date-time-readonly-input'>
                        {input.value}
                    </div>
                </div>
            );
        }

        // Since we're rendering the full control, add a few more classes.
        classes.push(
            includesTime
                ? 'date-time-text-input--with-time'
                : 'date-time-text-input--without-time'
        );

        if (isInline) {
            classes.push('date-time-text-input--inline');
        } else {
            classes.push('date-time-text-input--standard');
        }

        return (
            <div className={classes.join(' ')}>
                <input
                    id={getInputId(DateTimeTextInput, this.props)}
                    className=''
                    style={style}
                    type='text'
                    disabled={!isEnabled}
                    onChange={!isEnabled ? undefined : input.onChange}
                    placeholder={placeholder}
                    autoComplete='off'
                    tabIndex={!isEnabled ? -1 : 0}
                    value={input.value}
                    onFocus={!isEnabled ? undefined : onInputFocus}
                />
            </div>
        );
    }
}

export default class DateTimeTextInput extends Component<
    DateTimeTextInputProps
> {
    static defaultProps: Partial<DateTimeTextInputProps> = {
        className: '',
        theme: '',
        isInline: false,
        isReadOnly: false,
        isEnabled: true,
    };

    private currentValue: IDateTime;

    parse = (textValue) => {
        if (!textValue && this.currentValue) {
            return null;
        }
        if (!textValue) {
            return this.currentValue;
        }
        const isValid = (this.props.includesTime
            ? /[\d]{1,2}\/[\d]{1,2}\/[\d]{2,4} [\d]{1,2}:[\d]{2} a|p[m]/i
            : /[\d]{1,2}\/[\d]{1,2}\/[\d]{2,4}/
        ).test(textValue);
        const momentTime =
            isValid &&
            moment(
                textValue,
                this.props.includesTime
                    ? ['MM/DD/YYYY hh:mm A', 'M/D/YY h:mm A', 'M/D/YYYY h:mm A']
                    : ['MM/DD/YYYY', 'M/D/YY', 'M/D/YYYY']
            );
        const f =
            isValid && momentTime.isValid()
                ? {
                      year: momentTime.year(),
                      month: momentTime.month() + 1,
                      day: momentTime.date(),
                      hour: momentTime.hour(),
                      minute: momentTime.minute(),
                      textValue,
                  }
                : {
                      textValue,
                  };
        console.log(f);
        return f;
    };

    format = (reduxValue) => {
        this.currentValue = reduxValue;
        if (Reflection.isEmptyValue(reduxValue)) {
            return '';
        }

        return (
            reduxValue.textValue ||
            formatDateTime(
                reduxValue,
                this.props.includesTime
                    ? DEFAULT_MOMENT_DATE_PLUS_TIME_FORMAT
                    : DEFAULT_MOMENT_DATE_FORMAT
            )
        );
    };

    render() {
        const {
            field,
            includesTime,
            isReadOnly,
            onFocus,
            className,
            theme,
            style,
            placeholder,
            isInline,
            isEnabled,
        } = this.props;
        return (
            <TtdInputField
                style={style}
                placeholder={placeholder}
                className={className}
                theme={theme}
                field={field}
                parse={this.parse}
                format={this.format}
                component={DateTimeTextInputField}
                includesTime={includesTime}
                isReadOnly={isReadOnly}
                isEnabled={isEnabled}
                isInline={isInline}
                onInputFocus={onFocus}
            />
        );
    }
}
