﻿import { ReactNode } from 'react';
import Immutable, { ImmutableObject } from 'seamless-immutable';
import uuid from 'uuid/v4';
import { getStateByKey } from 'util/getStateByKey';
import { ToastieProps } from '.';

// ------------------------------------
// Constants
// ------------------------------------
const TOAST_ERROR_POPUP = 'TOAST_ERROR_POPUP';
const TOAST_SUCCESS_POPUP = 'TOAST_SUCCESS_POPUP';
const TOAST_INFORMATIONAL_POPUP = 'TOAST_INFORMATIONAL_POPUP';
const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE';
const TOASTIE_STATE_KEY = 'maui.display.toasties';

export const getToastiesState = (state): ImmutableToastiesState =>
    getStateByKey(state, TOASTIE_STATE_KEY);

// ------------------------------------
// Action Creators
// ------------------------------------

/**
 * Displays a popup "toast" notification that there is an error.
 *
 * @param error {ReactNode} the error to display
 * @param id {string} optional id for this notification
 */
export function toastError(error: ReactNode, id?: string) {
    if (typeof error === 'string' || Array.isArray(error)) {
        return {
            type: TOAST_ERROR_POPUP,
            payload: {
                error: {
                    message: error,
                },
                id: id || uuid(),
            },
        };
    }

    return {
        type: TOAST_ERROR_POPUP,
        payload: {
            error,
            id: id || uuid(),
        },
    };
}

/**
 * Displays a popup "toast" success notification.
 *
 * @param message {ReactNode} the message to display
 * @param id {string} optional id for this notification
 * @param preventAutoClose {boolean} Indicates if the toastie should not be auto-closed
 * @param shouldHideClose {boolean} Indicates if the close button should not be displayed
 */
export function toastSuccess(
    message: ReactNode = 'Success!',
    id?: string,
    preventAutoClose?: boolean,
    shouldHideClose?: boolean
) {
    return {
        type: TOAST_SUCCESS_POPUP,
        payload: {
            message,
            id: id || uuid(),
            preventAutoClose,
            shouldHideClose,
        },
    };
}

/**
 * Displays a popup "toast" informational notification.
 *
 * @param message {ReactNode} the message to display
 * @param id {string} optional id for this notification
 * @param preventAutoClose {boolean} Indicates if the toastie should not be auto-closed
 * @param shouldHideClose {boolean} Indicates if the close button should not be displayed
 */
export function toastInformational(
    message: ReactNode,
    id?: string,
    preventAutoClose?: boolean,
    shouldHideClose?: boolean
) {
    return {
        type: TOAST_INFORMATIONAL_POPUP,
        payload: {
            message,
            id: id || uuid(),
            preventAutoClose,
            shouldHideClose,
        },
    };
}

/**
 * Dismisses a toast notification
 * @param {string} id of the notification
 */
export function clearToast(id: string) {
    return {
        type: CLEAR_TOAST_MESSAGE,
        payload: id,
    };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [TOAST_ERROR_POPUP]: (state, action) => {
        const toastie = Immutable({
            type: 'error',
            message: action.payload.error.message,
            id: action.payload.id,
        });
        return state.set('toasties', pushToastie(state.toasties, toastie));
    },
    [TOAST_SUCCESS_POPUP]: (state, action) => {
        const { preventAutoClose, ...rest } = action.payload;
        const toastie = Immutable({
            ...(preventAutoClose ? {} : { closeTimeMs: 3000 }),
            type: 'success',
            ...rest,
        });
        return state.set('toasties', pushToastie(state.toasties, toastie));
    },
    [TOAST_INFORMATIONAL_POPUP]: (state, action) => {
        const { preventAutoClose, ...rest } = action.payload;
        const toastie = Immutable({
            ...(preventAutoClose ? {} : { closeTimeMs: 6000 }),
            type: 'informational',
            ...rest,
        });
        return state.set('toasties', pushToastie(state.toasties, toastie));
    },
    [CLEAR_TOAST_MESSAGE]: (state, action) => {
        return state.set(
            'toasties',
            removeToastie(state.toasties, action.payload)
        );
    },
};

const pushToastie = (toasties, toastie) => {
    if (toasties.find((t) => t.id === toastie.id)) {
        return toasties;
    }

    return [...toasties, toastie];
};

const removeToastie = (
    toasties: ImmutableToastiesState['toasties'],
    id: string
) => toasties.filter((t) => t.id !== id);

// ------------------------------------
// Reducer
// ------------------------------------
type ToastieInstanceState = {
    id: string;
    preventAutoClose?: boolean;
} & Omit<ToastieProps, 'dismiss'>;

type ToastiesState = {
    toasties: ToastieInstanceState[];
};
type ImmutableToastiesState = ImmutableObject<ToastiesState>;

const initialState: ImmutableToastiesState = Immutable({ toasties: [] });

export default function notificationReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
