import { initialize } from 'redux-form';
import { ResolveThunks, useDispatch } from 'react-redux';
import { IStepProgressBar } from 'components/display/SteppedProgressBar';

export enum DIALOG_ACTION_TYPES {
    SHOW_DIALOG = '@@dialog/SHOW_DIALOG',
    TRANSITION_DIALOG = '@@dialog/TRANSITION_DIALOG',
    HIDE_DIALOG = '@@dialog/HIDE_DIALOG',
    REGISTER_DIALOG = '@@dialog/REGISTER_DIALOG',
    UNREGISTER_DIALOG = '@@dialog/UNREGISTER_DIALOG',

    // TaskDialog and SteppedTaskDialog actions
    SET_TASK_DIALOG_DETAILS = '@@dialog/SET_TASK_DIALOG_DETAILS',

    // SteppedTaskDialog actions
    COMPLETE_STEP = '@@dialog/COMPLETE_STEP',
    SET_CURRENT_STEP = '@@dialog/SET_CURRENT_STEP',
}

export enum DIALOG_STATUS {
    NONE = 'NONE',
    INITIALIZING = 'INITIALIZING',
    UNSUBMITTED = 'UNSUBMITTED',
    SUBMITTING = 'SUBMITTING',
    SUBMIT_SUCCESS = 'SUBMIT_SUCCESS',
    SUBMIT_FAILURE = 'SUBMIT_FAILURE',
    REVIEW = 'REVIEW',
    ERROR = 'ERROR',
}

export type SuccessData = {
    nextStatus?: DIALOG_STATUS;
    currentStatus?: DIALOG_STATUS;
    successResponse?: any;
    nextStep?: number;
};

export type ErrorData = {
    message: string;
    statusCode?: number;
    messageDetail?: string | JSX.Element;
    type?: string;
};

export type DialogConfig = {
    successCallback: (successData?: SuccessData) => void;
    errorCallback: (errorData: ErrorData) => void;
    dialogId: string;
    progressCallback: (progressRate: number, isComplete: boolean) => void;
    successParams: SuccessData;
    errorParams: {};
};

export const showDialog = (dialogId: string) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_ACTION_TYPES.SHOW_DIALOG,
            dialogId,
        });
        setTimeout(() => {
            dispatch({
                type: DIALOG_ACTION_TYPES.TRANSITION_DIALOG,
                dialogId,
                isTransitioning: false,
            });
        });
    };
};

export const useShowDialog = (dialogId: string) => {
    const dispatch = useDispatch();
    return () => dispatch(showDialog(dialogId));
};

export const hideDialog = (dialogId: string, syncClose?: boolean) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_ACTION_TYPES.TRANSITION_DIALOG,
            dialogId,
            isTransitioning: true,
        });

        if (syncClose) {
            dispatch({
                type: DIALOG_ACTION_TYPES.HIDE_DIALOG,
                dialogId,
            });
        } else {
            setTimeout(() => {
                dispatch({
                    type: DIALOG_ACTION_TYPES.HIDE_DIALOG,
                    dialogId,
                });
            }, 400);
        }
    };
};

export const useHideDialog = (dialogId: string, syncClose?: boolean) => {
    const dispatch = useDispatch();
    return () => dispatch(hideDialog(dialogId, syncClose));
};

export const registerDialog = (dialogId: string) => {
    return {
        type: DIALOG_ACTION_TYPES.REGISTER_DIALOG,
        dialogId,
    };
};

export const unregisterDialog = (dialogId: string) => {
    return {
        type: DIALOG_ACTION_TYPES.UNREGISTER_DIALOG,
        dialogId,
    };
};

/**
 *
 * @param {string} dialogId unique id of the dialog, used to map to the correct redux-form instance
 * @param {object} initialValues if provided initial values will (re)initialize the redux-form state.
 * @param {object} statusDetails this is the rest of the metadata needed by our Task Dialogs in order to show the correct dialog state.
 */
export interface IStatusDetails {
    dialogStatus: DIALOG_STATUS;
    statusCode?: string | number;
    statusText?: string;
    showTimerWhileSubmitting?: boolean;
    messageGeneral?: string;
    messageDetail?: string | JSX.Element;
    pollingProgress?: number;
    stepProgresses?: IStepProgressBar;
    suppressBackButtonOnError?: boolean;
    suppressRetryButtonOnError?: boolean;
}

export interface IDialogStatusDetails extends IStatusDetails {
    dialogId: string;
    initialValues?: any;
    isComplete?: boolean;
    onFormInitialized?(initialModel: any): void;
}

export const setDialogStatusDetails = ({
    dialogId,
    initialValues,
    onFormInitialized,
    ...statusDetails
}: IDialogStatusDetails) => {
    return (dispatch) => {
        if (initialValues) {
            dispatch(initialize(`${dialogId}-form`, initialValues));
            if (onFormInitialized) {
                onFormInitialized(initialValues);
            }
        }

        dispatch({
            type: DIALOG_ACTION_TYPES.SET_TASK_DIALOG_DETAILS,
            payload: {
                dialogId,
                statusDetails,
            },
        });
    };
};

export const completeStep = (dialogId, stepIndex) => {
    return {
        type: DIALOG_ACTION_TYPES.COMPLETE_STEP,
        dialogId,
        stepIndex,
    };
};

export const setCurrentStep = (dialogId, stepIndex) => {
    return {
        type: DIALOG_ACTION_TYPES.SET_CURRENT_STEP,
        dialogId,
        stepIndex,
    };
};

const actions = {
    showDialog,
    hideDialog,
    registerDialog,
    unregisterDialog,
    setDialogStatusDetails,
    completeStep,
    setCurrentStep,
};
export type DialogActions = ResolveThunks<typeof actions>;
export type showDialogSignature = DialogActions['showDialog'];
export type hideDialogSignature = DialogActions['hideDialog'];
