import React, { PureComponent, ReactNode } from 'react';
import ToolTip, { DisplayMode } from 'components/display/ToolTip';
import DotsLoader from 'components/display/Loaders/Dots/DotsComponent';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import {
    dynamicColors,
    grayScaleColor1,
    PrimaryColorOptions,
} from 'util/Colors';
import { Localization } from 'util/LocalizationService';
import NumberFormattingService from 'util/NumberFormattingService';
import themeClassName from 'util/Themes';
import Strings from './SteppedProgressBar.strings.json';
import './SteppedProgressBar.scss';

export interface IStep {
    totalItems?: number;
    totalItemsProcessed?: number;
    percentComplete?: number;
    itemsProcessedLabel?: string;
    /**
     * Optional name of the step
     */
    stepName?: string;
    stepSummary?: ReactNode;
    stepKey: string;
    isComplete?: boolean;
    /**
     * @summary If provided this will get rendered as a templated string , injecting the IStep as data
     * @example "{{totalItemsProcessed}}/{{totalItems}} complete" will be transpiled to "1/2 complete"
     */
    stepDetailsTemplate?: string;
    // When there is no stepDetailsTemplate, show the % details instead
    showPercentDetails?: boolean;
    /**
     * Indicates if this step is vertical
     */
    isVertical?: boolean;
    /**
     * Indicates if the step details should always be displayed irrespective of the step being the "active" step
     */
    alwaysDisplayStepDetails?: boolean;
    /**
     * Step color for Active steps
     */
    activeStepColor?: PrimaryColorOptions;
    /**
     * Step color for Completed steps
     */
    completedStepColor?: PrimaryColorOptions;
    /**
     * Any additional details that need to be appended after
     */
    additionalSummaryDetails?: ReactNode[];
    /**
     * Indicates if the step is also the final step
     */
    isCompleteStep?: boolean;
    /**
     * Indicates if the step is enabled
     */
    isEnabled?: boolean;
    /**
     * If true, the loading indicator on the active step will animate; otherwise,
     * it will appear static. Defaults to true
     */
    animateActiveStep?: boolean;
}

export interface IStepProgressBar {
    steps: IStep[];
    currentStepIndex: number;
}

function getPercentComplete(step: IStep) {
    return Math.min(
        step.isComplete
            ? 100
            : 100 *
                  (step.percentComplete !== undefined
                      ? step.percentComplete
                      : step.totalItems
                      ? (step.totalItemsProcessed || 0) / step.totalItems
                      : 0),
        100
    );
}

interface SteppedProgressBarProps
    extends IStepProgressBar,
        Pick<IStep, 'activeStepColor' | 'completedStepColor' | 'isEnabled'> {
    className?: string;
    style?: React.CSSProperties;
    /**
     * Indicates if the layout of the progress bar is vertical
     */
    isVertical?: boolean;
    theme?: string;
}

export class SteppedProgressBarStep extends PureComponent<
    IStep & {
        isActiveStep: boolean;
        index?: number;
        nextStepPercentComplete?: number;
        isNextStepActive?: boolean;
    }
> {
    static defaultProps = {
        isEnabled: true,
        animateActiveStep: true,
    };

    render() {
        const step = this.props;
        const completedStepColor =
            dynamicColors[`primaryColor${step.completedStepColor}`];
        const activeStepColor =
            dynamicColors[`primaryColor${step.activeStepColor}`];

        const classList = ['stepped-progress-bar-step'];
        let glyph;
        if (!step.isEnabled) {
            glyph = (
                <Glyph
                    name='menu'
                    className='stepped-progress-bar-step__glyph-disabled'
                />
            );
            classList.push('stepped-progress-bar-step--disabled');
        } else if (step.isComplete) {
            glyph = (
                <Glyph
                    name='status-success'
                    className='stepped-progress-bar-step__glyph-complete'
                />
            );
            classList.push('stepped-progress-bar-step--complete');
            classList.push(
                `stepped-progress-bar-step--completed-${step.completedStepColor}`
            );
        } else {
            classList.push('stepped-progress-bar-step--pending');

            if (step.isActiveStep) {
                glyph = step.animateActiveStep ? (
                    <DotsLoader
                        className='stepped-progress-bar-step--loading-dots'
                        color={
                            step.isActiveStep
                                ? grayScaleColor1
                                : activeStepColor
                        }
                    />
                ) : (
                    <Glyph
                        className='stepped-progress-bar-step--static-loading-glyph'
                        name='menu-horizontal'
                    />
                );
            }
        }

        if (step.isCompleteStep) {
            classList.push('stepped-progress-bar-step--complete-step');
        }

        if (step.isActiveStep) {
            classList.push('stepped-progress-bar-step--active');
            classList.push(
                `stepped-progress-bar-step--active-${step.activeStepColor}`
            );
        }

        if (step.isNextStepActive) {
            classList.push('stepped-progress-bar-step--with-gradient');
        }

        let direction = 'right';
        if (step.isVertical) {
            direction = 'bottom';
            classList.push('stepped-progress-bar-step--vertical');
        }

        const percentComplete = getPercentComplete(step);
        const componentStyle = {
            flex: percentComplete,
            backgroundImage:
                step.isNextStepActive && step.isEnabled
                    ? `linear-gradient(to ${direction}, ${completedStepColor} 0%, ${activeStepColor} 60%)`
                    : undefined,
        };
        const componentSpacerStyle = {
            flex: 100 - percentComplete,
        };

        const { index } = step;
        const stepNameDisplay = (
            <div className='stepped-progress-bar-step__index'>
                {step.stepName
                    ? step.stepName
                    : Localization.getString(
                          step.isCompleteStep
                              ? Strings.complete
                              : Strings.stepName,
                          { index }
                      )}
            </div>
        );

        const { totalItems, totalItemsProcessed } = step;
        const stepDetailDisplay = (step.stepSummary ||
            step.stepDetailsTemplate ||
            step.showPercentDetails) && (
            <>
                <div className='stepped-progress-bar-step__summary'>
                    {step.stepSummary || (step.showPercentDetails && '')}
                </div>
                <div className='stepped-progress-bar-step__summary-details'>
                    {step.stepDetailsTemplate
                        ? Localization.getString(step.stepDetailsTemplate, {
                              totalItemsProcessed,
                              totalItems,
                          })
                        : step.showPercentDetails
                        ? Localization.getString(Strings.percentTemplate, {
                              percent: NumberFormattingService.formatPercentage(
                                  percentComplete,
                                  1,
                                  true
                              ),
                          })
                        : ''}
                </div>
            </>
        );

        const displaySummaryAsToolTip =
            !step.isActiveStep &&
            stepDetailDisplay &&
            !step.alwaysDisplayStepDetails &&
            !step.isEnabled;
        return (
            <div className={classList.join(' ')}>
                <div className='stepped-progress-bar-step__circle'>
                    {glyph}
                    {displaySummaryAsToolTip && (
                        <ToolTip
                            displayMode={DisplayMode.default}
                            wrapperClassName='stepped-progress-bar-step__summary-wrapper'
                            content={stepDetailDisplay}
                        >
                            <div className='stepped-progress-bar-step__summary__details'>
                                {stepNameDisplay}
                            </div>
                        </ToolTip>
                    )}
                    {!displaySummaryAsToolTip && (
                        <div className='stepped-progress-bar-step__summary-wrapper'>
                            <div className='stepped-progress-bar-step__summary__details'>
                                {stepNameDisplay}
                                {(step.isActiveStep ||
                                    step.alwaysDisplayStepDetails) &&
                                    stepDetailDisplay}
                            </div>
                            {step.additionalSummaryDetails}
                        </div>
                    )}
                </div>
                {!step.isCompleteStep && (
                    <div className='stepped-progress-bar-step__progress-wrapper'>
                        <div
                            className='stepped-progress-bar-step__progress'
                            style={componentStyle}
                        />
                        <div
                            className='stepped-progress-bar-step__progress__spacer'
                            style={componentSpacerStyle}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default class SteppedProgressBarComponent extends PureComponent<
    SteppedProgressBarProps
> {
    public static defaultProps = {
        className: '',
        steps: [],
        isVertical: false,
        activeStepColor: 1,
        completedStepColor: 6,
        isEnabled: true,
    };

    render() {
        const {
            style,
            className,
            steps,
            currentStepIndex,
            isVertical,
            activeStepColor,
            completedStepColor,
            isEnabled,
            theme,
        } = this.props;

        if (!steps.length) {
            return null;
        }

        const currentStep = steps[currentStepIndex];
        const completeGlyphClass =
            currentStep &&
            currentStep.isComplete &&
            currentStepIndex + 1 === steps.length
                ? 'stepped-progress-bar__completion-wrapper--complete'
                : '';

        const classNames = ['stepped-progress-bar', className];
        if (isVertical) {
            classNames.push('stepped-progress-bar--vertical');
        }
        if (!isEnabled) {
            classNames.push('stepped-progress-bar--disabled');
        }
        if (theme) {
            classNames.push(themeClassName(theme));
        }

        // Check if the last element is the final step
        const hasFinalStep = !!steps[steps.length - 1].isCompleteStep;

        return (
            <div style={style} className={classNames.join(' ')}>
                {steps.map((step, index, steps) => {
                    const isCompleteStep =
                        step.isCompleteStep && index + 1 === steps.length;
                    const isActive = currentStep === step;
                    const isComplete = !!(
                        (step.isComplete && index <= currentStepIndex) ||
                        index < currentStepIndex
                    );
                    const nextStep =
                        index + 1 < steps.length && steps[index + 1];

                    const stepEl = (
                        <SteppedProgressBarStep
                            key={step.stepKey}
                            isVertical={isVertical}
                            isCompleteStep={isCompleteStep}
                            index={index + 1}
                            {...step}
                            isActiveStep={isActive}
                            isComplete={isComplete}
                            nextStepPercentComplete={
                                index === currentStepIndex
                                    ? getPercentComplete(currentStep)
                                    : undefined
                            }
                            // If the current step is complete, and the next step is active and not complete
                            isNextStepActive={
                                isComplete &&
                                nextStep &&
                                currentStep === nextStep &&
                                !nextStep.isComplete
                            }
                            activeStepColor={activeStepColor}
                            completedStepColor={completedStepColor}
                            isEnabled={isEnabled}
                        />
                    );

                    return isCompleteStep ? (
                        stepEl
                    ) : (
                        <div
                            className={`stepped-progress-bar__step-wrapper ${
                                isVertical
                                    ? 'stepped-progress-bar__step-wrapper--vertical'
                                    : ''
                            }`}
                            key={step.stepKey}
                        >
                            {stepEl}
                        </div>
                    );
                })}
                {!hasFinalStep && (
                    <SteppedProgressBarStep
                        isVertical={isVertical}
                        isCompleteStep
                        isComplete={!!completeGlyphClass}
                        isActiveStep={false}
                        stepKey='complete'
                        activeStepColor={activeStepColor}
                        completedStepColor={completedStepColor}
                        isEnabled={isEnabled}
                    />
                )}
            </div>
        );
    }
}
