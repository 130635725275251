import React, { Component } from 'react';
import './IndicatorComponent.scss';

const baseClassName = 'indicator-component--';

export enum TYPES {
    danger = 'red',
    success = 'green',
    warning = 'yellow',
    neutral = 'neutral',
    positiveBidAdjustment = 'green',
    negativeBidAdjustment = 'red',
    targetList = 'green',
    blockList = 'red',
    optimized = 'primary1',
    general = 'primary3tint1',
}

export interface IndicatorProps {
    type: TYPES;
    style?: React.CSSProperties;
    className?: string;
    content?: string | number;
    sizeInPixels?: number;
    isDisabled?: boolean;
}

export default class IndicatorComponent extends Component<IndicatorProps, {}> {
    static defaultProps = {
        className: '',
        sizeInPixels: 8,
        isDisabled: false,
    };

    static assertProps(props) {
        if (props.content) {
            const value = props.content.toString();
            if (value.length >= 2) {
                throw new Error(
                    `Content in IndicatorComponent is longer than 1 character.`
                );
            }
        }
    }

    render() {
        IndicatorComponent.assertProps(this.props);
        const {
            type,
            className,
            style,
            content = '',
            sizeInPixels,
            isDisabled,
        } = this.props;

        const size = sizeInPixels + 'px';
        const contentLeft = content.toString().length === 2 ? '.05em' : '.48em';
        return (
            <span
                className={`indicator-component ${baseClassName}${type} ${
                    isDisabled ? `${baseClassName}disabled` : ''
                } ${className}`}
                style={{ ...style, fontSize: size, height: size, width: size }}
            >
                <span
                    className='indicator-component__content'
                    children={content}
                    style={{ left: contentLeft }}
                />
            </span>
        );
    }
}
