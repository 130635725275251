import React, { Component } from 'react';
import withReadOnly from 'components/hocs/withReadOnly';
import themeClassName from 'util/Themes';
import './FixedContentControl.scss';

export type FixedContentControlProps<TComponentProps> = {
    icon?: React.ReactNode;
    // use before and after to display two icons
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
    content?: React.ReactNode;
    component: React.ComponentType<TComponentProps>;
    style?: React.CSSProperties;
    className?: string;
    theme?: string;
    fixedContentClassName?: string;
    isContentAfterInput?: boolean;
    hasValidationErrors?: boolean;
    isInline?: boolean;
    isEnabled?: boolean;
    isReadOnly?: boolean;
} & TComponentProps;

/**
 * Used to display inputs with a "fixed" icon or content ($, %, etc.). Usually used for currency inputs, search inputs, etc.
 * Note: The icon takes precedence over the content provided.
 * see: http://localhost:8080/view/test/input/input-add-ons
 *
 * @export
 * @class FixedContentControl
 * @extends {Component}
 */
class FixedContentControl<TComponentProps> extends Component<
    FixedContentControlProps<TComponentProps>
> {
    static defaultProps: Partial<FixedContentControlProps<any>> = {
        className: '',
        theme: '',
        fixedContentClassName: '',
    };

    /**
     * Order: content < icon < (iconBefore, iconAfter)
     */
    getIconsAndContent = () => {
        const {
            iconBefore,
            iconAfter,
            isContentAfterInput,
            icon,
            content,
        } = this.props;
        let contentAfter: boolean = isContentAfterInput;
        let iBefore: React.ReactNode = iconBefore;
        let iAfter: React.ReactNode = iconAfter;
        if (iconBefore || iconAfter) {
            contentAfter = !iconBefore && !!iconAfter;
        } else if (icon || content) {
            if (contentAfter) {
                iBefore = undefined;
                iAfter = icon;
            } else {
                iBefore = icon;
                iAfter = undefined;
            }
        }
        return {
            iconBefore: iBefore,
            iconAfter: iAfter,
            isContentAfterInput: contentAfter,
            content,
        };
    };

    render() {
        const {
            content,
            component,
            className,
            theme,
            fixedContentClassName,
            isContentAfterInput: isca,
            icon,
            iconBefore: ib,
            iconAfter: ia,
            isInline,
            isEnabled,
            isReadOnly,
            hasValidationErrors,
            ...rest
        } = this.props;
        const InputComponent = component;

        const {
            iconBefore,
            iconAfter,
            isContentAfterInput,
        } = this.getIconsAndContent();

        const rightAlign =
            isContentAfterInput && isInline ? 'input--right-align' : '';
        const inputClass = `fixed-content-control__control ${rightAlign}`;

        const classes = ['fixed-content-control', className];
        if (isContentAfterInput && content) {
            // So that content can still render as after if needed
            classes.push('fixed-content-control--after');
        }
        if (isInline) {
            classes.push('fixed-content-control--inline');
        }
        if (theme) {
            classes.push(themeClassName(theme));
        }
        // Avoid coercing to false because value can be undefined.
        const disabledAttribute = {} as any;
        if (isEnabled === false) {
            disabledAttribute.disabled = true;
            classes.push('fixed-content-control--disabled');
        }
        if (isReadOnly) {
            classes.push('fixed-content-control--readonly');
        }

        if (hasValidationErrors) {
            classes.push('fixed-content-control--error');
        }

        if (iconBefore || iconAfter) {
            classes.push('fixed-content-control--icon');
        }

        return (
            <div className={classes.join(' ')} {...disabledAttribute}>
                <div
                    className={`fixed-content-control__fixed-content ${fixedContentClassName}`}
                >
                    {iconBefore || content}
                </div>
                <InputComponent
                    {...(rest as any)}
                    isInline={isInline}
                    className={inputClass}
                    isEnabled={isEnabled}
                    isReadOnly={isReadOnly}
                />
                <div
                    className={`fixed-content-control__fixed-content ${fixedContentClassName}`}
                >
                    {iconAfter}
                </div>
            </div>
        );
    }
}

export default withReadOnly(FixedContentControl);
