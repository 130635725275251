import React, { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { querySelector } from 'util/documentHelper';
import { browserHistory } from '../../../util/browserHistory';
import keycode from 'keycode';
// '<Glyph>' is deprecated in favor of direct SVG imports
import Glyph from '../Glyph';
import { clickSource, keyupSource } from '../../../util/EventSubscriptions';
import PositionService from '../../../util/PositionService';
import themeClassName from '../../../util/Themes';
// eslint-disable-next-line import/no-webpack-loader-syntax
import dropDownVariables from '!!sass-variable-loader!./DropDownMenu.scss';

import './DropDownMenu.scss';

// Pull in CSS heights for various page elements.
// eslint-disable-next-line import/no-webpack-loader-syntax
import {
    siteToolbarHeight,
    selectListItemHeight,
    baseUnit,
} from '../../../styles/variables/sizes.scss';

const MENU_ITEM_HEIGHT = parseInt(selectListItemHeight);
const SITE_TOOLBAR_HEIGHT = parseInt(siteToolbarHeight);
const SMALL_GUTTER = parseInt(baseUnit);
const USING_MOUSE_FOR_COMPONENT = -1;
const MAX_LIST_SIZE = 7;

export interface DropDownMenuProps extends React.Props<DropDownMenuComponent> {
    id: string;
    label: ReactNode;
    glyphName?: string;
    suppressGlyph?: boolean;
    className?: string;
    alignment?: 'left' | 'right' | 'none';
    isEnabled?: boolean;
    tabIndex?: number;
    hasDarkBackground?: boolean;
    onToggle?: (open: boolean) => void;
    onClick?: (event: any) => void;
    style?: React.CSSProperties;
    hasTabIndex?: boolean;
    isOpen?: boolean;
    embedInPortal?: boolean;
    onFocus?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLAnchorElement>) => void;
    customDropDownComponent?: JSX.Element;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'site-toolbar' is a theme.
    theme?: string;

    zIndex?: number;
    captureAllKeyPress?(event: KeyboardEvent): void;
    offsetTop?: number;
    menuClassName?: string;
}

export interface DropDownMenuState {
    open: boolean;
    focus: number;
    menuItems?: any[];
}

export const DROP_DOWN_PORTAL_ID = 'portal-drop-down-div';
export const DROP_DOWN_ID = 'drop-down-menu';

class DropDownMenuComponent extends Component<
    DropDownMenuProps,
    DropDownMenuState
> {
    dropDownPortalRoot: HTMLElement;
    portalContainer: HTMLElement;
    element: any;
    menu: any;

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.addPortaling = this.addPortaling.bind(this);

        this.state = {
            open: this.props.isOpen,
            focus: USING_MOUSE_FOR_COMPONENT,
        };

        this.portalContainer = document.createElement('div');
    }

    static defaultProps = {
        className: '',
        alignment: 'right',
        isEnabled: true,
        hasTabIndex: true,
        embedInPortal: false,
        theme: '',
        offsetTop: 0,
        menuClassName: '',
    };

    componentDidMount() {
        if (this.props.embedInPortal) {
            this.addPortaling(this.props);
        }

        if (this.props.isEnabled) {
            this.setState({
                menuItems: React.Children.toArray(this.props.children).filter(
                    (c) => c !== null
                ),
            });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (!this.props.isEnabled) {
            return;
        }
        if (
            !this.state.open &&
            this.state.focus !== USING_MOUSE_FOR_COMPONENT
        ) {
            this.setMouseFocus();
        }

        if (this.props.embedInPortal) {
            this.updatePortalPosition();
        }

        if (this.state.open !== previousState.open) {
            if (this.props.onToggle) {
                this.props.onToggle(this.state.open);
            }

            if (this.props.embedInPortal) {
                this.portalContainer.className = this.generateClassName(
                    this.props,
                    this.state.open,
                    true
                );
            }
        }

        if (this.state.open) {
            this.updateMenuPosition(
                this.state.menuItems &&
                    previousState.menuItems &&
                    this.state.menuItems.length !==
                        previousState.menuItems.length
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen !== this.props.isOpen) {
            this.setState({ open: nextProps.isOpen });
        }

        // Removed check for !isEqual(this.props.children, nextProps.children), as it causes intermittent
        // failures in React v16 (i.e. sometimes gets hung while trying to do the full compare of fields
        // within the component), and it appears to fail the isEqual check fairly consistently anyway.
        // TODO: Need to find some other workaround for this (especially with setting state with components)
        this.setState({
            menuItems: React.Children.toArray(nextProps.children).filter(
                (c) => c !== null
            ),
        });

        if (this.props.embedInPortal !== nextProps.embedInPortal) {
            nextProps.embedInPortal
                ? this.addPortaling(nextProps)
                : this.removePortaling();
        } else if (this.props.embedInPortal) {
            this.portalContainer.className = this.generateClassName(
                nextProps,
                this.state.open,
                true
            );

            if (nextProps.style) {
                this.portalContainer.removeAttribute('style');
                Object.keys(nextProps.style).forEach((key) => {
                    this.portalContainer.style[key] = nextProps.style[key];
                }, this);
            }
        }
    }

    componentWillUnmount() {
        this.clickAwaySubscription.unsubscribe();
        this.documentKeyUpSubscription.unsubscribe();
        this.stopListeningToNavigationChange();
        if (this.props.embedInPortal) {
            this.removePortaling();
        }
    }

    addPortaling(props) {
        if (!props) {
            props = this.props;
        }

        this.dropDownPortalRoot = querySelector(`#${DROP_DOWN_PORTAL_ID}`);

        const className = this.generateClassName(props, this.state.open, true);
        this.portalContainer.className = className;
        if (props.style) {
            Object.keys(props.style).forEach((key) => {
                this.portalContainer.style[key] = props.style[key];
            }, this);
        }

        this.updatePortalPosition();

        document.addEventListener('scroll', this.handleScroll, true);
        this.dropDownPortalRoot.appendChild(this.portalContainer);
    }

    removePortaling() {
        document.removeEventListener('scroll', this.handleScroll, true);
        this.dropDownPortalRoot.removeChild(this.portalContainer);
    }

    handleScroll(e) {
        if (this.state.open) {
            const target = e.target;
            // Ignore if the target is the child of the label (search field for example)
            if (!target?.closest?.('.drop-down-menu__label-text')) {
                const parentClass =
                    target.parentNode && target.parentNode.className;
                //Unless you're scrolling within the menu itself, close the menu when scrolling
                if (
                    !parentClass ||
                    parentClass.indexOf('drop-down-menu__menu') < 0
                ) {
                    this.toggle(false);
                }
            }
        }
    }

    clickAwaySubscription = clickSource
        .filter(() => {
            return this.state.open;
        }, this)
        .delay(0) // give child components the chance to prevent closing of the menu.
        .subscribe((e: any) => {
            // Elements within this dropdown (whether or not we're portaling) should be able to
            // preventDefault() and prevent the menu from closing. Clicks outside this menu that
            // call preventDefault() shouldn't result in this menu not closing (e.g. clicks on
            // <Link>).

            let containsClick = false;
            if (
                this.element.contains(e.target) ||
                (this.props.embedInPortal &&
                    this.dropDownPortalRoot.contains(e.target))
            ) {
                containsClick = true;
            }
            if (e.defaultPrevented && containsClick) {
                return;
            }

            this.toggle(false);
        });

    stopListeningToNavigationChange = browserHistory.listen((location) => {
        if (this.state.open) {
            this.toggle(false);
        }
    });

    documentKeyUpSubscription = keyupSource
        .filter(
            (e: any) =>
                this.state.open &&
                e.target !== this.element &&
                !this.element.contains(e.target)
        )
        .delay(0) // give other components the chance to prevent closing of the menu.
        .subscribe((e: any) => {
            if (e.defaultPrevented) {
                return;
            }
            this.toggle(false);
        });

    onMouseEnter() {
        if (this.state.focus !== USING_MOUSE_FOR_COMPONENT) {
            this.setState({ focus: USING_MOUSE_FOR_COMPONENT });
        }
    }

    setMouseFocus() {
        this.setState({ focus: USING_MOUSE_FOR_COMPONENT });
    }

    setFocus(down) {
        if (!this.menu) {
            return;
        }

        const menuItemsIndexCount =
            React.Children.count(this.state.menuItems) - 1;

        let focusIndex = this.state.focus;
        let tryCalculateNextIndex = true;
        while (tryCalculateNextIndex) {
            if (down) {
                focusIndex =
                    menuItemsIndexCount === focusIndex
                        ? menuItemsIndexCount
                        : focusIndex + 1;
            } else {
                focusIndex = focusIndex < 0 ? -1 : focusIndex - 1;
            }

            tryCalculateNextIndex = false; // we found an index
            if (focusIndex >= 0) {
                const focusedChild = this.state.menuItems[focusIndex];
                if (
                    focusedChild &&
                    (focusedChild.props.isDisabled ||
                        focusedChild.props.disableFocus)
                ) {
                    // we want to skip over explicitly disabled menu-item, so do a retry if we can
                    // we should only retry if we still have more index to go in the direction we want to go
                    tryCalculateNextIndex =
                        (!down && focusIndex > 0) ||
                        (down && focusIndex < menuItemsIndexCount);

                    // if we're not going to retry, set focus to -1
                    if (!tryCalculateNextIndex) {
                        focusIndex = down ? this.state.focus : -1;
                    }
                }
            }
        }

        const menu = this.menu.querySelector('ul');
        const focusedItem = menu.children[focusIndex];
        if (
            focusedItem &&
            ((down &&
                menu.scrollTop <
                    Math.max(focusIndex + 1 - MAX_LIST_SIZE, 0) *
                        MENU_ITEM_HEIGHT) ||
                menu.scrollTop > focusIndex * MENU_ITEM_HEIGHT)
        ) {
            menu.scrollTop =
                focusedItem.offsetTop -
                (down
                    ? MENU_ITEM_HEIGHT * MAX_LIST_SIZE - MENU_ITEM_HEIGHT
                    : MENU_ITEM_HEIGHT);
        }

        this.setState({ focus: focusIndex });
    }

    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }

        if (!e.defaultPrevented && this.props.isEnabled) {
            // If this click landed outside a given menu item but within the menu's element,
            // it was a click in the menu's whitespace. We don't want to close the menu when
            // someone clicks in the whitespace (MEGA-1439).
            if (e.target === this.menu) {
                e.preventDefault();
            } else {
                this.toggle(!this.state.open);
            }
        }
    }

    onMouseDown(e) {
        // If this click landed outside a given menu item but within the menu's element,
        // it was a click in the menu's whitespace. We don't want to change focus in this
        // case (MEGA-1680). Similarly, this prevents changing focus if the click is on
        // an input that's a menu item (e.g. load more button).
        if (
            e.target === this.menu ||
            (this.menu && this.menu.contains(e.target))
        ) {
            e.preventDefault();
        }
    }

    toggle(isOpen) {
        const open =
            this.props.isOpen !== undefined ? this.props.isOpen : isOpen;
        this.setState({ open });

        if (!open) {
            this.element.querySelector('.drop-down-menu__label').focus();
        }
    }

    updatePortalPosition() {
        const elemBoundingRect = (
            this.element || ReactDOM.findDOMNode(this)
        ).getBoundingClientRect();
        const docBoundingRect = document.body.getBoundingClientRect();

        this.portalContainer.style.width = elemBoundingRect.width + 'px';
        this.portalContainer.style.height = '0px';

        const topPos = elemBoundingRect.top - docBoundingRect.top;
        const leftPos = elemBoundingRect.left - docBoundingRect.left;

        PositionService.positionElement(this.portalContainer, leftPos, topPos);
    }

    updateMenuPosition(didMenuItemsChange: boolean = false) {
        if (!this.menu) {
            return;
        }

        // If it's displayed on the top, ignore it since we've performed the calculation unless the items changed, in which case we need to re-calculate the height.
        const hasRenderedOnTop = this.menu.classList.contains(
            'select-list__menu-top'
        );
        if (!didMenuItemsChange && hasRenderedOnTop) {
            return;
        }

        const menuEl = this.menu.querySelector('ul') || this.menu.firstChild;
        const menuBoundingRect = this.menu.getBoundingClientRect();
        const docBoundingRect = document.body.getBoundingClientRect();
        const scroller = 'drop-down-menu__menu--long';

        // Always re-calculate height since we're starting from a small size. The menu could be off-screen, or there's a lot of render space, or no space to render
        // Determine how we're going to change the menu's position.
        const heightAvailableBelow =
            docBoundingRect.height - menuBoundingRect.top - MENU_ITEM_HEIGHT;

        // If there is enough space to render at least 3 items, simply cut off the menu and add a scrollbar. Unless we previously calculated that we should render on top.
        // Previous calculation === same paint cycle. This will always be calculated when the drop down menu is closed and re-opened.
        if (!hasRenderedOnTop && heightAvailableBelow >= MENU_ITEM_HEIGHT * 3) {
            //...cut off the menu or if availableHeight > height needed for menu
            menuEl.style.maxHeight = Math.round(heightAvailableBelow) + 'px';

            //...add a scrollbar
            this.menu.classList.add(scroller);
            this.menu.style.top = `calc(${this.props.offsetTop}px + ${dropDownVariables.top})`;
            // Otherwise, open the menu upwards. We still may need to cut off the menu if it runs
            // into the top of the screen or hangs over the main fixed-position menu.
        } else {
            let menuTop = menuBoundingRect.top;
            if (hasRenderedOnTop) {
                // If it previously rendered on top, menu items must have changed.
                // We assume that the menu is placed under the select input to get the true `topOfPageOffset`.
                menuTop = menuBoundingRect.bottom + MENU_ITEM_HEIGHT;
            }

            // We could have more space available upwards, but the height could be smaller due to the initial size.
            let newTopOfMenu: number;
            const topOfPageOffset =
                menuTop - SITE_TOOLBAR_HEIGHT - SMALL_GUTTER - MENU_ITEM_HEIGHT;
            const menuPadding = 2 * SMALL_GUTTER;
            let resetMaxHeightTo: string | undefined;

            // It's not going to fit. Need to cut it off.
            if (menuBoundingRect.height > topOfPageOffset) {
                newTopOfMenu = -topOfPageOffset;
                // Also deduct SMALL_GUTTER to account for padding in the menu.
                menuEl.style.maxHeight = `${topOfPageOffset - menuPadding}px`; // Since it will always shrink, we can just set it here

                //...add a scrollbar
                this.menu.classList.add(scroller);
            } else {
                // Per style guide, menu should accommodate 10 menu items before scrolling. See `selectList.js`.
                const maxHeightOfMenu = MENU_ITEM_HEIGHT * 10;
                const maxTopOfMenu = maxHeightOfMenu + menuPadding;

                // The entire menu can fit when fully expanded and no restrictions.
                if (menuEl.scrollHeight <= topOfPageOffset) {
                    newTopOfMenu = -(menuEl.scrollHeight + menuPadding);
                    if (menuEl.scrollHeight <= maxHeightOfMenu) {
                        // The menu is small enough to fit entirely. Position it as normal.
                        resetMaxHeightTo = `${menuEl.scrollHeight}px`;
                        // ...remove the scrollbar
                        this.menu.classList.remove(scroller);
                    } else {
                        // Even though the entire menu can fit, we limit it to 10 items;
                        resetMaxHeightTo = `${maxHeightOfMenu}px`;
                        // ...add the scrollbar
                        this.menu.classList.add(scroller);
                    }
                } else {
                    // There's not enough space to render the entire menu. It will definitely scroll.
                    // However, the menu has space to expand since it's small.
                    // It can either expand in size to `maxHeightOfMenu` or to `maxTopOfMenu`
                    newTopOfMenu = -topOfPageOffset;
                    if (topOfPageOffset < maxTopOfMenu) {
                        // We have a list with some scroll, but it cant be rendered to its max-height
                        resetMaxHeightTo = `${topOfPageOffset - menuPadding}px`;
                    } else {
                        // We have a list with some scroll, and it can be rendered to its max-height
                        resetMaxHeightTo = `${maxHeightOfMenu}px`;
                    }
                    // If we increase the maxHeight before re-positioning, an overflow/scroll may occur. Hence we resize later.

                    //...add a scrollbar
                    this.menu.classList.add(scroller);
                }

                if (-newTopOfMenu > maxTopOfMenu) {
                    // Since we scroll after 10 items, there's a chance newTopOfMenu is higher than needed
                    newTopOfMenu = -maxTopOfMenu;
                }
            }

            // We've already figured out horizontal alignment so parameter 2 can be undefined (left offset);
            PositionService.positionElement(this.menu, undefined, newTopOfMenu);
            if (resetMaxHeightTo !== undefined) {
                // Do this later so we don't force a scroll
                menuEl.style.maxHeight = resetMaxHeightTo;
            }
            this.menu.classList.add('select-list__menu-top');
        }

        // Check to see if the menu's right side is cut off.
        if (
            menuBoundingRect.left + menuBoundingRect.width >
            docBoundingRect.width
        ) {
            this.menu.classList.add('drop-down-menu__menu--align-right');
        }
    }

    onKeyDown(event) {
        if (!this.props.isEnabled) {
            return;
        }
        const key = keycode(event);
        switch (key) {
            case 'enter':
                if (this.state.focus !== USING_MOUSE_FOR_COMPONENT) {
                    this.menu
                        .getElementsByClassName(
                            'drop-down-menu-item--active'
                        )[0]
                        .getElementsByClassName(
                            'drop-down-menu-item__label-content'
                        )[0]
                        .click();
                } else {
                    this.toggle(!this.state.open);
                }
                break;
            case 'space':
                if (!this.state.open) {
                    this.toggle(true);
                    event.preventDefault();
                } else if (
                    this.state.focus !== USING_MOUSE_FOR_COMPONENT &&
                    this.menu
                ) {
                    event.preventDefault();
                    this.menu
                        .getElementsByClassName(
                            'drop-down-menu-item--active'
                        )[0]
                        .getElementsByClassName(
                            'drop-down-menu-item__label-content'
                        )[0]
                        .click();
                }
                break;
            case 'esc':
                if (this.state.open) {
                    this.toggle(false);
                }
                break;
            case 'down':
            case 'up':
                if (this.state.open) {
                    this.setFocus(key === 'down');
                    event.preventDefault();
                }
                break;
        }

        if (event.defaultPrevented) {
            return;
        }

        if (this.props.captureAllKeyPress) {
            this.props.captureAllKeyPress(event);
        }
    }

    generateClassName(props, isOpen, generateForPortal?) {
        const classes = [
            'drop-down-menu__main',
            props.className,
            `drop-down-menu__main--align-${props.alignment}`,
        ];

        if (generateForPortal) {
            classes.push('drop-down-menu__main--portal');
        }

        if (isOpen) {
            classes.push('drop-down-menu__main--open');
        }

        if (!props.isEnabled) {
            classes.push('drop-down-menu__main--disabled');
        }

        if (props.theme) {
            classes.push(themeClassName(props.theme));
        }

        return classes.join(' ');
    }

    render() {
        const isOpen = this.state.open;
        const className = this.generateClassName(this.props, isOpen);
        let menuItems;
        if (isOpen) {
            menuItems =
                this.state.focus > -1
                    ? React.Children.map(
                          this.state.menuItems,
                          (child, index) => {
                              return React.cloneElement(child, {
                                  hasFocus: this.state.focus === index,
                              });
                          }
                      )
                    : this.state.menuItems;
        }

        const labelConfig = {
            id: `DropDownMenu_${this.props.id}`,
            className: `drop-down-menu__label ${
                this.props.isEnabled ? '' : 'drop-down-menu__label--disabled'
            }${
                this.props.hasDarkBackground
                    ? 'drop-down-menu__dark-background'
                    : ''
            } drop-down-menu__label--${isOpen ? 'open' : 'closed'}`,
            onKeyDown: this.onKeyDown,
        };

        let labelChildren;
        if (this.props.suppressGlyph) {
            labelChildren = [
                <div className='drop-down-menu__label-text' key='label-text'>
                    {this.props.label}
                </div>,
            ];
        } else if (this.props.customDropDownComponent) {
            labelChildren = [
                this.props.customDropDownComponent,
                <Glyph
                    name={`${isOpen ? 'up' : 'down'}-arrow`}
                    key='label-glyph'
                    className='drop-down-menu-arrow'
                />,
            ];
        } else if (this.props.glyphName) {
            labelChildren = [
                <div className='drop-down-menu__label-text' key='label-text'>
                    {this.props.label}
                </div>,
                <span
                    className='drop-down-menu__label-glyph'
                    key='label-text-span'
                >
                    <Glyph name={this.props.glyphName} key='label-glyph' />
                </span>,
            ];
        } else {
            labelChildren = [
                <div className='drop-down-menu__label-text' key='label-text'>
                    {this.props.label}
                </div>,
                <Glyph
                    name={`${isOpen ? 'up' : 'down'}-arrow`}
                    key='label-glyph'
                    className='drop-down-menu-arrow'
                />,
            ];
        }

        let dropDownMenu;
        if (isOpen && menuItems && menuItems.length) {
            dropDownMenu = (
                <div
                    id={DROP_DOWN_ID}
                    className={`drop-down-menu__menu ${this.props.menuClassName}`}
                    style={{ zIndex: this.props.zIndex }}
                    onMouseEnter={this.onMouseEnter}
                    ref={(node) => {
                        this.menu = node;
                    }}
                >
                    <ul
                        className='drop-down-menu__list'
                        // Start with a small max height, and update it `onOpen`
                        style={{ maxHeight: MENU_ITEM_HEIGHT }}
                    >
                        {menuItems}
                    </ul>
                </div>
            );
        }

        return (
            <div
                className={className}
                onClick={this.onClick}
                onMouseDown={this.onMouseDown}
                style={this.props.style}
                ref={(element) => {
                    this.element = element;
                }}
            >
                {this.props.hasTabIndex ? (
                    <a
                        {...labelConfig}
                        children={labelChildren}
                        onFocus={
                            this.props.isEnabled
                                ? this.props.onFocus
                                : undefined
                        }
                        onBlur={
                            this.props.isEnabled ? this.props.onBlur : undefined
                        }
                        tabIndex={
                            this.props.isEnabled ? this.props.tabIndex || 0 : -1
                        }
                    />
                ) : (
                    <span {...labelConfig} children={labelChildren} />
                )}
                {this.props.embedInPortal && dropDownMenu
                    ? ReactDOM.createPortal(dropDownMenu, this.portalContainer)
                    : dropDownMenu}
            </div>
        );
    }
}

export default DropDownMenuComponent;
