﻿import Immutable from 'seamless-immutable';
import { browserHistory } from '../util/browserHistory';
import {
    hideDialog,
    showDialog,
} from '../components/display/Dialog/DialogActions';
import { submit, initialize } from 'redux-form';
import { getStateByKey } from '../util/getStateByKey';

export const UPDATE_NEXT_ROUTE = 'UPDATE_NEXT_ROUTE';
export const UPDATE_CONFIRM_PENDING = 'UPDATE_CONFIRM_PENDING';
export const UPDATE_FORM_NAME = 'UPDATE_FORM_NAME';
export const UPDATE_SUBMITTING = 'UPDATE_SUBMITTING';
export const RESET_NAVIGATION_FIELDS = 'RESET_NAVIGATION_FIELDS';
export const SET_CONFIRMATION_CONTEXT = 'SET_CONFIRMATION_CONTEXT';
export const UPDATE_FIELD_ERROR_COUNT = 'UPDATE_FIELD_ERROR_COUNT';
export const UPDATE_PARENT_DIALOG_ID = 'UPDATE_PARENT_DIALOG_ID';
export const NAVIGATION_STATE_KEY = 'maui.navigation';

export enum CONTEXT_TYPE {
    DIALOG = 'DIALOG',
    PAGE = 'PAGE',
}

export const getNavigationState = (state) =>
    getStateByKey(state, NAVIGATION_STATE_KEY);

// ------------------------------------
// Actions
// ------------------------------------
export const resetNavigationFields = () => {
    return {
        type: RESET_NAVIGATION_FIELDS,
        payload: null,
    };
};

export const updateNextRoute = (route) => {
    return {
        type: UPDATE_NEXT_ROUTE,
        payload: route,
    };
};

export const updateFormName = (formName) => {
    return {
        type: UPDATE_FORM_NAME,
        payload: formName,
    };
};

export const updateConfirmPending = (status) => {
    return {
        type: UPDATE_CONFIRM_PENDING,
        payload: status,
    };
};

export const updateContextType = (contextType) => {
    return {
        type: SET_CONFIRMATION_CONTEXT,
        payload: contextType,
    };
};

export const updateFieldErrorCount = (count) => {
    return {
        type: UPDATE_FIELD_ERROR_COUNT,
        payload: count,
    };
};

export const updateParentDialogId = (parentDialogId) => {
    return {
        type: UPDATE_PARENT_DIALOG_ID,
        payload: parentDialogId,
    };
};

export const setFieldErrorCount = (count) => {
    return (dispatch, getState) => {
        let errorCount = getNavigationState(getState()).fieldErrorCount;
        errorCount += count;
        dispatch(updateFieldErrorCount(errorCount));
    };
};

export const initializeConfirmation = (route, formName) => {
    return (dispatch, getState) => {
        dispatch(updateNextRoute(route));
        dispatch(updateFormName(formName));
        dispatch(updateConfirmPending(true));
        dispatch(showDialog(getNavigationState(getState()).dialogId));
        dispatch(updateContextType(CONTEXT_TYPE.PAGE));
    };
};

export const initializeDialogConfirmation = (formName, parentDialogId) => {
    return (dispatch, getState) => {
        dispatch(updateNextRoute(null));
        dispatch(updateFormName(formName));
        dispatch(updateConfirmPending(true));
        dispatch(showDialog(getNavigationState(getState()).dialogId));
        dispatch(updateParentDialogId(parentDialogId));
        dispatch(updateContextType(CONTEXT_TYPE.DIALOG));
    };
};

export const confirmUnsavedNavigate = () => {
    return updateConfirmPending(false);
};

export const setSubmissionStatus = (isSubmitting) => {
    return {
        type: UPDATE_SUBMITTING,
        payload: isSubmitting,
    };
};

export const confirmSaveAndNavigate = () => {
    return (dispatch, getState) => {
        const formName = getNavigationState(getState()).formName;
        dispatch(submit(formName));
    };
};

export const processDelayedNavigate = () => {
    return (dispatch, getState) => {
        const route = getNavigationState(getState()).nextRoute;

        if (route !== null) {
            dispatch(resetNavigationFields());
            dispatch(hideDialog(getNavigationState(getState()).dialogId));
            browserHistory.push(route);
            return true;
        }

        return false;
    };
};

// standard success handler for processing forms
export const processFormSubmission = (formName, values, successAction) => {
    return (dispatch, getState) => {
        dispatch(
            initialize(
                formName,
                typeof values === 'function' ? values() : values
            )
        );
        if (successAction) {
            dispatch(successAction());
        }
        dispatch(setSubmissionStatus(false));
        if (getNavigationState(getState()).isConfirmationPending) {
            return dispatch(processDelayedNavigate());
        }

        return false;
    };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [UPDATE_NEXT_ROUTE]: (state, action) => {
        return state.set('nextRoute', action.payload);
    },
    [UPDATE_CONFIRM_PENDING]: (state, action) => {
        return state.set('isConfirmationPending', action.payload);
    },
    [UPDATE_FORM_NAME]: (state, action) => {
        return state.set('formName', action.payload);
    },
    [UPDATE_SUBMITTING]: (state, action) => {
        return state.set('isSubmitting', action.payload);
    },
    [SET_CONFIRMATION_CONTEXT]: (state, action) => {
        return state.set('contextType', action.payload);
    },
    [RESET_NAVIGATION_FIELDS]: (state, action) => {
        // don't want to reset error count
        return initialState.merge({
            fieldErrorCount: state.fieldErrorCount,
        });
    },
    [UPDATE_FIELD_ERROR_COUNT]: (state, action) => {
        return state.set('fieldErrorCount', action.payload);
    },
    [UPDATE_PARENT_DIALOG_ID]: (state, action) => {
        return state.set('parentDialogId', action.payload);
    },
};

// ------------------------------------
// Selectors
// ------------------------------------
export const getNextRoute = (state) => {
    return state.nextRoute;
};

export const getIsConfirmationPending = (state) => {
    return state.isConfirmationPending;
};

export const getFormName = (state) => {
    return state.formName;
};

export const getIsSubmitting = (state) => {
    return state.isSubmitting;
};

export const getContextType = (state) => {
    return state.contextType;
};

export const getFieldErrorCount = (state) => {
    return state.fieldErrorCount;
};

export const getParentDialogId = (state) => {
    return state.parentDialogId;
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = Immutable({
    dialogId: 'unsaved-confirmation',
    nextRoute: null,
    formName: null,
    isConfirmationPending: false,
    isSubmitting: false,
    contextType: null,
    fieldErrorCount: 0,
    parentDialogId: null,
});

export default function navigationReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
