﻿import React from 'react';
import TtdInputField, { TtdInputFieldExtensionProps } from '../TtdInputField';
import { Reflection } from '../../../util/Reflection';
import ValidationService from '../../../util/ValidationService';
import getInputId from '../../../util/getInputId';
import ValidationError from '../../display/ValidationError';
import DecimalControl, {
    DecimalControlProps,
} from '../../controls/DecimalControl/DecimalControl';
import { Parser, Formatter, Normalizer } from 'redux-form';

export interface DecimalInputProps
    extends Omit<
            DecimalControlProps,
            'onDragStart' | 'onBlur' | 'onDrop' | 'onFocus' | 'onChange'
        >,
        TtdInputFieldExtensionProps {
    isValidationInline?: boolean;
    onBeforeChange?(value: number, previousValue: number): boolean;
}

class DecimalInput extends React.Component<DecimalInputProps> {
    static displayName = 'DecimalInput';

    static defaultProps: Partial<DecimalInputProps> = {
        isValidationInline: false,
        decimalLimit: 2,
        allowNegative: true,
    };

    normalize: Normalizer = (value, previousValue) => {
        if (
            this.props.onBeforeChange &&
            this.props.onBeforeChange(value, previousValue) === false
        ) {
            return previousValue;
        } else {
            return value;
        }
    };

    format: Formatter = (value) => {
        return Reflection.toInputFromStorage(value);
    };

    parse: Parser = (value) => {
        return Reflection.toStorageFromInput(value);
    };

    renderInput = (fieldProps) => {
        const {
            input,
            meta,
            field,
            isValidationInline,
            parse,
            format,
            normalize,
            onBeforeChange,
            validate,
            ...rest
        } = fieldProps;
        const errors = ValidationService.getVisibleErrors(meta);
        const errorClass = (errors.length && 'input--error') || '';

        const wrapperClass = ValidationService.getWrapperClass(
            'decimal-input',
            isValidationInline
        );

        return (
            <div className={wrapperClass}>
                <DecimalControl
                    {...input}
                    {...rest}
                    id={getInputId(DecimalInput, fieldProps)}
                    className={errorClass}
                />

                <ValidationError
                    isInline={isValidationInline}
                    meta={meta}
                    inlineTopOffsetPixels={3}
                    inlineLeftOffsetPixels={8}
                />
            </div>
        );
    };

    render() {
        const {
            className,
            style,
            id,
            field,
            isValidationInline,
            parse,
            normalize,
            format,
            isEnabled,
            ...rest
        } = this.props;

        return (
            <div className={className} style={style}>
                <TtdInputField
                    id={id}
                    field={field}
                    isValidationInline={isValidationInline}
                    component={this.renderInput}
                    parse={parse || this.parse}
                    format={format || this.format}
                    normalize={normalize || this.normalize}
                    isEnabled={isEnabled}
                    {...rest}
                />
            </div>
        );
    }
}

export default DecimalInput;
