import React, { Component } from 'react';
import ReadOnlyContext from 'components/contexts/ReadOnlyContext';
import { getComponentDisplayName } from 'util/getComponentDisplayName';
import hoistNonReactStatics from 'hoist-non-react-statics';

export interface ReadOnlyComponentProps {
    isReadOnly?: boolean;
}

export type ReadOnlyComponent<T> = React.ComponentType<T>;

const withReadOnly = <ComponentProps extends ReadOnlyComponentProps>(
    WrappedComponent: React.ComponentType<ComponentProps>
) => {
    class WithReadOnly extends Component<
        ComponentProps & { forwardedRef: any }
    > {
        static displayName = `WithReadOnly[${getComponentDisplayName(
            WrappedComponent
        )}]`;
        render() {
            const { isReadOnly, forwardedRef, ...rest } = this.props;
            // If the consumer sets "isReadOnly" directly (to true OR false) this
            // should override anything coming from a higher level.

            return (
                <ReadOnlyContext.Consumer>
                    {(contextIsReadOnly) => (
                        <WrappedComponent
                            ref={forwardedRef}
                            {...(rest as ComponentProps)}
                            isReadOnly={
                                isReadOnly === undefined
                                    ? contextIsReadOnly
                                    : isReadOnly
                            }
                        />
                    )}
                </ReadOnlyContext.Consumer>
            );
        }
    }

    return hoistNonReactStatics(
        React.forwardRef((props: ComponentProps, ref) => {
            return <WithReadOnly {...props} forwardedRef={ref} />;
        }),
        WrappedComponent
    );
};

export default withReadOnly;
