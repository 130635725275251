import Immutable from 'seamless-immutable';
import { getStateByKey } from 'util/getStateByKey';

const DATAVIEW_CONFIG_INITIALIZE =
    '@@enhanceddataview/DATAVIEW_CONFIG_INITIALIZE';
const DATAVIEW_CONFIG_UPDATE_SORT_FIELD =
    '@@enhanceddataview/DATAVIEW_CONFIG_UPDATE_SORT_FIELD';
const DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION =
    '@@enhanceddataview/DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION';
const DATAVIEW_CONFIG_SELECT_ALL_VISIBLE =
    '@@enhanceddataview/DATAVIEW_CONFIG_SELECT_ALL_VISIBLE';
const DATAVIEW_CONFIG_SELECT_ALL_KNOWN_VALUES =
    '@@enhanceddataview/DATAVIEW_CONFIG_SELECT_ALL_KNOWN_VALUES';
const DATAVIEW_CONFIG_SELECT_ITEM =
    '@@enhanceddataview/DATAVIEW_CONFIG_SELECT_ITEM';
const CLEAR_ALL_SELECTIONS = '@@enhanceddataview/CLEAR_ALL_SELECTIONS';

export const ENHANCED_DATAVIEW_STATE_KEY = 'maui.display.enhanceddataview';

export enum SelectAllState {
    Unselected,
    AllVisibleSelected,
    AllKnownItemsSelected,
}

// ------------------------------------
// Selectors
// ------------------------------------
const getDataViewState = (state) =>
    getStateByKey(state, ENHANCED_DATAVIEW_STATE_KEY) || initialState;

export const getDataViewStateById = (state, id) =>
    getDataViewState(state)[id] || {};

export const getDataViewSortingConfig = (state, dataViewId) => {
    const dataViewState = getDataViewState(state);
    return {
        sortField: dataViewState[dataViewId]
            ? dataViewState[dataViewId].sortField
            : null,
        sortDirection: dataViewState[dataViewId]
            ? dataViewState[dataViewId].sortDirection
            : null,
    };
};

// ------------------------------------
// Actions
// ------------------------------------
export const initializeDataViewConfig = (id, sortField, sortDirection) => {
    return {
        type: DATAVIEW_CONFIG_INITIALIZE,
        payload: {
            id,
            sortField,
            sortDirection,
        },
    };
};

export const itemSelect = (id, itemId, item, checked) => {
    return {
        type: DATAVIEW_CONFIG_SELECT_ITEM,
        payload: {
            id,
            itemId,
            item,
            checked,
        },
    };
};

export const selectAllVisible = (id: string, selectionMap) => ({
    type: DATAVIEW_CONFIG_SELECT_ALL_VISIBLE,
    payload: {
        id,
        selectionMap,
    },
});

export const selectAllKnownValues = (id: string, selectionMap) => ({
    type: DATAVIEW_CONFIG_SELECT_ALL_KNOWN_VALUES,
    payload: {
        id,
        selectionMap,
    },
});

export const clearAllSelections = (id) => {
    return {
        type: CLEAR_ALL_SELECTIONS,
        payload: id,
    };
};

export const updateDataViewConfigSortField = (id, sortField) => {
    return {
        type: DATAVIEW_CONFIG_UPDATE_SORT_FIELD,
        payload: {
            id,
            sortField,
        },
    };
};

export const updateDataViewConfigSortDirection = (id, sortDirection) => {
    return {
        type: DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION,
        payload: {
            id,
            sortDirection,
        },
    };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const ENHANCED_DATAVIEW_ACTION_HANDLERS = {
    [DATAVIEW_CONFIG_INITIALIZE]: (state, action) => {
        return state
            .setIn([action.payload.id, 'sortField'], action.payload.sortField)
            .setIn(
                [action.payload.id, 'sortDirection'],
                action.payload.sortDirection
            )
            .setIn(
                [action.payload.id, 'selectAllState'],
                SelectAllState.Unselected
            )
            .setIn([action.payload.id, 'selectionMap'], {});
    },
    [DATAVIEW_CONFIG_UPDATE_SORT_FIELD]: (state, action) => {
        return state.setIn(
            [action.payload.id, 'sortField'],
            action.payload.sortField
        );
    },
    [DATAVIEW_CONFIG_UPDATE_SORT_DIRECTION]: (state, action) => {
        return state.setIn(
            [action.payload.id, 'sortDirection'],
            action.payload.sortDirection
        );
    },
    [DATAVIEW_CONFIG_SELECT_ALL_VISIBLE]: (state, action) => {
        const currentState = state[action.payload.id].selectAllState;
        const newSelectionState =
            currentState === SelectAllState.Unselected
                ? SelectAllState.AllVisibleSelected
                : SelectAllState.Unselected;

        return state
            .setIn([action.payload.id, 'selectAllState'], newSelectionState)
            .setIn(
                [action.payload.id, 'selectionMap'],
                newSelectionState !== SelectAllState.Unselected
                    ? action.payload.selectionMap
                    : {}
            );
    },
    [DATAVIEW_CONFIG_SELECT_ALL_KNOWN_VALUES]: (state, action) => {
        const allShouldBeDeSelected =
            state[action.payload.id].selectAllState ===
            SelectAllState.AllKnownItemsSelected;
        return state
            .setIn(
                [action.payload.id, 'selectAllState'],
                allShouldBeDeSelected
                    ? SelectAllState.Unselected
                    : SelectAllState.AllKnownItemsSelected
            )
            .setIn(
                [action.payload.id, 'selectionMap'],
                allShouldBeDeSelected ? {} : action.payload.selectionMap
            );
    },
    [DATAVIEW_CONFIG_SELECT_ITEM]: (state, action) => {
        const newState = state.setIn(
            [action.payload.id, 'selectAllState'],
            SelectAllState.Unselected
        );
        if (action.payload.checked) {
            return newState.setIn(
                [action.payload.id, 'selectionMap', action.payload.itemId],
                action.payload.item
            );
        }

        const map = newState.getIn([action.payload.id, 'selectionMap']);
        const newMap = map.without(action.payload.itemId);
        return newState.setIn([action.payload.id, 'selectionMap'], newMap);
    },
    [CLEAR_ALL_SELECTIONS]: (state, action) => {
        return state
            .setIn([action.payload, 'selectionMap'], {})
            .setIn(
                [action.payload, 'selectAllState'],
                SelectAllState.Unselected
            );
    },
};

// ------------------------------------
// Reducer
// ------------------------------------

export const initialState = Immutable({});

const enhancedDataViewReducer = (state = initialState, action) => {
    const handler = ENHANCED_DATAVIEW_ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default enhancedDataViewReducer;
