import { connect } from 'react-redux';
import { setDialogStatusDetails, hideDialog } from '../DialogActions';
import MessageDialogComponent from './MessageDialogComponent';

const mapActionCreators = {
    setDialogStatusDetails,
    hideDialog,
};

export default connect(
    null,
    mapActionCreators
)(MessageDialogComponent as any) as any;
