import NaturalComparer from 'util/NaturalComparer';

/**
 * @summary Helpful shared code used by both the EnumRadioGroup* and EnumSelectList* components.
 * @description  Start with a typescript enum.  Then populate $displayValues with a mapping of the string representation.
 * @example
 *     export enum MyEnum = {
 *         'Value1': 'Value1',
 *         'Value2': 'Value2'
 *     };
 *     (MyEnum as IUiEnum)['$displayValues'] = {
 *         'Value1': 'Display for Value 1',
 *         'Value2': 'Display for Value 2'
 *     };
 *
 */
export interface IUiEnum {
    [key: number]: string | { [key: string]: string };
}

export type EnumValueModel<T extends IUiEnum> = {
    id: T[keyof T];
    name: string;
};

export interface IEnumControlProps<T extends IUiEnum> {
    enumType: T;
    /**
     *  An optional list of enum values (e.g., [MyEnum.Value1, MyEnum.Value2]) that will be
     * used as the available items instead of all of the values in the enum.
     * The order the values appear in this list is the same order the values will be displayed in the
     * radio group (unless `enableSort` is set to `true`, which will then sort this list alphabetically).
     */
    enumValuesToInclude?: T[keyof T][];
    /**
     * An optional list of enum values (e.g., [MyEnum.Value1, MyEnum.Value2]) that are to be
     * removed from the available items.
     */
    enumValuesToExclude?: T[keyof T][];
}

export function getAvailableValueModels<T extends IUiEnum>(
    uiEnum: T,
    valuesToInclude?: T[keyof T][],
    valuesToExclude?: T[keyof T][]
): EnumValueModel<T>[] {
    let uiEnumKeys = Object.keys(uiEnum).filter(
        (key) => key !== '$displayValues'
    ) as T[keyof T][];

    if (valuesToInclude && valuesToInclude.length) {
        uiEnumKeys = valuesToInclude;
    }

    if (valuesToExclude && valuesToExclude.length) {
        uiEnumKeys = uiEnumKeys.filter((key) => !valuesToExclude.includes(key));
    }

    return uiEnumKeys.map((key) => {
        return {
            id: uiEnum[key as any],
            name:
                // eslint-disable-next-line dot-notation
                ((uiEnum['$displayValues'] && uiEnum['$displayValues'][key]) ||
                    uiEnum[key as any]) as string,
        } as EnumValueModel<T>;
    });
}

export function getSortedAvailableValueModels<T extends IUiEnum>(
    uiEnum: T,
    valuesToInclude: T[keyof T][],
    valuesToExclude: T[keyof T][]
) {
    return getAvailableValueModels(
        uiEnum,
        valuesToInclude,
        valuesToExclude
    ).sort((a, b) => NaturalComparer(a.name, b.name));
}

export function getValueModelById<T extends IUiEnum>(
    id: T[keyof T],
    models: EnumValueModel<T>[]
): EnumValueModel<T> {
    let result: EnumValueModel<T>;
    models.forEach((model) => {
        if (model.id === id) {
            result = model;
        }
    });
    return result;
}

export function getIdFromValueModel<T extends IUiEnum>(
    model: EnumValueModel<T>
): T[keyof T] | undefined {
    return model && model.id;
}
