import {
    grayScaleColor1,
    grayScaleColor2,
    grayScaleColor3,
    grayScaleColor4,
    grayScaleColor5,
    grayScaleColor6,
    grayScaleColor7,
    neutralColor1,
    neutralColor2,
    neutralColor3,
    neutralColor4,
    neutralColor5,
    neutralColor6,
    neutralColor7,
    neutralColor8,
    primaryColor1,
    primaryColor2,
    primaryColor3,
    primaryColor4,
    primaryColor5,
    primaryColor6,
    primaryColor7,
    primaryColor8,
    primary1Tint1,
    primary1Tint2,
    primary1Tint3,
    primary1Tint4,
    primary1Tint5,
    primary2Tint1,
    primary2Tint2,
    primary2Tint3,
    primary2Tint4,
    primary2Tint5,
    primary3Tint1,
    primary3Tint2,
    primary3Tint3,
    primary3Tint4,
    primary3Tint5,
    primary4Tint1,
    primary4Tint2,
    primary4Tint3,
    primary4Tint4,
    primary4Tint5,
    primary5Tint1,
    primary5Tint2,
    primary5Tint3,
    primary5Tint4,
    primary5Tint5,
    primary6Tint1,
    primary6Tint2,
    primary6Tint3,
    primary6Tint4,
    primary6Tint5,
    primary7Tint1,
    primary7Tint2,
    primary7Tint3,
    primary7Tint4,
    primary7Tint5,
    primary8Tint1,
    primary8Tint2,
    primary8Tint3,
    primary8Tint4,
    primary8Tint5,
    primary1Shade1,
    primary1Shade2,
    primary1Shade3,
    primary2Shade1,
    primary2Shade2,
    primary2Shade3,
    primary3Shade1,
    primary3Shade2,
    primary3Shade3,
    primary4Shade1,
    primary4Shade2,
    primary4Shade3,
    primary5Shade1,
    primary5Shade2,
    primary5Shade3,
    primary6Shade1,
    primary6Shade2,
    primary6Shade3,
    primary7Shade1,
    primary7Shade2,
    primary7Shade3,
    primary8Shade1,
    primary8Shade2,
    primary8Shade3,
    stoplightLightGreen,
    stoplightLightYellow,
    stoplightLightRed,
    stoplightGreen,
    stoplightYellow,
    stoplightRed,
    stoplightDarkGreen,
    stoplightDarkYellow,
    stoplightDarkRed,
    primaryPaletteNumbers,
    stoplightPaletteNames,
} from 'styles/variables/color-palette.scss';

const originalColorVariables = {
    neutralColor1,
    neutralColor2,
    neutralColor3,
    neutralColor4,
    neutralColor5,
    neutralColor6,
    neutralColor7,
    neutralColor8,
    primaryColor1,
    primaryColor2,
    primaryColor3,
    primaryColor4,
    primaryColor5,
    primaryColor6,
    primaryColor7,
    primaryColor8,
    primary1Tint1,
    primary1Tint2,
    primary1Tint3,
    primary1Tint4,
    primary1Tint5,
    primary2Tint1,
    primary2Tint2,
    primary2Tint3,
    primary2Tint4,
    primary2Tint5,
    primary3Tint1,
    primary3Tint2,
    primary3Tint3,
    primary3Tint4,
    primary3Tint5,
    primary4Tint1,
    primary4Tint2,
    primary4Tint3,
    primary4Tint4,
    primary4Tint5,
    primary5Tint1,
    primary5Tint2,
    primary5Tint3,
    primary5Tint4,
    primary5Tint5,
    primary6Tint1,
    primary6Tint2,
    primary6Tint3,
    primary6Tint4,
    primary6Tint5,
    primary7Tint1,
    primary7Tint2,
    primary7Tint3,
    primary7Tint4,
    primary7Tint5,
    primary8Tint1,
    primary8Tint2,
    primary8Tint3,
    primary8Tint4,
    primary8Tint5,
    primary1Shade1,
    primary1Shade2,
    primary1Shade3,
    primary2Shade1,
    primary2Shade2,
    primary2Shade3,
    primary3Shade1,
    primary3Shade2,
    primary3Shade3,
    primary4Shade1,
    primary4Shade2,
    primary4Shade3,
    primary5Shade1,
    primary5Shade2,
    primary5Shade3,
    primary6Shade1,
    primary6Shade2,
    primary6Shade3,
    primary7Shade1,
    primary7Shade2,
    primary7Shade3,
    primary8Shade1,
    primary8Shade2,
    primary8Shade3,
};

let dynamicColors = { ...originalColorVariables };

function getColorMap() {
    // temporarily create an element
    const div = document.createElement('div');
    document.body.appendChild(div);

    // calculate the actual rendered color of the variables
    const map = Object.keys(originalColorVariables).reduce((map, key) => {
        div.style.color = originalColorVariables[key];
        const color = window.getComputedStyle(div).getPropertyValue('color');
        map[key] = color;
        return map;
    }, {});

    // clean up the DOM
    document.body.removeChild(div);
    return map;
}

if (window.document) {
    let colorMap = getColorMap();
    const e = document.getElementsByTagName('body')[0];
    const observer = new MutationObserver((records: MutationRecord[]) => {
        colorMap = getColorMap();
    });

    observer.observe(e, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
    });

    dynamicColors = new Proxy(originalColorVariables, {
        get: (target, prop) => {
            return colorMap[prop];
        },
    });
}

const stoplightColors = {
    lightGreen: stoplightLightGreen,
    lightYellow: stoplightLightYellow,
    lightRed: stoplightLightRed,
    green: stoplightGreen,
    yellow: stoplightYellow,
    red: stoplightRed,
    darkGreen: stoplightDarkGreen,
    darkYellow: stoplightDarkYellow,
    darkRed: stoplightDarkRed,
};

export type PrimaryColorOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type StoplightColorOptions =
    | 'red'
    | 'light-red'
    | 'dark-red'
    | 'green'
    | 'light-green'
    | 'dark-green'
    | 'yellow'
    | 'light-yellow'
    | 'dark-yellow';

// To keep the color lists in sync with `color-palette.scss` (where they're defined),
// we pull in the comma-delimited string of colors and split them out into a colors
// list. Note that the un-minified version of the string from sass contains commas *and*
// spaces between the colors, while the minified version does not have spaces.
const primaryColorNumbers: PrimaryColorOptions[] = primaryPaletteNumbers
    .split(',')
    .map((n) => n.trim() as PrimaryColorOptions);
const stoplightColorNames: StoplightColorOptions[] = stoplightPaletteNames
    .split(',')
    .map((n) => n.trim().replace(/"/g, '') as StoplightColorOptions);

export {
    grayScaleColor1,
    grayScaleColor2,
    grayScaleColor3,
    grayScaleColor4,
    grayScaleColor5,
    grayScaleColor6,
    grayScaleColor7,
    dynamicColors,
    stoplightColors,
    primaryColorNumbers,
    stoplightColorNames,
};

/**
 * Given a hue and saturation, generate a list of css compatible HSL colors in descending lightness value.
 * Example: [ "hsl(155, 50, 85)", "hsl(155, 50, 55)", "hsl(155, 50, 25)" ]
 */
export function generateColorScale(
    hue: number,
    saturation: number,
    length: number
): string[] {
    const lightnessStart = 85;
    const lightnessEnd = 25;
    const stepSize =
        length === 1 ? 0 : (lightnessStart - lightnessEnd) / (length - 1);
    const colorList = [];

    for (let i = 0; i < length; i++) {
        colorList.push(
            `hsl(${hue}, ${saturation}%, ${lightnessStart - i * stepSize}%)`
        );
    }

    return colorList;
}

// Given a string reprentation of an rgb color, ie: "rgb(255, 128, 0)",
// parse and return the RGB values as an array of numbers.
export function parseRgbColorString(color: string): [number, number, number] {
    const matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
    const match = matchColors.exec(color);

    return [
        parseInt(match[0], 10),
        parseInt(match[1], 10),
        parseInt(match[2], 10),
    ];
}
