import React, { Component, ComponentClass, ComponentType } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { getComponentDisplayName } from 'util/getComponentDisplayName';
import { v1 } from 'uuid';

export interface WithStateProps {
    useOuterStateKeyProp?: boolean;
    stateKey?: string;
}

export interface InjectedWithStateProps {
    stateKey: string;
}

export default function withState<
    WrappedComponentProps extends InjectedWithStateProps & WithStateProps,
    WrappedComponentState extends object = {}
>(WrappedComponent: ComponentType<WrappedComponentProps>) {
    type WithState = ComponentClass<
        Omit<WrappedComponentProps, keyof InjectedWithStateProps> &
            WithStateProps,
        WrappedComponentState
    >;

    class WrappedComponentWithState
        extends Component<
            Omit<WrappedComponentProps, keyof InjectedWithStateProps> &
                WithStateProps,
            WrappedComponentState
        >
        implements InjectedWithStateProps {
        static displayName = `withState(${getComponentDisplayName(
            WrappedComponent
        )})`;

        stateKey: string;

        constructor(props: WrappedComponentProps & WithStateProps) {
            super(props);
            if (!props.useOuterStateKeyProp) {
                this.stateKey = v1();
            }
        }

        render() {
            const { useOuterStateKeyProp, stateKey } = this.props;
            return (
                <WrappedComponent
                    stateKey={useOuterStateKeyProp ? stateKey : this.stateKey}
                    {...(this.props as WrappedComponentProps & WithStateProps)}
                />
            );
        }
    }

    // Note: Done this way since `hoistStatistics` complains since propTypes is reserved. Remove this when not needed.
    // Added when adding linting, please fix if you edit this file.
    // eslint-disable-next-line dot-notation
    WrappedComponentWithState['propTypes'] = WrappedComponent.propTypes;

    return hoistStatics<WithState, typeof WrappedComponent>(
        WrappedComponentWithState,
        WrappedComponent
    );
}
