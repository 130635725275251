import React, { Component } from 'react';
import ValidationService from '../../../util/ValidationService';
import ValidationError from '../../display/ValidationError';
import CheckboxControl from '../../controls/CheckboxControl';
import getInputId from '../../../util/getInputId';
import { CheckboxInputProps } from './CheckboxInputProps';
import { WrappedFieldProps } from 'redux-form';

export interface CheckboxInputFieldProps
    extends WrappedFieldProps,
        CheckboxInputProps {}
export class CheckboxInputField extends Component<CheckboxInputFieldProps> {
    static displayName = 'CheckboxInputField';
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.input.onChange(e);
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };
    renderCheckbox = (
        props: CheckboxInputFieldProps,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
        const {
            label,
            input,
            initialValue,
            field,
            isValidationInline,
            ...rest
        } = props;
        let value = false;
        if (input.value) {
            value = input.value;
        } else if (initialValue) {
            value = initialValue;
        }

        return (
            <CheckboxControl
                {...rest}
                value={value}
                id={getInputId(CheckboxInputField, props)}
                label={label}
                name={input.name}
                onChange={onChange}
            />
        );
    };
    render() {
        const { meta, isValidationInline } = this.props;
        const renderCheckbox = this.props.renderCheckbox || this.renderCheckbox;
        const wrapperClass = ValidationService.getWrapperClass(
            'checkbox-input',
            isValidationInline
        );
        return (
            <div className={wrapperClass}>
                {renderCheckbox(this.props, this.onChange)}
                <ValidationError isInline={isValidationInline} meta={meta} />
            </div>
        );
    }
}
