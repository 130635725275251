import React from 'react';
//import './Timer.scss';

export interface TimerProps {
    className?: string;
    style?: React.CSSProperties;
}

interface TimerState {
    count?: number;
}
class Timer extends React.Component<TimerProps, TimerState> {
    state = {
        count: 0,
    };

    timer: any;
    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({ count: this.state.count + 1 });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <div className='timer'>
                {new Date(1000 * this.state.count).toISOString().substr(14, 5)}
            </div>
        );
    }
}
export default Timer;
