import React from 'react';
import { createPortal } from 'react-dom';
import DraggableCore from 'react-draggable';
import 'rxjs/add/observable/fromEvent';
import {
    throttledEscapeSource,
    clickSource,
    keyupSource,
} from 'util/EventSubscriptions';
import themeClassName from 'util/Themes';
import { getApplicationContentRoot } from 'util/documentHelper';
import './DialogCore.scss';

// Dialog overlay modes -- indicates how dark the rest of the screen gets when a dialog is opened.
export enum DIALOG_OVERLAY_MODE {
    NONE = 'none',
    LIGHT = 'light',
    DARK = 'dark',
}

export interface DialogCoreProps extends React.Props<DialogCoreComponent> {
    // All dialogs require a globally unique ID.  It is the parameter to pass to
    // dialog actions (showDialog, hideDialog, etc.).
    dialogId: string;

    // An optional className to add to the root element in this component.
    className?: string;

    // Optional additional styles to apply
    style?: React.CSSProperties;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'first-run-wizard' is a theme.
    theme?: string;

    // The height of the dialog in pixels.
    height?: number;

    // An optional function that is called when the dialog core component is mounted or unmounted.
    // The function is called with a single parameter, the DOM element of the dialog container.
    getDialogRef?: (elem: any) => void;

    // The overlay mode of the dialog -- indicates how dark the rest of the screen gets when a dialog
    // is opened.
    overlayMode?: DIALOG_OVERLAY_MODE;

    // Boolean indicating whether hitting the escape key when the dialog is open should attempt to cancel it.
    escapeToCancel?: boolean;

    // Boolean indicating whether clicking outside the dialog when the dialog is open should attempt to cancel it.
    clickAwayToCancel?: boolean;

    // Optional function that's called when the user hits the enter key
    onEnterKeyHit?: () => void;

    // Optional function to call when the user attempts to cancel the dialog.
    // The function is called with a single parameter, the dialog ID.
    onCancel?: (dialogId: string) => void;

    // Boolean indicating whether this dialog can be dragged around by the user.
    isDraggable?: boolean;

    // An optional callback to call when the user starts dragging the dialog.
    onDragStart?: () => void;

    // An optional callback to call when the user is dragging the dialog.
    onDrag?: () => void;

    // An optional callback to call when the user finishes dragging the dialog.
    onDragStop?: () => void;

    // An optional callback to call when the dialog opens.
    // The function is called with a single parameter, the dialog ID.
    onOpen?: (dialogId: string) => void;

    // An optional callback to call when the dialog closes.
    // The function is called with a single parameter, the dialog ID.
    onClose?: (dialogId: string) => void;

    onMouseDown: () => void;
    onMouseUp: () => void;

    // From connected container
    isOpen?: boolean;
    isTransitioning?: boolean;
    zIndex?: number;
    isTop?: boolean;
    registerDialog: (dialogId: string) => void;
    unregisterDialog: (dialogId: string) => void;
}

interface DialogCoreState {}

// DialogCore is the base component for all dialogs.  It is in charge of
// basic dialog behavior (opening and closing, dragging, closing on clicks and escapes).
export default class DialogCoreComponent extends React.Component<
    DialogCoreProps,
    DialogCoreState
> {
    static defaultProps = {
        className: '',
        theme: '',
        escapeToClose: false,
        clickAwayToClose: false,
        overlayMode: DIALOG_OVERLAY_MODE.NONE,
        isOpen: false,
        zIndex: 0,
        isTop: false,
    };

    escapeSubscription: any;
    clickAwaySubscription: any;
    enterToSubmitSubscription: any;
    element: any;
    portalElement: HTMLDivElement;

    constructor(props) {
        super(props);

        // Create portal element for this dialog to render in to.
        this.portalElement = document.createElement('div');
        // The ID on this element is never actually used; it's just helpful
        // for identifying the resulting element in the DOM.
        this.portalElement.setAttribute('id', `portal-for-${props.dialogId}`);
        getApplicationContentRoot().append(this.portalElement);
    }

    componentDidMount() {
        const { dialogId, isOpen, registerDialog } = this.props;
        registerDialog(dialogId);

        if (isOpen) {
            this.handleDialogOpen(dialogId);
        }
    }

    componentDidUpdate(prevProps: DialogCoreProps) {
        const {
            dialogId,
            isOpen,
            registerDialog,
            unregisterDialog,
        } = this.props;
        if (prevProps.dialogId !== dialogId) {
            this.handleDialogClose(prevProps.dialogId);
            unregisterDialog(prevProps.dialogId);
            registerDialog(dialogId);
        }
        if (!prevProps.isOpen && isOpen) {
            // The dialog has just opened.
            this.handleDialogOpen(dialogId);
        }
        if (prevProps.isOpen && !isOpen) {
            // The dialog has just closed.
            this.handleDialogClose(dialogId);
        }
    }

    componentWillUnmount() {
        this.props.unregisterDialog(this.props.dialogId);

        this.detachEscapeHandler();
        this.detachClickAwayHandler();
        this.detachEnterHandler();
        this.portalElement.remove();
    }

    handleDialogOpen(dialogId: string) {
        const { onOpen } = this.props;

        if (onOpen) {
            onOpen(dialogId);
        }
        this.maybeAttachEscapeHandler();
        this.maybeAttachClickAwayHandler();
        this.maybeAttachEnterHandler();
    }

    handleDialogClose(dialogId: string) {
        const { onClose } = this.props;

        if (onClose) {
            onClose(dialogId);
        }
        this.detachEscapeHandler();
        this.detachClickAwayHandler();
        this.detachEnterHandler();
    }

    maybeAttachEscapeHandler() {
        const { escapeToCancel, onCancel, dialogId } = this.props;

        if (escapeToCancel && onCancel) {
            this.escapeSubscription = throttledEscapeSource.subscribe(() => {
                // Note: Do not pull these from the original destructuring!
                // If you do, they'll be stale by the time this callback is
                // actually executed. We need their most up-to-date values.
                if (this.props.isTop && this.props.isOpen) {
                    onCancel(dialogId);
                }
            });
        }
    }

    detachEscapeHandler() {
        if (this.escapeSubscription) {
            this.escapeSubscription.unsubscribe();
            this.escapeSubscription = null;
        }
    }

    maybeAttachClickAwayHandler() {
        const { clickAwayToCancel, onCancel, dialogId } = this.props;

        if (clickAwayToCancel && onCancel) {
            this.clickAwaySubscription = clickSource
                .filter((event: any) => {
                    return event.target.id === `@@dialog__overlay--${dialogId}`;
                })
                .subscribe((event: any) => {
                    // React synthetic events aren't really like native events.  Since the event handler for React
                    // is attached at the root element, there isn't really propagation and bubbling like native
                    // events.  Thus, we call preventDefault on this event as well as stopPropagation to try to
                    // stop the event from being a click anymore.
                    event.stopPropagation();
                    event.preventDefault();

                    onCancel(dialogId);
                });
        }
    }

    detachClickAwayHandler() {
        if (this.clickAwaySubscription) {
            this.clickAwaySubscription.unsubscribe();
            this.clickAwaySubscription = null;
        }
    }

    maybeAttachEnterHandler() {
        const { onEnterKeyHit } = this.props;

        if (onEnterKeyHit) {
            this.enterToSubmitSubscription = keyupSource
                .filter((event: any) => event.key === 'Enter')
                .subscribe((event) => {
                    onEnterKeyHit();
                });
        }
    }

    detachEnterHandler() {
        if (this.enterToSubmitSubscription) {
            this.enterToSubmitSubscription.unsubscribe();
            this.enterToSubmitSubscription = null;
        }
    }

    refDialog = (elem) => {
        const { getDialogRef } = this.props;
        this.element = elem;

        if (getDialogRef) {
            getDialogRef(elem);
        }
    };

    render() {
        const {
            height,
            theme,
            className,
            children,
            zIndex,
            style,
            isTransitioning,
            dialogId,
            isOpen,
            isDraggable,
            onMouseDown,
            onMouseUp,
            onDragStart,
            onDrag,
            onDragStop,
            overlayMode,
        } = this.props;

        let dialogCoreStyle: any = {
            zIndex: zIndex + 1,
        };

        if (height) {
            dialogCoreStyle.height = height;
        }

        dialogCoreStyle = Object.assign({}, dialogCoreStyle, style);

        const coreClasses = ['dialog-core__dialog', className];
        if (theme) {
            coreClasses.push(themeClassName(theme));
        }
        if (isTransitioning) {
            coreClasses.push('dialog-core__dialog--transitioning');
        }

        const dialogCore = (
            <div
                className={coreClasses.join(' ')}
                style={dialogCoreStyle}
                ref={this.refDialog}
                id={'@@dialog--' + dialogId}
            >
                {children}
            </div>
        );

        const overlayClasses = ['dialog-core__overlay'];
        if (overlayMode !== DIALOG_OVERLAY_MODE.NONE) {
            overlayClasses.push(`dialog-core__overlay--${overlayMode}`);
        }
        if (isTransitioning) {
            overlayClasses.push('dialog-core__overlay--transitioning');
        }

        return createPortal(
            isOpen && (
                <div
                    className='dialog-core'
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                >
                    {isDraggable ? (
                        <DraggableCore
                            handle={`.dialog-drag-handle--${dialogId}`}
                            onStart={onDragStart}
                            onDrag={onDrag}
                            onStop={onDragStop}
                        >
                            {dialogCore}
                        </DraggableCore>
                    ) : (
                        dialogCore
                    )}
                    <div
                        id={`@@dialog__overlay--${dialogId}`}
                        className={overlayClasses.join(' ')}
                        style={{ zIndex: zIndex }}
                    />
                </div>
            ),
            this.portalElement
        );
    }
}
