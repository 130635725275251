import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from '../../../util/TTDNumberMask';
import NumberFormattingService from 'util/NumberFormattingService';
import withReadOnly from 'components/hocs/withReadOnly';

export interface DecimalControlProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'maxLength'> {
    decimalLimit?: number;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    isEnabled?: boolean;
    isInline?: boolean;
    inlineAlignRight?: boolean;
    // Whether, when rendering in readonly mode, we should trim trailing zeros.
    readOnlyTrimInsignificantZeros?: boolean;
    allowNegative?: boolean;
    value?: number;
    /**
     * @summary maxLength is supported, however be aware that this will include any thousand separator characters etc...
     */
    maxLength?: number;
}

class DecimalControl extends Component<DecimalControlProps> {
    static defaultProps: Partial<DecimalControlProps> = {
        className: '',
        isEnabled: true,
        inlineAlignRight: true,
        readOnlyTrimInsignificantZeros: false,
        allowNegative: false,
    };
    private mask: any;

    constructor(props: DecimalControlProps) {
        super(props);

        this.createMask(props.decimalLimit, props.allowNegative);
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.decimalLimit !== nextProps.decimalLimit ||
            this.props.allowNegative !== nextProps.allowNegative
        ) {
            this.createMask(nextProps.decimalLimit, nextProps.allowNegative);
        }
    }

    createMask(decimalLimit, allowNegative) {
        this.mask = createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: NumberFormattingService.getThousandsSeparator(),
            allowDecimal: true,
            decimalSymbol: NumberFormattingService.getDecimalMark(),
            decimalLimit: decimalLimit,
            allowNegative: allowNegative,
        });
    }

    render() {
        const {
            isReadOnly,
            isInline,
            className,
            value,
            decimalLimit,
            isEnabled,
            allowNegative,
            inlineAlignRight,
            readOnlyTrimInsignificantZeros,
            ...rest
        } = this.props;

        const wrapperClasses = ['text-control', className];
        if (isInline) {
            wrapperClasses.push('input--inline');

            if (inlineAlignRight) {
                wrapperClasses.push('input--right-align');
            }
        }

        return (
            <div className={wrapperClasses.join(' ')}>
                {isReadOnly ? (
                    <div className='input--readonly'>
                        {NumberFormattingService.formatNumber(
                            value,
                            decimalLimit,
                            readOnlyTrimInsignificantZeros,
                            this.props.placeholder
                        )}
                    </div>
                ) : (
                    <MaskedInput
                        mask={this.mask}
                        guide={true}
                        placeholderChar={'\u2000'}
                        autoComplete='off'
                        value={value}
                        disabled={!isEnabled}
                        {...rest}
                    />
                )}
            </div>
        );
    }
}

export default withReadOnly(DecimalControl);
