import React, { Component } from 'react';
import ValidationError from 'components/display/ValidationError';
import getInputId from 'util/getInputId';
import MultiSelectListControl from 'components/controls/MultiSelectListControl';
import TtdInputFieldArray from 'components/inputs/TtdInputFieldArray';
import { MultiSelectListInputProps } from 'components/inputs/MultiSelectListInput';
import {
    getSortedAvailableValueModels,
    getAvailableValueModels,
    getIdFromValueModel,
    getValueModelById,
    IUiEnum,
    EnumValueModel,
    IEnumControlProps,
} from 'util/UiEnumUtils';
import { MultiSelectListInputFieldProps } from '../MultiSelectListInput/MultiSelectListInputField';
import { TtdInputFieldComponentProps, UiFieldModel } from '../TtdInputField';

export interface EnumMultiSelectListInputProps<T extends IUiEnum>
    extends IEnumControlProps<T>,
        TtdInputFieldComponentProps<
            Omit<
                MultiSelectListInputProps<T>,
                | 'availableItems'
                | 'onSearchInputKeyDown'
                | 'onReadyForMoreData'
                | 'onSearchFocus'
                | 'onSearchBlur'
                | 'onNeedData'
                | 'onLabelClick'
                | 'onLabelMouseEnter'
                | 'onLabelMouseLeave'
                | 'onKeyDown'
                | 'onBlur'
                | 'onFocus'
                | 'onItemChange'
                | 'onItemClick'
                | 'onToggleSelectAll'
            >
        > {
    disableSort?: boolean;
    multiSelectControlClassName?: string;
}

// This component doesn't support some of the dynamic loading of the normal MultiSelectListInput
interface EnumMultiSelectListInputFieldProps<T extends IUiEnum>
    extends Omit<
            MultiSelectListInputFieldProps<T[keyof T]>,
            | 'customItemMapper'
            | 'availableItems'
            | 'onChange'
            | 'itemType'
            | 'primaryKey'
            | 'displayNameKey'
            | 'renderItem'
            | 'onItemChange'
            | 'onItemClick'
            | 'getItemDisplay'
        >,
        EnumMultiSelectListInputProps<T> {
    selectedItems: T[keyof T][];
}

class EnumMultiSelectListInputField<T extends IUiEnum> extends Component<
    EnumMultiSelectListInputFieldProps<T>
> {
    private availableItems: EnumValueModel<T>[];
    static defaultProps = {
        disableSort: false,
    };

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    constructor(props) {
        super(props);
        this.setAvailableItems();
    }

    componentDidMount() {
        this.setInitialValues();
    }

    componentDidUpdate(prevProps: EnumMultiSelectListInputFieldProps<T>) {
        this.setInitialValues(prevProps);
    }

    setInitialValues(prevProps?: EnumMultiSelectListInputFieldProps<T>) {
        // set initial values only if there is nothing currently selected and if the values in previous props
        // are different than values in current props
        if (
            this.props.fields &&
            !this.props.fields.length &&
            this.props.selectedItems &&
            this.props.selectedItems.length &&
            (!prevProps || prevProps.selectedItems !== this.props.selectedItems)
        ) {
            this.props.selectedItems.forEach((v) =>
                // TODO Why are we not initializing this in the standard way?
                // Field initial values should not be set in this manor, as now the form is dirty...
                // :( https://atlassian.thetradedesk.com/jira/browse/UXACC-84
                this.props.fields.push(v as any)
            );
        }
    }

    setAvailableItems() {
        this.availableItems = this.props.disableSort
            ? getAvailableValueModels(
                  this.props.enumType,
                  this.props.enumValuesToInclude,
                  this.props.enumValuesToExclude
              )
            : getSortedAvailableValueModels(
                  this.props.enumType,
                  this.props.enumValuesToInclude,
                  this.props.enumValuesToExclude
              );
    }

    // this replaces the current fields array with `newFields`, likely after a select/unselect all
    replaceFields = (event, newFields) => {
        const { fields } = this.props;
        fields.removeAll();
        newFields.forEach((f) => fields.push(f.id));
    };

    onChange = (item: EnumValueModel<T>, event, index) => {
        event.preventDefault();
        const isForRemoval = index !== undefined;
        const { fields, onChange } = this.props;
        const value = getIdFromValueModel(item);
        if (onChange) {
            const currentValue = fields.getAll() || [];
            const newValue = isForRemoval
                ? currentValue.filter((v, idx) => index !== idx)
                : [...currentValue, value];
            onChange(event, newValue, index === undefined);
        }

        return isForRemoval ? fields.remove(index) : fields.push(value);
    };

    render() {
        const {
            fields,
            isEnabled,
            isReadOnly,
            field,
            isInline,
            itemName,
            color,
            emptyText,
            pluralDisplayName,
            showSummary,
            showNoSelectionsAvailable,
            meta,
            displayLabelSkittle,
            showValidation,
            embedInPortal,
            multiSelectControlClassName,
            onLabelMouseLeave,
            onToggle,
            ...rest
        } = this.props;
        return (
            <span>
                <MultiSelectListControl
                    {...rest}
                    id={getInputId(EnumMultiSelectListInput, {
                        meta,
                        input: { name: (field as UiFieldModel).name },
                    })}
                    color={color}
                    isReadOnly={isReadOnly}
                    className={multiSelectControlClassName}
                    displayNameKey='name'
                    primaryKey='id'
                    showSummary={showSummary}
                    itemName={itemName}
                    isInline={isInline}
                    availableItems={this.availableItems}
                    selectedItems={(fields.getAll() || []).map((value) =>
                        getValueModelById<T>(value, this.availableItems)
                    )}
                    isEnabled={isEnabled}
                    showNoSelectionsAvailable={showNoSelectionsAvailable}
                    pluralDisplayName={pluralDisplayName}
                    emptyText={emptyText}
                    displayLabelSkittle={displayLabelSkittle}
                    onItemClick={this.onChange}
                    embedInPortal={embedInPortal}
                    onLabelMouseLeave={onLabelMouseLeave}
                    onToggle={onToggle}
                    onToggleSelectAll={this.replaceFields}
                />
                {showValidation && (
                    <ValidationError isInline={isInline} meta={meta} />
                )}
            </span>
        );
    }
}

export default class EnumMultiSelectListInput<
    T extends IUiEnum
> extends Component<EnumMultiSelectListInputProps<T>> {
    static defaultProps = {
        className: '',
        size: 'sm',
        isEnabled: true,
        showValidation: true,
    };

    render() {
        const { className, style, showValidation, ...rest } = this.props;
        return (
            <div
                className={`multi-select-list-input ${className}`}
                style={style}
            >
                <TtdInputFieldArray
                    component={EnumMultiSelectListInputField}
                    showValidation={showValidation}
                    {...rest}
                />
            </div>
        );
    }
}
