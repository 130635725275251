import React from 'react';
import ttdForm from '../../../hocs/ttdForm';
import { autoFocus } from '../../../../util/AutoFocus';
import HelpContext from '../../../contexts/HelpContext';

// A higher-order-component that wraps a given component with some
// helpful behavior.
//
// In particular, this HOC creates components that are compatible with the SteppedTaskDialog.
// It wraps the content of the component in a form, and wraps that in a ttdForm that will
// not destroy itself on submission (because the stepped dialog controls the destruction
// of the redux-form data).  It also allows for two forms of custom validation and warning
// callbacks: it calls any static validate and warn methods on the wrapped component (e.g.,
// CreateAdGroupWizardSecondPage), as well as calling the same methods on the main dialog
// client component (e.g., the CreateAdGroupWizard).

export default function ttdSteppedTaskDialogPage(
    WrappedComponent,
    formId,
    uiModel,
    validate,
    warn,
    stepIndex
) {
    class ResultClass extends React.Component {
        static uniqueName = formId;
        static uiModel = uiModel;

        component: any;
        static validate(service, model) {
            if (WrappedComponent.validate) {
                WrappedComponent.validate(service, model);
            }
            if (validate) {
                validate(service, model, stepIndex);
            }
        }

        static warn(service, model) {
            if (WrappedComponent.warn) {
                WrappedComponent.warn(service, model);
            }
            if (warn) {
                warn(service, model);
            }
        }

        componentDidMount() {
            if (this.component) {
                autoFocus(this.component);
            }
        }

        render() {
            return (
                <HelpContext.Provider
                    value={`help-context__${formId}__step-${stepIndex}`}
                >
                    <WrappedComponent
                        ref={(component) => {
                            this.component = component;
                        }}
                        {...this.props}
                        uiModel={uiModel}
                    />
                </HelpContext.Provider>
            );
        }
    }

    return ttdForm(ResultClass, false /* destroyOnUnmount */);
}
