import React from 'react';
import './LoadingIndicator.scss';

export interface LoadingIndicatorProps {
    isDelayed: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export default class LoadingIndicator extends React.Component<
    LoadingIndicatorProps,
    {}
> {
    static defaultProps = {
        isDelayed: false,
        className: '',
    };

    render() {
        const { isDelayed, className } = this.props;

        return (
            <div
                style={this.props.style}
                className={`loading-indicator ${className} ${
                    isDelayed ? 'loading-indicator--delayed' : ''
                }`}
            >
                <div className='loading-indicator__loader' />
            </div>
        );
    }
}
