import React, { ReactNode } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';

export interface MultilineTextProps {
    // The text to display/crop
    text: string;

    // The maximum number of lines allowed to display the text (if the length of the text exceeds the
    // number of lines, the text will crop at the last line and display the truncationText).
    // Defaults to 3
    maxLines?: number;

    // The text to display when the number of lines in the text exceeds the value of maxLines
    // Defaults to '...'
    truncationText?: ReactNode;

    // Callback function invoked when the reflow logic complete.
    // Defaults to 'undefined'
    onReflow?: any;

    className?: string;
}

// This is really just a Megagon wrapper for the LinesEllipsis React component:
// https://www.npmjs.com/package/react-lines-ellipsis
// The prop types defined here are a subset of those defined in LinesEllipsis, just with slightly
// altered names.
class MultilineText extends React.Component<MultilineTextProps, {}> {
    static defaultProps = {
        text: '',
        maxLines: 3,
    };

    render() {
        const {
            text,
            maxLines,
            truncationText,
            className,
            onReflow,
        } = this.props;

        // The all-CSS version of LinesEllipsis (i.e. "Loose") doesn't support
        // the `truncationText` or `onReflow` options. If either are specified,
        // use the full component.
        return truncationText || onReflow ? (
            <LinesEllipsis
                text={text}
                className={className}
                maxLine={maxLines}
                ellipsis={truncationText || '...'}
                onReflow={onReflow}
                // This whiteSpace style is a workaround to fix a problem in chrome 76: https://github.com/xiaody/react-lines-ellipsis/issues/59
                // It should be removed if/when the react-lines-ellipsis library fixes the problem.
                style={{ whiteSpace: 'pre-wrap' }}
            />
        ) : (
            <LinesEllipsisLoose
                text={text}
                maxLine={maxLines}
                style={{ overflow: 'hidden' }}
                className={className}
            />
        );
    }
}

export default MultilineText;
