import { compose } from 'redux';
import { connect } from 'react-redux';
import DataFiltersComponent from './DataFiltersComponent';
import withState from 'components/hocs/withState';
import { reduxForm, getFormValues } from 'redux-form';

const mapStateToProps = (state, ownProps) => {
    const result = {
        hasClearableFieldValues: false,

        // this gets passed to reduxForm automagically via compose
        // thus allowing using stateKey as form name, as well as onChange event being handled
        form: ownProps.formName ? ownProps.formName : ownProps.stateKey,
        enableReinitialize: ownProps.enableReinitialize,
        keepDirtyOnReinitialize: true,
        destroyOnUnmount: ownProps.destroyOnUnmount,
    };

    const formValues = getFormValues(
        ownProps.formName ? ownProps.formName : ownProps.stateKey
    )(state);
    if (formValues && ownProps.clearableFilters) {
        result.hasClearableFieldValues = Object.keys(formValues).some((key) => {
            if (ownProps.clearableFilters.includes(key)) {
                const v = formValues[key];
                if (v !== undefined && v !== null && v.length !== 0) {
                    return true;
                }
            }

            return false;
        });
    }

    return result;
};

const ConnectedDataFilters = compose(
    withState,
    connect(mapStateToProps),
    reduxForm({})
)(DataFiltersComponent as any) as any;

// Need to set this here as it is used by the mapStateToProps function
ConnectedDataFilters.defaultProps = {
    destroyOnUnmount: true,
};

export default ConnectedDataFilters;
