let documentRootSelector: () => HTMLElement | Document = () => document;

export function setApplicationDocumentRootSelector(
    selector: () => HTMLElement
) {
    documentRootSelector = selector;
}

export function getApplicationDocumentRoot() {
    return documentRootSelector();
}

export function getApplicationContentRoot() {
    const root = getApplicationDocumentRoot();
    if ('body' in root) {
        return root.body;
    }
    return root;
}

const querySelector: typeof document.querySelector = (selector: string) => {
    return getApplicationContentRoot().querySelector(selector);
};

export { querySelector };
