import { connect } from 'react-redux';
import {
    setDialogStatusDetails,
    hideDialog,
    completeStep,
    setCurrentStep,
} from '../DialogActions';
import { destroy, submit, isDirty } from 'redux-form';
import {
    initializeDialogConfirmation,
    getNavigationState,
} from '../../../../reducers/navigationcontrol';
import SteppedTaskDialogComponent from './SteppedTaskDialogComponent';
import { getDialogState } from '../DialogReducer';

const mapActionCreators = {
    setDialogStatusDetails,
    hideDialog,
    completeStep,
    setCurrentStep,
    initializeDialogConfirmation,
    destroyForm: destroy,
    submitForm: submit,
};

const mapStateToProps = (state, ownProps) => {
    const dialogInstance = getDialogState(state).instances[ownProps.dialogId];
    const dialogStatus =
        dialogInstance && dialogInstance.statusDetails.dialogStatus;
    const currentStepIndex =
        (dialogInstance && dialogInstance.currentStepIndex) || 0;
    const completedSteps = dialogInstance && dialogInstance.completedSteps;
    const navigationState = getNavigationState(state);
    const isConfirmationPending =
        navigationState.parentDialogId === ownProps.dialogId
            ? navigationState.isConfirmationPending
            : false;
    const formName = state.maui.navigation.formName;
    const dirty = isDirty(`${ownProps.dialogId}-form`)(state);

    return {
        dialogStatus: dialogStatus,
        currentStepIndex,
        completedSteps,
        isDirty: dirty,
        currentConfirmationFormName: formName,
        isConfirmationPending,
        formId: `${ownProps.dialogId}-form`,
    };
};

export default connect(mapStateToProps, mapActionCreators, null, {
    forwardRef: true,
})(SteppedTaskDialogComponent as any);
