import React from 'react';
import {
    TtdField,
    UiFieldModel,
    StrippedFieldProps,
} from 'components/inputs/TtdInputField';
import { FieldArray, BaseFieldArrayProps } from 'redux-form';

import PropTypes from 'prop-types';

interface TtdInputFieldArrayProps
    extends Omit<BaseFieldArrayProps, 'name' | 'component'> {
    field: TtdField;
    [key: string]: any;
}

export type TtdInputFieldArrayComponentProps<
    ComponentProps extends {}
> = ComponentProps &
    Omit<BaseFieldArrayProps<ComponentProps>, StrippedFieldProps> & {
        field: TtdField;
    };

export default class TtdInputFieldArray extends React.Component<
    TtdInputFieldArrayProps
> {
    static fieldValidation = () => undefined;
    static propTypes = {
        // Definition for a field.
        field: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                isRequired: PropTypes.bool,
            }),
        ]).isRequired,
    };
    render() {
        const { field, validate, ...rest } = this.props;
        return (
            <FieldArray
                name={(field as UiFieldModel).name || (field as string)}
                field={field}
                {...(rest as any)}
            />
        );
    }
}
