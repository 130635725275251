import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { NavigateFunction } from 'react-router';
import { Location } from 'history';
import qs, { ParsedUrlQuery } from 'querystring';
import createPropProviderHOC from '../createPropProviderHOC';

export interface RouteComponentProps<
    ParamProps extends Record<string, string>
> {
    params: ParamProps & { '*': string };
    location: Location;
    query: ParsedUrlQuery;
    navigate: NavigateFunction;
}

export default createPropProviderHOC('withRouter', () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const search = useRef(location.search);
    const [query, setQuery] = useState(
        qs.parse(search.current.replace(/^\?/, ''))
    );

    useEffect(() => {
        if (search.current !== location.search) {
            search.current = location.search;
            setQuery(qs.parse(location.search.replace(/^\?/, '')));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search.current, location.search]);

    return {
        navigate,
        params,
        location,
        query,
    };
});
