export default function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

/**
 * @example
 * combineModifier('base-class', {modifier1: true, modifier2: false, modifier3: true});
 * return 'base-class base-class--modifier1 base-class--modifier3';
 */
export const combineModifiers = (
    base: string,
    modifiers: { [modifier: string]: boolean }
) => {
    return `${base} ${Object.entries(modifiers)
        .filter(([, shouldCombine]) => shouldCombine)
        .map(([modifier]) => `${base}--${modifier}`)
        .join(' ')}`;
};
