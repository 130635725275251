import React from 'react';
// '<Glyph>' is deprecated in favor of direct SVG imports
import Glyph from '../../../Glyph';
import ToolTip from '../../../ToolTip';
import './StepStatus.scss';

export interface StepStatusProps {
    dialogId: string;
    steps: { name: string; isComplete: boolean }[];
    currentStepIndex: number;
    className?: string;
    onStepClick: (dialogId: string, index: number) => void;
}

export default class StepStatusComponent extends React.Component<
    StepStatusProps,
    {}
> {
    render() {
        const {
            dialogId,
            steps,
            currentStepIndex,
            className,
            onStepClick,
        } = this.props;

        const stepList = steps.map((step, index) => {
            const result = [];

            result.push(
                <ToolTip content={step.name} key={step.name}>
                    <div
                        className={`step-status__step ${
                            index === currentStepIndex
                                ? 'step-status__step--current step-status__step--current-index-' +
                                  currentStepIndex
                                : ''
                        }`}
                    >
                        {step.isComplete ? (
                            <Glyph
                                className='step-status__step--complete'
                                name='status-success'
                                onClick={() => onStepClick(dialogId, index)}
                            />
                        ) : (
                            <div className='step-status__step--incomplete'>
                                {index + 1}
                            </div>
                        )}
                    </div>
                </ToolTip>
            );

            if (index !== steps.length - 1) {
                result.push(
                    <div
                        key={`${step.name}-separator`}
                        className={`step-status__line ${
                            step.isComplete ? 'step-status__line--complete' : ''
                        }`}
                    />
                );
            }
            return result;
        });

        return (
            <div className={`step-status ${className}`}>
                <div className='step-status__steps'>{stepList}</div>
            </div>
        );
    }
}
