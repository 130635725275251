import React from 'react';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import { Placement } from 'popper.js';
import uuidV4 from 'uuid/v4';
import { isOverflowingX } from 'util/DOMUtil';
import themeClassName from 'util/Themes';
import ToolTipPopper from './ToolTipPopperComponent';
import './ToolTip.scss';

export enum DisplayMode {
    // Normal behavior: when the anchor element is hovered, render the tooltip
    default = 'default',

    // Same as normal behavior but only display if the tooltip content is wider
    // than the anchor element content. This allows using tooltips to display
    // the full content of text overflow content.
    overflow = 'overflow',

    // Used only when the tooltip has a `MultilineText` component as a child. This
    // mode renders the tooltip content only when we detect that said child has
    // been clamped.
    multiLineOverflow = 'multiLineOverflow',

    // Show the tooltip all the time, as long as the anchor element is in view.
    always = 'always',

    // Show the tooltip all the time, even if the anchor element would otherwise be out of view.
    force = 'force',
}

export interface TooltipProps extends React.Props<ToolTipComponent> {
    // The content of the tooltip to display. If this is false-y, no tooltip is rendered.
    content: any;

    // The dom element to use for the tooltip wrapper.
    wrapperElementIsDiv: boolean;

    // Defines the conditions where the tooltip is displayed.
    displayMode: DisplayMode;

    className?: string;

    // className to apply to the outer div of the anchor wrapper
    wrapperClassName?: string;

    // When true, the tooltip anchor wrapper will have display: inline-block instead of block.
    isInline?: boolean;

    // When true, preserves any whitespace (e.g. consecutive spaces, newlines) in the content.
    preserveWhitespace?: boolean;

    // One of the placements allowed by the popper library.
    // https://popper.js.org/popper-documentation.html#Popper.placements
    placement: Placement;

    // Pass in any of the modifiers listed here https://popper.js.org/popper-documentation.html#modifiers
    // Useful to handle very specific cases.
    popperModifiers?: any;
    // Theme to apply.
    theme?: string;
}

interface TooltipState {
    isTooltipVisible?: boolean;
    isMouseOverAnchor?: boolean;
}

class ToolTipComponent extends React.Component<TooltipProps, TooltipState> {
    static defaultProps = {
        className: '',
        displayMode: DisplayMode.default,
        placement: 'bottom',
        wrapperElementIsDiv: true,
        preserveWhitespace: false,
        wrapperClassName: '',
        theme: '',
    };

    portalContainer: Element;
    debugId: any;
    nativeMouseLeaveRegistered: boolean;
    tooltipAnchorWrapper: any;
    tooltipWrapper: any;

    constructor(props) {
        super(props);

        let portalContainer = document.querySelector('#tooltips-portal');
        if (!portalContainer) {
            portalContainer = document.createElement('div');
            document.body.appendChild(portalContainer);
        }
        this.portalContainer = portalContainer;
        // Note: this tooltipId (and the related helper methods) aren't actually necessary for correct
        // tooltip operations -- but they are really useful when investigating tooltips, since it allows
        // a reader to associate the "anchor" with the "tooltip", since they are not related in the DOM
        // structure.
        this.debugId = uuidV4();

        this.onWrapperMouseEnter = this.onWrapperMouseEnter.bind(this);
        this.onWrapperMouseLeave = this.onWrapperMouseLeave.bind(this);
        this.watchForNativeMouseLeave = this.watchForNativeMouseLeave.bind(
            this
        );

        this.nativeMouseLeaveRegistered = false;

        this.state = {
            isTooltipVisible: ToolTipComponent.shouldAlwaysDisplayTooltip(
                props.displayMode
            ),
            isMouseOverAnchor: false,
        };

        // Refs
        this.tooltipAnchorWrapper = React.createRef();
    }

    /**
     * Searches for an oveflowing child Element inside the startingElement tree.
     * @param  {Element} startingElement The Element that may have an oveflowing element.
     * @return {Element}                 Returns an element with overflowing content if found or null if there isn't any.
     */
    getOverflowingElement = (startingElement) => {
        const queue = [startingElement];
        while (queue.length) {
            const element = queue.shift();
            if (isOverflowingX(element)) {
                return element;
            }
            queue.push(...Array.from(element.children));
        }
        return null;
    };

    onWrapperMouseEnter() {
        const { displayMode } = this.props;
        const rootEl: any = this.tooltipAnchorWrapper.current;
        const visibleState = {
            isTooltipVisible: true,
            isMouseOverAnchor: true,
        };

        // We're not going to calculate whether we should show overflow tooltips until we get the hover event.
        // This prevents us from doing the client size calculations for every tooltip element on load.
        if (displayMode === DisplayMode.overflow) {
            const overflowingElement = this.getOverflowingElement(rootEl);
            if (overflowingElement) {
                this.setState(visibleState);
            }
        } else if (displayMode === DisplayMode.multiLineOverflow) {
            // The goal here is to determine if there is a child `MultilineText`
            // component which has clamped (aka hidden) some of its contents.
            // Unfortunately, pulling this info out of these child components
            // is not feasible, as it's only stored in state. As such, we'll take
            // a "straightforward but brittle approach": looking for CSS classes.
            // This is hardly ideal, but it's the best we can do.

            // As a hedge against changes to `MultilineText`, we'll first try to
            // detect its base class, `.LinesEllipsis`. If this class doesn't exist,
            // it means that either (1) the component has been updated such that this
            // detection logic is no longer valid, or (2) there aren't actually any

            // Alternatively, we can measure the scroll height vs. actual height of an element. This works in cases where we are using the line-clamp css rule for native multi-line clamping
            if (rootEl.querySelector('.LinesEllipsis.LinesEllipsis--clamped')) {
                this.setState(visibleState);
            }

            if (this.tooltipAnchorWrapper?.current && rootEl) {
                const {
                    scrollHeight,
                    clientHeight,
                } = this.tooltipAnchorWrapper.current;

                if (scrollHeight > clientHeight) {
                    this.setState(visibleState);
                }
            }
        } else {
            this.setState(visibleState);
        }
    }

    onWrapperMouseLeave() {
        const nextState: TooltipState = { isMouseOverAnchor: false };
        if (
            !ToolTipComponent.shouldAlwaysDisplayTooltip(this.props.displayMode)
        ) {
            nextState.isTooltipVisible = false;
        }
        this.setState(nextState);
    }

    static shouldAlwaysDisplayTooltip(displayMode) {
        return (
            displayMode === DisplayMode.always ||
            displayMode === DisplayMode.force
        );
    }

    // https://stackoverflow.com/a/38620042/203002
    //
    // onMouseLeave doesn't work well on disabled elements
    // https://github.com/facebook/react/issues/4251
    watchForNativeMouseLeave() {
        if (!this.tooltipWrapper || this.nativeMouseLeaveRegistered) {
            return;
        }

        this.tooltipWrapper.addEventListener(
            'mouseleave',
            this.onWrapperMouseLeave
        );
        this.nativeMouseLeaveRegistered = true;
    }

    componentDidMount() {
        this.watchForNativeMouseLeave();
    }

    componentWillUnmount() {
        if (
            this.tooltipAnchorWrapper.current &&
            this.nativeMouseLeaveRegistered
        ) {
            this.tooltipAnchorWrapper.current.removeEventListener(
                'mouseleave',
                this.onWrapperMouseLeave
            );
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const isTooltipVisible =
            ToolTipComponent.shouldAlwaysDisplayTooltip(
                nextProps.displayMode
            ) || prevState.isMouseOverAnchor;
        if (prevState.isTooltipVisible !== isTooltipVisible) {
            return { isTooltipVisible };
        } else {
            return null;
        }
    }

    render() {
        const {
            wrapperClassName,
            children,
            content,
            className,
            isInline,
            preserveWhitespace,
            placement,
            popperModifiers,
            wrapperElementIsDiv,
            theme,
        } = this.props;

        const tooltipClasses = [className, 'tooltip'];

        if (preserveWhitespace) {
            tooltipClasses.push('tooltip--preserve-whitespace');
        }

        if (theme) {
            tooltipClasses.push(themeClassName(theme));
        }

        let anchorClassName = 'tooltip__anchor';
        if (isInline) {
            anchorClassName += ` ${anchorClassName}--inline`;
        }

        const ToolTipWrapper = wrapperElementIsDiv ? 'div' : 'span';

        return (
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <ToolTipWrapper
                            ref={ref}
                            className={`${wrapperClassName} ${anchorClassName}`}
                            onMouseEnter={this.onWrapperMouseEnter}
                            onMouseLeave={this.onWrapperMouseLeave}
                            data-debug-id={this.debugId}
                        >
                            {/* Second wrapper is needed to be able to use a custom ref. */}
                            <ToolTipWrapper
                                className={anchorClassName}
                                ref={this.tooltipAnchorWrapper}
                            >
                                {children}
                            </ToolTipWrapper>
                        </ToolTipWrapper>
                    )}
                </Reference>
                {this.state.isTooltipVisible &&
                    content &&
                    ReactDOM.createPortal(
                        <Popper
                            placement={placement}
                            modifiers={popperModifiers}
                        >
                            {({
                                ref,
                                style,
                                placement,
                                arrowProps,
                                scheduleUpdate,
                                outOfBoundaries,
                            }) => (
                                <ToolTipPopper
                                    reference={ref}
                                    style={style}
                                    placement={placement}
                                    arrowProps={arrowProps}
                                    outOfBoundaries={outOfBoundaries}
                                    scheduleUpdate={scheduleUpdate}
                                    tooltipClassName={tooltipClasses.join(' ')}
                                    content={content}
                                    debugId={this.debugId}
                                />
                            )}
                        </Popper>,
                        this.portalContainer
                    )}
            </Manager>
        );
    }
}

export default ToolTipComponent;
