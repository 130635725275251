import PropTypes from 'prop-types';
import React from 'react';
import {
    BaseFieldProps,
    Field,
    Validator,
    WrappedFieldInputProps,
    WrappedFieldMetaProps,
} from 'redux-form';

export type WrappedTtdFieldProps<T> = {
    input: Omit<WrappedFieldInputProps, 'value'> & {
        value: T;
    };
    meta: WrappedFieldMetaProps;
};

export type UiFieldModel = {
    name: string;
    isRequired?: boolean;
};

export interface IUiModel<T> {
    $displayNameKey: keyof T;
    $primaryKey: keyof T;
}

export type TtdField = string | UiFieldModel;

export interface TtdInputFieldProps
    extends Omit<BaseFieldProps, 'name' | 'validate'> {
    field: TtdField;
    validate?: Validator;
    [key: string]: any;
}

export type StrippedFieldProps =
    // component and name are implemented by usage of TtdInputField
    | 'component'
    | 'name'
    // onDrop and onDragStart just aren't going to be supported unless explicitely by the author
    | 'onDragStart'
    | 'onDrop'
    // onFocus and onBlur get swallowed by Field
    | 'onFocus'
    | 'onBlur'
    | 'validate';

export type TtdInputFieldComponentProps<
    ComponentProps extends {}
> = ComponentProps &
    Omit<BaseFieldProps, StrippedFieldProps> & {
        field: TtdField;
        validate?: Validator;
    };

export interface TtdInputFieldExtensionProps
    extends Omit<BaseFieldProps, StrippedFieldProps> {
    field: TtdField;
    validate?: Validator;
}

export const getFieldName = (
    field: TtdField,
    ...childFieldNames: TtdField[]
): string => {
    if (!field && (!childFieldNames || !childFieldNames.length)) {
        throw new Error('A field must be provided');
    } else if (!field) {
        return childFieldNames.map((f) => getFieldName(f)).join('.');
    }
    const fieldName = typeof field === 'string' ? field : field.name;
    if (childFieldNames && childFieldNames.length) {
        return `${fieldName}.${childFieldNames
            .map((f) => getFieldName(f))
            .join('.')}`;
    }
    return fieldName;
};

export const getFieldNameInList = (
    field: TtdField,
    position: number,
    ...childFieldNames: TtdField[]
) => {
    if (!field || position === undefined || position === null) {
        throw new Error('A field and position must be provided');
    }
    const fieldName = `${
        typeof field === 'string' ? field : field.name
    }[${position}]`;
    return childFieldNames && childFieldNames.length
        ? getFieldName(fieldName, ...childFieldNames)
        : fieldName;
};

export default class TtdInputField extends React.Component<TtdInputFieldProps> {
    static propTypes = {
        // Definition for a field.
        field: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                isRequired: PropTypes.bool,
            }),
        ]).isRequired,
    };

    validate: Validator = (value, allValues, props, name) => {
        const { validate } = this.props;
        return validate && validate(value, allValues, props, name);
    };

    render() {
        const { field, validate, ...rest } = this.props;

        return (
            <Field
                name={(field as UiFieldModel).name || (field as string)}
                validate={this.validate}
                field={field}
                {...rest}
            />
        );
    }
}
