import React from 'react';
import DialogCore, { DIALOG_OVERLAY_MODE } from '../DialogCore';
import DialogHeader from '../DialogHeader';
import { autoFocus } from '../../../../util/AutoFocus';
import themeClassName from '../../../../util/Themes';
import './Panel.scss';

export interface PanelProps extends React.Props<PanelComponent> {
    dialogId: string;
    title?: string | JSX.Element;
    subtitle?: string;
    isSubtitleUnderneath?: boolean;
    className?: string;
    onCancel?: (dialogId: string) => void;
    // From connected container
    hideDialog: (dialogId: string) => void;
    // Optional theme
    theme?: string;
    // An optional callback to call when the dialog opens.
    // The function is called with a single parameter, the dialog ID.
    onOpen?: (dialogId: string) => void;
}

export default class PanelComponent extends React.Component<PanelProps, {}> {
    static defaultProps = {
        className: '',
        theme: '',
    };

    panelContent: any;

    componentDidUpdate() {
        if (this.panelContent) {
            autoFocus(this.panelContent);
        }
    }

    render() {
        const {
            dialogId,
            className,
            title,
            subtitle,
            isSubtitleUnderneath,
            children,
            onCancel,
            hideDialog,
            theme,
            ...rest
        } = this.props;

        // By default, we'll hide the panel when the cancel action happens (the close 'X').
        // If the client passes an onCancel, they are in charge of hiding the panel when they see fit (e.g., after
        // confirming the intention of the user).
        const wrappedOnCancel = (dialogId) => {
            if (onCancel) {
                onCancel(dialogId);
            } else {
                hideDialog(dialogId);
            }
        };

        return (
            <DialogCore
                dialogId={dialogId}
                className={`panel ${className} ${themeClassName(theme)}`}
                onCancel={wrappedOnCancel}
                {...rest}
                escapeToCancel
                clickAwayToCancel
                overlayMode={DIALOG_OVERLAY_MODE.DARK}
            >
                <DialogHeader
                    dialogId={dialogId}
                    title={title}
                    subtitle={subtitle}
                    isSubtitleUnderneath={isSubtitleUnderneath}
                    isLight
                    theme='panel-header'
                    onCancel={wrappedOnCancel}
                />
                <div
                    ref={(e) => {
                        this.panelContent = e;
                    }}
                    className='panel__content'
                >
                    {children}
                </div>
            </DialogCore>
        );
    }
}
