import { CSSProperties, MouseEvent, ReactNode } from 'react';

export type GetPrimaryKey<TItem> = (item: TItem) => string | number;
export type DataViewItemPrimaryKey<TItem> = ReturnType<GetPrimaryKey<TItem>>;

export type DataViewItemsData<TItemData> = { [key: string]: TItemData };

export type DataViewProps<TItem, TDataViewData = {}, TItemData = {}> = {
    /**
     * Unique ID for this data view (across the application)
     */
    id: string;

    /**
     * Apply CSS class to data view.
     */
    className?: string;
    /**
     * How should the data view look? Use one of the predefined themes.
     */
    theme?: string;
    /**
     * Apply inline style to data view.
     */
    style?: CSSProperties;
    /**
     * Height of the "body" of the view, including only non-fixed items (eg. excluding headers, footers, etc.).
     */
    bodyHeight?: {
        /**
         * Height of the body in pixels
         */
        heightInPixels?: number;

        /**
         * If true, the view will always take up the maximum height. If false, it will shrink
         * to the minimum view size when empty and grow as items are added.
         */
        isFixed?: boolean;
    };

    /**
     * Optional element content to render when there are no rows.
     */
    emptyContent?: ReactNode;

    /**
     * An arbitrary set of data for the view, changing this will cause the view
     * to re-render.  Example of this would be currency-code, which needs to update all
     * the money display in the view.
     */
    viewData?: TDataViewData;

    /**
     * The data to be display in the list.
     */
    items: TItem[];

    /**
     * A function that returns the primary key for a given
     * item. This value should never change - if you need
     * to change the value of this, generate a new ID that
     * isn't dependent on things that can change.
     * object -> string
     */
    getPrimaryKey: GetPrimaryKey<TItem>;

    /**
     * Optional element content to render when there are no items but you still want to show the header, etc.
     */
    emptyItemsContent?: ReactNode;

    /**
     * Optional CSS class to be applied to an item
     */
    itemClassName?:
        | string
        | ((
              item: TItem,
              itemIndex: number,
              itemData: TItemData,
              viewData: TDataViewData
          ) => string);

    /**
     * A map between an item's ID and an arbitrary set of data
     * to be consumed by other item-specific functions.
     * For instance, you could store items visibility or expansion
     * in this property.
     */
    itemsData?: DataViewItemsData<TItemData>;

    /**
     * Optional function to handle actions when the user clicks on an item
     *
     * @param {TItem} item Item that was clicked on
     * @param {TItemData} itemData Item data
     * @param {MouseEvent<HTMLElement>} event Event fired
     */
    onItemClick?(
        item: TItem,
        itemIndex: number,
        itemData: TItemData,
        viewData: TDataViewData,
        event: MouseEvent<HTMLElement>
    ): void;

    /**
     * Should be a PURE function, where the output is determined only by the inputs.
     */
    getItemHeightPixels?:
        | number
        | ((
              item: TItem,
              itemData?: TItemData,
              dataViewData?: TDataViewData
          ) => number);

    /**
     * Used to "disable" items. A result of `true` will apply an `isDisabled` prop to the item.
     * Should be a PURE function, where the output is determined only by the inputs
     *
     * @param item Item
     * @param itemData Item data
     * @param dataViewData The view's data
     */
    getIsItemDisabled?(
        item: TItem,
        itemData?: TItemData,
        dataViewData?: TDataViewData
    ): boolean;

    /**
     * Required for "filtering" items client-side while still
     * keeping all of the data in memory.
     *
     * Should be a PURE function, where the output is determined only by the inputs.
     *
     * @param item Item
     * @param itemData Item data
     * @param dataViewData The view's data
     */
    getIsItemVisible?(
        item: TItem,
        itemData?: TItemData,
        dataViewData?: TDataViewData
    ): boolean;

    /**
     * Allow the consumer to interact with how items are rendered.
     * This could be used to integrate with redux-form.
     *
     * Usage:
     * renderItems: (items, renderItem, viewData) => {items.map((item,index) => <div className="foo">{renderItem(item, index)}</div>)}
     *
     * And this would cause each item in the view to be surrounded
     * a div with a "foo" CSS class.
     *
     * @param {TItem[]} items Items being rendered
     * @param {(item: TItem, index: number) => ReactNode} renderItem The method which will render the item. This must be called.
     * @param {TDataViewData} dataViewData The view's data
     * @returns {(ReactNode[] | void)}
     */
    renderItems?(
        items: TItem[],
        renderItem: (item: TItem, index: number) => ReactNode,
        dataViewData?: TDataViewData
    ): ReactNode[] | void;
};

export type DataViewField<TItem, TDataViewData = {}> = {
    /**
     * The ID of the field
     */
    id: string;
    /**
     * The field's user-friendly name
     */
    header: ReactNode;
    /**
     * Indicates if the field is sortable
     */
    isSortable?: boolean;
    /**
     * Sort comparator
     *
     * (a, b, tableData, direction) => return 1 if a is greater than b, -1 if less, 0 if equal. (Two additional params allow us to have more info when sorting)
     */
    sortComparator?(
        item1: TItem,
        item2: TItem,
        tableData: TDataViewData,
        direction: SORT_DIRECTION
    ): number;
    /**
     * Indicates if the field is visible or not (always default to true unless specifically set to false or return false)
     */
    isVisible?: boolean | ((tableData: TDataViewData) => boolean);

    /** Indicates whether the field is selected for display or not - always default to true unless explicitly set to false
     *  Note - isVisible field always take precedence over this field
     */
    isSelectedForDisplay?: boolean;
};

export enum SORT_DIRECTION {
    ASCENDING = 'Asc',
    DESCENDING = 'Desc',
}

export type DataViewSortInformation<TItem> = {
    /**
     * The sort direction
     */
    direction: SORT_DIRECTION;

    /**
     * ID of the field against which a sort should be performed
     */
    fieldId: string;

    /**
     * Called when the sort action was clicked on.
     */
    onChangeSort?: (fieldId: string, newSortDirection: SORT_DIRECTION) => void;

    /**
     * Optional function that allows the consumer to filter items so they are not part of the sorting process.
     */
    onBeforeSort?: (fieldId: string, items: TItem[]) => TItem[];

    /**
     * Optional function that allows the consumer to re-add items to the collection after sorting is finished.
     */
    onAfterSort?: (fieldId: string, items: TItem[]) => TItem[];

    /**
     * Optional boolean which indicates if all the items (initial and newly added) should be sorted.
     * By default only the initial items are sorted and not the newly added ones.
     */
    sortAllItems?: boolean;

    /**
     * Indicates if the items are pre-sorted
     */
    itemsArePreSorted?: boolean;
};

export enum ADDED_ITEMS_LOCATION {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export type DataViewLoadFilterAndSortProps<TItem, TDataViewData = {}> = {
    /**
     * Definition for fields of the view.
     * Used to identify sorting information.
     */
    fields: DataViewField<TItem, TDataViewData>[];

    /**
     * The sorting information for the view
     */
    sorting?: DataViewSortInformation<TItem>;

    /**
     * This function will be called when the user has requested more data.
     */
    onReadyForMoreData?: () => void;

    /**
     * When set to true, a filter is in the process of being applied to the view.
     */
    isFiltering?: boolean;

    /**
     * When set to true, the view is retrieving more data (whether it's a new set of data, or a new
     * page; indicates that it's actively loading)
     */
    isRetrievingMoreData?: boolean;

    /**
     * When set to true, the view is retrieving a new page of data (i.e. after clicking "load more")
     */
    isRetrievingNewPage?: boolean;

    /**
     * When set to true, more data is available.
     */
    isMoreDataAvailable?: boolean;

    /**
     * When new items are added to the view, do they go to the top of bottom.
     *
     * Default top.
     */
    addedItemsLocation?: ADDED_ITEMS_LOCATION;

    /**
     * A map of primary keys, whose existence indicates that it was an item
     * that was part of the initialization of the view.  This is used for
     * determining whether to put newly added items at the top of the view.
     */
    initialItems?: { readonly [PrimaryKey: string]: boolean };
};
