import React, { PureComponent } from 'react';
import './PercentBar.scss';

export type colors =
    | 'primary-1'
    | 'primary-2'
    | 'primary-3'
    | 'primary-4'
    | 'primary-5'
    | 'primary-6'
    | 'primary-7'
    | 'primary-8';

interface PercentBarProps {
    value?: number;
    total?: number;
    /**  decimal value inclusively between the range of 0 and 1 */
    percentValue?: number;
    // uses primary-{n} as valid values
    color?: colors;
    backgroundColor?: colors;
    className?: string;
    style?: React.CSSProperties;
}

interface PercentBarState {
    smoothTransition: boolean;
    percent: number;
}

export default class PercentBarComponent extends PureComponent<
    PercentBarProps,
    PercentBarState
> {
    public static defaultProps = {
        className: '',
        color: 'primary-1',
    };

    state = {
        smoothTransition: true,
        percent: 0,
    };

    static getDerivedStateFromProps(
        nextProps: PercentBarProps,
        prevState: PercentBarState
    ) {
        const { percentValue, total, value } = nextProps;

        let percent = percentValue;
        if (typeof percentValue === 'undefined' && total > 0) {
            percent = value / total;
        }
        percent *= 100;
        if (prevState.percent !== percent) {
            const smoothTransition = prevState.percent < percent;
            return {
                smoothTransition,
                percent,
            };
        }
        return null;
    }

    render() {
        const { color, backgroundColor, className, style } = this.props;

        const { smoothTransition, percent } = this.state;

        const meterClassName = `percent-bar__meter percent-bar__meter--${color}`;
        const meterStyle = {
            width: `${percent}%`,
        };

        const classes = ['percent-bar', className];

        if (backgroundColor) {
            classes.push(`percent-bar__background--${backgroundColor}`);
        }

        if (smoothTransition) {
            classes.push('percent-bar--animated');
        }

        return (
            <div className={classes.join(' ')} style={style}>
                <span className={meterClassName} style={meterStyle} />
            </div>
        );
    }
}
