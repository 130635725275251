import React from 'react';
import DialogCore, { DIALOG_OVERLAY_MODE } from '../DialogCore';
import { throttledResizeSource } from '../../../../util/EventSubscriptions';
import PositionService from '../../../../util/PositionService';
import './BasicDialog.scss';

// Dialog sizes -- not every type of dialog supports every size; check
// prop types on the dialogs for the supported sizes.
export enum DIALOG_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    EXTRA_LARGE = 'extra-large',
}

// List of Dialog sizes that should be draggable
export const draggableDialogSizes = [DIALOG_SIZE.SMALL, DIALOG_SIZE.MEDIUM];

// List of Dialog sizes that expand to fill the maximum window height available
export const maxHeightDialogSizes = [
    DIALOG_SIZE.LARGE,
    DIALOG_SIZE.EXTRA_LARGE,
];

export interface BasicDialogProps extends React.Props<BasicDialog> {
    // All props not shown here are passed through to the DialogCore component.

    // All dialogs require a globally unique ID.  It is the parameter to pass to
    // dialog actions (showDialog, hideDialog, etc.).
    dialogId: string;

    // The size of the dialog.
    size: DIALOG_SIZE;

    // Optional property for dialogs of size SMALL or MEDIUM to define the maximum expected height of the dialog.
    // This is used to determine the vertical positioning of dialogs that don't expand to the full window height,
    // and is useful in situations where we don't know the exact height of the dialog content when it loads, likely
    // because the dialog content is loading asynchronously
    maxDialogHeight?: number;

    // An optional className to add to the root element in this component.
    className?: string;

    // Optional theme to apply to this component (see SCSS file for available themes or add a new one).
    // e.g., 'first-run-wizard' is a theme.
    theme?: string;

    // An optional callback, called when the user cancels the dialog (either via
    // the 'cancel' button or closing the dialog via the X or hitting the escape key).
    onCancel?: (dialogId: string) => void;

    // An optional callback, called when the dialog opens.
    onOpen?: (dialogId: string) => void;

    // An optional callback, called when the dialog closes.
    onClose?: (dialogId: string) => void;

    escapeToCancel?: boolean;

    height?: number;

    onEnterKeyHit?: () => void;

    clickAwayToCancel?: boolean;
}

interface BasicDialogState {
    isDragged: boolean;
}

// BasicDialog is the base component for the three main dialog types (message,
// task and stepped dialogs).  It supports different sizes and auto centering (except
// for when the dialog has been dragged by the user).
export default class BasicDialog extends React.Component<
    BasicDialogProps,
    BasicDialogState
> {
    static defaultProps = {
        className: '',
        theme: '',
    };

    static placementOffset = 32;

    dialogElement: any;
    resizeSubscription: any;

    constructor(props) {
        super(props);

        this.state = {
            isDragged: false,
        };

        this.getDialogRef = this.getDialogRef.bind(this);
        this.onDragStart = this.onDragStart.bind(this);

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onOpen(dialogId) {
        this.setState({ isDragged: false });
        this.addResizeHandler();
        this.autoPositionDialog();

        if (this.props.onOpen) {
            this.props.onOpen(dialogId);
        }
    }

    onClose(dialogId) {
        this.removeResizeHandler();
        if (this.props.onClose) {
            this.props.onClose(dialogId);
        }
    }

    componentWillUnmount() {
        this.removeResizeHandler();
    }

    getDialogRef(dialogElement) {
        this.dialogElement = dialogElement;
    }

    autoPositionDialog() {
        // Center the dialog vertically and horizontally.
        // Small and medium dialogs have a minimum height and are vertically centered within the full window.
        // Large and extra large dialogs expand to the full height of the window with a set distance from the
        // top and bottom (currently 32px).
        PositionService.centerElement(
            this.dialogElement,
            window,
            BasicDialog.placementOffset,
            [DIALOG_SIZE.LARGE, DIALOG_SIZE.EXTRA_LARGE].includes(
                this.props.size
            ),
            [DIALOG_SIZE.SMALL, DIALOG_SIZE.MEDIUM].includes(this.props.size)
                ? this.props.maxDialogHeight
                : undefined
        );
    }

    addResizeHandler() {
        this.resizeSubscription = throttledResizeSource.subscribe(() => {
            if (!this.state.isDragged) {
                this.autoPositionDialog();
            }
        });
    }

    removeResizeHandler() {
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
            this.resizeSubscription = null;
        }
    }

    onDragStart(e, draggableData) {
        if (e.currentTarget.id === this.dialogElement.id) {
            this.setState({ isDragged: true });
        }
    }

    render() {
        const {
            dialogId,
            size,
            className,
            theme,
            children,
            onCancel,
            onOpen,
            onClose,
            ...rest
        } = this.props;
        return (
            <DialogCore
                dialogId={dialogId}
                className={`basic-dialog basic-dialog--${size} ${className}`}
                theme={theme}
                onCancel={onCancel}
                getDialogRef={this.getDialogRef}
                overlayMode={DIALOG_OVERLAY_MODE.DARK}
                isDraggable={draggableDialogSizes.includes(size)}
                onDragStart={this.onDragStart}
                onOpen={this.onOpen}
                onClose={this.onClose}
                {...rest}
            >
                {children}
            </DialogCore>
        );
    }
}
