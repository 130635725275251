﻿import React from 'react';
// '<Glyph>' is deprecated in favor of direct SVG imports
import Glyph from '../../Glyph';
import ToolTip, { DisplayMode } from '../../ToolTip';
import themeClassName from '../../../../util/Themes';

import './DialogHeader.scss';

export interface DialogHeaderProps {
    dialogId: string;
    className?: string;
    theme?: string;
    title?: string | JSX.Element;
    subtitle?: string;
    isSubtitleUnderneath?: boolean;
    isLight?: boolean;
    onCancel: (dialogId: string) => void;
}

export default class DialogHeaderComponent extends React.Component<
    DialogHeaderProps,
    {}
> {
    public static defaultProps = {
        className: '',
        theme: '',
    };

    render() {
        const {
            dialogId,
            className,
            theme,
            title,
            subtitle,
            isSubtitleUnderneath,
            isLight,
            onCancel,
        } = this.props;

        return (
            <div
                className={`dialog-drag-handle--${dialogId} dialog-header ${className} ${
                    isSubtitleUnderneath
                        ? 'dialog-header--subtitle-underneath'
                        : ''
                } ${themeClassName(theme)} ${
                    isLight ? 'dialog-header--light' : ''
                }`}
            >
                <ToolTip
                    displayMode={DisplayMode.overflow}
                    wrapperClassName='dialog-header__tooltip-anchor'
                    className='dialog-header-tooltip'
                    content={
                        <>
                            <span className='dialog-header-tooltip__title'>
                                {title}
                            </span>
                            <span className='dialog-header-tooltip__subtitle'>
                                {subtitle}
                            </span>
                        </>
                    }
                >
                    <div className='dialog-header__titles'>
                        <span className='dialog-header__title'>{title}</span>
                        <span className='dialog-header__subtitle'>
                            {subtitle}
                        </span>
                    </div>
                </ToolTip>
                <Glyph
                    name='close'
                    className='dialog-header__close'
                    onClick={() => onCancel(dialogId)}
                />
            </div>
        );
    }
}
