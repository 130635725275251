import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import keycode from 'keycode';

export default function useDropDownState(
    modalRef
): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        const portalRoot = document.querySelector('#portal-drop-down-div');
        const onClick = (event) => {
            // should we close?
            if (
                modalRef.current.contains(event.target) ||
                portalRoot.contains(event.target)
            ) {
                return;
            }
            setIsOpen(false);
        };
        document.addEventListener('click', onClick);

        const onKeyPress = (event) => {
            switch (keycode(event)) {
                case 'esc':
                    setIsOpen(false);
                    break;
            }
        };
        document.addEventListener('keydown', onKeyPress);

        return () => {
            document.removeEventListener('click', onClick);
            document.removeEventListener('keydown', onKeyPress);
        };
    }, [isOpen, modalRef]);

    return [isOpen, setIsOpen];
}
