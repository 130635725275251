import React, { Component } from 'react';
import { createSelector } from 'reselect';
import DropDownMenu from 'components/display/DropDownMenu';
import DropDownMenuItem from 'components/display/DropDownMenuItem';
import ToolTip, { DisplayMode } from 'components/display/ToolTip';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import selectList, {
    SelectListProps,
    isHeader,
    HeaderItem,
} from 'components/hocs/selectList';
import { Localization } from 'util/LocalizationService';
import Strings from './SelectListControl.strings.json';
import Measure from 'react-measure';
import themeClassName from 'util/Themes';
import withIsExportMode, {
    InjectedWithIsExportMode,
} from 'components/hocs/withIsExportMode';
import withReadOnly from 'components/hocs/withReadOnly';
import './SelectListControl.scss';

export interface SelectListControlProps<T>
    extends Omit<SelectListProps<T>, 'onKeyCombo' | 'color'>,
        InjectedWithIsExportMode {
    /**
     * Optional theme property
     */
    theme?: string;

    /**
     * function called when the value of the list has changed.
     */
    onChange(value: T): void;
    /**
     * the selected item
     */
    value: T;
    /**
     * set to false to not allow the user to deselect an item.
     */
    allowEmptySelection?: boolean;
    /**
     * Set to true to force clear the search text after selecting the empty option
     */
    clearSearchTextOnEmptySelection?: boolean;

    /**
     * optional function that modify the select-list items prior to rendering
     */
    customItemMapper?(item: T, index: number): string | number | object;

    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/

    isReadOnly?: boolean;

    /**
     * Optional placeholder string to customize the search bar text when empty
     */
    searchPlaceholder?: string;

    /* whether to display the summary with the skittle count instead of normal label
     */
    displayLabelSkittle?: boolean;

    /**
     * function for defining how to display the control as readonly when isReadOnly is true
     */
    renderReadOnly?(
        item: T[],
        displayNameKey?: keyof T,
        itemType?: any
    ): string | JSX.Element;

    // Text to display for the 'None' item (defaults to 'None')
    emptyItemText?: string;

    // If set to true the component will set a fixed width based upon the text of the available items.
    setWidthBasedOnLongestAvailableItem?: boolean;

    /*
     * Indicate whether the drop-down is to open align left, right, or default
     */
    alignment?: 'left' | 'right' | 'none';
    // Additional properties are passed through to the selectInput HOC (components/hocs/selectList), see that component for details
    showSearch?: boolean;

    captureAllKeyPress?: boolean;
    suppressGlyph?: boolean;

    /* In order to support analytics, tihs function can be used to generate id attributes for each menu item */
    generateIdAttribute?: (
        item: T,
        dropdownMenuId: string,
        index: number
    ) => string;
}
class SelectListControl<T> extends Component<SelectListControlProps<T>> {
    static defaultProps = {
        isEnabled: true,
        availableItems: [],
        allowEmptySelection: true,
        clearSearchTextOnEmptySelection: false,
        searchPlaceholder: Localization.getString(Strings.search),
        className: '',
        style: {},
    };

    onChange(item, event: Event, callback) {
        if (item.isDisabled || isHeader(item)) {
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        callback(item, event);
        event.stopPropagation();
    }

    onClickEmptySelection = (event: Event, callback) => {
        if (this.props.clearSearchTextOnEmptySelection) {
            // pass `null` event to `clearSearch` so closing the dropdown is not prevented
            this.props.clearSearch(null, false);
        }

        callback(null, event);
        event.stopPropagation();
    };

    customItemMapper: any = (
        item: T,
        index: number,
        thisObj: string[] | number[] | object[]
    ) => {
        return ((this.props.customItemMapper &&
            this.props.customItemMapper(item, index)) ||
            item) as string | number | object;
    };

    getLabelDisplay(selectedValue, itemName) {
        return (
            (selectedValue && (
                <div>
                    <div className='header'>{itemName}</div>
                    <div className='skittle'>1</div>
                </div>
            )) ||
            itemName
        );
    }

    captureAllKeyPress = (event: KeyboardEvent) => {
        if (this.props.captureAllKeyPress && this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    itemSelectorByPk = null;
    getSelectedItemByPk = (value: T, allItems: T[], primaryKey: string): T => {
        this.itemSelectorByPk =
            this.itemSelectorByPk ||
            createSelector(
                (v, all, pk) => v[pk],
                (v, all, pk) => all,
                (keyVal, all: T[]) =>
                    all.find((item) => (item && item[primaryKey]) === keyVal) ||
                    value
            );

        return this.itemSelectorByPk(value, allItems, primaryKey);
    };

    renderSelectList = ({ measureRef, contentRect }: any) => {
        const {
            id,
            allowEmptySelection,
            onChange,
            isReadOnly,
            isExportMode,
            alignment,
            searchPlaceholder,

            // these should be passed in by the selectInput HOC (components/hocs/selectList), see that component for details
            showSearch,
            onSearchInputKeyDown,
            clearSearch,
            searchText,
            onLabelMouseEnter,
            onLabelMouseLeave,
            onLabelClick,
            onToggle,
            getItemDisplay,
            getItemTooltip,
            tooltipDisplayMode,
            renderReadOnly,
            emptyItemText,
            staticLastMenuItems,

            // the following props are defined within the selectInput HOC
            availableItems,
            isRetrievingData,
            primaryKey,
            displayNameKey,
            tooltipNameKey,
            setWidthBasedOnLongestAvailableItem,
            emptyText,
            className,
            style,
            isEnabled = true,
            itemType,
            itemName,
            doNotUseItemName,
            onNeedData,
            embedInPortal,
            displayLabelSkittle,
            onFocus,
            theme,
            onBlur,
            suppressGlyph,
            generateIdAttribute,
        } = this.props;

        let value = this.props.value;

        if (
            setWidthBasedOnLongestAvailableItem &&
            availableItems &&
            availableItems.length &&
            !contentRect.bounds.width
        ) {
            return (
                <span
                    className='select-list__auto-measure-div'
                    ref={measureRef}
                >
                    {
                        // find longest text and render it
                        availableItems
                            .map((i) => (i[displayNameKey] || '') as string)
                            .reduce((a, b) => (a.length > b.length ? a : b), '')
                    }
                </span>
            );
        }

        const selectedPropValue =
            value && value[primaryKey || (itemType && itemType.$primaryKey)];

        if (
            selectedPropValue &&
            getItemDisplay(
                value,
                displayNameKey,
                itemType,
                true /*isSelected*/,
                false /*inMenu*/
            ) === null
        ) {
            // if pk exists but display name is (possibly intentionally) null, then find the item in availableItems by pk
            value = this.getSelectedItemByPk(
                value,
                availableItems,
                primaryKey || (itemType && itemType.$primaryKey)
            );
        }

        if (isReadOnly || isExportMode) {
            return renderReadOnly([value], displayNameKey, itemType);
        }

        let labelValue;
        if (showSearch) {
            labelValue = (
                <span className='select-list__search'>
                    <input
                        value={searchText}
                        type='text'
                        placeholder={searchPlaceholder}
                        onChange={onNeedData}
                        onClick={(e) => e.preventDefault()}
                        onKeyDown={onSearchInputKeyDown}
                    />
                    <Glyph name='close' onClick={clearSearch} />
                </span>
            );
        } else {
            const tooltip =
                value && getItemTooltip(value, tooltipNameKey, itemType);
            const selectionText = doNotUseItemName
                ? Localization.getString(
                      Strings.selectionInstructionsNoItemName
                  )
                : Localization.getString(
                      Strings.selectionInstructions,
                      Object.assign({ itemName })
                  );
            const toolTipContent =
                (displayLabelSkittle &&
                    this.getLabelDisplay(value, itemName)) ||
                (value &&
                    (tooltip ||
                        getItemDisplay(
                            value,
                            displayNameKey,
                            itemType,
                            true /*isSelected*/,
                            false /*inMenu*/
                        )));
            labelValue = (
                <ToolTip
                    content={toolTipContent}
                    displayMode={
                        tooltipDisplayMode ||
                        (tooltip ? DisplayMode.default : DisplayMode.overflow)
                    }
                >
                    <div
                        className='select-list__tooltip-content'
                        onMouseEnter={onLabelMouseEnter}
                        onMouseLeave={onLabelMouseLeave}
                    >
                        {toolTipContent || (
                            <div className='select-list__tooltip-placeholder'>
                                {emptyText || selectionText}
                            </div>
                        )}
                    </div>
                </ToolTip>
            );
        }
        const selectedValue =
            selectedPropValue !== undefined ? selectedPropValue : value;
        let menuItems = [
            (allowEmptySelection ? (
                <DropDownMenuItem
                    id={`${id}__select-list__blank-selection`}
                    className='select-list__blank-selection'
                    key='blank_selection'
                    onClick={(e) =>
                        this.onClickEmptySelection(e as any, onChange)
                    }
                >
                    {emptyItemText ||
                        Localization.getString(Strings.defaultEmptyItemText)}
                </DropDownMenuItem>
            ) : null) as JSX.Element,
            ...(availableItems as any[])
                .map(this.customItemMapper)
                .map((item: any, index) => {
                    // map of the selected ids for performant lookup of selected items for rendering.
                    const propValue =
                        item[primaryKey || (itemType && itemType.$primaryKey)];
                    const itemIdValue =
                        propValue !== undefined ? propValue : item;
                    // Please fix if you edit this file.  Added when adding linting to maui
                    const isDisabled: boolean = !!(item as object)[
                        // eslint-disable-next-line dot-notation
                        'isDisabled'
                    ];
                    const selected =
                        !isDisabled && selectedValue === itemIdValue;
                    const isHeaderItem = isHeader(item);
                    const label = isHeaderItem ? (
                        <HeaderItem item={item} />
                    ) : (
                        getItemDisplay(
                            item,
                            displayNameKey,
                            itemType,
                            selected,
                            true /*inMenu*/
                        )
                    );
                    const tooltip = getItemTooltip(
                        item,
                        tooltipNameKey,
                        itemType
                    );
                    return (
                        <DropDownMenuItem
                            key={index}
                            tooltip={
                                (tooltip || <span>{label}</span>) as JSX.Element
                            }
                            id={
                                generateIdAttribute
                                    ? generateIdAttribute(item, id, index)
                                    : `${id}__select-list-item__${index}`
                            }
                            tooltipDisplayMode={
                                tooltipDisplayMode ||
                                (tooltip
                                    ? DisplayMode.default
                                    : DisplayMode.overflow)
                            }
                            isEnabled={isEnabled && !isDisabled}
                            onClick={(e) =>
                                this.onChange(item, e as any, onChange)
                            }
                            className={`select-list-item ${
                                selected
                                    ? ' select-list-item--selected'
                                    : isDisabled
                                    ? ' select-list-item--disabled'
                                    : ''
                            }`}
                            theme={isHeaderItem ? 'header' : undefined}
                            disableFocus={isHeaderItem}
                        >
                            {label}
                        </DropDownMenuItem>
                    );
                }),
        ];

        if (
            staticLastMenuItems &&
            staticLastMenuItems.length > 0 &&
            menuItems.length > 0
        ) {
            menuItems = menuItems.concat(staticLastMenuItems as any);
        }

        return (
            <DropDownMenu
                id={id}
                alignment={alignment || 'none'}
                className={`${className} ${themeClassName(theme)} ${
                    selectedValue !== '' ? 'select-list--has-selection' : ''
                }`}
                label={labelValue}
                offsetTop={8}
                isEnabled={isEnabled}
                onClick={onLabelClick}
                onFocus={onFocus}
                captureAllKeyPress={this.captureAllKeyPress}
                suppressGlyph={suppressGlyph}
                style={
                    setWidthBasedOnLongestAvailableItem
                        ? {
                              ...style,
                              minWidth: contentRect.bounds.width + 35, // allow room for the drop down carrot
                          }
                        : style
                }
                onBlur={onBlur}
                onToggle={onToggle}
                embedInPortal={embedInPortal}
                children={
                    availableItems.length ? (
                        menuItems
                    ) : isRetrievingData ? (
                        <DropDownMenuItem
                            id={`${id}__initializing-data`}
                            key='initializing-data'
                            className='select-list-item--initializing'
                        >
                            {Localization.getString(
                                Strings.initializingData,
                                Object.assign({
                                    itemName:
                                        itemName || Strings.defaultItemName,
                                })
                            )}
                        </DropDownMenuItem>
                    ) : (
                        <DropDownMenuItem
                            key='no-selections'
                            id={`${id}__no-selections`}
                        >
                            {Localization.getString(
                                Strings.noSelectionsAvailable
                            )}
                        </DropDownMenuItem>
                    )
                }
            />
        );
    };

    render() {
        const { setWidthBasedOnLongestAvailableItem } = this.props;
        return setWidthBasedOnLongestAvailableItem ? (
            <Measure bounds>{this.renderSelectList}</Measure>
        ) : (
            this.renderSelectList({})
        );
    }
}

type ComponentType = SelectListControlProps<any>;

export default withIsExportMode<ComponentType>(
    withReadOnly<ComponentType>(selectList(SelectListControl))
);
