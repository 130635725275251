﻿import { connect } from 'react-redux';
import DialogStatusPageComponent from './DialogStatusPageComponent';
import { getDialogState } from '../DialogReducer';

const mapActionCreators = {};

const mapStateToProps = (state, ownProps) => {
    const dialogInstance = getDialogState(state).instances[ownProps.dialogId];
    const statusDetails = dialogInstance && dialogInstance.statusDetails;

    return {
        statusDetails: statusDetails,
    };
};

export default connect(
    mapStateToProps,
    mapActionCreators
)(DialogStatusPageComponent as any);
