import React, { Component } from 'react';
import DecimalInput, {
    DecimalInputProps,
} from 'components/inputs/DecimalInput';
import FixedContentInput from 'components/inputs/FixedContentInput';

// Create Typescript interface for this model.
export interface UiCurrency {
    shouldPrefixCurrencySymbol?: boolean;
    currencySymbol?: string;
    currencyName?: string;
    currencyNamePlural?: string;
    currencyCodeId?: string;
}

export interface CurrencyInputProps extends DecimalInputProps {
    currency: UiCurrency;
}
export default class CurrencyInput extends Component<CurrencyInputProps> {
    static displayName = 'CurrencyInput';

    static defaultProps: Partial<CurrencyInputProps> = {
        currency: {},
    };

    render() {
        const { currency, ...rest } = this.props;
        return (
            <FixedContentInput
                content={currency.currencySymbol || ''}
                isContentAfterInput={
                    currency.shouldPrefixCurrencySymbol === false
                }
                component={DecimalInput}
                theme='currency-input'
                {...rest}
            />
        );
    }
}
