import React from 'react';
import { Placement } from 'popper.js';

export interface TooltipPopperProps {
    reference: any;
    style?: React.CSSProperties;
    placement: Placement;
    arrowProps: any;
    outOfBoundaries?: boolean;
    scheduleUpdate: () => void;
    tooltipClassName?: string;
    content?: JSX.Element;
    debugId: string;
}

export interface TooltipPopperState {
    isHidden?: boolean;
}

export default class TooltipPopper extends React.PureComponent<
    TooltipPopperProps,
    TooltipPopperState
> {
    constructor(props) {
        super(props);
        this.state = {
            isHidden: true,
        };
    }

    timeoutId: any;

    componentDidMount() {
        // For some reason, popper doesn't apply styles on first render,
        // that makes it necessary to update the position of the tooltip
        // but only once.
        // It is necessary to do it inside a setTimeout so that the popper
        // component is fully initialized and be able to schedule an update.
        // The isHidden state property allows to both prevent any sudden
        // "jumps" caused by the position update and show a fade-in effect
        // when displaying a tooltip.
        // TODO: The first render problem may be solved in a future
        // release of the react-popper library.
        this.timeoutId = setTimeout(() => {
            this.props.scheduleUpdate();
            this.setState({ isHidden: false });
        }, 0);
    }

    componentWillUnmount() {
        // Cancel the async call to prevent a possible memory leak.
        clearTimeout(this.timeoutId);
    }

    render() {
        const {
            reference,
            style,
            placement,
            arrowProps,
            outOfBoundaries,
            tooltipClassName,
            content,
            debugId,
        } = this.props;
        const classes = [tooltipClassName, `tooltip--${placement}`];
        if (this.state.isHidden) {
            classes.push('tooltip--hidden');
        }

        return (
            <div
                ref={reference}
                style={style}
                data-placement={placement}
                className={classes.join(' ')}
                data-debug-id={debugId}
                data-out-of-boundaries={outOfBoundaries}
            >
                {content}
                <div
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                    className='tooltip__arrow'
                />
            </div>
        );
    }
}
