﻿import React, { ComponentProps } from 'react';
import ValidationService from '../../../util/ValidationService';
import { initialize, reduxForm, getFormValues, ConfigProps } from 'redux-form';
import { getComponentDisplayName } from '../../../util/getComponentDisplayName';
function enforceProperty(component, property) {
    if (component[property] === undefined) {
        const msg =
            `Using ttdForm without a ${property} method on ${getComponentDisplayName(
                component
            )}.` +
            ' If this is intentional, create a static property ' +
            `named '${property}' on ${getComponentDisplayName(
                component
            )} and set its value to null.`;
        throw msg;
    }
}

// Default validators
// to override, import and redefine a factory function that takes component as an argument.
export const validationServices = {
    validate: (component) =>
        function (values) {
            enforceProperty(component, 'validate');
            enforceProperty(component, 'uiModel');

            const results = ValidationService.validate(
                component.uiModel ? component.uiModel.from(values) : null,
                component.validate
            ).results;
            return results;
        },
    warn: (component) =>
        function (values) {
            enforceProperty(component, 'warn');

            const results = ValidationService.warn(
                component.uiModel ? component.uiModel.from(values) : null,
                component.warn
            ).results;

            return results;
        },
};

export type FormChangeFunctionType = ConfigProps['onChange'];

// A higher-order-component that wraps a given component with some
// helpful behavior.
//
// (1) It wraps the component with reduxForm, providing wiring necessary
//     to use our input components.
//
// (2) It forces the consumer to specify validate and warn static methods for
//     implementing custom validation functions (null is a valid value if
//     the methods are unnecessary).
//
// (3) It forces the consumer to specify a uiModel to automatically handle
//     validation for that model (null is a valid value if uiModel functionality
//     is not desired).
//
// (4) It creates a static method on the component named "getValues" which gets
//     the raw values from reduxForm. If you use this method, it's suggested that
//     you use it in mapStateToProps. Then, in your render function (for instance)
//     you can do something like MyModel.from(this.props.values) to get a clean
//     version of the model with extension methods, etc.

export type TtdForm<UiModel, ComponentProps> = React.Component<
    ComponentProps
> & {
    getValues(state: any): UiModel;
    initialize(data: Partial<UiModel>, options): void;
    new (props: ComponentProps);
};

export default function ttdForm(
    component,
    destroyOnUnmount = true,
    onChange: FormChangeFunctionType = undefined
) {
    const ReduxForm = reduxForm({
        form: component.uniqueName,
        destroyOnUnmount: destroyOnUnmount,
        validate: validationServices.validate(component),
        warn: validationServices.warn(component),
        onChange: onChange,
    })(component);

    class ResultClass extends React.Component {
        static initialize(data, options) {
            return initialize(component.uniqueName, data, options);
        }

        static getValues(state) {
            return getFormValues(component.uniqueName)(state);
        }

        wrappedComponent: any;

        render() {
            return (
                <ReduxForm
                    {...this.props}
                    ref={(node) => {
                        this.wrappedComponent = node;
                    }}
                />
            );
        }
    }

    // Visual Studio claims the following code is unreachable, but that is not the case.

    (ResultClass as any).displayName = `ttdForm(${getComponentDisplayName(
        component
    )})`;

    return (ResultClass as unknown) as TtdForm<
        typeof component.uiModel,
        ComponentProps<typeof component>
    >;
}
