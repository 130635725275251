﻿import React from "react";
import { DIALOG_STATUS, IStatusDetails } from "../DialogActions";
import LoadingIndicator from "../../LoadingIndicator";
import PercentBar from "../../PercentBar";
import Timer from "../../Timer";
import Strings from "./DialogStatusPage.strings.json";
import { Localization } from "../../../../util/LocalizationService";
import themeClassName from "../../../../util/Themes";
import "./DialogStatusPage.scss";
import SteppedProgressBar from "components/display/SteppedProgressBar";

export interface DialogStatusPageProps {
  // An optional className to add to the root element in this component.
  className?: string;

  /** Additional processing to get the message detail (e.g. as JSX Element) if applicable */
  getMessageDetail?(dialogStatus: DIALOG_STATUS): JSX.Element;

  /** Additional processing to get the message title (e.g. as JSX Element) if applicable */
  getMessageTitle?(dialogStatus: DIALOG_STATUS): JSX.Element;

  // From connected container
  statusDetails: IStatusDetails;

  theme?: string;
}

export default class DialogStatusPageComponent extends React.Component<
  DialogStatusPageProps,
  {}
> {
  static defaultProps = {
    className: "",
    theme: "",
  };

  getMessageDetailFromProps = () => {
    const { statusDetails, getMessageDetail } = this.props;
    return (
      statusDetails.messageDetail ||
      (getMessageDetail && getMessageDetail(statusDetails.dialogStatus))
    );
  };

  getMessageTitleFromProps = () => {
    const { statusDetails, getMessageTitle } = this.props;
    return (
      statusDetails.statusText ||
      (getMessageTitle && getMessageTitle(statusDetails.dialogStatus))
    );
  };

  render() {
    let loadingComponent = null;
    let imageUrl = null;
    let title: string | JSX.Element = "";
    let message: string | JSX.Element = "";
    let showTimer = false;

    let additionalClassName = "";

    const { statusDetails, theme } = this.props;

    switch (statusDetails.dialogStatus) {
      case DIALOG_STATUS.NONE:
        break;
      case DIALOG_STATUS.INITIALIZING:
      case DIALOG_STATUS.SUBMITTING:
        if (
          statusDetails.stepProgresses !== undefined &&
          statusDetails.stepProgresses !== null
        ) {
          loadingComponent = (
            <SteppedProgressBar
              steps={statusDetails.stepProgresses.steps}
              className="dialog-status-page__stepped-progress-bar"
              currentStepIndex={statusDetails.stepProgresses.currentStepIndex}
            />
          );
        } else if (
          statusDetails.pollingProgress !== undefined &&
          statusDetails.pollingProgress !== null
        ) {
          loadingComponent = (
            <PercentBar
              percentValue={statusDetails.pollingProgress}
              color="primary-1"
            />
          );
        } else {
          loadingComponent = (
            <LoadingIndicator
              className="dialog-status-page__loading"
              isDelayed
            />
          );
          showTimer = !!statusDetails.showTimerWhileSubmitting;
        }
        title = this.getMessageTitleFromProps();
        message =
          this.getMessageDetailFromProps() || statusDetails.messageGeneral;
        break;
      case DIALOG_STATUS.SUBMIT_SUCCESS:
        imageUrl = `../../../images/dialog-success-animated.svg?cb=${Date.now()}`;
        additionalClassName = "dialog-status-page__success";
        title = this.getMessageTitleFromProps();
        message =
          this.getMessageDetailFromProps() || statusDetails.messageGeneral;
        break;
      case DIALOG_STATUS.ERROR:
        imageUrl = `../../../images/dialog-error.svg`;
        additionalClassName = "dialog-status-page__error";
        switch (statusDetails.statusCode) {
          case 503:
            title = Localization.getString(Strings.actionTimedOutTitle);
            message = Localization.getString(Strings.actionTimedOutMessage);
            break;
          default:
            title = this.getMessageTitleFromProps();
            message =
              this.getMessageDetailFromProps() || statusDetails.messageGeneral;
        }
        break;
    }

    return (
      statusDetails.dialogStatus !== DIALOG_STATUS.UNSUBMITTED && (
        <div
          className={`dialog-status-page ${
            this.props.className
          } ${themeClassName(theme)}`}
        >
          {imageUrl && (
            <img
              className={`dialog-status-page__image ${additionalClassName}`}
              src={imageUrl}
            />
          )}
          {loadingComponent}
          {title && <div className="dialog-status-page__title">{title}</div>}
          {message && (
            <div className="dialog-status-page__message">{message}</div>
          )}
          {showTimer && (
            <div className="dialog-status-page__timer">
              {Localization.getString(Strings.timerLabel)} <Timer />
            </div>
          )}
        </div>
      )
    );
  }
}
