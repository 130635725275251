import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';
import TtdInputField, { TtdInputFieldComponentProps } from '../TtdInputField';
import ValidationError from '../../display/ValidationError';
import SelectListControl, {
    SelectListControlProps,
} from '../../controls/SelectListControl/SelectListControl';
import getInputId from '../../../util/getInputId';

/**
 * This component inherits most of the propTypes defined in SelectListControl
 */

type BaseFieldProps<T> = Omit<
    TtdInputFieldComponentProps<
        Omit<
            SelectListControlProps<T>,
            'id' | 'value' | 'onChange' | 'onFocus' | 'onBlur'
        >
    >,
    'onChange'
>;

export interface SelectListInputProps<T> extends BaseFieldProps<T> {
    onChange?: (newValue: T) => void;
    isValidationInline?: boolean;
    selectControlClassName?: string;
    showValidation?: boolean;
}

interface SelectListInputFieldProps<T>
    extends Omit<SelectListInputProps<T>, 'className' | 'style' | 'onChange'>,
        WrappedFieldProps {
    onFieldValueChange?: (newItem: any) => void;
    isValidationInline?: boolean;
    selectControlClassName?: string;
    isInline?: boolean;
    showValidation?: boolean;
}

class SelectListInputField<T> extends Component<SelectListInputFieldProps<T>> {
    id: string;

    constructor(props) {
        super(props);
        this.id = getInputId(SelectListInput, props);
    }

    onChange = (newItem) => {
        this.props.input.onChange(newItem);
        this.props.onFieldValueChange && this.props.onFieldValueChange(newItem);
    };

    onBlur = () => {
        const {
            input,
            availableItems,
            primaryKey,
            itemType,
            customItemMapper,
        } = this.props;

        // The blur event can come from two places:
        // 1. The DropDownMenu
        // 2. The "search" box for the DropDown, when it's enabled
        // If (2) is happening, the Blur event is going to contain
        // the value of the search box, not the value selected in the
        // dropdown. Since redux-form's `blur` action sets the value
        // of the field, we need to ensure it actually contains the
        // field's value, not the search text. As such, we manually
        // override the value getting passed through to ensure it's
        // the field's value, not the search text's value.
        return input.onBlur(
            // using input.value, try and find the correct match for the value, if not found, we default to `null`
            // instead of using the input.value which could be the value from the search field
            typeof input.value === 'string'
                ? availableItems?.find(
                      (i, idx) =>
                          (customItemMapper &&
                              customItemMapper(i, idx) === input.value) ||
                          i === input.value ||
                          (i &&
                              input.value &&
                              typeof i === 'object' &&
                              typeof input.value === 'object' &&
                              i[
                                  primaryKey ||
                                      (itemType && itemType.$primaryKey)
                              ] ===
                                  input.value[
                                      primaryKey ||
                                          (itemType && itemType.$primaryKey)
                                  ])
                  )
                    ? input.value
                    : null
                : input.value
        );
    };

    render() {
        const {
            input,
            meta,
            selectControlClassName,
            showValidation,
            ...fieldProps
        } = this.props;
        const { isInline, isValidationInline } = fieldProps;

        return (
            <span
                className={
                    isValidationInline
                        ? 'select-list-inline-validation'
                        : 'select-list-standard-validation'
                }
            >
                <SelectListControl
                    {...fieldProps}
                    id={this.id}
                    className={selectControlClassName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onFocus={input.onFocus}
                    value={input.value}
                />
                {showValidation && (
                    <ValidationError
                        isInline={
                            // Ideally we'd only check for `isValidationInline` here,
                            // but existing code is relying on the `isInline` path.
                            isInline || isValidationInline
                        }
                        meta={meta}
                    />
                )}
            </span>
        );
    }
}

export default class SelectListInput<T> extends Component<
    SelectListInputProps<T>
> {
    static defaultProps = {
        showValidation: true,
    };

    render() {
        const {
            className,
            style,
            onChange,
            field,
            showValidation,
            ...rest
        } = this.props;

        return (
            <div className={className} style={style}>
                <TtdInputField
                    {...rest}
                    field={field}
                    onFieldValueChange={onChange}
                    component={SelectListInputField}
                    showValidation={showValidation}
                />
            </div>
        );
    }
}
