import React from 'react';
import { BUTTON_TYPE } from '../../Button';
import BasicDialog, { DIALOG_SIZE } from '../BasicDialog';
import { DIALOG_STATUS } from '../DialogActions';
import DialogHeader from '../DialogHeader';
import DialogActionBar from '../DialogActionBar';
import { Localization } from '../../../../util/LocalizationService';
import Strings from './MessageDialogComponent.strings.json';
import './MessageDialog.scss';

export interface MessageDialogProps {
    dialogId: string;
    size: DIALOG_SIZE.SMALL | DIALOG_SIZE.MEDIUM; // We only allow small or medium message dialogs.
    title?: JSX.Element | string;
    showTitleInHeader?: boolean;
    message?: JSX.Element;
    imageUrl?: string;
    actions?: any[];
    showFooter?: boolean;
    isLight?: boolean;
    onCancel?: (dialogId: string) => void;
    syncCloseDialog?: boolean;
    theme?: string;
    onOpen?: (dialogId: string) => void;
    // From connected container
    setDialogStatusDetails: (details: any) => void;
    hideDialog: (dialogId: string, syncClose?: boolean) => void;
    shouldDismissOnAction: boolean;
}

interface MessageDialogState {
    wrappedActions: any[];
}

class MessageDialogComponent extends React.Component<
    MessageDialogProps,
    MessageDialogState
> {
    static defaultProps = {
        size: DIALOG_SIZE.SMALL,
        actions: [{ label: Localization.getString(Strings.ok) }],
        syncCloseDialog: false,
        showFooter: true,
        isLight: true,
        showTitleInHeader: false,
        shouldDismissOnAction: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            wrappedActions: [],
        };
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        return nextProps.actions !== prevState.actions
            ? {
                  wrappedActions: nextProps.actions.map(
                      ({ onClick, ...rest }) => {
                          return {
                              onClick: (dialogId, action) => {
                                  if (nextProps.shouldDismissOnAction) {
                                      nextProps.hideDialog(
                                          dialogId,
                                          nextProps.syncCloseDialog
                                      );
                                  }

                                  if (onClick) {
                                      onClick(dialogId, action);
                                  }
                              },
                              ...rest,
                          };
                      }
                  ),
                  actions: nextProps.actions,
              }
            : null;
    };

    wrappedOnOpen = (dialogId) => {
        const { onOpen, setDialogStatusDetails } = this.props;
        // Dialogs open in a "none" state.  Since message dialogs don't have a model
        // to initialize or anything, we just advance the status to "unsubmitted" so the dialog is
        // in "normal" mode where the actions show in the action bar.
        setDialogStatusDetails({
            dialogStatus: DIALOG_STATUS.UNSUBMITTED,
            dialogId,
        });

        if (onOpen) {
            onOpen(dialogId);
        }
    };

    // Since the cancel action for all message dialogs should close the dialog, wrap whatever
    // callback was provided with another to close the dialog.
    wrappedOnCancel = (dialogId) => {
        this.props.hideDialog(dialogId);
        if (this.props.onCancel) {
            this.props.onCancel(dialogId);
        }
    };

    handleEnterKeyhit = () => {
        // When enter is hit, trigger the first primary action
        const firstPrimaryAction = this.state.wrappedActions.find(
            (action) => action.type === BUTTON_TYPE.PRIMARY
        );

        if (
            firstPrimaryAction &&
            firstPrimaryAction.isEnabled !== false &&
            typeof firstPrimaryAction.onClick === 'function'
        ) {
            firstPrimaryAction.onClick(this.props.dialogId, firstPrimaryAction);
        }
    };

    render() {
        const {
            dialogId,
            size,
            title,
            message,
            imageUrl,
            theme,
            showFooter,
            showTitleInHeader,
            isLight,
        } = this.props;
        return (
            <BasicDialog
                dialogId={dialogId}
                size={size}
                className='message-dialog'
                escapeToCancel
                onCancel={this.wrappedOnCancel}
                onOpen={this.wrappedOnOpen}
                theme={theme}
                onEnterKeyHit={this.handleEnterKeyhit}
            >
                <DialogHeader
                    dialogId={dialogId}
                    title={showTitleInHeader ? title : null}
                    onCancel={this.wrappedOnCancel}
                    isLight={isLight}
                />
                <div className='message-dialog__content basic-dialog__content'>
                    {imageUrl && (
                        <img className='message-dialog__image' src={imageUrl} />
                    )}
                    {!showTitleInHeader && title && (
                        <div className='message-dialog__title'>{title}</div>
                    )}
                    {message && (
                        <div className='message-dialog__message'>{message}</div>
                    )}
                </div>
                {showFooter && (
                    <div className='message-dialog__footer'>
                        <DialogActionBar
                            dialogId={dialogId}
                            onOpen={null}
                            onClose={null}
                            actions={this.state.wrappedActions}
                            onCancel={this.wrappedOnCancel}
                        />
                    </div>
                )}
            </BasicDialog>
        );
    }
}

export default MessageDialogComponent;
