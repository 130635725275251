import React, {
    createRef,
    Component,
    CSSProperties,
    KeyboardEvent,
    ReactNode,
    RefObject,
} from 'react';
import get from 'lodash/get';
import Measure from 'react-measure';
import isEqual from 'lodash/isEqual';
import keycode from 'keycode';
import Immutable from 'seamless-immutable';
import Button, { BUTTON_TYPE } from 'components/display/Button';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import ToggleInput from 'components/inputs/ToggleInput';
import RowDeleteButtonInput, {
    DeleteMode,
} from 'components/inputs/RowDeleteButtonInput';
import { TtdField } from 'components/inputs/TtdInputField';
import { fromDateTimeUtcToMomentUtc, IDateTime } from 'util/DateTimeUtils';
import Environment from 'util/Environment';
import { Localization } from 'util/LocalizationService';
import NaturalComparer from 'util/NaturalComparer';
import DataTableRow, {
    sortColumns,
    DataColumn,
    DataColumnLayout,
} from './DataTableRow';
import {
    SORT_DIRECTION,
    ADDED_ITEMS_LOCATION,
    DataViewProps,
    DataViewLoadFilterAndSortProps,
    DataViewSortInformation,
    DataViewField,
} from './types';
import Strings from './DataTable.strings.json';
import './DataTable.scss';
import './themes/DataTableTheme-light.scss';
import './themes/DataTableTheme-bidlist.scss';
import './themes/DataTableTheme-expandable-rows.scss';
import './themes/DataTableTheme-datahub.scss';
import './themes/DataTableTheme-audience.scss';
import './themes/DataTableTheme-transparent.scss';
import './themes/DataTableTheme-light-no-highlight.scss';

// standard table row height from the styleguide
export const standardRowHeight = 48;
// table row height for 2 lines (per table styleguide, 19px line height and 16px padding on top/bottom)
export const standardRowHeightTwoLines = 70;
// table row height for 3 lines (per table styleguide, 19px line height and 16px padding on top/bottom)
export const standardRowHeightThreeLines = 89;
// allowing space to show validation
export const validationRowHeight = 60;

export enum ROW_HEIGHT {
    SINGLE = standardRowHeight,
    DOUBLE = standardRowHeightTwoLines,
    TRIPLE = standardRowHeightThreeLines,
    SINGLE_VALIDATION = validationRowHeight,
}

export enum THEME {
    LIGHT = 'datatable--light',
    BIDLIST = 'datatable--light datatable--bidlist',
    EXPANDABLE_ROWS = 'datatable--light datatable--expandable-rows',
    DATAHUB = 'datatable--light datatable--bidlist datatable--datahub',
    AUDIENCE = 'datatable--light datatable--bidlist datatable--datahub datatable--audience',
    TRANSPARENT = 'datatable--light datatable--bidlist datatable--transparent',
    LIGHT_NO_HIGHLIGHT = 'datatable--light datatable--light-no-highlight',
}

export { ADDED_ITEMS_LOCATION, SORT_DIRECTION };

export enum VERTICAL_ALIGNMENT {
    TOP = 'top',
    CENTER = 'center',
    BOTTOM = 'bottom',
}

export enum HORIZONTAL_ALIGNMENT {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export type DataTableRowExpandedContent<TRow, TTableData, TRowData> = {
    // Should be a PURE function, where the output is determined
    // only by the inputs.
    //
    // (row, rowIndex, rowData[id], tableData) -> elements
    renderExpandedRowContent: (
        row: TRow,
        rowIndex?: number,
        rowData?: TRowData,
        tableData?: TTableData
    ) => ReactNode[];

    // Should be a PURE function, where the output is determined
    // only by the inputs.
    //
    // (row, rowData[id], tableData) -> number (pixels)
    getExpandedContentRowHeightPixels: (
        row: TRow,
        rowData?: TRowData,
        tableData?: TTableData
    ) => number;

    // Should be a PURE function, where the output is determined
    // only by the inputs.
    //
    // (row, rowData[id], tableData) -> bool
    getIsRowExpanded: (
        row: TRow,
        rowData?: TRowData,
        tableData?: TTableData
    ) => boolean;
};

export type DataTableSortInformation<TRow> = Pick<
    DataViewSortInformation<TRow>, // Eventually we should migrate entirely to these types, just a lot of refactoring now to update prop names
    'onChangeSort' | 'onBeforeSort' | 'onAfterSort'
> & {
    direction: DataViewSortInformation<TRow>['direction'] | string;

    columnId: DataViewSortInformation<TRow>['fieldId'];
    /**
     * Optional boolean which indicates if all the rows (initial and newly added) should be sorted.
     * By default only the initial rows are sorted and not the newly added ones.
     */
    sortAllRows?: DataViewSortInformation<TRow>['sortAllItems'];

    /**
     * Indicates if the items are pre-sorted
     */
    rowsArePreSorted?: DataViewSortInformation<TRow>['itemsArePreSorted'];

    // onBeforeSort functionality is used to support expandable columns, see ExpandableRowsDataTable

    // onAfterSort functionality is used to support expandable columns, see ExpandableRowsDataTable
};

export type DataTableColumnAlignment = {
    vertical?: VERTICAL_ALIGNMENT;
    horizontal?: HORIZONTAL_ALIGNMENT;
};

export type DataColumnWidthSettings = {
    // When set, this will be the width of the column in pixels - other settings will be ignored.
    overrideWidthPixels?: number;
    // The minimum width of the column, in pixels. This will be used as the width of the column
    // when the column is fixed.
    minWidthPixels: number;
    // The maximum width of the column, the column will not grow to exceed this size.
    maxWidthPixels?: number;
    // When extra space is available, this is a weight that will determine how much of that space
    // is allocated to this column.
    flexGrow?: number;
    // When not enough space is available, this is a weight that will determine how much width
    // will be taken away from this column, relative to other columns.
    flexShrink?: number;
};

export interface DataTableColumn<
    TRow,
    TTableData = {},
    TRowData = {},
    THeaderData = {},
    TFooterData = {}
>
    extends DataColumn<TRow, TTableData, TRowData>,
        DataViewField<TRow, TTableData> {
    // A CSS class to apply to this column.
    className?: string;

    // CSS styles to apply to this column.
    style?: CSSProperties;

    // How to align the content and header of the column.
    alignment?: DataTableColumnAlignment;

    // Controls the alignment of the header text if different from the content.
    headerAlignment?: DataTableColumnAlignment;

    // Render function for the content of this column's additional header
    //
    // (index, additionalHeaderData) => (element, Object)
    renderAdditionalHeader?(
        index: number,
        additionalHeaderData: THeaderData
    ): ReactNode;

    // Render function for the content of this column's footer
    //
    // (index) => element
    renderFooter?(index: number, footerData: TFooterData): ReactNode;

    // This is the direction the column will be sorted when it is
    // first clicked by the user.
    defaultSortDirection?: SORT_DIRECTION;

    layout: DataColumnLayout;

    width: DataColumnWidthSettings;
}

export interface DataTableProps<
    TRow,
    TTableData = {},
    TRowData = {},
    THeaderData = {},
    TFooterData = {}
>
    extends Omit<
            // Eventually we should migrate entirely to these types, just a lot of refactoring now to update prop names
            DataViewProps<TRow, TTableData, TRowData>,
            | 'theme'
            | 'bodyHeight'
            | 'items'
            | 'itemsData'
            | 'getItemHeightPixels'
            | 'getIsItemDisabled'
            | 'getIsItemVisible'
            | 'itemClassName'
            | 'emptyItemsContent'
            | 'onItemClick'
            | 'renderItems'
            | 'viewData'
        >,
        Omit<
            DataViewLoadFilterAndSortProps<TRow, TTableData>,
            'sorting' | 'fields'
        > {
    // How should the table look? Use one of the predefined themes.
    theme?: THEME;

    // Height of the "body" of the component, including only non-fixed rows.
    // Pixels will override rows if both are supplied.
    bodyHeight?: DataViewProps<TRow, TTableData, TRowData>['bodyHeight'] & {
        heightInRows?: number;
    };

    /** Does the table support row-highlighting selection (ie no check boxes)? */
    isRowHighlightSelectionEnabled?: boolean;

    // Show the selection only when using the keyboard?
    shouldShowSelectionOnlyWithKeyboard?: boolean;

    // An arbitrary set of data for the table, changing this will cause the table
    // to re-render.  Example of this would be currency-code, which needs to update all
    // the money display in the table.
    tableData?: DataViewProps<TRow, TTableData, TRowData>['viewData'];

    // The data to be display in the list.
    rows?: DataViewProps<TRow, TTableData, TRowData>['items'];

    // A map between a row ID and an arbitrary set of data
    // to be consumed by other row-specific functions. For
    // instance, you could store row visibility or expansion
    // in this property.
    rowData?: DataViewProps<TRow, TTableData, TRowData>['itemsData'];

    // An arbitrary set of data to be consumed by the
    // renderFooter function of each column
    footerData?: TFooterData;

    // Should be a PURE function, where the output is determined
    // only by the inputs. If not provided, defaults to `standardRowHeight`
    //
    // (row || null, rowData[id], tableData) -> number (pixels)
    getRowHeightPixels?: DataViewProps<
        TRow,
        TTableData,
        TRowData
    >['getItemHeightPixels'];

    // Settings related to expanded content.
    expandedContent?: DataTableRowExpandedContent<TRow, TTableData, TRowData>;

    sorting?: DataTableSortInformation<TRow>;

    // Used to "disable" rows. A result of `true` will apply an `isDisabled` prop to the row.
    //
    // Should be a PURE function, where the output is determined only by the inputs:
    // (row, rowData[id], tableData) => boolean
    getIsRowDisabled?: DataViewProps<
        TRow,
        TTableData,
        TRowData
    >['getIsItemDisabled'];

    // Required for "filtering" rows client-side while still
    // keeping all of the data in memory.
    //
    // Should be a PURE function, where the output is determined
    // only by the inputs.
    //
    // (row, rowData[id], tableData) => boolean
    getIsRowVisible?: DataViewProps<
        TRow,
        TTableData,
        TRowData
    >['getIsItemVisible'];

    // Allow the consumer to interact with how rows are rendered.
    // This could be used to integrate with redux-form.
    //
    // The first parameter is an array containing the rows in the table
    // and the second parameter is a function that should be called
    // for each row.
    //
    // You could use it like this:
    // renderRows: (rows, renderRow, tableData) => {rows.map((row,index) => <div className="foo">{renderRow(row,index)}</div>)}
    //
    // And this would cause each (non-header) row in the table to be surrounded
    // a div with a "foo" CSS class.
    //
    // (rows, renderRow, tableData) -> elements
    renderRows?: DataViewProps<TRow, TTableData, TRowData>['renderItems'];

    // Allow the consumer to interact with how the header is rendered.
    // This could be used to insert additional HTML around the header,
    // for instance specifying:
    //
    //      renderHeader: (renderHeader, tableData) => <div className="foo">{renderHeader()}</div>
    //
    // would wrapper the header in a div with the "foo" CSS class. This
    // doesn't give you complete control over rendering the header - just
    // allows you to add additional elements *around* the header. You
    // will need to be sure to call the renderHeader function supplied
    // as the first argument to this function yourself in *your* renderHeader
    // function.
    //
    // Should be a PURE function, where the output is determined only by the
    // inputs.
    //
    // (renderHeader, tableData) -> elements
    renderHeader?(
        renderHeader: () => ReactNode,
        tableData: TTableData
    ): ReactNode;

    // Specify the height of the "body" of the component using the number of rows (where a
    // row's height matches "standardRowHeight")
    heightInRows?: number;

    // An arbitrary set of data to be consumed by the
    // renderAdditionalHeader function of each column
    additionalHeaderData?: THeaderData;

    // Whether to display an additional header row
    shouldShowAdditionalHeader?: boolean;

    // Whether to display the footer for each column
    shouldShowFooter?: boolean;

    // Optional function to get a dynamic CSS class to apply to a particular column. Use columns.className if the class should be applied to all columns.
    // (row, rowIndex, rowData[id], tableData) => element
    getColumnClassName?(
        row: TRow,
        rowIndex?: number,
        rowData?: TRowData,
        tableData?: TTableData
    ): ReactNode;

    // Optional CSS class to be applied to a table row
    rowClassName?: DataViewProps<TRow, TTableData, TRowData>['itemClassName'];

    // Definition for columns in the table
    columns: DataTableColumn<
        TRow,
        TTableData,
        TRowData,
        THeaderData,
        TFooterData
    >[];

    // Optional element content to render when there are no rows but you still want to show the header and table
    emptyRowsContent?: DataViewProps<
        TRow,
        TTableData,
        TRowData
    >['emptyItemsContent'];

    // Optional function to handle actions when the user clicks on a row in the table (e.g. select/deselect a checkbox
    // in the row instead of having to click on the checkbox itself)
    onRowClick?(
        columnId: string,
        row: TRow,
        rowData: TRowData,
        event: React.MouseEvent<HTMLElement>
    ): void;

    // Called when the user presses space or enter on the selection - parameter is selected rows
    onSelectionAction?(rows: TRow[]): void;

    // Called when the selection is extended with shift-click - parameter is selected rows. If it's because
    // of a click, the column and clicked row are also passed in.
    onSelectionExtended?(rows: TRow[], columnId?: string, row?: TRow): void;
}

interface DataTableState<TRow, TTableData, TRowData> {
    scrollLeft: number;
    verticalScrollPage: number;
    hasUsedKeyboard: boolean;
    rowIndexToScrollTo: number;
    columnInformation: {
        [columnId: string]: DataColumn<TRow, TTableData, TRowData>;
    };
    rowInformation: {
        constantRowHeightPixels?: number;
        rowHeightsPixels?: {
            [rowId: string]: number;
        };
        expandedRowHeightsPixels?: {
            [rowId: string]: number;
        };
        rowDisabled?: {
            [rowId: string]: boolean;
        };
        rowVisibility?: {
            [rowId: string]: boolean;
        };
    };
    selection?: any;
}

type SizingParameters = {
    hasHorizontalScrollbar: boolean;
    hasVerticalScrollbar: boolean;
    forcedWidthPixels: number | null;
};

class DataTable<
    TRow,
    TTableData = {},
    TRowData = {},
    THeaderData = {},
    TFooterData = {}
> extends Component<
    DataTableProps<TRow, TTableData, TRowData, THeaderData, TFooterData>,
    DataTableState<TRow, TTableData, TRowData>
> {
    static ROW_HEIGHT = ROW_HEIGHT;
    static THEME = THEME;
    static SORT_DIRECTION = SORT_DIRECTION;
    static VERTICAL_ALIGNMENT = VERTICAL_ALIGNMENT;
    static HORIZONTAL_ALIGNMENT = HORIZONTAL_ALIGNMENT;
    static ADDED_ITEMS_LOCATION = ADDED_ITEMS_LOCATION;

    // a helper method for creating a Delete column on a table.  Accepts the field name and the row order.
    static getDeleteColumn = (
        fieldName: TtdField,
        order: number = undefined,
        renderDeleteColumn = undefined,
        // Called without a row when rendering the header
        isReadOnly: (row?: any) => boolean = undefined,
        deleteModeAll = DeleteMode.all,
        preRemoveHandler = undefined,
        overrideIndex = undefined
    ) => {
        return {
            id: 'delete',
            className: 'datatable__column--delete',
            isVisible: true, // we should always show this column and do not allow field-display-selector to hide it
            header: (
                <RowDeleteButtonInput
                    id='delete-header'
                    field={fieldName}
                    className='datatable__column--delete--header'
                    deleteMode={deleteModeAll}
                    preRemoveHandler={preRemoveHandler}
                    isReadOnly={isReadOnly && isReadOnly()}
                    overrideIndex={overrideIndex}
                />
            ),
            width: {
                minWidthPixels: 40,
                flexGrow: 0,
                flexShrink: 0,
            },
            layout: {
                order: order || 100, // arbitrarily large number to put at the end of the table
            },
            headerAlignment: {
                vertical: DataTable.VERTICAL_ALIGNMENT.CENTER,
            },
            isSortable: false,
            renderContent: (row, index, rowData, tableData) => {
                const deleteColumnContent = (
                    <RowDeleteButtonInput
                        field={fieldName}
                        index={index}
                        deleteMode={DeleteMode.individual}
                        id={`delete-row-${index}`}
                        className='datatable__column--delete--content'
                        isReadOnly={isReadOnly && isReadOnly(row)}
                        preRemoveHandler={preRemoveHandler}
                        overrideIndex={overrideIndex}
                    />
                );

                return renderDeleteColumn
                    ? renderDeleteColumn(
                          row,
                          deleteColumnContent,
                          rowData,
                          tableData,
                          index
                      )
                    : deleteColumnContent;
            },
        };
    };

    // a helper method for creating a toggle column on a table
    static getToggleColumn = (
        getFieldName: (index, tableData) => string,
        localizedHeader: string,
        onToggle: (row, newVal: boolean) => void,
        order: number = undefined,
        isReadOnly: (row: any) => boolean = undefined,
        isVisible: boolean = true
    ) => {
        return {
            id: 'toggle',
            className: 'datatable__column--toggle',
            isVisible: isVisible,
            header: localizedHeader,
            width: {
                minWidthPixels: 60,
                flexGrow: 0,
                flexShrink: 0,
            },
            layout: {
                order: order || 100, // arbitrarily large number to put at the end of the table
            },
            headerAlignment: {
                vertical: DataTable.VERTICAL_ALIGNMENT.CENTER,
            },
            isSortable: false,
            renderContent: (row, index, rowData, tableData) => {
                return (
                    <ToggleInput
                        field={getFieldName(index, tableData)}
                        theme='stoplight'
                        onChange={(e, newValue: boolean, _oldValue: boolean) =>
                            onToggle(row, newValue)
                        }
                        isReadOnly={isReadOnly && isReadOnly(row)}
                        hideValidationIfNoErrors={true}
                    />
                );
            },
        };
    };

    static COMPARATOR = {
        STRING: (key) => {
            return (a, b) => NaturalComparer(get(a, key, ''), get(b, key, ''));
        },
        // adding extra ( || '') since get(a, key, '') default value does not cover null
        FAST_STRING: (key) => {
            return (a, b) =>
                (get(a, key, '') || '').toLowerCase() >
                (get(b, key, '') || '').toLowerCase()
                    ? 1
                    : (get(a, key, '') || '').toLowerCase() <
                      (get(b, key, '') || '').toLowerCase()
                    ? -1
                    : 0;
        },
        NUMERIC: (key) => {
            return (a, b) =>
                Number(get(a, key)) > Number(get(b, key))
                    ? 1
                    : Number(get(a, key)) < Number(get(b, key))
                    ? -1
                    : 0;
        },
        NUMERIC_RANGE: (key) => {
            return (a, b) =>
                (Number(get(a, key)[0]) + Number(get(a, key)[1])) / 2 >
                (Number(get(b, key)[0]) + Number(get(b, key)[1])) / 2
                    ? 1
                    : (Number(get(a, key)[0]) + Number(get(a, key)[1])) / 2 <
                      (Number(get(b, key)[0]) + Number(get(b, key)[1])) / 2
                    ? -1
                    : 0;
        },
        BOOLEAN: (key) => {
            return (a, b) =>
                get(a, key) === get(b, key) ? 0 : get(a, key) ? -1 : 1;
        },
        DATETIME: (key) => {
            return (a, b) => {
                const momentA = fromDateTimeUtcToMomentUtc(
                    get(a, key) as IDateTime
                );
                const momentB = fromDateTimeUtcToMomentUtc(
                    get(b, key) as IDateTime
                );

                if (!momentA && !momentB) return 0;
                else if (!momentA) return 1;
                else if (!momentB) return -1;

                return momentA.isAfter(momentB)
                    ? 1
                    : momentA.isBefore(momentB)
                    ? -1
                    : 0;
            };
        },
    };

    static defaultProps: Pick<
        DataTableProps<any, any, any, any, any>,
        | 'className'
        | 'theme'
        | 'bodyHeight'
        | 'addedItemsLocation'
        | 'getRowHeightPixels'
        | 'isRowHighlightSelectionEnabled'
        | 'shouldShowSelectionOnlyWithKeyboard'
        | 'isFiltering'
        | 'isRetrievingMoreData'
        | 'isMoreDataAvailable'
    > = {
        className: '',
        theme: DataTable.THEME.LIGHT,
        bodyHeight: {
            heightInRows: 5,
            isFixed: true,
        },
        addedItemsLocation: DataTable.ADDED_ITEMS_LOCATION.TOP,
        getRowHeightPixels: standardRowHeight,
        isRowHighlightSelectionEnabled: false,
        shouldShowSelectionOnlyWithKeyboard: false,
        isFiltering: false,
        isRetrievingMoreData: false,
        isMoreDataAvailable: false,
    };

    previousRowCount: number;
    sorting: DataTableSortInformation<TRow>;
    rowRefs: DataTableRow<TRow, TTableData, TRowData>[];
    bodyRef: RefObject<HTMLDivElement>;
    expandedContent?: DataTableRowExpandedContent<TRow, TTableData, TRowData>;
    previousRowsMap: {};
    scrollBarSize: number;
    verticalScrollHandler?: number;

    constructor(
        props: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) {
        super(props);

        this.previousRowCount = 0;
        this.sorting = {
            columnId: null,
            direction: null,
        };

        this.state = {
            scrollLeft: 0,
            verticalScrollPage: 0,
            hasUsedKeyboard: false,
            rowIndexToScrollTo: 0,
            columnInformation: this.getColumnInformation(props),
            rowInformation: this.getRowInformation(props),
        };

        this.rowRefs = [];
        this.bodyRef = createRef<HTMLDivElement>();
        this.scrollBarSize = (Environment as any).scrollBarSize;
    }

    shouldComponentUpdate(
        nextProps: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >,
        nextState: DataTableState<TRow, TTableData, TRowData>
    ) {
        if (
            (nextProps.emptyContent !== this.props.emptyContent ||
                nextProps.emptyRowsContent !== this.props.emptyRowsContent) &&
            (!this.props.rows || this.props.rows.length === 0)
        ) {
            return true;
        }

        if (!isEqual(nextState, this.state)) {
            return true;
        }

        if (!isEqual(nextProps.columns, this.props.columns)) {
            return true;
        }

        if (!isEqual(nextProps.rows, this.props.rows)) {
            return true;
        }

        if (!isEqual(nextProps.sorting, this.props.sorting)) {
            return true;
        }

        if (!isEqual(nextProps.tableData, this.props.tableData)) {
            return true;
        }

        if (
            !isEqual(
                nextProps.isRetrievingMoreData,
                this.props.isRetrievingMoreData
            )
        ) {
            return true;
        }

        if (nextProps.heightInRows !== this.props.heightInRows) {
            return true;
        }

        if (nextProps.isFiltering !== this.props.isFiltering) {
            return true;
        }

        if (nextProps.className !== this.props.className) {
            return true;
        }

        return false;
    }

    getColumnInformation = (
        props: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) => {
        let columnInformation = {
            ...((this.state && this.state.columnInformation) || {}),
        };

        const visibleColumns = props.columns
            .sort(sortColumns)
            .filter((c) => this.isColumnVisible(c));

        // If there are any columns that were removed from props, make sure to remove
        // them from columnInformation as well
        // (I.Skei) The following throws Typescript error: "Property 'without' does not exist on type
        // 'typeof SeamlessImmutable'" so the removal has to be done manually instead.  We should
        // periodically check to see if the library has been updated to support TS
        /*columnInformation = Immutable.without(
            columnInformation,
            (value, key) => !props.columns.some((column) => column.id === key)
        );*/
        Object.keys(columnInformation).forEach((key) => {
            if (!visibleColumns.some((column) => column.id === key)) {
                delete columnInformation[key];
            }
        });

        visibleColumns.forEach((column, index, columns) => {
            const { alignment } = column;
            const columnContainerClassName = `datatable__column-content datatable__column-content-vert-align-${
                (alignment || {}).vertical || 'center'
            }`;
            const contentHorizontalAlignment =
                column.alignment && column.alignment.horizontal;
            let marginStyle;
            switch (contentHorizontalAlignment) {
                case DataTable.HORIZONTAL_ALIGNMENT.LEFT:
                    marginStyle = 'marginRight';
                    break;
                case DataTable.HORIZONTAL_ALIGNMENT.CENTER:
                    marginStyle = 'margin';
                    break;
                case DataTable.HORIZONTAL_ALIGNMENT.RIGHT:
                    marginStyle = 'marginLeft';
                    break;
            }

            columnInformation = Object.assign(columnInformation, {
                [column.id]: {
                    columnContainerClassName,
                    id: column.id,
                    layout: column.layout,
                    getColumnClassName: this.props.getColumnClassName,
                    columnClassName: this.getColumnClassName(
                        column,
                        index === 0,
                        visibleColumns.length > 0 &&
                            visibleColumns[visibleColumns.length - 1].id ===
                                column.id
                    ),
                    styleWithoutHorizontalScrollbar: Immutable.from(
                        this.getColumnStyle(column, column.alignment, false)
                    ),
                    styleWithHorizontalScrollbar: Immutable.from(
                        this.getColumnStyle(column, column.alignment, true)
                    ),
                    renderContent: column.renderContent,
                    marginStyle,
                },
            });
        });

        return Immutable(columnInformation);
    };

    getRowInformation = (
        props: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) => {
        const getPrimaryKey = this.props.getPrimaryKey;
        const rowData = props.rowData || {};

        let rowInformation = {
            rowVisibility: null,
            rowHeightsPixels: null,
            expandedRowHeightsPixels: null,
            constantRowHeightPixels: null,
            rowDisabled: null,
        };

        if (props.getIsRowVisible) {
            const getIsRowVisible = props.getIsRowVisible;
            const rowVisibility = {};

            props.rows.forEach((row) => {
                const key = getPrimaryKey(row);
                rowVisibility[key] = getIsRowVisible(
                    row,
                    rowData[key],
                    props.tableData
                );
            });

            rowInformation.rowVisibility = rowVisibility;
        }

        if (props.getIsRowDisabled) {
            const getIsRowDisabled = props.getIsRowDisabled;
            const rowDisabled = {};

            props.rows.forEach((row) => {
                const key = getPrimaryKey(row);
                rowDisabled[key] = getIsRowDisabled(
                    row,
                    rowData[key],
                    props.tableData
                );
            });

            rowInformation.rowDisabled = rowDisabled;
        }

        if (typeof props.getRowHeightPixels === 'number') {
            rowInformation.constantRowHeightPixels = props.getRowHeightPixels;
        } else if (typeof props.getRowHeightPixels === 'function') {
            const getRowHeightPixels = props.getRowHeightPixels;
            const rowHeightsPixels = {};

            props.rows.forEach((row) => {
                const key = getPrimaryKey(row);
                rowHeightsPixels[key] = getRowHeightPixels(
                    row,
                    rowData[key],
                    props.tableData
                );
            });

            rowInformation.rowHeightsPixels = rowHeightsPixels;
        }

        if (props.expandedContent) {
            const getIsRowExpanded = props.expandedContent.getIsRowExpanded;
            const getExpandedContentRowHeightPixels =
                props.expandedContent.getExpandedContentRowHeightPixels;
            const expandedRowHeightsPixels = {};

            props.rows.forEach((row) => {
                const key = getPrimaryKey(row);

                expandedRowHeightsPixels[key] = getIsRowExpanded(
                    row,
                    rowData[key],
                    props.tableData
                )
                    ? getExpandedContentRowHeightPixels(
                          row,
                          rowData[key],
                          props.tableData
                      )
                    : null;
            });

            rowInformation.expandedRowHeightsPixels = expandedRowHeightsPixels;
        }

        if (this.state && this.state.rowInformation) {
            rowInformation = Object.assign(
                { ...this.state.rowInformation },
                rowInformation
            );
        }

        return Immutable(rowInformation);
    };

    componentWillReceiveProps(
        nextProps: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) {
        let rowInfo = this.state.rowInformation;
        let colInfo = this.state.columnInformation;

        // If the columns have changed, recompute the column properties.
        if (nextProps.columns !== this.props.columns) {
            colInfo = this.getColumnInformation(nextProps);
        }

        // If the rows have changed, or if the row data has changed,
        // recompute the row information.
        if (
            nextProps.tableData !== this.props.tableData ||
            nextProps.rowData !== this.props.rowData ||
            nextProps.getRowHeightPixels !== this.props.getRowHeightPixels ||
            nextProps.rows !== this.props.rows
        ) {
            rowInfo = this.getRowInformation(nextProps);
        }

        this.setState({
            rowInformation: rowInfo,
            columnInformation: colInfo,
        });
    }

    componentDidUpdate(
        prevProps: DataTableProps<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >,
        prevState: DataTableState<TRow, TTableData, TRowData>
    ) {
        // If a new page of data has come through, scroll to the start of that new page (i.e. should
        // be positioned to where the "load more" button was before the new page came in)
        if (
            prevProps.isRetrievingNewPage &&
            !this.props.isRetrievingNewPage &&
            this.state.rowIndexToScrollTo > 0
        ) {
            const scrollToIndex =
                this.state.rowIndexToScrollTo < this.rowRefs.length
                    ? this.state.rowIndexToScrollTo
                    : this.rowRefs.length - 1;
            this.scrollRowIntoView(scrollToIndex);
        }
    }

    getExpandedContentStyle = (row: TRow) => {
        const rowHeightPixels = this.props.expandedContent.getExpandedContentRowHeightPixels(
            row
        );

        return {
            height: rowHeightPixels + 'px',
            maxHeight: rowHeightPixels + 'px',
            minHeight: rowHeightPixels + 'px',
        };
    };

    getColumnStyle = (
        column: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >,
        alignmentProps: DataTableColumnAlignment,
        hasHorizontalScrollbar: boolean | number
    ) => {
        const width = column.width;

        const base = {
            ...(column.style || {}),
        };

        const alignment = {
            textAlign: 'left',
            alignSelf: 'center',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
        };

        if (alignmentProps) {
            const horz = alignmentProps.horizontal;
            const vert = alignmentProps.vertical;

            if (horz) {
                alignment.textAlign = horz;
                if (horz === 'center') {
                    alignment.justifyContent = 'center';
                }
            }

            if (vert === DataTable.VERTICAL_ALIGNMENT.TOP) {
                alignment.alignSelf = 'flex-start';
            } else if (vert === DataTable.VERTICAL_ALIGNMENT.CENTER) {
                alignment.alignSelf = 'center';
                alignment.alignItems = 'center';
            } else if (vert === DataTable.VERTICAL_ALIGNMENT.BOTTOM) {
                alignment.alignSelf = 'flex-end';
            }
        }

        let sizing;

        if (width.overrideWidthPixels !== undefined) {
            // If there is an override, use it for the column width, every time.
            sizing = {
                minWidth: width.overrideWidthPixels + 'px',
                maxWidth: width.overrideWidthPixels + 'px',
            };
        } else if (hasHorizontalScrollbar) {
            // When a horizontal scrollbar is present, we use the minimum column width unless there is an override.
            sizing = {
                maxWidth: width.minWidthPixels,
                minWidth: width.minWidthPixels,
            };
        } else {
            // Otherwise, allow the browser to determine the size of a column via flexbox.
            if (width.minWidthPixels === undefined) {
                throw new Error('minWidthPixels is required');
            }

            sizing = {
                minWidth: width.minWidthPixels + 'px',
                maxWidth:
                    width.maxWidthPixels === undefined
                        ? undefined
                        : width.maxWidthPixels + 'px',
                flexGrow: width.flexGrow,
                flexShrink: width.flexShrink,
            };
        }

        sizing.flexBasis = sizing.minWidth;

        const scroll = {
            left: 0,
        };
        if (!column.layout.isFixed && this.state && this.state.scrollLeft) {
            scroll.left = -this.state.scrollLeft;
        }

        return {
            ...base,
            ...sizing,
            ...alignment,
            ...scroll,
            order:
                column.layout.order + Number(column.layout.isFixed) ? -5000 : 0,
        };
    };

    isColumnVisible = (
        column: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) => {
        const isVisible =
            typeof column.isVisible === 'function'
                ? column.isVisible(this.props.tableData)
                : column.isVisible;

        if (isVisible == null) {
            // if is null or undefined (ie, not set yet)
            // no specific value set, then allow column selection to take affect
            return column.isSelectedForDisplay !== false;
        }

        return isVisible !== false;
    };

    getColumnClassName(
        column: DataTableColumn<TRow, TTableData, TRowData>,
        isFirst: boolean,
        isLast: boolean
    ) {
        return `datatable__column ${column.className || ''} ${
            column.layout.isFixed
                ? 'datatable__column--fixed'
                : 'datatable__column--not-fixed'
        } ${isFirst ? 'datatable__column--first' : ''} ${
            isLast ? 'datatable__column--last' : ''
        }`;
    }

    renderRow = (
        row: TRow,
        index: number,
        hasHorizontalScrollbar: boolean,
        renderContext: {
            verticalHeight: number;
            top: number;
            bottom: number;
        }
    ) => {
        const { tableData, rowData, rowClassName, getPrimaryKey } = this.props;
        const key = getPrimaryKey(row);

        // If the row is hidden, skip rendering entirely.
        if (
            this.state.rowInformation.rowVisibility &&
            !this.state.rowInformation.rowVisibility[key]
        ) {
            return null;
        }

        const rowHeightPixels =
            this.state.rowInformation.constantRowHeightPixels ||
            this.state.rowInformation.rowHeightsPixels[key];
        const expandedRowHeightPixels =
            this.expandedContent &&
            this.state.rowInformation.expandedRowHeightsPixels[key];

        const top = renderContext.verticalHeight;
        const bottom =
            renderContext.verticalHeight +
            rowHeightPixels +
            (expandedRowHeightPixels || 0);

        const renderAllRows =
            this.props.bodyHeight.heightInPixels === undefined &&
            this.props.bodyHeight.heightInRows === undefined;

        const isOffScreen =
            !renderAllRows &&
            (bottom < renderContext.top || top > renderContext.bottom);
        const isDisabled = this.state.rowInformation.rowDisabled
            ? this.state.rowInformation.rowDisabled[key]
            : false;
        const sortedRowIndex = this.previousRowsMap[key];
        const isSelected =
            this.props.isRowHighlightSelectionEnabled &&
            (!this.props.shouldShowSelectionOnlyWithKeyboard ||
                this.state.hasUsedKeyboard) &&
            this.state.selection !== undefined &&
            sortedRowIndex >= this.state.selection.start &&
            sortedRowIndex <= this.state.selection.end;

        // Set the render context vertical height to the current "bottom".
        renderContext.verticalHeight = bottom;

        return (
            <DataTableRow
                key={key}
                value={row}
                rowIndex={index}
                rowData={rowData ? rowData[key] : null}
                ref={(e) => {
                    this.rowRefs[index] = e;
                }}
                tableData={tableData}
                columnInformation={this.state.columnInformation}
                rowHeightPixels={rowHeightPixels}
                expandedRowHeightPixels={expandedRowHeightPixels}
                renderExpandedRowContent={
                    this.expandedContent &&
                    this.expandedContent.renderExpandedRowContent
                }
                hasHorizontalScrollbar={hasHorizontalScrollbar}
                scrollLeftPixels={this.state.scrollLeft}
                isOffScreen={isOffScreen}
                isSelected={isSelected}
                isDisabled={isDisabled}
                onRowClick={this.onRowClick}
                className={
                    typeof rowClassName === 'function'
                        ? rowClassName(
                              row,
                              index,
                              rowData ? rowData[key] : null,
                              tableData
                          )
                        : rowClassName
                }
            />
        );
    };

    sortRows = () => {
        const columnId = this.props.sorting.columnId;
        const getPrimaryKey = this.props.getPrimaryKey;
        const initialMap = this.props.initialItems;
        const tableData = this.props.tableData;
        const rows = this.props.rows;
        let sortedRows = [];

        const sortDirection = (sortFunc, direction) =>
            direction === DataTable.SORT_DIRECTION.ASCENDING
                ? (a, b) => sortFunc(a, b, tableData, direction)
                : (a, b) => -1 * sortFunc(a, b, tableData, direction);

        const sortColumn = this.props.columns.filter((item) => {
            return item.id === columnId;
        })[0];

        const initialRows = [];
        let addedRows = [];

        if (initialMap) {
            rows.forEach((row) => {
                if (initialMap[getPrimaryKey(row)]) {
                    initialRows.push(row);
                } else {
                    // last row added is first
                    addedRows.unshift(row);
                }
            });
        } else {
            addedRows = [...rows].slice().reverse();
        }

        let rowsToSort = initialRows;
        const sortAllRows =
            this.props.sorting && this.props.sorting.sortAllRows;
        if (sortAllRows) {
            rowsToSort = [...initialRows, ...addedRows];
        }
        if (sortColumn && sortColumn.isSortable && sortColumn.sortComparator) {
            if (this.props.sorting && this.props.sorting.onBeforeSort) {
                rowsToSort = this.props.sorting.onBeforeSort(
                    columnId,
                    rowsToSort
                );
            }
            let initialSorted = rowsToSort.sort(
                sortDirection(
                    sortColumn.sortComparator,
                    this.props.sorting.direction
                )
            );
            if (this.props.sorting && this.props.sorting.onAfterSort) {
                initialSorted = this.props.sorting.onAfterSort(
                    columnId,
                    initialSorted
                );
            }

            if (!sortAllRows) {
                if (
                    this.props.addedItemsLocation ===
                    DataTable.ADDED_ITEMS_LOCATION.TOP
                ) {
                    initialSorted.unshift(...addedRows);
                } else {
                    initialSorted.push(...addedRows);
                }
            }
            sortedRows = initialSorted;
        } else {
            sortedRows = rows.slice();
        }

        this.sorting = {
            columnId: columnId,
            direction: this.props.sorting.direction,
        };

        return sortedRows;
    };

    renderRows = (sizingParameters: SizingParameters) => {
        const rows = this.props.rows;
        const maxRows = rows.length;

        // Early exit if we don't have any rows.
        if (maxRows === 0) {
            /**
             * Resetting the map and count used in sorting in case the table is filtered empty
             */
            this.previousRowCount = 0;
            this.previousRowsMap = {};
            if (this.props.emptyRowsContent) {
                return this.props.emptyRowsContent;
            }

            return null;
        }

        // Used while rendering the individual rows below in a loop.
        const { hasHorizontalScrollbar } = sizingParameters;

        // This is used to avoid recalculating extra values during the render.
        const renderContext = {
            verticalHeight: 0,
            top:
                Environment.screenHeightPixels *
                (this.state.verticalScrollPage - 1),
            bottom:
                Environment.screenHeightPixels *
                (this.state.verticalScrollPage + 2),
        };

        let sortedRows = [];
        const indexMap = {};
        const getPrimaryKey = this.props.getPrimaryKey;

        rows.forEach((item, index) => {
            indexMap[getPrimaryKey(item)] = index;
        });

        if (this.props.sorting && !this.props.sorting.rowsArePreSorted) {
            if (
                this.previousRowCount !== rows.length ||
                this.props.sorting.columnId !== this.sorting.columnId ||
                this.props.sorting.direction !== this.sorting.direction
            ) {
                sortedRows = this.sortRows();
            } else {
                // row count and sort didn't change.  ensure that we have the same primary keys in the table
                let mismatch = false;
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    if (
                        this.previousRowsMap[getPrimaryKey(row)] === undefined
                    ) {
                        mismatch = true;
                        break;
                    }
                }

                if (mismatch) {
                    sortedRows = this.sortRows();
                } else {
                    // reconstruct previous rows list
                    const keys = Object.keys(this.previousRowsMap);
                    sortedRows = new Array(keys.length);
                    Object.keys(this.previousRowsMap).forEach((key) => {
                        const row = rows[indexMap[key]];
                        sortedRows[this.previousRowsMap[key]] = row;
                    });
                }
            }
        } else {
            sortedRows = [...rows].slice();
        }

        // create primary key => sorted index map
        this.previousRowsMap = {};
        sortedRows.forEach((item, index) => {
            this.previousRowsMap[getPrimaryKey(item)] = index;
        });
        this.previousRowCount = sortedRows.length;

        let result;

        if (this.props.renderRows) {
            result = this.props.renderRows(
                sortedRows,
                (row, index) => {
                    return this.renderRow(
                        row,
                        indexMap[getPrimaryKey(row)],
                        hasHorizontalScrollbar,
                        renderContext
                    );
                },
                this.props.tableData
            );

            if (!result || (result.length > 0 && result[0] === undefined)) {
                throw new Error(
                    'renderRows function supplied to DataTable is not returning an array of elements. Are you missing a return?'
                );
            }
        } else {
            result = sortedRows.map((row) => {
                return this.renderRow(
                    row,
                    indexMap[getPrimaryKey(row)],
                    hasHorizontalScrollbar,
                    renderContext
                );
            });
        }

        return result;
    };

    getHeaderFooterColumnClassName = (
        column: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >,
        index: number,
        columns: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >[]
    ) => {
        const sortable =
            this.props.sorting &&
            column.isSortable &&
            'datatable__column--sortable';
        const sorted =
            sortable &&
            this.props.sorting.columnId === column.id &&
            `datatable__column--sorted-${this.props.sorting.direction}`;
        const unsorted = sortable && !sorted && `datatable__column--unsorted`;
        const visibleColumns = columns.filter((c) => this.isColumnVisible(c));
        const isColumnLast =
            visibleColumns.length > 0 &&
            visibleColumns[visibleColumns.length - 1].id === column.id;

        return `${this.getColumnClassName(column, index === 0, isColumnLast)} ${
            sortable || ''
        } ${sorted || ''} ${unsorted || ''}`;
    };

    getScrollbarSpacerStyle = () => {
        const width = this.scrollBarSize + 'px';
        return {
            display: 'inline-block',
            minWidth: width,
            maxWidth: width,
            width: width,
            layout: {
                order: 5000, // arbitrary high number, spacer is always at the end
            },
        };
    };

    handleChangeSort = (
        column: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >,
        event: React.MouseEvent<HTMLAnchorElement>
    ) => {
        if (event.defaultPrevented) {
            return;
        }
        if (
            this.props.sorting &&
            column.isSortable &&
            this.props.sorting.onChangeSort
        ) {
            let newDirection =
                column.defaultSortDirection ||
                DataTable.SORT_DIRECTION.ASCENDING;

            if (this.props.sorting.columnId === column.id) {
                // Swap out which direction if this was already the sorted column.
                if (
                    this.props.sorting.direction ===
                    DataTable.SORT_DIRECTION.ASCENDING
                ) {
                    newDirection = DataTable.SORT_DIRECTION.DESCENDING;
                } else {
                    newDirection = DataTable.SORT_DIRECTION.ASCENDING;
                }
            }

            this.props.sorting.onChangeSort(column.id, newDirection);
        }
    };

    renderSortIndicator = (
        column: DataTableColumn<
            TRow,
            TTableData,
            TRowData,
            THeaderData,
            TFooterData
        >
    ) => {
        if (column.id !== (this.props.sorting && this.props.sorting.columnId)) {
            return null;
        }
        const glyphName =
            this.props.sorting.direction === DataTable.SORT_DIRECTION.ASCENDING
                ? 'sort-up'
                : 'sort-down';
        return (
            <Glyph
                name={glyphName}
                className={`datatable__sort-arrow datatable__sort-arrow--${glyphName}`}
            />
        );
    };

    renderHeader = (sizingParameters: SizingParameters) => {
        const { hasVerticalScrollbar, forcedWidthPixels } = sizingParameters;

        return (
            <div className='datatable__row'>
                {this.props.columns.sort(sortColumns).map(
                    (c, i, cs) =>
                        this.isColumnVisible(c) && (
                            <div
                                className={this.getHeaderFooterColumnClassName(
                                    c,
                                    i,
                                    cs
                                )}
                                style={this.getColumnStyle(
                                    c,
                                    c.headerAlignment || c.alignment,
                                    forcedWidthPixels
                                )}
                                key={c.id}
                            >
                                <a
                                    onClick={(e) => this.handleChangeSort(c, e)}
                                    id={`${this.props.id}--sortby-${c.id}`}
                                    className={`datatable__column--header-title datatable__column--header-title-align-${
                                        (c.headerAlignment || c.alignment || {})
                                            .horizontal || 'left'
                                    }`}
                                >
                                    {this.renderSortIndicator(c)}
                                    <span>{c.header}</span>
                                </a>
                            </div>
                        )
                )}
                {hasVerticalScrollbar && (
                    <div style={this.getScrollbarSpacerStyle()}>&nbsp;</div>
                )}
            </div>
        );
    };

    renderAdditionalHeader = (sizingParameters: SizingParameters) => {
        const { hasVerticalScrollbar, forcedWidthPixels } = sizingParameters;

        return (
            <div className='datatable__additional-header datatable__row'>
                {this.props.columns.sort(sortColumns).map(
                    (c, i, cs) =>
                        this.isColumnVisible(c) && (
                            <div
                                className={this.getHeaderFooterColumnClassName(
                                    c,
                                    i,
                                    cs
                                )}
                                style={this.getColumnStyle(
                                    c,
                                    c.headerAlignment || c.alignment,
                                    forcedWidthPixels
                                )}
                                key={c.id}
                            >
                                {c.renderAdditionalHeader &&
                                    c.renderAdditionalHeader(
                                        i,
                                        this.props.additionalHeaderData
                                    )}
                            </div>
                        )
                )}
                {hasVerticalScrollbar && (
                    <div style={this.getScrollbarSpacerStyle()}>&nbsp;</div>
                )}
            </div>
        );
    };

    renderFooter = (sizingParameters: SizingParameters) => {
        const { hasVerticalScrollbar, forcedWidthPixels } = sizingParameters;

        return (
            <div className='datatable__footer datatable__row'>
                {this.props.columns.map(
                    (c, i, cs) =>
                        this.isColumnVisible(c) && (
                            <div
                                className={this.getHeaderFooterColumnClassName(
                                    c,
                                    i,
                                    cs
                                )}
                                style={this.getColumnStyle(
                                    c,
                                    c.alignment,
                                    forcedWidthPixels
                                )}
                                key={c.id}
                                id={`${this.props.id}--footer-${c.id}`}
                            >
                                {c.renderFooter &&
                                    c.renderFooter(i, this.props.footerData)}
                            </div>
                        )
                )}
                {hasVerticalScrollbar && (
                    <div style={this.getScrollbarSpacerStyle()}>&nbsp;</div>
                )}
            </div>
        );
    };

    static renderLoader() {
        return (
            <div className='datatable__loading'>
                {Localization.getString(Strings.loadingMessage)}
            </div>
        );
    }

    getBodyStyle = (
        sizingParameters: SizingParameters,
        shouldShowLoadMoreButton: boolean
    ) => {
        const { hasVerticalScrollbar } = sizingParameters;
        const heightInRows = shouldShowLoadMoreButton
            ? this.props.bodyHeight.heightInRows + 1
            : this.props.bodyHeight.heightInRows;

        if (this.props.bodyHeight) {
            let bodyHeightPixels;

            if (this.props.bodyHeight.heightInPixels) {
                bodyHeightPixels = this.props.bodyHeight.heightInPixels;
            } else if (typeof this.props.getRowHeightPixels === 'function') {
                bodyHeightPixels =
                    this.props.getRowHeightPixels(null) * heightInRows;
            } else {
                bodyHeightPixels = this.props.getRowHeightPixels * heightInRows;
            }

            return {
                overflowY: hasVerticalScrollbar ? 'scroll' : 'hidden',
                maxHeight: Number.isNaN(bodyHeightPixels)
                    ? undefined
                    : bodyHeightPixels,
                minHeight: this.props.bodyHeight.isFixed
                    ? bodyHeightPixels
                    : undefined,
                height: this.props.bodyHeight.isFixed
                    ? bodyHeightPixels
                    : undefined,
            } as CSSProperties;
        } else {
            return {} as CSSProperties;
        }
    };

    getHasVerticalScrollbar = () => {
        const bodyHeight = this.props.bodyHeight;
        const { rows, tableData, rowData, getPrimaryKey } = this.props;

        let itemsToCheck = 0;

        if (bodyHeight.heightInPixels) {
            let sum = 0;

            for (let i = 0; i < rows.length; ++i) {
                const key = getPrimaryKey(rows[i]);
                if (typeof this.props.getRowHeightPixels === 'function') {
                    sum += this.props.getRowHeightPixels(
                        rows[i],
                        (rowData || {})[key],
                        tableData
                    );
                } else {
                    sum += this.props.getRowHeightPixels;
                }

                if (sum > bodyHeight.heightInPixels) {
                    return true;
                }
            }

            itemsToCheck = rows.length;
        } else if (bodyHeight.heightInRows) {
            const getIsRowVisible = this.props.getIsRowVisible;
            let visibleRowCount = rows.length;

            if (getIsRowVisible) {
                visibleRowCount = rows.reduce(
                    (count, row, index) =>
                        count +
                        (getIsRowVisible(
                            row,
                            rowData ? rowData[index] : null,
                            tableData
                        )
                            ? 1
                            : 0),
                    0
                );
            }

            if (visibleRowCount > bodyHeight.heightInRows) {
                return true;
            }

            itemsToCheck = bodyHeight.heightInRows;
        } else {
            // If neither heightInPixels or heightInRows is set then
            // we will never scroll.
            return false;
        }

        if (this.props.expandedContent) {
            // If any of the rows that we would display have been expanded, then
            // go ahead and make the table scroll instead of investigating expansion
            // heights.

            itemsToCheck = Math.min(itemsToCheck, rows.length);
            for (let i = 0; i < itemsToCheck; ++i) {
                if (this.props.expandedContent.getIsRowExpanded(rows[i])) {
                    return true;
                }
            }
        }

        return false;
    };

    handleVerticalScroll = (e) => {
        /*
         * When the user scrolls, check to see if we need to update the
         * current page. In order to prevent re-rendering during scrolling
         * we debounce updating our state.
         */
        const DEBOUNCE_SCROLL_MILLISECONDS = 100;
        const target = e.target;

        if (this.verticalScrollHandler) {
            clearTimeout(this.verticalScrollHandler);
        }

        this.verticalScrollHandler = window.setTimeout(() => {
            const verticalScrollPage = Math.floor(
                target.scrollTop / Environment.screenHeightPixels
            );

            this.verticalScrollHandler = null;

            if (verticalScrollPage !== this.state.verticalScrollPage) {
                this.setState({
                    verticalScrollPage: verticalScrollPage,
                });
            }
        }, DEBOUNCE_SCROLL_MILLISECONDS);
    };

    handleHorizontalScroll = (e) => {
        this.setState({
            scrollLeft: e.target.scrollLeft,
        });
    };

    getSelectedRows = (selection) => {
        if (selection) {
            return this.props.rows.filter((row) => {
                const rowIndex = this.previousRowsMap[
                    this.props.getPrimaryKey(row)
                ];
                return rowIndex >= selection.start && rowIndex <= selection.end;
            });
        } else {
            return [];
        }
    };

    onRowClick = (
        columnId: string,
        row: TRow,
        rowData?: TRowData,
        e?: React.MouseEvent<HTMLDivElement>
    ) => {
        if (this.props.onRowClick && !e.shiftKey) {
            this.props.onRowClick(columnId, row, rowData, e);
        }

        if (this.props.isRowHighlightSelectionEnabled) {
            const rowIndex = this.previousRowsMap[
                this.props.getPrimaryKey(row)
            ];
            if (rowIndex !== undefined) {
                if (e.shiftKey && this.state.selection !== undefined) {
                    let newSelection = {};
                    if (rowIndex < this.state.selection.start) {
                        newSelection = {
                            start: rowIndex,
                            end: this.state.selection.end,
                        };
                    } else {
                        newSelection = {
                            start: this.state.selection.start,
                            end: rowIndex,
                        };
                    }

                    this.setState({ selection: newSelection });

                    if (this.props.onSelectionExtended) {
                        this.props.onSelectionExtended(
                            this.getSelectedRows(newSelection),
                            columnId,
                            row
                        );
                    }
                } else {
                    this.setState({
                        selection: { start: rowIndex, end: rowIndex },
                    });
                }
            }
        }
    };

    scrollRowIntoView = (index: number) => {
        const rowRef = this.rowRefs[index];
        if (rowRef) {
            rowRef.scrollIntoView(this.bodyRef.current);
        }
    };

    onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (this.props.isRowHighlightSelectionEnabled) {
            const key = keycode(event as any);
            const selection = this.state.selection;
            switch (key) {
                case 'enter':
                case 'space':
                    if (
                        selection !== undefined &&
                        this.props.onSelectionAction
                    ) {
                        this.props.onSelectionAction(
                            this.getSelectedRows(this.state.selection)
                        );
                        event.preventDefault();
                    }
                    this.setState({ hasUsedKeyboard: true });
                    break;
                case 'up':
                    if (selection !== undefined && selection.start > 0) {
                        const newSelection = {
                            start: selection.start - 1,
                            end: event.shiftKey
                                ? selection.end
                                : selection.start - 1,
                        };
                        this.setState({
                            selection: newSelection,
                            hasUsedKeyboard: true,
                        });
                        if (event.shiftKey) {
                            this.scrollRowIntoView(selection.start - 1);
                            if (this.props.onSelectionExtended) {
                                this.props.onSelectionExtended(
                                    this.getSelectedRows(newSelection)
                                );
                            }
                        }
                    }
                    break;
                case 'down':
                    if (
                        selection !== undefined &&
                        selection.end < this.previousRowCount - 1
                    ) {
                        const newSelection = {
                            start: event.shiftKey
                                ? selection.start
                                : selection.end + 1,
                            end: selection.end + 1,
                        };
                        this.setState({
                            selection: newSelection,
                            hasUsedKeyboard: true,
                        });
                        if (event.shiftKey) {
                            this.scrollRowIntoView(selection.end + 1);
                            if (this.props.onSelectionExtended) {
                                this.props.onSelectionExtended(
                                    this.getSelectedRows(newSelection)
                                );
                            }
                        }
                    }
                    break;
            }
        }
    };

    onTableFocus = () => {
        if (this.state.selection === undefined && this.previousRowCount > 0) {
            this.setState({ selection: { start: 0, end: 0 } });
        }
    };

    onReadyForMoreData = () => {
        const {
            onReadyForMoreData,
            bodyHeight,
            getRowHeightPixels,
            rows,
        } = this.props;
        // Do some calculations to see what row position the table should scroll to when
        // the new page is added (i.e. we want it to position itself so that the next row
        // of data appears where the "load more" button was before); if we don't calculate
        // the offset, it will position the row previously above the "load more" button
        // at the top of the dialog, instead of near the bottom
        let offset = 0;
        if (bodyHeight) {
            // If we measure height in rows, just use that for the offset
            if (bodyHeight.heightInRows) {
                offset = bodyHeight.heightInRows;
            } else if (bodyHeight.heightInPixels) {
                // Otherwise, calculate the row offset by dividing the body height by the row height
                const rowHeightInPixels =
                    typeof getRowHeightPixels === 'function'
                        ? getRowHeightPixels(rows[0])
                        : getRowHeightPixels;
                if (typeof rowHeightInPixels === 'number') {
                    offset = Math.floor(
                        bodyHeight.heightInPixels / rowHeightInPixels
                    );
                }
            }
        }
        this.setState({ rowIndexToScrollTo: rows.length - offset + 1 }); // add 1 to account for the "load more" row
        if (onReadyForMoreData && typeof onReadyForMoreData === 'function') {
            onReadyForMoreData();
        }
    };

    renderTable = ({ measureRef, contentRect }) => {
        // In Jest the content rectangle is not computed so we need to avoid this rendering.
        if (!Environment.isTest && !contentRect.bounds.width) {
            // If we don't have a width yet, do a cheap render of a div with a width of 100%.
            // Note that we still apply className and style so that this will be affected by
            // whatever the consumer has set (if, for instance, they have set a specific width).
            return (
                <div
                    className={`datatable ${this.props.theme} ${this.props.className}`}
                    style={{ width: '100%', ...this.props.style }}
                    ref={measureRef}
                />
            );
        } else {
            const hasVerticalScrollbar =
                (!this.props.isFiltering || this.props.isRetrievingNewPage) &&
                this.getHasVerticalScrollbar();

            const shouldShowLoader =
                this.props.isRetrievingMoreData && !this.props.isFiltering;

            this.scrollBarSize = Environment.scrollBarSize;
            // Only show the load more button when we receive there's more data available and there's
            // a function to retrieve that data, except when we're loading a new set of data (or filtering
            // an existing set) that isn't a new page to the existing set
            const shouldShowLoadMoreButton =
                (this.props.isRetrievingNewPage ||
                    (!this.props.isRetrievingMoreData &&
                        !this.props.isFiltering)) &&
                this.props.onReadyForMoreData &&
                this.props.isMoreDataAvailable;

            let totalWidthPixels = this.props.columns
                .filter(this.isColumnVisible)
                .map((c) =>
                    c.width.overrideWidthPixels === undefined
                        ? c.width.minWidthPixels
                        : c.width.overrideWidthPixels
                )
                .reduce((sum, val) => sum + val, 0);
            if (hasVerticalScrollbar) {
                totalWidthPixels += this.scrollBarSize;
            }

            const hasHorizontalScrollbar =
                totalWidthPixels > contentRect.bounds.width;

            const sizingParameters: SizingParameters = {
                hasHorizontalScrollbar,
                hasVerticalScrollbar,
                forcedWidthPixels: hasHorizontalScrollbar
                    ? totalWidthPixels
                    : null,
            };
            const scrollBarCornerSize = hasVerticalScrollbar
                ? this.scrollBarSize
                : 0;
            return (
                <div
                    className={`datatable ${this.props.theme} ${this.props.className}`}
                    style={{ ...this.props.style, isolation: 'isolate' }}
                >
                    <div className='datatable__header'>
                        {this.props.renderHeader &&
                            this.props.renderHeader(
                                () => this.renderHeader(sizingParameters),
                                this.props.tableData
                            )}
                        {!this.props.renderHeader &&
                            this.renderHeader(sizingParameters)}
                    </div>
                    {this.props.shouldShowAdditionalHeader &&
                        this.renderAdditionalHeader(sizingParameters)}
                    <div
                        className={`datatable__body ${
                            this.props.isRetrievingNewPage
                                ? 'datatable__loading-more'
                                : ''
                        }`}
                        ref={this.bodyRef}
                        style={this.getBodyStyle(
                            sizingParameters,
                            shouldShowLoadMoreButton
                        )}
                        onScroll={this.handleVerticalScroll}
                        tabIndex={
                            this.props.isRowHighlightSelectionEnabled
                                ? -1
                                : undefined
                        }
                        onKeyDown={this.onKeyDown}
                        onFocus={this.onTableFocus}
                    >
                        {this.props.isFiltering &&
                        !this.props.isRetrievingNewPage ? (
                            <div>
                                {Localization.getString(
                                    Strings.filteringMessage
                                )}
                            </div>
                        ) : shouldShowLoader ? null : (
                            this.renderRows(sizingParameters)
                        )}
                        {shouldShowLoadMoreButton && (
                            <div className='datatable__loadmore-container'>
                                <Button
                                    id={`${this.props.id}--load-more-button`}
                                    className='datatable__loadmore'
                                    isFlat
                                    type={BUTTON_TYPE.PRIMARY}
                                    onClick={this.onReadyForMoreData}
                                >
                                    {Localization.getString(Strings.loadMore)}
                                </Button>
                            </div>
                        )}
                        {shouldShowLoader && DataTable.renderLoader()}
                    </div>
                    {this.props.shouldShowFooter &&
                        this.renderFooter(sizingParameters)}
                    {hasHorizontalScrollbar && (
                        <div
                            className='datatable__scrollbar__container'
                            style={{ height: this.scrollBarSize }}
                        >
                            <div
                                className='datatable__scrollbar'
                                onScroll={this.handleHorizontalScroll}
                                style={{
                                    height: this.scrollBarSize,
                                    width: 'auto',
                                    display: 'block',
                                    marginRight: scrollBarCornerSize,
                                }}
                            >
                                <div
                                    className='datatable__scrollbar-content'
                                    style={{ width: totalWidthPixels }}
                                >
                                    {/* Chrome doesn't allow scrolling for some reason with an empty div */}
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    };

    render() {
        return !this.props.rows.length &&
            this.props.emptyContent &&
            !this.props.isFiltering &&
            !this.props.isRetrievingMoreData &&
            !this.props.isRetrievingNewPage ? (
            this.props.emptyContent
        ) : (
            <Measure bounds>{this.renderTable}</Measure>
        );
    }
}

export default DataTable;
