import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { redirect } from 'util/redirect';

export type BetaAwareLinkProps = Omit<LinkProps, 'to'> & { to: string };

function isModifiedEvent(event) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export default React.forwardRef(function BetaAwareLink(
    { to, onClick, ...props }: BetaAwareLinkProps,
    ref: React.Ref<HTMLAnchorElement>
) {
    // This link is important because we want to use redirect to ensure proper navigation,
    // navigation is done differently to other React Router pages than to Beta pages
    // We add a `to` as well so that open in a new tab still works.
    const handleClick =
        onClick ||
        ((e) => {
            // We only want to handle left click
            if (!isModifiedEvent(e)) {
                e.preventDefault();
                redirect(to as string);
            }
        });
    return <Link {...props} ref={ref} to={to} onClick={handleClick} />;
});
