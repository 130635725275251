import Mustache from 'mustache';

export { arrayToRecord } from './arrayToRecord';

export const Localization = {
    /**
     * Get a localized string.
     *
     * @param template key of localized string in a *.strings.json file
     * @param values string, string[], or key/value pairs to replace in a templated string (if applicable). eg
     * `Localization.getString(Strings.stringOne, { name: 'World!' })`
     * where `name` is a template key to be replaced with `World!`.
     */
    getString: (
        template: string,
        values?: Record<string, string | number>
    ): string => {
        return Mustache.render(template, values || {});
    },
    /**
     * Returns a string (template) as defined by stringsObject[stringName] or a default value if
     * that stringsObject doesn't have that string in it.  We have this method because there's a proxy
     * that throws an error (in dev mode) if a string doesn't exist (see strings-loader.js).
     */
    getStringOrDefault: (
        stringsObject: Record<string, string>,
        stringName: string,
        defaultValue: string
    ): string => {
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line no-prototype-builtins
        return stringsObject.hasOwnProperty(stringName)
            ? stringsObject[stringName]
            : defaultValue;
    },
};

/**
 * Convenient shorthand export for `Localization.getString()`.
 */
export const localize = Localization.getString;
