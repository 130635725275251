﻿import React, { Component } from 'react';
import TtdInputField, { TtdInputFieldComponentProps } from '../TtdInputField';
import { Reflection } from '../../../util/Reflection';
import CountControl, { CountControlProps } from '../../controls/CountControl';
import ValidationService from '../../../util/ValidationService';
import getInputId from '../../../util/getInputId';
import ValidationError from '../../display/ValidationError';
import { Normalizer, Formatter, Parser } from 'redux-form';

export type CountInputProps = Omit<CountControlProps, 'id' | 'name' | 'value'> &
    TtdInputFieldComponentProps<{
        defaultValue?: number;
        isValidationInline?: boolean;
        /**
         * @deprecated This has never had any affect.
         * @summary If you need to set a Max value, use a c# MaxValueAttribute or RangeAttribute
         */
        max?: number;
        /**
         * @deprecated This has never had any affect.
         * @summary If you need to set a Min value, use a c# MinValueAttribute or RangeAttribute
         */
        min?: number;
        onBeforeChange?: any;
    }>;

class CountInput extends Component<CountInputProps> {
    static defaultProps = {
        isValidationInline: false,
        theme: '',
    };

    static displayName = 'CountInput';

    normalize: Normalizer = (value, previousValue) => {
        if (
            this.props.onBeforeChange &&
            this.props.onBeforeChange(value, previousValue) === false
        ) {
            return previousValue;
        } else {
            return value;
        }
    };

    format: Formatter = (value) => {
        return Reflection.toInputFromStorage(value);
    };

    parse: Parser = (value) => {
        return Reflection.toStorageFromInput(value, null);
    };

    renderInput(fieldProps) {
        const {
            input,
            meta,
            field,
            isValidationInline,
            format,
            normalize,
            parse,
            validate,
            max,
            min,
            ...rest
        } = fieldProps;
        const errors = ValidationService.getVisibleErrors(meta);
        const errorClass = (errors.length && 'input--error') || '';

        const wrapperClass = ValidationService.getWrapperClass(
            'count-input',
            isValidationInline
        );

        return (
            <div className={wrapperClass}>
                <CountControl
                    {...input}
                    {...rest}
                    id={getInputId(CountInput, fieldProps)}
                    className={errorClass}
                />
                <ValidationError
                    isInline={fieldProps.isValidationInline}
                    meta={meta}
                    inlineTopOffsetPixels={3}
                    inlineLeftOffsetPixels={8}
                />
            </div>
        );
    }

    render() {
        const { className, style, ...rest } = this.props;

        return (
            <div className={className} style={style}>
                <TtdInputField
                    parse={this.parse} // parse format and normalize can be overridden by consumers
                    format={this.format}
                    normalize={this.normalize}
                    {...rest}
                    component={this.renderInput}
                />
            </div>
        );
    }
}

export default CountInput;
