﻿import { getUserPermissionState } from '../reducers/permissions';
import Environment from './Environment';

let store: { getState(): any };

export const setStore = (_store) => {
    store = _store;
};

const permissionOverridesKey = 'permissionOverrides';
const developerModeKey = 'developerMode';

window.overridePermission = function (permission, value) {
    const currentOverrides = getPermissionOverridesFromLocalStorage();

    // Turn on permission overrides. We store this in a simple boolean so that
    // allowing permission overrides doesn't impact performance in production.
    window.enablePermissionOverrides = true;
    const { allPermissions } = getUserPermissionState(store.getState());

    function outputCurrentState() {
        const otherPermissions = [];
        Object.keys(allPermissions)
            .filter((key) => !key.startsWith('$'))
            .forEach((permission) => {
                const override = currentOverrides[permission];

                if (override === undefined) {
                    otherPermissions.push(permission);
                } else if (override) {
                    console.log(`%c${permission} forced ON.`, 'color: green');
                } else {
                    console.log(`%c${permission} forced OFF.`, 'color: red');
                }
            });

        if (otherPermissions.length > 0) {
            console.log('Other Permissions: ' + otherPermissions.join(', '));
        }
    }

    if (permission !== undefined) {
        permission = permission.trim();
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line no-prototype-builtins
        if (!allPermissions.hasOwnProperty(permission)) {
            console.error(`The permission ${permission} was unrecognized.`);
        } else if (value === undefined) {
            delete currentOverrides[permission];
            updatePermissionOverridesInLocalStorage(currentOverrides);
        } else {
            currentOverrides[permission] = !!value;
            updatePermissionOverridesInLocalStorage(currentOverrides);
        }
    }

    outputCurrentState();
};

const emptyPermissionOverrides = Object.freeze({});

function getPermissionOverridesFromLocalStorage() {
    // If we're in production and haven't enabled permission overrides, don't mess
    // around with checking local storage for permission overrides - we don't want
    // the performance penalty.
    if (!window.enablePermissionOverrides && Environment.isProduction)
        return emptyPermissionOverrides;

    try {
        if (window.localStorage) {
            const json = window.localStorage.getItem(permissionOverridesKey);
            if (json === undefined) {
                return {};
            }
            return JSON.parse(json) || {};
        }
        return {};
    } catch (e) {
        // Not the end of the world if this doesn't work out, we'll just return an empty object.
        console.error(`Couldn't find existing permission override settings.`);
        return {};
    }
}

function updatePermissionOverridesInLocalStorage(permissionOverrides) {
    window.localStorage.setItem(
        permissionOverridesKey,
        JSON.stringify(permissionOverrides)
    );
}

export function hasPermission(state, permission) {
    const permissionOverrides = getPermissionOverridesFromLocalStorage();
    const { permissions, allPermissions } = getUserPermissionState(state);

    if (
        !Environment.isProduction &&
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line no-prototype-builtins
        !allPermissions.hasOwnProperty(permission)
    ) {
        console.error(
            `Warning: Checking for unrecognized permission ${permission}.`
        );
    }

    if (permissionOverrides[permission] === undefined) {
        return !!permissions[permission];
    } else {
        return permissionOverrides[permission];
    }
}

export function hasAnyPermission(state, permissionArray: string[]): boolean {
    return !!permissionArray.find((permission) => {
        return hasPermission(state, permission);
    });
}

export function hasAllPermissions(state, permissionArray) {
    return (
        permissionArray.filter((permission) => {
            return hasPermission(state, permission);
        }).length === permissionArray.length
    );
}

window.setDeveloperMode = function (value: boolean) {
    window.localStorage.setItem(developerModeKey, JSON.stringify(value));

    const newDevModeSetting = isDeveloperMode();
    if (newDevModeSetting) {
        console.log(`%c${developerModeKey} forced ON.`, 'color: green');
    } else {
        console.log(`%c${developerModeKey} forced OFF.`, 'color: red');
    }
};

export function isDeveloperMode() {
    try {
        if (window.localStorage) {
            const json = window.localStorage.getItem(developerModeKey);
            if (json === undefined) {
                return false;
            }
            return JSON.parse(json) || false;
        }
        return false;
    } catch (e) {
        // Not the end of the world if this doesn't work out, we'll just the default false behavior
        console.error(`Couldn't find existing developer mode setting.`);
        return false;
    }
}
