import React, { ReactNode, useRef } from 'react';

import { WrappedFieldProps } from 'redux-form';
import { Reflection } from 'util/Reflection';
import DropDownMenuComponent, { useDropDownState } from '../DropDownMenu';
import Button, { BUTTON_TYPE } from '../Button';
import { Localization } from 'util/LocalizationService';
import {
    isValidDateTime,
    formatDateTime,
    DEFAULT_MOMENT_DATE_FORMAT,
} from 'util/DateTimeUtils';
import Strings from './Filters.strings.json';
import FilterLabel from './FilterLabel';
import './Filter.scss';

type FilterProps = {
    label: ReactNode;
    description: ReactNode;
    inputNode: ReactNode;
    displayNameKey?: string;
    embedFilterInPortal?: boolean;
} & WrappedFieldProps;

const getInputValueText = (
    value: Record<string, string>,
    displayNameKey: string,
    truncate: boolean = false
): string => {
    let text: string = !Reflection.isEmptyValue(value)
        ? value[displayNameKey]
        : null;

    if (truncate && text && text.length > 3) {
        text = `${text.substr(0, 3)}...`;
    }

    return text;
};

const getDisplayValue = (input, displayNameKey, description) => {
    let valueText = null;
    if (Array.isArray(input.value)) {
        // if this is an array filter with one item selected (checkbox group or multiselect list)
        if (input.value.length === 1) {
            valueText = getInputValueText(input.value[0], displayNameKey);
        } else if (input.value.length > 1) {
            // For multiple values, include an ellipsed portion of the 2nd value, as a hint that multiple values selected
            valueText = [
                getInputValueText(input.value[0], displayNameKey),
                getInputValueText(input.value[1], displayNameKey, true),
            ].join(', ');
        }
    } else if (isValidDateTime(input.value)) {
        valueText = formatDateTime(input.value, DEFAULT_MOMENT_DATE_FORMAT);
    } else {
        valueText = getInputValueText(input.value, displayNameKey);
    }

    // Final output is "$description ($valueText)", falling back to $description valueText not set
    return valueText ? `${description} (${valueText})` : description;
};

export function Filter({
    inputNode,
    input,
    displayNameKey,
    description,
    embedFilterInPortal,
}: FilterProps) {
    const modal = useRef(null);
    const [isOpen, setIsOpen] = useDropDownState(modal);

    return (
        <DropDownMenuComponent
            menuClassName='ac-filter__drop-down'
            embedInPortal={embedFilterInPortal}
            suppressGlyph
            alignment='right'
            offsetTop={30}
            label={
                <FilterLabel
                    onClick={() => setIsOpen(!isOpen)}
                    hasOpenDialog={isOpen}
                    hasValue={
                        !Reflection.isEmptyValue(input.value) &&
                        input.value !== false
                    }
                    labelText={getDisplayValue(
                        input,
                        displayNameKey,
                        description
                    )}
                />
            }
            id={`ac-filter__menu__${input.name}`}
            isOpen={isOpen}
            theme='filter'
        >
            <li
                className='ac-filter__editor'
                ref={modal}
                onMouseDown={(e) => {
                    // This is to stop DropDownMenuComponents mousedown event from cancelling
                    // focus events for inputs, which it does by caling e.preventDefault()
                    e.stopPropagation();
                }}
            >
                {inputNode}
                <div className='ac-filter__editor__buttons'>
                    <Button
                        id='ac-filter__editor__buttons-save'
                        className='ac-filter__editor__buttons-save'
                        onClick={() => setIsOpen(false)}
                        isFlat
                        type={BUTTON_TYPE.PRIMARY}
                    >
                        {Localization.getString(Strings.close)}
                    </Button>

                    <div className='ac-filter__editor__buttons-spacer' />
                    <Button
                        id='ac-filter__editor__buttons-cancel'
                        onClick={() => {
                            input.onChange(null);
                        }}
                        type={BUTTON_TYPE.SECONDARY}
                        isFlat
                        isEnabled={
                            !Reflection.isEmptyValue(input.value) &&
                            input.value !== false
                        }
                    >
                        {Localization.getString(Strings.clear)}
                    </Button>
                </div>
            </li>
        </DropDownMenuComponent>
    );
}
