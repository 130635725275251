﻿import { ComponentType } from 'react';
import {
    WrappedFieldProps,
    WrappedFieldMetaProps,
    FieldArrayMetaProps,
} from 'redux-form';

type IdOnly = { id: string };
type NameAndMeta = {
    input: {
        name: string;
    };
    meta: WrappedFieldMetaProps | FieldArrayMetaProps;
};
type GetInputIdParams = WrappedFieldProps | IdOnly | NameAndMeta;
export default function getInputId(
    component: ComponentType<any>,
    fieldProps: GetInputIdParams
) {
    const { id } = fieldProps as IdOnly;
    const { input, meta } = fieldProps as NameAndMeta | WrappedFieldProps;

    // Allow the consumer to override the ID.
    if (id) return id;

    // @ts-ignore - `id` does not exist on WrappedFieldInputProps
    // Allow the consumer to override the ID by placing it on the <Field/> component.
    if (input && input.id) return input.id;

    // If meta & input exist, use them to produce a unique ID for the field.
    if (meta && input) {
        return `${meta.form}-${
            component.displayName ||
            component.name ||
            component.constructor.name ||
            'unknown_component'
        }-${input.name}`;
    } else {
        throw new Error('Must specify "id", "input.id" or "input" AND "meta".');
    }
}
