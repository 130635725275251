import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/throttle';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/observable/interval';

import keycode from 'keycode';

export const keyupSource = Observable.fromEvent<KeyboardEvent>(
    document,
    'keyup'
);

export const keydownSource = Observable.fromEvent<KeyboardEvent>(
    document,
    'keydown'
);

export const throttledEscapeSource = keyupSource
    .throttle((val) => Observable.interval(100))
    .filter((event: KeyboardEvent) => keycode(event) === 'esc');

export const mouseMoveSource = Observable.fromEvent<MouseEvent>(
    document,
    'mousemove'
);

export const mouseUpSource = Observable.fromEvent<MouseEvent>(
    document,
    'mouseup'
);

export const dragEndSource = Observable.fromEvent<MouseEvent>(
    document,
    'dragend'
);

export const throttledMouseMoveSource = mouseMoveSource.throttle((_) =>
    Observable.interval(50)
);

export const clickSource = Observable.fromEvent<MouseEvent>(
    document.body,
    'click'
);

export const resizeSource = Observable.fromEvent<UIEvent>(window, 'resize');

export const focusSource = Observable.fromEvent<FocusEvent>(
    document,
    'focus',
    true
);

export const throttledResizeSource = resizeSource.throttle((_) =>
    Observable.interval(100)
);

export const debouncedResizeSource = resizeSource.debounce((_) =>
    Observable.interval(100)
);
