import { connect } from 'react-redux';
import { change } from 'redux-form';
import MultiSelectListInputField from './MultiSelectListInputField';
const mapActionCreators = {
    change,
};

export default connect(null, mapActionCreators, null, { forwardRef: true })(
    MultiSelectListInputField
);
