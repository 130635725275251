import React from 'react';
import ToolTip from '../ToolTip';
import themeClassName from 'util/Themes';
import glyphMap from 'fonts/glyphs/glyphMap.json';
import GlyphCore from 'components/core/Glyph';
import './Glyph.scss';

export interface GlyphProps {
    name: string;
    title?: string;
    id?: string;
    className?: string;
    style?: React.CSSProperties;
    theme?: string;
    onClick?: any;
    tabIndex?: number;
    isEnabled?: boolean;
}

class Glyph extends React.Component<GlyphProps, {}> {
    static defaultProps = {
        theme: '',
        className: '',
        isEnabled: true,
    };

    render() {
        const {
            id,
            name,
            title,
            onClick,
            className,
            style,
            theme,
            tabIndex,
            isEnabled,
        } = this.props;
        const family = glyphMap[name];
        const generatedId =
            id ||
            `glyph-family-${family}--glyph-${family}-${name}--${new Date().getTime()}`;

        let RootElement: any = 'div';
        const classes = [
            'glyph',
            `glyph--${name}`,
            className,
            isEnabled ? '' : 'disabled',
        ];
        const optionalProps: { type?: string; disabled?: boolean } = {};

        if (onClick) {
            classes.push('glyph--clickable', 'reset-styles');
            // Clickable glyphs are actual <button> elements (so that they support tab/keyboard navigation).
            // We're not using our own <Button> component here because it adds too many additional layers
            // to the DOM, and glyphs are a sensitive styling components where we can't tolerate those extra
            // elements.
            RootElement = 'button';
            optionalProps.type = 'button';

            if (!isEnabled) {
                optionalProps.disabled = true;
            }
        }

        if (theme) {
            classes.push(themeClassName(theme));
        }

        return (
            <RootElement
                id={generatedId}
                className={classes.join(' ')}
                onClick={isEnabled ? onClick : null}
                tabIndex={tabIndex}
                {...optionalProps}
            >
                <ToolTip content={title} isInline>
                    <GlyphCore
                        className='glyph__inner'
                        iconName={name}
                        style={style}
                    />
                </ToolTip>
            </RootElement>
        );
    }
}

export default Glyph;
