import React, { useRef } from 'react';
import { WrappedFieldsProps } from 'redux-form';
import {
    FilterProps,
    FilterProp,
} from 'components/display/DataFilters/SingleDataFilter/FilterProps';
import { DataFilter } from 'components/display/DataFilters/SingleDataFilter';
import FilterLabel from './FilterLabel';
import { Localization } from 'util/LocalizationService';
import Strings from './Filters.strings.json';
import { BasicDialog, DIALOG_SIZE, showDialog, hideDialog } from '../Dialog';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import { Reflection } from 'util/Reflection';
import Button, { BUTTON_TYPE } from '../Button';
import './OverflowFilters.scss';
// '<Glyph>' is deprecated in favor of direct SVG imports
import Glyph from '../Glyph';
import { getFieldName } from 'components/inputs/TtdInputField';

type OverflowProps = {
    filters: FilterProps;
    names: string[];
    show: typeof showDialog;
    hide: typeof hideDialog;
} & WrappedFieldsProps;

function Overflow({
    filters,
    names,
    show,
    hide,
    ...injectedInputs
}: OverflowProps) {
    const dialogId = useRef(v4());

    const fieldValues = Object.values(injectedInputs).map((i) => i.input.value);

    const appliedFilterCount = fieldValues.filter(
        (value) => !Reflection.isEmptyValue(value)
    ).length;

    const clearFilters = () => {
        Object.values(injectedInputs).forEach((i) => i.input.onChange(null));
    };

    const showDialog = () => {
        show(dialogId.current);
    };
    const hideDialog = () => {
        hide(dialogId.current);
    };

    return filters ? (
        <>
            <div className='overflow-filters__label'>
                <FilterLabel
                    labelText={Localization.getString(Strings.more, {
                        appliedFilterCount: appliedFilterCount || '',
                        indicator: appliedFilterCount > 0 ? '·' : '',
                    })}
                    onClick={showDialog}
                    hasValue={appliedFilterCount > 0}
                />
            </div>
            <BasicDialog
                clickAwayToCancel
                onCancel={hideDialog}
                dialogId={dialogId.current}
                size={DIALOG_SIZE.MEDIUM}
                className='overflow-filters__dialog'
            >
                <div className='overflow-filters__dialog__content'>
                    <div className='overflow-filters__dialog__content__header'>
                        <div className='overflow-filters__dialog__content__header__title'>
                            {Localization.getString(Strings.overflowTitle)}
                        </div>
                        <Glyph onClick={hideDialog} name='close' />
                    </div>
                    <div className='overflow-filters__dialog__content__filters'>
                        {filters.map((filter) => {
                            return (
                                <div
                                    className='overflow-filters__dialog__content__filters__filter'
                                    key={getFieldName(filter.field)}
                                >
                                    <DataFilter
                                        isInline={false}
                                        filter={
                                            {
                                                ...filter,
                                                embedInPortal: true,
                                            } as FilterProp
                                        }
                                        className='ac-filters__input'
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className='overflow-filters__dialog__content__buttons'>
                        <Button
                            type={BUTTON_TYPE.PRIMARY}
                            id={`overflow-filters-${dialogId.current}`}
                            onClick={hideDialog}
                            isFlat
                        >
                            {Localization.getString(Strings.close)}
                        </Button>
                        <Button
                            type={BUTTON_TYPE.SECONDARY}
                            className='overflow-filters__dialog__content__buttons__clear'
                            id={`overflow-filters-${dialogId.current}`}
                            onClick={clearFilters}
                            isEnabled={appliedFilterCount > 0}
                            isFlat
                            glyphAfter='close'
                        >
                            {Localization.getString(Strings.clear)}
                        </Button>
                    </div>
                </div>
            </BasicDialog>
        </>
    ) : null;
}

export const OverflowFilters = connect(null, {
    show: showDialog,
    hide: hideDialog,
})(Overflow);
