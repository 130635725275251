/**
 * Convert an array to a a Record, where the keys are the index in the array of each element
 * eg { '0': val0, '1': val1, ... }
 *
 * @param arr Array to convert to a record
 */
export const arrayToRecord = <T>(arr: T[]): Record<string, T> => {
    return arr.reduce(
        (vals, elem, index) => ({
            ...vals,
            [`${index}`]: elem,
        }),
        {}
    );
};
