import React, { Component } from 'react';
import TtdInputFieldArray from '../../inputs/TtdInputFieldArray';
import MultiSelectListInputField from './MultiSelectListInputFieldContainer';
import { MultiSelectListInputProps } from './MultiSelectListInputProps';

export default class MultiSelectListInput<T> extends Component<
    MultiSelectListInputProps<T>
> {
    static defaultProps = {
        className: '',
        size: 'sm',
        isEnabled: true,
        showValidation: true,
    };

    render() {
        const { className, style } = this.props;
        return (
            <div
                className={`multi-select-list-input ${className}`}
                style={style}
            >
                <TtdInputFieldArray
                    {...this.props}
                    component={MultiSelectListInputField}
                />
            </div>
        );
    }
}
