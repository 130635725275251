import React, {
    ChangeEvent,
    Children,
    Component,
    Fragment,
    ReactNode,
    isValidElement,
    cloneElement,
} from 'react';
import v4 from 'uuid/v4';
import { FormAction } from 'redux-form';

import CoordinatedDataFilters, {
    BaseCoordinatedDataFiltersProps,
    CoordinatedDataFiltersFiltersProps,
    CoordinatedDataFilterMode,
} from 'components/display/CoordinatedDataFilters';
import { FilterCoordinatorOptions } from 'util/FilterCoordinator';

import DataViewToolbarComponent, {
    DataViewToolbarComponentProps,
} from './DataViewToolBar';
import { SelectAllState } from './EnhancedDataViewReducer';
import {
    SORT_DIRECTION,
    DataViewProps,
    DataViewLoadFilterAndSortProps,
} from '../types';
import './EnhancedDataView.scss';
export * from './DataViewToolBar';
export { default as DataViewToolbar } from './DataViewToolBar';

export interface EnhancedDataViewActionProps<TItem> {
    initializeDataViewConfig(
        id: string,
        fieldId: string,
        direction: SORT_DIRECTION
    ): void;

    updateDataViewConfigSortField(id: string, fieldId: string): void;

    updateDataViewConfigSortDirection(
        id: string,
        direction: SORT_DIRECTION
    ): void;

    selectAllVisible(id: string, selectionMap): void;
    itemSelect(id: string, itemId: string, item: TItem, checked: boolean): void;
    clearAllSelections(id: string): void;
    selectAllKnownValues(id: string, selectionMap): void;
    change(
        form: string,
        field: string,
        value: any,
        touch?: boolean,
        persistentSubmitErrors?: boolean
    ): FormAction;
}

export interface IBaseFilter<TItem> {
    sortQuery?: {
        fieldId?: string;
        direction: string;
    };
}

export type EnhancedDataViewFiltersProps<
    TItem,
    TFilter extends IBaseFilter<TItem>,
    TFilterResponse
> = Pick<
    BaseCoordinatedDataFiltersProps<TFilter>,
    | 'clearableFilters'
    | 'onClearFilters'
    | 'embedFiltersInPortal'
    | 'maxFilterElements'
> &
    CoordinatedDataFiltersFiltersProps & {
        onFilterItems(filter: TFilter, items: TItem[]);
        handleFilterResponse?: FilterCoordinatorOptions<
            TFilter,
            TFilterResponse
        >['handleResponse'];
        initialFilter?: Partial<TFilter>;
    };

export interface IItemSelectionsChangedData {
    selections: string[];
    allAvailableItemsSelected: boolean;
}

export interface EnhancedDataViewProps<
    TItem,
    TDataViewData = {},
    TItemData = {},
    TFilter = {},
    TFilterResponse = {}
>
    extends DataViewProps<TItem, TDataViewData, TItemData>,
        Omit<DataViewLoadFilterAndSortProps<TItem, TDataViewData>, 'sorting'> {
    formName?: string;

    fieldName?: string;

    children: ReactNode;

    ref?: React.RefObject<
        EnhancedDataViewComponent<
            TItem,
            TDataViewData,
            TItemData,
            TFilter,
            TFilterResponse
        >
    >;

    /**
     * Requires the filtering.onFilterItems function to be provided for EnhancedDataTable to handle
     * the onSort callback, so that any filters are respected
     */
    sorting?: {
        defaultDirection: SORT_DIRECTION;

        defaultField: string;

        // Optional boolean which indicates if all the items (initial and newly added) should be sorted.
        // By default only the initial items are sorted and not the newly added ones.
        sortAllItems?: boolean;

        itemsArePreSorted?: boolean;
    };

    filtering?: EnhancedDataViewFiltersProps<TItem, TFilter, TFilterResponse>;

    enableItemSelection?: boolean;

    onSelectionChanged?(config: IItemSelectionsChangedData): void;

    toolbarActions?: DataViewToolbarComponentProps<TItem>['toolbarActions'];

    maxToolbarActionsDisplay?: DataViewToolbarComponentProps<
        TItem
    >['maxToolbarActionsDisplay'];

    totalAvailableItems?: number;
}

export interface EnhancedDataViewStateProps {
    sortField?: string;
    sortDirection?: SORT_DIRECTION;
    selectionMap: any;
    selectAllState: SelectAllState;
}

interface EnhancedDataViewState<TItem, TFilter> {
    isMoreDataAvailable?: boolean;
    enableItemSelection?: boolean;
    selectAllState: SelectAllState;
    items: TItem[];
    filter?: Partial<TFilter>;
}

export type InjectedDataViewProps<TItem, TDataViewData = {}> = Pick<
    EnhancedDataViewProps<TItem>,
    'enableItemSelection' | 'getPrimaryKey'
> &
    Pick<EnhancedDataViewStateProps, 'selectAllState'> &
    Pick<
        DataViewLoadFilterAndSortProps<TItem, TDataViewData>,
        | 'initialItems'
        | 'isFiltering'
        | 'isMoreDataAvailable'
        | 'isRetrievingMoreData'
        | 'isRetrievingNewPage'
    > & {
        /**
         * Sorting info
         */
        sorting: {
            direction: SORT_DIRECTION;
            fieldId: string;
            onChangeSort: (fieldId: string, direction: SORT_DIRECTION) => void;
            sortAllItems: boolean;
            itemsArePreSorted: boolean;
            // The following properties are for compatibility with DataTable.
            // Eventually we should migrate to a single model.
            columnId: string;
            sortAllRows: boolean;
            rowsArePreSorted: boolean;
        };

        /**
         * Table data that was passed in
         */
        tableData: TDataViewData & {
            getPrimaryKey: EnhancedDataViewProps<TItem>['getPrimaryKey'];
            selectionMap: any;
        };

        /**
         * Should be called to change individual selection states
         */
        getOnCheckedChange: (
            item: TItem
        ) => (event: ChangeEvent<HTMLInputElement>) => void;

        /**
         * Should be called to select all the visible items
         */
        selectAllChange: () => void;
    };

/**
 * A "wrapper" component which wraps it's children data views with sorting, filtering and selection capabilities.
 *
 * A good example of the current usage is the `EnhancedDataTableWrapper` component.
 */
class EnhancedDataViewComponent<
    TItem,
    TDataViewData,
    TItemData,
    TFilter,
    TFilterResponse
> extends Component<
    EnhancedDataViewProps<
        TItem,
        TDataViewData,
        TItemData,
        TFilter,
        TFilterResponse
    > &
        EnhancedDataViewActionProps<TItem> &
        EnhancedDataViewStateProps,
    EnhancedDataViewState<TItem, TFilter>
> {
    static SORT_DIRECTION = SORT_DIRECTION;
    static FILTER_MODE = CoordinatedDataFilterMode;

    static defaultProps = {
        className: '',
    };

    state: EnhancedDataViewState<TItem, TFilter> = {
        isMoreDataAvailable: undefined,
        enableItemSelection: undefined,
        selectAllState: undefined,
        items: [],
        filter: this.props.filtering
            ? this.props.filtering.initialFilter
            : undefined,
    };

    private readonly dataFilterFormName: string = v4();

    componentDidMount() {
        const { id, sortField, sortDirection, sorting } = this.props;
        const fieldId = (sorting && sorting.defaultField) || sortField;
        const direction =
            (sorting && sorting.defaultDirection) || sortDirection;
        this.props.initializeDataViewConfig(id, fieldId, direction);
    }

    componentDidUpdate(prevProps: EnhancedDataViewStateProps) {
        if (
            this.props.onSelectionChanged &&
            ((prevProps.selectAllState &&
                prevProps.selectAllState !== this.props.selectAllState) ||
                (prevProps.selectionMap &&
                    this.props.selectionMap !== prevProps.selectionMap))
        ) {
            this.props.onSelectionChanged({
                selections: Object.keys(this.props.selectionMap),
                allAvailableItemsSelected:
                    this.props.selectAllState ===
                    SelectAllState.AllVisibleSelected,
            });
        }
    }

    static getDerivedStateFromProps(
        nextProps: EnhancedDataViewProps<any> &
            EnhancedDataViewStateProps &
            EnhancedDataViewActionProps<any>,
        prevState: EnhancedDataViewState<any, any>
    ) {
        if (
            nextProps.enableItemSelection !== prevState.enableItemSelection ||
            nextProps.selectAllState !== prevState.selectAllState ||
            nextProps.items !== prevState.items
        ) {
            return {
                selectAllState: nextProps.selectAllState,
                enableItemSelection: nextProps.enableItemSelection,
                isMoreDataAvailable: nextProps.isMoreDataAvailable,
                items: nextProps.items,
            } as EnhancedDataViewState<any, any>;
        }
        return null;
    }

    private static selectAllChange = (
        {
            id,
            items,
            getPrimaryKey,
            getIsItemVisible,
            getIsItemDisabled,
            itemsData = {},
            viewData,
        }: Pick<
            EnhancedDataViewProps<any> & EnhancedDataViewActionProps<any>,
            | 'id'
            | 'items'
            | 'getPrimaryKey'
            | 'getIsItemVisible'
            | 'getIsItemDisabled'
            | 'itemsData'
            | 'viewData'
        >,
        callback: (id: string, selectionMap) => void
    ) => () => {
        let computedItems = items;
        if (getIsItemVisible) {
            computedItems = computedItems.filter((r) => {
                const key = getPrimaryKey(r);
                return getIsItemVisible(r, itemsData[key], viewData);
            });
        }

        if (getIsItemDisabled) {
            computedItems = computedItems.filter((r) => {
                const key = getPrimaryKey(r);
                return !getIsItemDisabled(r, itemsData[key], viewData);
            });
        }

        callback(
            id,
            computedItems.reduce((map, item) => {
                map[getPrimaryKey(item)] = true;
                return map;
            }, {})
        );
    };

    onSort = (fieldId: string, direction: SORT_DIRECTION) => {
        if (this.props.filtering && this.props.filtering.onFilterItems) {
            const sortQuery = {
                fieldId,
                direction,
            };

            // we need to update redux-form in order to trigger a filter change event
            this.props.change(this.dataFilterFormName, 'sortQuery', sortQuery);
        }

        this.props.updateDataViewConfigSortField(this.props.id, fieldId);
        this.props.updateDataViewConfigSortDirection(this.props.id, direction);
    };

    getOnCheckedChange = () => {
        const { id, itemSelect, getPrimaryKey } = this.props;
        return (item: TItem) => (event: ChangeEvent<HTMLInputElement>) => {
            const itemId = getPrimaryKey(item) as string;
            itemSelect(id, itemId, item, event.target.checked);
        };
    };

    selectAllKnownItems = () => {
        EnhancedDataViewComponent.selectAllChange(
            this.props,
            this.props.selectAllKnownValues
        )();
    };

    onFilterChange = (filter: TFilter) => {
        this.setState({ filter });

        const result = this.props.filtering.onFilterItems(
            {
                ...filter,
                sortQuery: {
                    // To avoid changing existing endpoints, just using columnId.
                    // Eventually we should migrate to a single model.
                    columnId: this.props.sortField,
                    direction: this.props.sortDirection,
                },
            },
            this.props.items
        );
        // Always return something so we don't blow up the filtercoordinator.
        return result === undefined || result === null ? true : result;
    };

    clearSelections = () => {
        this.props.clearAllSelections(this.props.id);
    };

    getInjectedProps = () => {
        const {
            sorting,
            sortField,
            sortDirection,
            viewData,
            selectionMap,
            id,
            items,
            getPrimaryKey,
            getIsItemVisible,
            getIsItemDisabled,
            itemsData,
            enableItemSelection,
            selectAllVisible,
            selectAllState,
            initialItems,
            isFiltering,
            isMoreDataAvailable,
            isRetrievingMoreData,
            isRetrievingNewPage,
        } = this.props;

        const sortParams = sorting
            ? {
                  direction: sortDirection || sorting.defaultDirection,
                  fieldId: sortField || sorting.defaultField,
                  onChangeSort: this.onSort,
                  sortAllItems: sorting.sortAllItems,
                  itemsArePreSorted: sorting.itemsArePreSorted,

                  // Duplicating for backwards compat
                  columnId: sortField || sorting.defaultField,
                  sortAllRows: sorting.sortAllItems,
                  rowsArePreSorted: sorting.itemsArePreSorted,
              }
            : undefined;

        const computedViewData = {
            ...(viewData || ({} as any)),
            getPrimaryKey,
            selectionMap,
        };

        return {
            initialItems,
            enableItemSelection,
            sorting: sortParams,
            getPrimaryKey,
            tableData: computedViewData,
            isFiltering,
            isMoreDataAvailable,
            isRetrievingMoreData,
            isRetrievingNewPage,

            // Pass the selection props down to the components to handle selection
            selectAllChange: EnhancedDataViewComponent.selectAllChange(
                {
                    id,
                    items,
                    getPrimaryKey,
                    getIsItemVisible,
                    getIsItemDisabled,
                    itemsData,
                    viewData,
                },
                selectAllVisible
            ),
            getOnCheckedChange: this.getOnCheckedChange(),
            selectAllState,
        };
    };

    render() {
        const {
            selectionMap,
            selectAllState,
            toolbarActions = [],
            maxToolbarActionsDisplay,
            totalAvailableItems,
            filtering,
            children,
        } = this.props;

        const showToolbar =
            toolbarActions.length > 0 &&
            Object.keys(selectionMap || {}).length > 0;
        const {
            rightFilters = [],
            leftFilters = [],
            filters = [],
            onFilterItems,
            initialFilter,
            filterMode,
            ...remainingFilterProps
        } = filtering || {};
        const left = leftFilters && leftFilters.length ? leftFilters : null;
        const right = rightFilters && rightFilters.length ? rightFilters : null;
        const allFilters = filters && filters.length ? filters : null;
        const filterProps = {
            ...remainingFilterProps,
            ...(filterMode === CoordinatedDataFilterMode.HideOverflow
                ? {
                      filters: allFilters,
                      leftFilters: null as never,
                      rightFilters: null as never,
                  }
                : {
                      leftFilters: left,
                      rightFilters: right,
                      filters: null as never,
                  }),
        };
        const hasToolbar = showToolbar || left || right || allFilters;
        const Wrapper = hasToolbar ? 'div' : Fragment;

        const propsToPass = this.getInjectedProps();
        const childrenWithProps = Children.map(children, (child: any) =>
            !child ||
            !child.type ||
            (child.type &&
                child.type.$$typeof !== Symbol.for('react.forward_ref') &&
                (!isValidElement(child) || typeof child.type !== 'function'))
                ? child
                : cloneElement(child, propsToPass)
        );

        const wrapperProps = hasToolbar
            ? {
                  className: 'enhanced-dataview-wrapper',
              }
            : {};

        return (
            <Wrapper {...wrapperProps}>
                <DataViewToolbarComponent
                    clearSelections={this.clearSelections}
                    selectAllKnownItems={this.selectAllKnownItems}
                    selectAllState={selectAllState}
                    selectionMap={selectionMap}
                    toolbarActions={toolbarActions}
                    maxToolbarActionsDisplay={maxToolbarActionsDisplay}
                    totalAvailableItems={totalAvailableItems}
                    isVisible={showToolbar}
                />
                {(left || right || allFilters) && (
                    <CoordinatedDataFilters
                        {...filterProps}
                        filterMode={filterMode as any}
                        className={`enhanced-dataview-filters ${
                            showToolbar
                                ? 'enhanced-dataview-filters--hidden'
                                : ''
                        } ${
                            filterMode ===
                            CoordinatedDataFilterMode.HideOverflow
                                ? 'enhanced-dataview-filters--with-overflow'
                                : ''
                        }`}
                        formName={this.dataFilterFormName}
                        destroyOnUnmount={true}
                        onFilterChange={this.onFilterChange}
                        initialValues={filtering.initialFilter}
                    />
                )}
                {childrenWithProps}
            </Wrapper>
        );
    }
}

export default EnhancedDataViewComponent;
