import React from 'react';
import ToolTip, { DisplayMode } from '../ToolTip';
import ReactDOM from 'react-dom';
import themeClassName from 'util/Themes';
import './DropDownMenuItem.scss';

export interface DropDownMenuItemProps<TData>
    extends React.Props<DropDownMenuItem<TData>> {
    id: string;
    data?: TData;
    onClick?: (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        data: TData
    ) => void;
    hasFocus?: boolean;
    tooltip?: JSX.Element;
    tooltipDisplayMode?: DisplayMode;
    className?: string;
    isEnabled?: boolean;
    // Optional that indicates this menu item can't have focus
    // This prop is read by DropDownMenuComponent
    disableFocus?: boolean;
    // Optional theme to apply to this dropdown menu item
    theme?: 'header' | 'disabled' | 'callouts' | 'audience-search';
}

interface DropDownMenuItemState {
    hasFocus: boolean;
}

class DropDownMenuItem<TData> extends React.Component<
    DropDownMenuItemProps<TData>,
    DropDownMenuItemState
> {
    state = {
        hasFocus: false,
    };

    onClick = (e) => {
        if (!this.props.isEnabled) {
            e.preventDefault();
            return;
        }
        if (!e.defaultPrevented) {
            this.props.onClick && this.props.onClick(e, this.props.data);

            if (!e.ctrl) {
                const child = (ReactDOM.findDOMNode(
                    this
                ) as HTMLElement).querySelector('a');
                /* forward event info to the intended recipient
                (needed for ctrl events and other mouse/keyboard related modifications of the original event) */
                return (
                    child &&
                    e.target !== child &&
                    child.dispatchEvent(e.nativeEvent)
                );
            }
        }
    };

    onMouseOver = () => {
        this.setState({
            hasFocus: true,
        });
    };

    onMouseOut = () => {
        this.setState({
            hasFocus: false,
        });
    };

    onDragLeave(e) {
        e.preventDefault();
    }

    static defaultProps = {
        className: '',
        isEnabled: true,
    };

    render() {
        const {
            id,
            children,
            className,
            hasFocus,
            isEnabled,
            theme,
            tooltip,
            tooltipDisplayMode,
        } = this.props;

        const classes = [
            'drop-down-menu-item',
            className,
            themeClassName(theme),
        ];
        classes.push(
            isEnabled
                ? 'drop-down-menu-item--enabled'
                : 'drop-down-menu-item--disabled'
        );
        if (hasFocus || this.state.hasFocus) {
            classes.push('drop-down-menu-item--active');
        }
        return (
            <li
                id={id}
                className={classes.join(' ')}
                onClick={this.onClick}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                draggable={true}
                onDragLeave={this.onDragLeave}
            >
                <ToolTip
                    content={tooltip}
                    displayMode={tooltipDisplayMode || DisplayMode.overflow}
                >
                    <div
                        className={`drop-down-menu-item__label-content ${
                            !isEnabled
                                ? 'drop-down-menu-item__label-content-disabled'
                                : ''
                        }`}
                    >
                        {React.isValidElement(children) ? (
                            children
                        ) : (
                            <span>{children}</span>
                        )}
                    </div>
                </ToolTip>
            </li>
        );
    }
}

export default DropDownMenuItem;
