import Immutable from 'seamless-immutable';
import { DIALOG_ACTION_TYPES, DIALOG_STATUS } from './DialogActions';
import zIndexes from '../../../util/ZIndex';
import { getStateByKey } from '../../../util/getStateByKey';

const DIALOG_STATE_KEY = 'maui.display.dialog';

type IInstance = Immutable.ImmutableObject<{
    zIndex: number;
    isOpen: boolean;
    statusDetails: {
        dialogStatus: DIALOG_STATUS;
    };
    currentStepIndex: number;
    completedSteps: null;
    isTransitioning: boolean;
}>;

type IState = Immutable.ImmutableObject<{
    currentMaxZIndex: number;
    instances:
        | {
              [k: string]: IInstance;
          }
        | {};
    instanceKeys: string[];
}>;

export const initialInstanceState: IInstance = Immutable({
    zIndex: 0,
    isOpen: false,
    statusDetails: {
        dialogStatus: DIALOG_STATUS.NONE,
    },
    currentStepIndex: 0,
    completedSteps: null,
    isTransitioning: true,
});

export const initialState: IState = Immutable({
    currentMaxZIndex: 0,
    instances: {},
    instanceKeys: [],
});

export const getDialogState = (state: unknown) =>
    getStateByKey(state, DIALOG_STATE_KEY);

const getInstances = (state: IState) => state.instances;

export const getDialogInstanceState = (
    dialogState: IState,
    dialogId: string
): IInstance & any => {
    const instance = getInstances(dialogState)[dialogId];
    return instance || {};
};

export const getIsDialogInstanceOpen = (
    dialogsState: IState,
    dialogId: string
) => {
    const { isOpen } = getDialogInstanceState(dialogsState, dialogId);
    return !!isOpen;
};

export const hasOpenDialogs = (dialogsState: IState) =>
    dialogsState.instanceKeys.filter(
        (key) => dialogsState.instances[key].isOpen
    ).length > 0;

export const INSTANCE_ACTION_HANDLERS = {
    [DIALOG_ACTION_TYPES.SHOW_DIALOG]: (state, zIndex) =>
        state.merge({ isOpen: true, zIndex }),
    [DIALOG_ACTION_TYPES.SET_TASK_DIALOG_DETAILS]: (
        state,
        statusDetails,
        initialValues
    ) =>
        state.merge({
            statusDetails: statusDetails,
        }),
    [DIALOG_ACTION_TYPES.REGISTER_DIALOG]: (
        state = initialInstanceState,
        action
    ) => state.setIn(['dialogId'], action.dialogId),
    [DIALOG_ACTION_TYPES.HIDE_DIALOG]: (state) =>
        state.merge(initialInstanceState),
    [DIALOG_ACTION_TYPES.TRANSITION_DIALOG]: (state, isTransitioning) =>
        state.merge({ isTransitioning }),
};

export const DIALOGS_ACTION_HANDLERS = {
    [DIALOG_ACTION_TYPES.REGISTER_DIALOG]: (state, action) => {
        if (state.instanceKeys.indexOf(action.dialogId) >= 0) {
            return state;
        }

        const instance = INSTANCE_ACTION_HANDLERS[
            DIALOG_ACTION_TYPES.REGISTER_DIALOG
        ](state.instances[action.dialogId], action);
        const instanceKeys = state.instanceKeys.concat(action.dialogId);
        state = state.setIn(['instanceKeys'], instanceKeys);
        return state.setIn(['instances', action.dialogId], instance);
    },
    [DIALOG_ACTION_TYPES.SHOW_DIALOG]: (state, action) => {
        const instance = state.instances[action.dialogId];
        if (instance.isOpen) {
            return state;
        }
        const dialogZIndex = state.currentMaxZIndex + zIndexes.Dialog;
        const target = INSTANCE_ACTION_HANDLERS[action.type](
            instance,
            dialogZIndex
        );
        const newState = state.setIn(['currentMaxZIndex'], dialogZIndex);
        return newState.setIn(['instances', action.dialogId], target);
    },
    [DIALOG_ACTION_TYPES.TRANSITION_DIALOG]: (state, action) => {
        const instance = INSTANCE_ACTION_HANDLERS[
            DIALOG_ACTION_TYPES.TRANSITION_DIALOG
        ](state.instances[action.dialogId], action.isTransitioning);
        return state.setIn(['instances', action.dialogId], instance);
    },
    [DIALOG_ACTION_TYPES.HIDE_DIALOG]: (state, action) => {
        const { dialogId } = action;
        const isOpen = getIsDialogInstanceOpen(state, dialogId);
        if (!isOpen) return state;

        const zIndex = state.instances[action.dialogId].zIndex;
        const instance = INSTANCE_ACTION_HANDLERS[action.type](
            state.instances[action.dialogId],
            action
        );
        state = state.setIn(['instances', action.dialogId], instance);
        const maxZindex = Math.max.apply(
            Math,
            state.instanceKeys
                .filter((k) => k !== action.dialogId)
                .map((k) => state.instances[k].zIndex)
                .concat([0])
        );

        return state.setIn(
            ['currentMaxZIndex'],
            zIndex === state.currentMaxZIndex
                ? maxZindex
                : state.currentMaxZIndex
        );
    },
    [DIALOG_ACTION_TYPES.UNREGISTER_DIALOG]: (state, action) => {
        const instances = state.instances.without(action.dialogId);
        const instanceKeys = state.instanceKeys.filter(
            (k) => k !== action.dialogId
        );
        const newState = state.setIn(['instanceKeys'], instanceKeys);

        return newState.merge({
            instances,
        });
    },

    // TaskDialog and SteppedTaskDialog actions
    [DIALOG_ACTION_TYPES.SET_TASK_DIALOG_DETAILS]: (state, action) => {
        const instance = INSTANCE_ACTION_HANDLERS[action.type](
            state.instances[action.payload.dialogId],
            action.payload.statusDetails
        );
        return state.setIn(['instances', action.payload.dialogId], instance);
    },

    // SteppedTaskDialog actions
    [DIALOG_ACTION_TYPES.SET_CURRENT_STEP]: (state, action) => {
        return state.setIn(
            ['instances', action.dialogId, 'currentStepIndex'],
            action.stepIndex
        );
    },
    [DIALOG_ACTION_TYPES.COMPLETE_STEP]: (state, action) => {
        return state.setIn(
            ['instances', action.dialogId, 'completedSteps', action.stepIndex],
            true
        );
    },
};

const dialogsReducer = (state = initialState, action) => {
    const handler = DIALOGS_ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default dialogsReducer;
