import { connect } from 'react-redux';
import { hideDialog } from '../DialogActions';
import PanelComponent from './PanelComponent';

const mapActionCreators = {
    hideDialog,
};

export default connect(null, mapActionCreators, null, {
    forwardRef: true,
})(PanelComponent);
