﻿import React from 'react';
import ValidationErrorInline, {
    ValidationErrorInlineProps,
} from './ValidationErrorInline';
import ValidationService from '../../../util/ValidationService';
import './ValidationError.scss';

// some props are only available when display is inline
type ValidationErrorType =
    | {
          isInline: true;
          inlineTheme?: ValidationErrorInlineProps['theme'];
          inlineTopOffsetPixels?: number;
          inlineLeftOffsetPixels?: number;
          inlineRightOffsetPixels?: number;
          inlineBottomOffsetPixels?: number;
      }
    | {
          isInline?: boolean;
          inlineTheme?: never;
          inlineTopOffsetPixels?: never;
          inlineLeftOffsetPixels?: never;
          inlineRightOffsetPixels?: never;
          inlineBottomOffsetPixels?: never;
      };

export type ValidationErrorProps = ValidationErrorType & {
    hideIfNoErrors?: boolean;
    meta?: any;
};

export interface ValidationErrorActionProps {
    setFieldErrorCount: (errorsCount: number) => void;
}

class ValidationError extends React.Component<
    ValidationErrorProps & ValidationErrorActionProps,
    {}
> {
    static defaultProps = {
        hideIfNoErrors: false,
    };

    constructor(props) {
        super(props);
        this.renderStandard = this.renderStandard.bind(this);
    }

    componentDidMount() {
        const errors = ValidationService.getVisibleErrors(this.props.meta);
        this.props.setFieldErrorCount(errors.length);
    }

    componentWillReceiveProps(nextProps) {
        const oldErrors = ValidationService.getVisibleErrors(this.props.meta);
        const errors = ValidationService.getVisibleErrors(nextProps.meta);
        if (errors && oldErrors.length !== errors.length) {
            this.props.setFieldErrorCount(errors.length - oldErrors.length);
        }
    }

    componentWillUnmount() {
        const errors = ValidationService.getVisibleErrors(this.props.meta);
        this.props.setFieldErrorCount(-errors.length);
    }

    renderInline(errors) {
        const {
            inlineTheme,
            inlineTopOffsetPixels,
            inlineLeftOffsetPixels,
            inlineRightOffsetPixels,
            inlineBottomOffsetPixels,
        } = this.props;

        if (errors.length === 0) {
            // If there are no errors or warnings, this component is a no-op.
            return null;
        } else {
            const style = {
                top: inlineTopOffsetPixels,
                left: inlineLeftOffsetPixels,
                right: inlineRightOffsetPixels,
                bottom: inlineBottomOffsetPixels,
            };

            return (
                <ValidationErrorInline
                    error={errors[0]}
                    style={style}
                    theme={inlineTheme}
                />
            );
        }
    }

    filterDuplicateErrors(errors) {
        const uniques = [...new Set(errors)];
        return uniques;
    }

    renderStandard(errors) {
        if (!errors || errors.length === 0) {
            // If there are no errors or warnings, this component is a no-op.
            return this.props.hideIfNoErrors ? (
                <></>
            ) : (
                <div className='validationerror validationerror--standard'>
                    &nbsp;
                </div>
            );
        } else if (errors.length === 1) {
            // With a single error, we display it in a div below the element.
            return (
                <div className='validationerror validationerror--standard'>
                    {errors[0]}
                </div>
            );
        } else {
            // With multiple errors, we create a list for displaying the individual errors.
            //
            // You might be tempted to replace the following code with <ul> & <li> instead
            // of using &#9679; for a circle.
            //
            // However - the distance between the bullet & the text is not defined in pixels
            // so as you zoom in & out in the browser, you will see different distances occur
            // which makes this tricky to line up correctly.
            //
            // Please feel free to improve the below code, but be careful that all of the
            // alignments actually work (and remember to check it across browsers).
            const uniques = this.filterDuplicateErrors(errors);
            return (
                <div className='validationerror validationerror--standard'>
                    {uniques.map((message: any) => (
                        <div key={message}>
                            <span className='validationerror-circle'>
                                &#9679;
                            </span>
                            &nbsp;
                            {message}
                        </div>
                    ))}
                </div>
            );
        }
    }

    render() {
        const errors = ValidationService.getVisibleErrors(this.props.meta);

        return this.props.isInline
            ? this.renderInline(errors)
            : this.renderStandard(errors);
    }
}

export default ValidationError;
