﻿import Immutable from 'seamless-immutable';
import { getStateByKey } from '../util/getStateByKey';
import { Action } from 'redux';

export const PERMISSIONS_STATE_KEY = 'maui.permissions';

export interface IPermissionState {
    permissions: {
        [key: string]: boolean;
    };
    allPermissions: {
        [key: string]: string;
    };
}

export const getUserPermissionState = (state): IPermissionState =>
    getStateByKey(state, PERMISSIONS_STATE_KEY);
const SET_USER_PERMISSIONS = '@@maui/permissions/SET_USER_PERMISSIONS';
const SET_ALL_PERMISSIONS = '@@maui/permissions/SET_ALL_PERMISSIONS';

export const setUserPermissions = (
    permissions: IPermissionState['permissions']
) => ({
    type: SET_USER_PERMISSIONS,
    payload: permissions,
});

export const setAllUserPermissions = (
    allPermissions: IPermissionState['allPermissions']
) => ({
    type: SET_ALL_PERMISSIONS,
    payload: allPermissions,
});

const ACTION_HANDLERS = {
    [SET_USER_PERMISSIONS]: (state, action): IPermissionState =>
        state.set('permissions', action.payload),
    [SET_ALL_PERMISSIONS]: (state, action): IPermissionState =>
        state.set('allPermissions', action.payload),
};

const initialState = Immutable(<IPermissionState>{
    permissions: {},
    allPermissions: {},
});

export default function permissionsReducer(
    state = initialState,
    action: Action
): IPermissionState {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
