﻿const defaultNumberFormat = {
    thousandsSeparator: ',',
    decimalMark: '.',
};

const DEFAULT_CONDENSED_CUTOFF = 10000;

let numberFormat = defaultNumberFormat;

export default class NumberFormattingService {
    static getThousandsSeparator() {
        return numberFormat.thousandsSeparator;
    }

    static getDecimalMark() {
        return numberFormat.decimalMark;
    }

    static setPreferredNumberFormat(format) {
        numberFormat = format;
    }

    static getPreferredNumberFormat() {
        return numberFormat;
    }

    static formatNumberCondensed(value, allowedDecimalPlaces) {
        value = NumberFormattingService.parseNumber(value);

        const magnitude = Math.abs(value);

        // Somewhat arbitrary cut off at which point we start
        // condensing the numbers.
        if (magnitude < 1000) {
            return NumberFormattingService.formatNumber(
                value,
                allowedDecimalPlaces
            );
        } else {
            const million = 1000000;
            const billion = million * 1000;
            const prefix = value < 0 ? '-' : '';

            let unit = 'K';
            let unitMagnitude = 1000;

            if (magnitude >= billion) {
                unit = 'B';
                unitMagnitude = billion;
            } else if (magnitude >= million) {
                unit = 'MM';
                unitMagnitude = million;
            }

            const roundingMagnitude = unitMagnitude / 10;

            const rounded =
                Math.round(magnitude / roundingMagnitude) * roundingMagnitude;

            const numberOfDecimals =
                rounded - Math.floor(rounded / unitMagnitude) * unitMagnitude >=
                unitMagnitude / 10
                    ? 1
                    : 0;

            // Format like #.#(B|M|K)
            return (
                prefix +
                NumberFormattingService.formatNumber(
                    rounded / unitMagnitude,
                    numberOfDecimals
                ) +
                unit
            );
        }
    }

    static formatNumberCondensedWithCutoff(
        value,
        cutoffValue = DEFAULT_CONDENSED_CUTOFF,
        allowedDecimalPlaces?
    ) {
        value = NumberFormattingService.parseNumber(value);

        const magnitude = Math.abs(value);
        const sign = value < 0 ? -1 : 1;
        let prefix = '';

        if (magnitude !== 0 && magnitude < cutoffValue) {
            value = cutoffValue * sign;
            prefix = sign === 1 ? '< ' : '> ';
        }

        return `${prefix}${NumberFormattingService.formatNumberCondensed(
            value,
            allowedDecimalPlaces
        )}`;
    }

    static formatInteger(value) {
        return NumberFormattingService.formatNumber(value, 0);
    }

    static formatNumber(
        value,
        decimalPlaces?,
        shouldRemoveInsignificantZeros?,
        defaultValue = null
    ) {
        // First, parse the value in case it isn't a number.
        value = NumberFormattingService.parseNumber(value);

        // If, after parsing, we have "null" return the default value.
        if (value === null) return defaultValue;

        // Track whether or not the value is negative, then make it positive.
        const valueIsNegative = value < 0;
        if (valueIsNegative) {
            value = -value;
        }

        // Find the integer & decimal parts.
        let integerPart = Math.floor(value);
        let decimalPart = value - integerPart;

        //Include a check if rounding will change any earlier digits and update integer & decimal parts accordingly
        if (decimalPlaces > 0) {
            const roundedValue = value.toFixed(decimalPlaces);
            integerPart = Math.floor(roundedValue);
            decimalPart = roundedValue - integerPart;
        }

        // Split the integer part into groups of 1,000's.
        const integerGroups = [];
        while (integerPart > 0) {
            const thisPart = integerPart % 1000;
            integerPart = Math.floor((integerPart - thisPart) / 1000);

            if (thisPart < 10 && integerPart > 0) {
                integerGroups.unshift('00' + thisPart);
            } else if (thisPart < 100 && integerPart > 0) {
                integerGroups.unshift('0' + thisPart);
            } else {
                integerGroups.unshift(thisPart);
            }
        }

        // Get the integer portion of the result, grouped into thousands.
        let result =
            integerGroups.length > 0
                ? integerGroups.join(numberFormat.thousandsSeparator)
                : '0';

        // Add the decimal portion.
        if (decimalPlaces > 0) {
            if (shouldRemoveInsignificantZeros) {
                // Adding the + makes Javascript treat the toFixed() result as a number, which removes
                // the insignificant zeros.  Found this trick in:
                // https://stackoverflow.com/questions/3612744/remove-insignificant-trailing-zeros-from-a-number/3613112
                const decimalPortion = (+decimalPart.toFixed(decimalPlaces))
                    .toString()
                    .substr(2);
                if (decimalPortion.length > 0) {
                    result += numberFormat.decimalMark + decimalPortion;
                }
            } else {
                result +=
                    numberFormat.decimalMark +
                    decimalPart.toFixed(decimalPlaces).substr(2);
            }
        }

        // Prepend the negative sign.
        if (valueIsNegative) {
            return '-' + result;
        } else {
            return result;
        }
    }

    static formatNumberRounded(value, decimalPlaces) {
        const powerOf10 = Math.pow(10, decimalPlaces);
        const roundedResult = Math.round(value * powerOf10) / powerOf10;
        return NumberFormattingService.formatNumber(
            roundedResult,
            decimalPlaces
        );
    }

    static formatPercentage(
        value,
        decimalPlaces,
        shouldRemoveInsignificantZeros?
    ) {
        const powerOf10 = Math.pow(10, decimalPlaces);
        let prefix = '';

        let roundedResult = Math.round(value * powerOf10) / powerOf10;
        if (value !== 0 && Math.abs(roundedResult) < 1 / powerOf10) {
            roundedResult = 1 / powerOf10;
            if (value < 0) {
                roundedResult = -roundedResult;
                prefix = '> ';
            } else {
                prefix = '< ';
            }
        }

        let formatted = NumberFormattingService.formatNumber(
            roundedResult,
            decimalPlaces,
            shouldRemoveInsignificantZeros
        );

        if (roundedResult < 1 && roundedResult > 0 && decimalPlaces > 0) {
            // Remove leading zeroes if the result is less than 1% but more than 0.
            formatted = formatted.substring(1);
        }

        return `${prefix}${formatted}%`;
    }

    // This method will throw an exception if the string contains
    // characters other than numerical digits after the decimal mark.
    static getDecimalPlacesInString(value) {
        const decimalMark = NumberFormattingService.getDecimalMark();
        const trimmedValue = value.trim();
        const decimalIndex = trimmedValue.indexOf(decimalMark);

        if (decimalIndex === -1) return 0;

        for (let i = decimalIndex + 1; i < trimmedValue.length; ++i) {
            const ch = trimmedValue[i];
            if (ch < '0' || ch > '9') {
                throw new Error(`Provided value is not a number.`);
            }
        }

        return Math.max(0, trimmedValue.length - decimalIndex - 1);
    }

    // throws an exception if input is not a number
    static getDecimalPlacesInNumber(value) {
        if (isNaN(value)) {
            throw new Error(`Provided value is not a number.`);
        }

        const decimalIndex = value
            .toString()
            .indexOf(NumberFormattingService.getDecimalMark());

        if (decimalIndex === -1) return 0;

        return Math.max(0, value.toString().length - decimalIndex - 1);
    }

    // This method will throw an exception if it can't parse the provided value.
    static parseNumberExact(value) {
        value = this.parseNumber(value);
        if (typeof value !== 'number') {
            throw new RangeError('value was not a valid number');
        }
        return value;
    }

    // This method will return null if it can't parse the provided value;
    static parseNumber(value) {
        if (typeof value === 'number') {
            if (isNaN(value)) {
                return null;
            } else {
                return value;
            }
        } else if (typeof value === 'string') {
            // If the string is empty, we're looking at null.
            if (value.length === 0) return null;

            // ensure all characters are acceptable
            let thouExists = false;
            let decExists = false;
            let invalid = false;

            // If the string starts with a negative sign, remove the
            // sign and multilpy by -1 later.
            let sign = 1;
            if (value[0] === '-') {
                sign = -1;
                value = value.substr(1);
            }

            value.split('').forEach((c) => {
                if (
                    (isNaN(c) || c === ' ') &&
                    c !== numberFormat.thousandsSeparator &&
                    c !== numberFormat.decimalMark
                ) {
                    invalid = true;
                }
                if (c === numberFormat.thousandsSeparator) {
                    thouExists = true;
                }
                if (c === numberFormat.decimalMark) {
                    decExists = true;
                }
            });

            if (
                invalid ||
                (thouExists &&
                    decExists &&
                    value.indexOf(numberFormat.decimalMark) <
                        value.indexOf(numberFormat.thousandsSeparator))
            ) {
                // decimal mark is before the thousands separator.  This is invalid
                return null;
            }

            const withoutThousands = value
                .split(numberFormat.thousandsSeparator)
                .join('');

            const withPeriodForDecimalMark =
                numberFormat.decimalMark === '.'
                    ? withoutThousands
                    : withoutThousands.replace(numberFormat.decimalMark, '.');

            const result = parseFloat(withPeriodForDecimalMark);

            if (Number.isNaN(result)) {
                return null;
            } else {
                return result * sign;
            }
        }

        return null;
    }

    // Works like Math.Round, but also works for large numbers, for example:
    // 12390 routed to 3 places = 12400
    // 123400 rounded to 3 places = 123000
    // originally written by Grzegorz
    static roundToNumberSignificantDigits(value, keepDigitCount: number) {
        if (value === 0) {
            return 0;
        }

        if (keepDigitCount <= 0) {
            throw new RangeError('Must choose to keep at least 1 digit.');
        }

        const power = Math.pow(
            10,
            parseInt(Math.log10(Math.abs(value)) + 1 - keepDigitCount + '')
        );

        const rounded = Math.round(value / power);
        return rounded * power;
    }
}
