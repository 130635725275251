import React, { Component } from 'react';
import FixedContentControl, {
    FixedContentControlProps,
} from 'components/controls/FixedContentControl';
import TtdInputField, {
    TtdInputFieldComponentProps,
} from 'components/inputs/TtdInputField';
import ValidationService from 'util/ValidationService';
import './FixedContentInput.scss';

export type FixedContentInputProps<TComponentProps> = Omit<
    TtdInputFieldComponentProps<FixedContentControlProps<TComponentProps>>,
    'component'
> & {
    component: React.ComponentType<TComponentProps>;
};

class FixedContentInput<TComponentProps> extends Component<
    FixedContentInputProps<TComponentProps>
> {
    renderInput({ meta, input, field, fieldProps, isInline }) {
        const errors = ValidationService.getVisibleErrors(meta);
        return (
            <FixedContentControl
                field={field}
                isInline={isInline}
                {...(fieldProps as FixedContentControlProps<TComponentProps>)}
                hasValidationErrors={errors.length > 0}
            />
        );
    }

    render() {
        const { field, isInline, validate, ...fieldProps } = this.props;
        return (
            <div
                className={`fixed-content-input ${
                    isInline ? 'fixed-content-input--inline' : ''
                }`}
            >
                <TtdInputField
                    field={field}
                    // make sure we preserve fieldProps.component in order to render the desired text input
                    fieldProps={fieldProps}
                    component={this.renderInput}
                    isInline={isInline}
                    validate={validate}
                />
            </div>
        );
    }
}

export default FixedContentInput;
