﻿import { useEffect, useState, useRef, useCallback } from 'react';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/skip';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/startWith';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

export interface FilterCoordinatorHookOptions<TFilter, TIntermediateResponse> {
    getRequest(filter: TFilter): any;
    initialState?: {
        response?: TIntermediateResponse;
        filter?: Partial<TFilter>;
    };
    compareFilters?(firstFilter: any, secondFilter: any): boolean;
    debounceMilliseconds?: number;
}

export interface FilterCoordinatorOptions<TFilter, TResponse>
    extends FilterCoordinatorHookOptions<TFilter, TResponse> {
    handleResponse(response: TResponse): void;
    handleError?(error: Error): void;
}

// A simple version of useFilterCoordinator that will suffice for most use cases.
export function useFilterCoordinator<TFilter, TResponse>(
    getRequest: (filter: TFilter) => Promise<TResponse>
): [Error, TResponse, (filter: TFilter) => void] {
    return useFilterCoordinatorWithOptions<TFilter, TResponse, TResponse>(
        {
            getRequest,
        },
        (response) => response
    );
}

// A more complex version of useFilterCoordinator, which offers more flexibility.
export function useFilterCoordinatorWithOptions<
    TFilter,
    TIntermediateResponse,
    TAggregateResponse
>(
    options: FilterCoordinatorHookOptions<TFilter, TIntermediateResponse>,
    aggregateResponse: (
        aggregate: TAggregateResponse,
        intermediateResponse: TIntermediateResponse
    ) => TAggregateResponse,
    dependencies = []
): [Error, TAggregateResponse, (filter: TFilter) => void] {
    const [currentResult, setCurrentResult] = useState(null);
    const [currentError, setCurrentError] = useState(null);
    const [queuedFilter, setQueuedFilter] = useState(null);
    const filterCoordinator = useRef(null);
    const lastResult = useRef(null);

    const pushFilter = useCallback(
        (filter) => {
            if (!filterCoordinator.current) {
                // If the filter coordinator isn't initialized yet, queue the new filter.
                setQueuedFilter(filter);
            } else {
                // Send the filter on to the filter coordinator.
                filterCoordinator.current.push(filter);
            }
        },
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterCoordinator.current]
    );

    useEffect(() => {
        filterCoordinator.current = new FilterCoordinator<
            TFilter,
            TIntermediateResponse
        >({
            getRequest: options.getRequest,
            compareFilters: options.compareFilters,
            handleResponse: (response) => {
                setCurrentError(null);
                if (lastResult.current) {
                    lastResult.current = aggregateResponse(
                        lastResult.current,
                        response
                    );
                } else {
                    lastResult.current = response;
                }
                setCurrentResult(lastResult.current);
            },
            handleError: (error) => {
                setCurrentError(error);
            },
            initialState: options.initialState,
            debounceMilliseconds: options.debounceMilliseconds,
        });

        // If we queued up a filter before the filter coordinator
        // was initialized, handle it now.
        if (queuedFilter != null) {
            filterCoordinator.current.push(queuedFilter);
            setQueuedFilter(null);
        }

        return () => {
            filterCoordinator.current.unsubscribe();
        };
        // PLEASE FIX: Turned off t enable linting, please fix if you touch this file!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);

    return [currentError, currentResult, pushFilter];
}

export default class FilterCoordinator<TFilter = {}, TResponse = {}> {
    private _lastFilter: any;
    private _distinctCheck: number;
    private _subject: any;
    private _subscription: any;
    constructor(options: FilterCoordinatorOptions<TFilter, TResponse>) {
        const {
            getRequest,
            handleResponse,
            handleError,
            initialState,
            compareFilters,
            debounceMilliseconds,
        } = options;
        this._distinctCheck = 0;
        this._subject = new Subject();

        // This will be used to invalidate the check for distinctness.
        // This is used to force a refresh from the server (e.g., if we
        // know something has changed server side and want to get new
        // data).
        let lastDistinctCheck = 0;

        // We start our pipeline with a deep clone in case the consumer
        // is mutating the filter instead of generating a new filter.
        let filterResponses = this._subject.map(cloneDeep);

        // Ignore new filters that are the same as the previous filter.
        filterResponses = filterResponses.distinctUntilChanged(
            (first, second) => {
                if (lastDistinctCheck !== this._distinctCheck) {
                    lastDistinctCheck = this._distinctCheck;
                    return false;
                }

                if (compareFilters) {
                    return compareFilters(first, second);
                } else {
                    return isEqual(first, second);
                }
            }
        );

        // If we have an initial state, skip the first response after the
        // distinctUntilChanged filter.
        if (initialState) {
            filterResponses = filterResponses.skip(1);
        }

        // Debounce 500ms, this means the pipeline will need to sit idle for
        // the specified amount of time before we will actually send a request
        // to the server.
        if (debounceMilliseconds !== 0) {
            filterResponses = filterResponses.debounceTime(
                debounceMilliseconds || 500
            );
        }

        // Only emit values from the most recent request. If we perform a new request
        // then we should ignore results from previous requests.
        filterResponses = filterResponses.switchMap((filter) => {
            const result = getRequest(filter);

            // "getRequest" should never return undefined - if it does
            // then it is likely someone forgot to return a value from
            // their function.
            if (result === undefined) {
                throw new Error(
                    'getRequest returned "undefined", but a promise or value was expected'
                );
            }

            // Detect if the returned value is a promise by checking
            // to see if it has a "then" property that is a function.
            if (typeof result.then === 'function') {
                // If the result from getRequest is a function, pass it
                // along as the result from this switchMap operator.
                return result;
            } else {
                // If the result from the getRequest is a value, return
                // it as an Observable so that
                return Observable.from([result]);
            }
        });

        // Start the pipeline off with a known response.
        if (initialState) {
            filterResponses = filterResponses.startWith(initialState.response);
        }

        // Set up our subscription.
        this._subscription = filterResponses.subscribe(
            handleResponse,
            handleError
        );

        // Prime the pipeline with the initial filter.
        if (initialState) {
            this._subject.next(initialState.filter);
        }
    }

    /**
     * Request the data again, even if the filter hasn't changed.
     * One use case for this - we know something has updated on the
     * server or the user has explicitly requested a refresh.
     *
     * @param {Object} filter
     * @returns {void}
     */
    refresh(filter) {
        // Increment our "distinctCheck" counter in order
        // to bypass our check for distinctness. This means
        // we will perform the request with the last filter
        // again, even if we already have a response for that
        // filter.
        this._distinctCheck++;
        this.push(filter || this._lastFilter);
    }

    /**
     * @param {Object} filter
     * @returns {void}
     */
    push(filter) {
        // Store the last filter sent into the pipe.
        this._lastFilter = filter;

        // Push the filter into the pipeline.
        this._subject.next(filter);
    }

    /**
     * Shuts down the event pipeline.
     *
     * @returns {void}
     */
    unsubscribe() {
        this._subscription.unsubscribe();
    }
}
