import React from 'react';
import themeClassName from '../../../util/Themes';

export const GUTTER_SIZES = [
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    'xxl',
    'xxxl',
] as const;

export const ALIGNMENT_OPTIONS = [
    'left',
    'right',
    'center',
    'justify',
] as const;

export const VALIGNMENT_OPTIONS = [
    'top',
    'bottom',
    'center',
    'baseline',
    'stretch',
] as const;

export interface WrappedFlexListProps {
    allowWrapping?: boolean;
    alignment?: typeof ALIGNMENT_OPTIONS[number];
    verticalAlignment?: typeof VALIGNMENT_OPTIONS[number];
    className?: string;
    style?: React.CSSProperties;
    additionalItemClassName?: string;
    gutterSize?: typeof GUTTER_SIZES[number];
    theme?: string;
    forwardedRef?: React.Ref<HTMLDivElement>;
    children?: NonNullable<React.ReactNode>;
}

const flexList = (WrappedComponent) => {
    function WrappedFlexList({
        children,
        allowWrapping,
        alignment,
        verticalAlignment,
        className,
        style,
        additionalItemClassName,
        gutterSize,
        theme,
        forwardedRef,
    }: WrappedFlexListProps) {
        const listBaseClass = `${WrappedComponent.direction}-list`;
        const listClasses = [listBaseClass, className];

        if (theme) {
            listClasses.push(themeClassName(theme));
        }
        if (allowWrapping) {
            listClasses.push(`${listBaseClass}--allow-wrapping`);
        }
        if (alignment) {
            listClasses.push(`${listBaseClass}--align-` + alignment);
        }
        if (verticalAlignment) {
            listClasses.push(`${listBaseClass}--valign-` + verticalAlignment);
        }
        if (gutterSize) {
            listClasses.push(`${listBaseClass}--gutter-` + gutterSize);
        }

        let itemClassName = `${listBaseClass}__item`;
        if (additionalItemClassName) {
            itemClassName += ' ' + additionalItemClassName;
        }

        const items = React.Children.map(children, (child, index) => (
            <div className={itemClassName} key={`list-item__${index}`}>
                {child}
            </div>
        ));

        return (
            <div
                className={listClasses.join(' ')}
                style={style}
                ref={forwardedRef}
            >
                {items}
            </div>
        );
    }

    WrappedFlexList.defaultProps = {
        className: '',
        theme: '',
    };

    return React.forwardRef<
        HTMLDivElement,
        React.HTMLProps<HTMLDivElement> & WrappedFlexListProps
    >((props, ref) => <WrappedFlexList {...props} forwardedRef={ref} />);
};

export default flexList;
