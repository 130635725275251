import { connect } from 'react-redux';
import { registerDialog, unregisterDialog } from '../DialogActions';
import DialogCoreComponent from './DialogCoreComponent';
import { getDialogState } from '../../Dialog/DialogReducer';

const mapActionCreators = {
    registerDialog,
    unregisterDialog,
};

const mapStateToProps = (state, ownProps) => {
    const dialogs = getDialogState(state);
    const { isOpen, zIndex, isTransitioning } = (dialogs.instances[
        ownProps.dialogId
    ] || {}) as any;

    return {
        isTransitioning,
        isOpen,
        zIndex,
        isTop: zIndex === dialogs.currentMaxZIndex,
    };
};

export default connect(mapStateToProps, mapActionCreators, null, {
    forwardRef: true,
})(DialogCoreComponent);
