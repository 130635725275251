﻿import React, { CSSProperties } from 'react';
import { FormSection, WrappedFieldProps } from 'redux-form';
import keycode from 'keycode';
import getInputId from 'util/getInputId';
import { Localization } from 'util/LocalizationService';
import TtdInputField, {
    TtdField,
    UiFieldModel,
} from 'components/inputs/TtdInputField';
import TextInput from 'components/inputs/TextInput';
import SelectListInput from 'components/inputs/SelectListInput';
import FixedContentControl from 'components/controls/FixedContentControl';
// '<Glyph>' is deprecated in favor of direct SVG imports
// eslint-disable-next-line no-restricted-imports
import Glyph from 'components/display/Glyph';
import SearchIcon from 'glyphs/default/search.svg';
import Strings from './TextSearchInput.strings.json';
import './TextSearchInput.scss';

export interface TextSearchInputProps {
    id: string;
    field: TtdField;
    className?: string;
    style?: CSSProperties;
    // Determines if the FixedContentControl and/or SelectListInput should be displayed
    // inline. If not specified, defaults to true
    isInline?: boolean;
    /** Is this read only? (see https://atlassian.thetradedesk.com/confluence/display/EN/Megagon+Read-Only+Support)*/
    isReadOnly?: boolean;
    isEnabled?: boolean;
    debounceTimeInMilliseconds?: number;
    // Text to show when search is empty
    placeholder?: string;
    // List of fields that this text-search component can filter on
    searchFields?: {
        display: string;
        field: string;
    }[];
    // If no field selected, it will search all fields, if this is false, this component
    // will default to the first in of fields
    allowSearchAllFields?: boolean;
}

interface TextSearchFieldState {
    searchIsActive?: boolean;
}

class TextSearchInputField extends React.Component<
    TextSearchInputProps & WrappedFieldProps,
    TextSearchFieldState
> {
    state: TextSearchFieldState = {
        searchIsActive: false,
    };

    onFocus = () => {
        this.setState({ searchIsActive: true });
    };

    onBlur = (e) => {
        if (
            e.relatedTarget == null ||
            !e.relatedTarget.id.includes('.searchField')
        ) {
            // only blur when next component is not the search-field drop-box
            this.setState({ searchIsActive: false });
        }
    };

    onKeyDown = (e) => {
        if (keycode(e) === 'esc') {
            this.clearSearch(e, this.props.input);
        }
    };

    getSearchPlaceholder = (searchIsActive, selectedSearchField) => {
        if (!searchIsActive) {
            return Localization.getString(Strings.searchPlaceholder);
        }
        if (selectedSearchField !== 'allFields') {
            return Localization.getString(Strings.searchPlaceholderTemplate, {
                field: this.props.searchFields.filter(
                    (f) => f.field === selectedSearchField
                )[0].display,
            });
        }

        return Localization.getString(Strings.searchPlaceholderTemplate, {
            field: this.props.searchFields.map((f) => f.display).join(', '),
        });
    };

    clearSearch = (e, input) => {
        const newValue = Object.assign({}, input.value);
        newValue.searchText = undefined;
        input.onChange(newValue);
    };

    render() {
        const {
            input,
            allowSearchAllFields,
            searchFields,
            field,
            isInline,
            isEnabled,
            isReadOnly,
            placeholder,
            debounceTimeInMilliseconds,
        } = this.props;

        const { searchIsActive } = this.state;

        const inputId = getInputId(TextSearchInput, this.props);

        const newSearchFields = allowSearchAllFields
            ? [
                  {
                      display: Localization.getString(Strings.allField),
                      field: 'allFields',
                  },
              ].concat(searchFields)
            : searchFields;

        const showSearchFields =
            newSearchFields.length > 1 &&
            (searchIsActive ||
                (input.value &&
                    (input.value.searchText !== undefined ||
                        input.value.searchField !== 'allFields')));

        const glyphOffsetClass = showSearchFields
            ? 'text-search-input-container__search-clear--with-select'
            : 'text-search-input-container__search-clear--without-select';
        return (
            <FormSection
                name={(field as UiFieldModel).name || (field as string)}
            >
                <div className='text-search-input-overlay'>
                    <div
                        id={inputId}
                        className={
                            'text-search-input-container ' +
                            (searchIsActive ? ' search-active' : '')
                        }
                    >
                        <FixedContentControl
                            className={`text-search-input-container__search-box ${
                                isInline
                                    ? 'text-search-input-container__search-box--inline'
                                    : ''
                            }`}
                            isInline={isInline}
                            icon={<SearchIcon />}
                            isReadOnly={isReadOnly}
                            isEnabled={isEnabled}
                            isValidationInline={isInline}
                            showValidation={false}
                            placeholder={
                                placeholder ||
                                this.getSearchPlaceholder(
                                    showSearchFields,
                                    input.value.searchField || 'allFields'
                                )
                            }
                            field='searchText'
                            debounceTimeInMilliseconds={
                                debounceTimeInMilliseconds
                            }
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onKeyDown={this.onKeyDown}
                            component={TextInput}
                        />
                        <Glyph
                            className={`${glyphOffsetClass} text-search-input-container__search-clear ${
                                input.value.searchText !== undefined
                                    ? ' search-field-active'
                                    : ''
                            }`}
                            name='close'
                            onClick={(e) => this.clearSearch(e, input)}
                        />
                        {showSearchFields && (
                            <SelectListInput
                                className={
                                    'text-search-input-container__fields-select ' +
                                    (showSearchFields
                                        ? ' search-field-active'
                                        : '')
                                }
                                availableItems={newSearchFields.map(
                                    (item) => item.field
                                )}
                                isInline={isInline}
                                isReadOnly={isReadOnly}
                                isEnabled={isEnabled}
                                itemName={Localization.getString(
                                    Strings.searchableField
                                )}
                                emptyText={newSearchFields[0].display}
                                emptyItemText={newSearchFields[0].display}
                                allowEmptySelection={false}
                                isValidationInline={isInline}
                                field='searchField'
                                renderItem={(item) =>
                                    newSearchFields.filter(
                                        (f) => f.field === item
                                    )[0].display
                                }
                            />
                        )}
                    </div>
                </div>
            </FormSection>
        );
    }
}

class TextSearchInput extends React.Component<TextSearchInputProps> {
    static defaultProps: Partial<TextSearchInputProps> = {
        className: '',
        searchFields: [],
        isInline: true,
        isReadOnly: false, // Note that unlike other components, we specifically require TextSearchInput to be explicitly set as isReadOnly, because it's purpose is searching not editing.
    };

    render() {
        const { className, style, ...rest } = this.props;
        return (
            <div className={`text-search-container ${className}`} style={style}>
                <TtdInputField
                    {...rest}
                    component={TextSearchInputField}
                    className={className}
                />
            </div>
        );
    }
}

export default TextSearchInput;
