import React from 'react';
import ReactDOM from 'react-dom';
import Button, { BUTTON_TYPE } from 'components/display/Button';
import DropDownMenuItem from 'components/display/DropDownMenuItem';
import { DisplayMode } from 'components/display/ToolTip';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/operator/scan';
import keycode from 'keycode';
import Strings from './selectList.strings.json';
import { PrimaryColorOptions } from 'util/Colors';
import FilterCoordinator from 'util/FilterCoordinator';
import { Subject } from 'rxjs/Subject';

export enum SelectControlItemType {
    // currently unused but this will represent a regular item
    Normal = 1,
    // A header that is not selectable
    Header = 2,
}

// Represents an item in the list that is a header, they are rendered
// differently as normal items as they cannot be selected
export interface Header {
    name: string;
    itemType: SelectControlItemType.Header;
}

// Determines if an item is of type Header, can be removed once availableItems is represented as discriminated union
export function isHeader(
    item: string | number | object | Header
): item is Header {
    return (item as Header).itemType === SelectControlItemType.Header;
}

export function HeaderItem(props: { item: Header }) {
    return (
        <div className='select-list-item--header'>
            <span>{props.item.name}</span>
        </div>
    );
}

export interface IRequestReceiveData {
    /**
     * The given function will be called when the user searches, or the component opens for the first time.
     */
    requestData(searchTerm: string): Promise<any> | any;
    /**
     * The given function gets called when data is received, most likely this can just be an connected action creator
     */
    receiveData(data: any): void;
    /**
     * The initial state to prime the filterCoordinator
     */
    initialState?: {
        filter?: string;
        response?: any;
    };
}

export interface SelectListProps<T> {
    /**
     * Optional CSS class name
     */
    className?: string;
    /**
     * Optional style object
     */
    style?: React.CSSProperties;
    /**
     * A list of available selections the user can choose from with this component.
     * Note: we should consider standardizing the interface for available items
     */
    availableItems: T[];
    /**
     * items available for selection.
     */
    isEnabled?: boolean;
    /**
     * UiModel class which the list of availableItems represents.
     */
    itemType?: any;
    /**
     * Display name of a selection. For example Ad Group.
     */
    itemName?: string;
    /**
     * Overrides selection instructions to not use item name.  Used for narrow fields. Merely displays "Please Select"
     */
    doNotUseItemName?: boolean;
    /**
     * Renders the select list without a border.
     */
    isInline?: boolean;
    /**
     * if provided the selected items will use this string as the primary key of the available items.
     */
    primaryKey?: keyof T;
    /**
     * if provided the selected items will use this string for the display value of the available items.
     */
    displayNameKey?: keyof T;
    /**
     * if provided the selected items will use this string for the tooltip value of the available items.
     */
    tooltipNameKey?: string;
    /**
     * optional minimum number of characters to trigger a search
     */
    minimumCharactersForSearch?: number;
    /**
     * if true the component will show a search bar when open
     */
    isSearchEnabled?: boolean;
    /**
     * the given function will be called when a user searches.
     * Using any here because MultiSelectControl props extends this same interface, and onNeedData is given an event instead of a string
     * which is what the rest of the code expects. That callback for onNeedData that is passed to MultiSelectControl is defined in this hoc, see this.onNeedData
     * ideally this hoc shouldn't force the component cast to any and instead implement proper type safety with generics and without relying on inheritance
     */
    onNeedData?(event: any): void;
    /**
     * the pair of functions is designed to ensure the order of response is ignored,
     * this ensures the response associated with the latest request will be the last to display.
     */
    requestAndReceiveData?: IRequestReceiveData;
    /**
     * Display the list as an auto complete like control.
     */
    showSearchWhenClosed?: boolean;
    /**
     * Toggle the list open on focus even if we show search when closed
     */
    toggleWhenClosed?: boolean;
    /**
     * Give a visual indication of loading when a user is searching.
     */
    shouldShowLoading?: boolean;
    /**
     * Optional function which returns the display value for the label.
     * Signature: renderItem(item, isSelected) where:
     * item is the data to render
     * isSelected indicates whether the item is currently selected
     */
    renderItem?(
        item: T,
        isSelected: boolean,
        inMenu: boolean
    ): JSX.Element | string;
    /**
     * Optional text when no item(s) is|are selected.
     */
    emptyText?: string;
    /**
     * If true, the div representing the opened menu of the drop-down will be placed in a div outside
     * from the React DOM structure.  This allows the menu to render above/outside of any CSS styles
     * applied within the React DOM.
     */
    embedInPortal?: boolean;
    /**
     * When true, it signals that the component supports "load more", and that there is more data
     * available to load (on the last page of results, this should be false so the "load more"
     * button doesn't continue to display)
     */
    isMoreDataAvailable?: boolean;
    /**
     * Function used with the "load more" capability in order to load the next page of data
     * (required when isMoreDataAvailable is true)
     */
    onReadyForMoreData?(event?: any): void;
    onKeyDown?(event: any): void;

    /**
     * optional number indicating a colored bar.  can be used in conjunction with PillList component for displaying selected items.
     */
    color?: PrimaryColorOptions;

    /**
     * if the user is searching, the value to populate the input with.
     *
     */
    searchText?: string;

    /**
     * function for defining the behavior when opening/closing the select field
     */
    onToggle?(isOpen: boolean): void;
    /**
     * unique id assigned to the component
     */
    id: string;
    /**
     * Number of milliseconds to sit idle before sending a search request
     */
    debounceMilliseconds?: number;

    /**
     * Passed by selectList HOC
     * Array of DropDownMenuItem components that will be appended to the end of the DropDownMenu
     * list (currently only used to pass in the "Load More" button, when applicable)
     */
    staticLastMenuItems?: object[];

    /**
     * Passed by selectList HOC
     * Boolean to indicate if the select list items are in the process of being initialized
     * (i.e. gets set by the selectList HOC when initially opening the list, and gets unset
     * when 1 or more items are passed to the component)
     */
    isRetrievingData?: boolean;

    // Passed in by the connected selectList HOC (components/hocs/selectList)
    /**
     * if search enabled, the function that handles what to do when the search input changes
     */
    onSearchInputKeyDown?(event: React.KeyboardEvent): void;

    /**
     * optional function that handles clearing the search, provided by the selectList HOC
     * Signature: clearSearch(event, clearInputField)
     *   event - click event object when clearing the search (optional param)
     *   clearInputField - boolean value, indicating if the input field should be cleared, or only the search text state
     */
    clearSearch?(
        event: React.MouseEvent<HTMLDivElement>,
        clearInputField: boolean
    ): void;

    /**
     * optional function for when the cursor enters the field
     */
    onLabelMouseEnter?(event: React.MouseEvent): void;

    /**
     * optional function for when the cursor leaves the field
     */
    onLabelMouseLeave?(event: React.MouseEvent): void;

    /**
     * optional function for when the cursor clicks on the field
     */
    onLabelClick?(event: React.MouseEvent): void;

    /**
     * function for defining the behavior when opening/closing the select field
     */
    onToggle?(isOpen: boolean): void;

    /**
     * function for defining how to display individual items in the select list
     */
    getItemDisplay?(
        item: T,
        displayNameKey: keyof T,
        itemType: any,
        selected: boolean,
        isInMenu?: boolean
    ): any;

    /**
     * whether or not to render the control input field as a search bar
     */
    showSearch?: boolean;

    /**
     * function for defining the tooltips for individual items in the select list
     */
    getItemTooltip?(item, displayNameKey: string, itemType?: any): any;

    /**
     * set the display mode for item tooltips in the list
     */
    tooltipDisplayMode?: DisplayMode;

    /**
     * option function to execute when the search box receives focus
     */
    onSearchFocus?(event: FocusEvent): void;

    onBlur?(event: React.FocusEvent<HTMLAnchorElement | HTMLInputElement>);
    onFocus?(event: React.FocusEvent<HTMLAnchorElement | HTMLInputElement>);
}

export default (WrappedSelectListComponent) => {
    interface SelectListState {
        toolTipOpen: boolean;
        searchText: string;
        isInitializingData: boolean;
        hasOpened: boolean;
        isDataInitialized: boolean;
        showSearch: boolean;
    }

    class GenericSelectListComponent<T> extends React.Component<
        SelectListProps<T>,
        SelectListState
    > {
        constructor(props: SelectListProps<T>) {
            super(props);
            this.onMouseEnter = this.onMouseEnter.bind(this);
            this.onMouseLeave = this.onMouseLeave.bind(this);
            this.onToggle = this.onToggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.onNeedData = this.onNeedData.bind(this);
            this.componentWillUnMount = this.componentWillUnMount.bind(this);
            this.getItemDisplay = this.getItemDisplay.bind(this);
            this.getItemTooltip = this.getItemTooltip.bind(this);
            this.clearSearch = this.clearSearch.bind(this);
            this.renderReadOnly = this.renderReadOnly.bind(this);
            this.getLoadMoreMenuItem = this.getLoadMoreMenuItem.bind(this);
            this.loadAdditionalItems = this.loadAdditionalItems.bind(this);
            this.state = {
                toolTipOpen: false,
                searchText: this.props.searchText || '',
                isInitializingData: false,
                showSearch: this.props.showSearchWhenClosed,
                hasOpened: false,
                isDataInitialized: false,
            };

            if (props.onNeedData) {
                this.setSearchEventHandler(props.onNeedData);
            } else if (props.requestAndReceiveData) {
                this.setSearchHandlerWithFilterCoordinator(
                    props.requestAndReceiveData
                );
            }

            if (
                props.isMoreDataAvailable === true &&
                typeof props.onReadyForMoreData !== 'function'
            ) {
                console.error(
                    'if isMoreDataAvailable is set, you must also pass onReadyForMoreData as a prop'
                );
            }

            if (props.requestAndReceiveData && props.onNeedData) {
                console.error(
                    'only specify one of requestAndReceiveData or onNeedData, requestAndReceiveData will be ignored!!!'
                );
            }
        }

        onNeedDataObserver: any;
        requestReceiveDataCoordinator: any;
        hasFocus: boolean;

        static propTypes = {};

        static defaultProps = {
            ...((WrappedSelectListComponent as any).defaultProps || {}),
            isEnabled: true,
            className: '',
            minimumCharactersForSearch: 1,
            availableItems: [],
            debounceMilliseconds: 400,
        };

        getClassName(
            {
                availableItems,
                shouldShowLoading,
                showSearchWhenClosed,
                isEnabled,
                className,
                isInline,
                color,
            }: SelectListProps<T>,
            { showSearch }
        ) {
            const classes = ['select-list', className];

            // Per style guide, menu should accomodate 10 menu items before scrolling.
            if (availableItems.length > 10) {
                classes.push('select-list--long');
            }
            if (color) {
                classes.push(
                    'select-list--color-indicator',
                    `select-list--color-indicator-${color}`
                );
            }
            if (showSearch) {
                classes.push('select-list--with-search');
            }
            if (showSearchWhenClosed) {
                classes.push('select-list--always-search');
            }
            if (!isEnabled) {
                classes.push('select-list--disabled');
            }
            if (shouldShowLoading) {
                classes.push('select-list--loading');
            }
            if (isInline) {
                classes.push('select-list--inline');
            }

            return classes.join(' ');
        }

        getItemDisplay(
            item,
            displayNameKey,
            itemType,
            isSelected?: boolean,
            inMenu?: boolean
        ) {
            return this.props.renderItem
                ? this.props.renderItem(item, isSelected, inMenu)
                : displayNameKey
                ? item[displayNameKey]
                : itemType
                ? item[itemType.$displayNameKey]
                : item.toString();
        }

        getItemTooltip(item, tooltipNameKey, itemType) {
            return this.props.getItemTooltip
                ? this.props.getItemTooltip(item, tooltipNameKey, itemType)
                : tooltipNameKey
                ? item[tooltipNameKey]
                : itemType
                ? item[itemType.$tooltipNameKey]
                : undefined;
        }

        // If `clearInputField` is true, we'll clear both the input field and the state.
        // If false, only clear the search text from the state
        clearSearch = (event = null, clearInputField = true) => {
            if (event) {
                event.preventDefault();
            }

            if (clearInputField) {
                const input = (ReactDOM.findDOMNode(
                    this
                ) as Element).querySelector(
                    '.select-list__search input'
                ) as HTMLInputElement;
                input.focus();
                input.value = '';
            }

            this.setState({ searchText: '' });
            this.onNeedDataObserver && this.onNeedDataObserver.next('');
            this.requestReceiveDataCoordinator &&
                this.requestReceiveDataCoordinator.push('');
        };

        onMouseEnter({ target }) {
            this.hasFocus = true;
            /**
             * show the tooltip if the text overflows the containing DOM node.
             */
            if (target.offsetWidth < target.scrollWidth) {
                setTimeout(() => {
                    if (this.hasFocus) {
                        this.setState({ toolTipOpen: true });
                    }
                }, 1000);
            }
        }

        onMouseLeave() {
            this.hasFocus = false;
            if (this.state.toolTipOpen) {
                this.setState({ toolTipOpen: false });
            }
        }

        renderReadOnly(fields, nameKey, itemType) {
            return (
                <ul className={`select-list--readonly ${this.props.className}`}>
                    {fields.map((v, i) => (
                        <li className='select-list--item' key={i}>
                            {v && this.getItemDisplay(v, nameKey, itemType)}
                        </li>
                    ))}
                </ul>
            );
        }

        onToggle(open) {
            const {
                availableItems,
                onNeedData,
                requestAndReceiveData,
                isSearchEnabled,
                showSearchWhenClosed,
                toggleWhenClosed,
                onToggle,
            } = this.props;

            if (open) {
                this.setState({ hasOpened: true });
                if (
                    (onNeedData || requestAndReceiveData) &&
                    (!showSearchWhenClosed || toggleWhenClosed) &&
                    (!availableItems || !availableItems.length)
                ) {
                    if (this.props.shouldShowLoading === undefined) {
                        // warn developer to provide this property
                        console.warn(
                            'Expected shouldShowLoading to be set when onNeedData is set. Check that the initialState initializing this property is properly set'
                        );
                    }

                    this.setState({ isInitializingData: true });
                    this.onNeedDataObserver && this.onNeedDataObserver.next();
                    if (this.requestReceiveDataCoordinator) {
                        this.requestReceiveDataCoordinator.push(
                            this.state.searchText
                        );
                    }
                }
                const element = ReactDOM.findDOMNode(this) as Element;
                const searchText = this.state.searchText;
                if (
                    (onNeedData || requestAndReceiveData) &&
                    (isSearchEnabled ||
                        (searchText && searchText.length > 0)) &&
                    (!showSearchWhenClosed || toggleWhenClosed)
                ) {
                    this.setState({ showSearch: true });
                    setTimeout(() => {
                        const inputField = element.querySelector(
                            'input[type="text"]'
                        ) as HTMLInputElement;
                        if (inputField) {
                            inputField.value = searchText || '';
                            inputField.focus();
                        }
                    }, 50);
                }
            } else if (this.state.showSearch && !showSearchWhenClosed) {
                this.setState({ showSearch: false });
            }

            onToggle && onToggle(open);
        }

        onNeedData(event) {
            const searchText = event.target.value;
            this.setState({ searchText: searchText });

            // onNeedDataObserver
            if (this.onNeedDataObserver) {
                this.onNeedDataObserver.next(searchText);
            } else if (this.requestReceiveDataCoordinator) {
                this.requestReceiveDataCoordinator.push(searchText);
            }
        }

        onClick(e) {
            if (!this.props.isEnabled) {
                e.preventDefault();
            }
        }

        setSearchEventHandler(handler) {
            const onNeedDataEventSource = Observable.create((observer) => {
                this.onNeedDataObserver = observer;
            })
                .debounce(() =>
                    Observable.interval(this.props.debounceMilliseconds)
                )
                .filter(
                    (v) =>
                        !v || v.length >= this.props.minimumCharactersForSearch
                )
                .map((v = '') => v.trim())
                .distinctUntilChanged();

            onNeedDataEventSource.subscribe(handler);

            this.setUpComboScan();
        }

        setSearchHandlerWithFilterCoordinator({
            requestData,
            receiveData,
            initialState,
        }: IRequestReceiveData) {
            this.requestReceiveDataCoordinator = new FilterCoordinator({
                getRequest: requestData,
                handleResponse: receiveData,
                debounceMilliseconds: this.props.debounceMilliseconds,
                initialState,
            });

            this.setUpComboScan();
        }

        keyComboObserver: Subject<any>;
        onKeyCombo: (event: React.KeyboardEvent) => void;

        setUpComboScan() {
            const keyComboEventObservable = Observable.create(
                (observer: Subject<any>) => {
                    this.keyComboObserver = observer;
                    this.onKeyCombo = this.keyComboObserver.next.bind(
                        this.keyComboObserver
                    );
                }
            ).scan(
                (combo, event) => {
                    return {
                        current: keycode(event),
                        last: combo.current,
                        event,
                    };
                },
                { last: null }
            );

            keyComboEventObservable.subscribe((combo) => {
                if (
                    (combo.last === 'up' || combo.last === 'down') &&
                    combo.current === 'space'
                ) {
                    return combo.event.preventDefault();
                }

                if (combo.current === 'space') {
                    return combo.event.stopPropagation();
                }

                if (this.props.onKeyDown) {
                    this.props.onKeyDown(combo.event);
                }
            });
        }

        componentWillReceiveProps(nextProps) {
            const {
                availableItems: currentAvailableItems,
                shouldShowLoading: currentShouldShowLoading,
            } = this.props;
            const {
                availableItems: nextAvailableItems,
                shouldShowLoading: nextShouldShowLoading,
            } = nextProps;
            if (
                ((!currentAvailableItems || !currentAvailableItems.length) &&
                    nextAvailableItems &&
                    nextAvailableItems.length) ||
                (currentShouldShowLoading && !nextShouldShowLoading)
            ) {
                this.setState({ isInitializingData: false });
                //If the input has opened at least once, we know that the data was initialized
                if (this.state.hasOpened) {
                    this.setState({ isDataInitialized: true });
                }
            }
        }

        componentWillUnMount() {
            if (this.onNeedDataObserver) {
                this.onNeedDataObserver.unsubscribe();
            }
            if (this.keyComboObserver) {
                this.keyComboObserver.unsubscribe();
            }
            if (this.requestReceiveDataCoordinator) {
                this.requestReceiveDataCoordinator.unsubscribe();
            }
        }

        getLoadMoreMenuItem() {
            const { isEnabled } = this.props;
            return (
                <DropDownMenuItem
                    key='select-list-load-more-item'
                    isEnabled={isEnabled}
                    onClick={this.loadAdditionalItems}
                    id={`${this.props.id}__select-list-load-more-item`}
                >
                    <Button
                        id={`${this.props.id}__select-list-load-more-item--load-more-button`}
                        isFlat
                        type={BUTTON_TYPE.PRIMARY}
                    >
                        {Strings.loadMore}
                    </Button>
                </DropDownMenuItem>
            );
        }

        loadAdditionalItems(event) {
            event.preventDefault();
            if (this.props.onReadyForMoreData) {
                // Sets a 0ms timeout on loading more data so the rest of the event proccessing can finish up.
                // This ensures that the open menu doesn't close automatically when the loaded set is the last
                // page of data. Specifically it's meant to ensure that, when the click event is handled in
                // DropDownMenuComponent's clickAwaySubscription, the "load more" button is still in the menu
                // and so the handler knows not to close the menu.  Otherwise, if the data is retrieved before
                // then, the button could already be removed by the time that handler gets hit.
                setTimeout(() => {
                    this.props.onReadyForMoreData();
                }, 0);
            }
        }

        render() {
            const { isMoreDataAvailable, onReadyForMoreData } = this.props;
            const staticLastMenuItems = [];
            // If there are more items available, and there's a function passed to retrieve those items,
            // display the "Load More" button at the bottom of the drop-down
            if (isMoreDataAvailable && onReadyForMoreData) {
                staticLastMenuItems.push(this.getLoadMoreMenuItem());
            }
            return (
                <WrappedSelectListComponent
                    {...this.props}
                    {...this.state}
                    onNeedData={this.onNeedData}
                    staticLastMenuItems={staticLastMenuItems}
                    isRetrievingData={
                        (this.props.shouldShowLoading ||
                            this.state.isInitializingData) &&
                        !this.state.isDataInitialized
                    }
                    onSearchInputKeyDown={this.onKeyCombo}
                    clearSearch={this.clearSearch}
                    onLabelMouseEnter={this.onMouseEnter}
                    onLabelMouseLeave={this.onMouseLeave}
                    onLabelClick={this.onClick}
                    onToggle={this.onToggle}
                    getItemDisplay={this.getItemDisplay}
                    getItemTooltip={this.getItemTooltip}
                    className={this.getClassName(this.props, this.state)}
                    renderReadOnly={this.renderReadOnly}
                />
            );
        }
    }

    return GenericSelectListComponent as any;
};
