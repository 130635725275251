import React from 'react';
import Immutable from 'seamless-immutable';
import ttdForm from '../../../hocs/ttdForm';
import { autoFocus } from '../../../../util/AutoFocus';
import HelpContext from '../../../contexts/HelpContext';

import './TaskDialog.scss';

// A higher-order-component that wraps a given component with some
// helpful behavior.
//
// In particular, this HOC creates components that are compatible with the TaskDialog.
// It wraps the content of the component in a form, and wraps that in a ttdForm that will
// not destroy itself on submission (because the task dialog controls the destruction
// of the redux-form data).  It also allows for custom validation and warning callbacks.

export default function ttdTaskDialogPage(
    formId,
    uiModel,
    validate,
    warn,
    onChange
) {
    class ResultClass extends React.Component {
        static uniqueName = formId;
        static uiModel = uiModel;

        static validate(service, model) {
            if (validate) {
                validate(service, model);
            }
        }

        static warn(service, model) {
            if (warn) {
                warn(service, model);
            }
        }

        container: any;

        componentDidMount() {
            // timeout to allow the dialog to appear
            setTimeout(() => {
                if (this.container) {
                    // To make use of this auto-focusing, add an `autoFocus` class to the input in a
                    // TaskDialog that should receive focus on load (further documentation in `util/AutoFocus.js`).
                    // By default, any text currently in the input will be selected (e.g. when renaming a bid list);
                    // if there is no text, the input will just receive focus.
                    autoFocus(this.container, true);
                }
            }, 500);
        }

        render() {
            const formPropKeys = [
                'anyTouched',
                'asyncValidating',
                'change',
                'dirty',
                'initialized',
                'invalid',
                'pristine',
                'submitting',
                'submitFailed',
                'submitSucceeded',
                'valid',
                'pure',
            ];
            const formPropsToPass = formPropKeys.reduce(
                (propsToPass, p) => propsToPass.set(p, this.props[p]),
                Immutable({})
            );
            const childrenWithFormProps = React.Children.map(
                this.props.children,
                (child: any) =>
                    !child ||
                    !child.type ||
                    (child.type &&
                        child.type.$$typeof !==
                            Symbol.for('react.forward_ref') &&
                        (!React.isValidElement(child) ||
                            typeof child.type !== 'function'))
                        ? child
                        : React.cloneElement(child, formPropsToPass)
            );

            return (
                <HelpContext.Provider value={`help-context__${formId}`}>
                    <div
                        className='task-dialog__page'
                        ref={(e) => {
                            this.container = e;
                        }}
                    >
                        {childrenWithFormProps}
                    </div>
                </HelpContext.Provider>
            );
        }
    }

    return ttdForm(ResultClass, false /* destroyOnUnmount */, onChange);
}
