/**
 * This is a natural sort comparer which provides a corresponding impl in C#
 * https://en.wikipedia.org/wiki/Natural_sort_order
 * ../../Server/Shared/Services/NaturalComparer.cs
 *
 * Intl.Collator was considered for this purpose, however the way it treats
 * non-word chars e.g. ]_-"... made it difficult to create an efficient
 * corresponding impl in C#. This impl has been perf tested across 10k records
 * and has equivalent perf to the Collator with transparency in the sorting
 * logic, making a corresponding impl in C# straightforwards.
 */

const ints = {
    '0': true,
    '1': true,
    '2': true,
    '3': true,
    '4': true,
    '5': true,
    '6': true,
    '7': true,
    '8': true,
    '9': true,
};

function compare(a, b) {
    a = !a ? '' : a;
    b = !b ? '' : b;

    // Memo length to save operations
    const aLen = a.length;
    const bLen = b.length;

    // No length or no string wins
    if (!aLen && bLen) return -1;
    if (aLen && !bLen) return 1;
    if (!aLen && !bLen) return 0;

    let i;
    let ai;
    let bi;

    // Unless we find an int we can use string compare
    for (i = 0; i < aLen && i < bLen; ++i) {
        ai = a[i];
        bi = b[i];

        if (ints[ai] || ints[bi]) break;
        if (ai !== bi) return bi < ai ? 1 : ai < bi ? -1 : 0;
    }

    // Shortest string wins
    if (aLen <= i && i <= bLen) return -1;
    if (bLen <= i && i <= aLen) return 1;

    // Ints beat strings
    if (ints[ai] && !ints[bi]) return -1;
    if (!ints[ai] && ints[bi]) return 1;

    // The extractor assumes we have an int at the index
    const [aDigit, aExtra] = extractIntAtIndex(a, i);
    const [bDigit, bExtra] = extractIntAtIndex(b, i);

    // Largest sig figures wins
    if (aDigit.length < bDigit.length) return -1;
    if (bDigit.length < aDigit.length) return 1;

    if (aDigit !== bDigit) {
        const aInt = parseInt(aDigit, 10);
        const bInt = parseInt(bDigit, 10);
        return bInt < aInt ? 1 : aInt < bInt ? -1 : 0;
    }

    return compare(aExtra, bExtra);
}

function extractIntAtIndex(str, start) {
    const sLen = str.length;

    // Skip leading zeros
    while (str[start] === '0' && start < sLen) start++;

    let i = start;
    while (ints[str[i]]) i++;

    const digit = i === start ? '0' : str.substring(start, i);
    const extra = sLen <= i ? '' : str.substring(i);
    return [digit, extra];
}

export default compare;
